import actions from '@store/project/delivery/delivery-actions'
import getters from '@store/project/delivery/delivery-getters'
import mutations from '@store/project/delivery/delivery-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/delivery/delivery-module
 */

/**
 * Get default state for deliveries module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the delivery module.
   * @property  {boolean} loading.deliveries - State for the project deliveries loader.
   * @property  {boolean} loading.videoProductElements - State for the video product elements loader.
   * @property  {boolean} loading.videoProducts - State for the project video products loader.
   * @property  {object}  deliveries - The project deliveries object data.
   * @property  {array}   deliveries.data - Array of objects of project video deliveries.
   * @property  {object}  deliveries.meta - The video deliveries meta.
   * @property  {number}  deliveries.meta.current_page - The current page of the video deliveries.
   * @property  {number}  deliveries.meta.from - The video deliveries from page.
   * @property  {number}  deliveries.meta.last_page - The video deliveries last page.
   * @property  {number}  deliveries.meta.per_page - The video deliveries per page.
   * @property  {number}  deliveries.meta.to - The video deliveries to page.
   * @property  {number}  deliveries.meta.total - The total count of video deliveries.
   * @property  {array}   videoProducts - State for the project video products.
   * @property  {number}  selectedVideoProductId - The ID of the selected video product
   * @property  {object}  projectForm - Details containing all info related to the project form.
   * @property  {boolean} projectForm.addNewFormOpen - The state of the add new form
   * @property  {boolean} projectForm.editFormOpen - The state of the edit form
   * @property  {array}   projectForm.videoProductElements - The available video product elements for the selected video product
   * @property  {number}  projectForm.selectedVideoDeliveryJobId - The selected video delivery job ID
   * @property  {boolean} selectAllDeliveries - State for checking if all deliveries were selected
   * @property  {array}   selectedDeliveryIds - Array of selected delivery ids
   * @property  {object}  modal - Object of modal options.
   * @property  {object}  modal.videoPlayer - Object of video player modal options.
   * @property  {boolean} modal.videoPlayer.show - State of video player modal if shown.
   * @property  {number}  selectedDeliveryId - Id of delivery modal that should be opened.
   * @property  {object}  deliveriesPage - The deliveries page object
   * @property  {object}  deliveriesPage.filters - The deliveries page's filters object
   * @property  {string}  deliveriesPage.filters.sort - The state for sort column.
   * @property  {string}  deliveriesPage.filters.sortBy - The state for sort by type.
   * @property  {string}  deliveriesPage.filters.search - The state for search against the deliveries list.
   */
  return {
    loading: {
      deliveries: false,
      videoProductElements: false,
      holdDeliveries: false,
      videoProducts: false,
    },
    deliveries: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      },
    },
    deliveriesPage: {
      filters: {
        sort: 'desc',
        sortBy: 'created_at',
        search: '',
      },
    },
    videoProducts: [],
    selectedVideoProductId: null,
    projectForm: {
      addNewFormOpen: false,
      editFormOpen: false,
      videoProductElements: [],
      selectedVideoDeliveryJobId: null,
    },
    selectAllDeliveries: false,
    selectedDeliveryIds: [],
    modal: {
      bulkAddVideos: {
        confirm: false,
        show: false,
        amount: 0,
        names: [],
      },
      videoPlayer: {
        show: false,
      },
    },
    selectedDeliveryId: null,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_DELIVERY_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
