export default Object.freeze({
  invoiceStatuses: {
    XERO_INVOICE_STATUS_ID_NEW: 1,
    XERO_INVOICE_STATUS_ID_PREPROCESSED: 2,
    XERO_INVOICE_STATUS_ID_VERIFYING: 3,
    XERO_INVOICE_STATUS_ID_SENT: 4,
    XERO_INVOICE_STATUS_ID_PAID: 5,
    XERO_INVOICE_STATUS_ID_ERROR: 6,
    XERO_INVOICE_STATUS_ID_RECONCILED: 7,
  },
  orderInvoiceStatuses: {
    ORDER_INVOICED: 1,
    ORDER_NOT_INVOICED: 2,
    ORDER_UNDER_100_INVOICING: 3,
    ORDER_UNDER_50_INVOICING: 4,
    ORDER_50_INVOICED: 5,
  },
  paymentTerms: {
    PAYMENT_TERM_ID_7_DAYS: 1,
    PAYMENT_TERM_ID_14_DAYS: 2,
    PAYMENT_TERM_ID_30_DAYS: 3,
    PAYMENT_TERM_ID_45_DAYS: 4,
    PAYMENT_TERM_ID_90_DAYS: 5,
  },
  orderInvoiceTerms: {
    ORDER_INVOICING_TERM_ID_FORTNIGHTLY: 1,
    ORDER_INVOICING_TERM_ID_100_PERCENT_ON_ORDER_SUBMISSION: 2,
    ORDER_INVOICING_TERM_ID_50_PERCENT_UPFRONT_AND_COMPLETION: 3,
    ORDER_INVOICING_TERM_ID_AFTER_FILMING: 4,
    ORDER_INVOICING_TERM_ID_AFTER_EDITING: 5,
    ORDER_INVOICING_TERM_ID_AFTER_KEY_DELIVERABLE: 6,
  },
  clientTerms: {
    OFFICE_CLIENT_TERM_FORTNIGHTLY: 1,
    OFFICE_CLIENT_TERM_BY_ORDER: 2,
  },
  orderStatuses: {
    ORDER_ACTIVE: 1,
    ORDER_QUOTE: 2,
    DRAFT_ORDER: 3,
    DRAFT_CREDIT_ORDER: 4,
    ORDER_CANCELLED: 5,
  },
  creditStatuses: {
    CREDIT_STATUS_ID_ACTIVE: 1,
    CREDIT_STATUS_ID_PENDING: 2,
    CREDIT_STATUS_ID_EXPIRED: 3,
  },
  projectStatuses: {
    PROJECT_STATUS_ID_DRAFT: 1,
    PROJECT_STATUS_ID_ACTIVE: 2,
    PROJECT_STATUS_ID_PRE_PRODUCTION: 3,
    PROJECT_STATUS_ID_FILMING: 4,
    PROJECT_STATUS_ID_POST_PRODUCTION: 5,
    PROJECT_STATUS_ID_VIDEO_DELIVERED: 6,
    PROJECT_STATUS_ID_WAITING_APPROVAL: 7,
    PROJECT_STATUS_ID_CHANGES: 8,
    PROJECT_STATUS_ID_APPROVED: 9,
    PROJECT_STATUS_ID_ARCHIVED: 10,
    PROJECT_STATUS_ID_ON_HOLD: 11,
    PROJECT_STATUS_ID_CANCELLED: 12,
  },
  orderJobStatuses: {
    ORDER_JOB_BOOKED: 1,
    ORDER_JOB_DELIVERED: 2,
    ORDER_JOB_CHANGES: 3,
    ORDER_JOB_POST_PRODUCTION: 4,
    ORDER_JOB_ARCHIVED: 5,
    ORDER_JOB_APPROVED: 6,
    ORDER_JOB_ON_YOUTUBE: 7,
    ORDER_JOB_CHANGES_UPLOADED: 8,
    ORDER_JOB_VIDEO_UPLOADED: 9,
    ORDER_JOB_CHANGES_SCHEDULED: 10,
    ORDER_JOB_ON_HOLD: 11,
  },
  productTypes: {
    PRODUCT_TYPE_CREATE: 1,
    PRODUCT_TYPE_MADE: 2,
    PRODUCT_TYPE_MISCELLANEOUS: 3,
    PRODUCT_TYPE_DATACREATIVE: 4,
    PRODUCT_TYPE_PACKAGES: 5,
    PRODUCT_TYPE_SOCIAL_MEDIA: 6,
    PRODUCT_TYPE_SERIES: 8,
    PRODUCT_TYPE_PRE_PURCHASED_CREDIT: 9,
    PRODUCT_TYPE_INVOICING: 10,
    PRODUCT_TYPE_BRAND_STORIES: 11,
    PRODUCT_TYPE_ANIMATION: 12,
    PRODUCT_TYPE_PRODUCT: 13,
    PRODUCT_TYPE_EVENTS: 14,
    PRODUCT_TYPE_TVC: 15,
    PRODUCT_TYPE_CASE_STUDIES_AND_TESTIMONIALS: 16,
    PRODUCT_TYPE_LIVE_STREAMING: 17,
    PRODUCT_TYPE_TRAINING_AND_HR: 18,
    PRODUCT_TYPE_INTERACTIVE: 19,
    PRODUCT_TYPE_VIDEO_AI: 20,
  },
  videoPrivacyOptions: {
    VIDEO_PRIVACY_PRIVATE: 0,
    VIDEO_PRIVACY_PUBLIC: 1,
  },
  userRoles: {
    USER_ROLE_ID_SUPER_ADMIN: 1,
    USER_ROLE_ID_ADMIN: 2,
    USER_ROLE_ID_ACCOUNT_MANAGER: 3,
    USER_ROLE_ID_PRODUCTION: 4,
    USER_ROLE_ID_ACCOUNTS: 5,
    USER_ROLE_ID_MEDIA_COORDINATOR: 6,
    USER_ROLE_ID_SHOOTER: 7,
    USER_ROLE_ID_EDITOR: 8,
    USER_ROLE_ID_EQUIPMENT_MANAGER: 9,
    USER_ROLE_ID_PRODUCER: 10,
    USER_ROLE_ID_DIRECTOR: 11,
    USER_ROLE_ID_CLIENT: 12,
    USER_ROLE_ID_FRONTEND_DEVELOPER: 13,
    USER_ROLE_ID_BACKEND_DEVELOPER: 14,
    USER_ROLE_ID_SALES_DEVELOPMENT_REPRESENTATIVE: 17,
    USER_ROLE_ID_ANIMATOR: 18,
    USER_ROLE_ID_PRE_PRODUCTION: 19,
    USER_ROLE_ID_ACCOUNTING: 21,
  },
  eventTypes: {
    EVENT_TYPE_ID_CAMERA_OPERATOR: 1,
    EVENT_TYPE_ID_EDIT: 2,
    EVENT_TYPE_ID_CHANGE: 3,
    EVENT_TYPE_ID_SECOND_CAMERA_OPERATOR: 4,
    EVENT_TYPE_ID_CAMERA_ASSISTANT: 5,
    EVENT_TYPE_ID_FIELD_PRODUCER: 6,
    EVENT_TYPE_ID_MODELS_TALENT: 7,
    EVENT_TYPE_ID_DRONE: 8,
    EVENT_TYPE_ID_PRE_PRODUCTION: 9,
    EVENT_TYPE_ID_SCRIPT_COPY_WRITING: 10,
    EVENT_TYPE_ID_STORYBOARD: 11,
    EVENT_TYPE_ID_VOICE_OVER: 12,
    EVENT_TYPE_ID_TRANSLATIONS: 13,
    EVENT_TYPE_ID_CAPTIONS: 14,
    EVENT_TYPE_ID_TRANSCRIBING: 15,
    EVENT_TYPE_ID_NOT_AVAILABLE: 16,
    EVENT_TYPE_ID_STUDIO: 17,
    EVENT_TYPE_ID_ADDITIONAL_CAMERA: 18,
    EVENT_TYPE_ID_FRONT_END_DEVELOPMENT_TIME: 19,
    EVENT_TYPE_ID_BACK_END_DEVELOPMENT_TIME: 20,
    EVENT_TYPE_ID_FRONT_END_VIDEO_BUILD: 21,
    EVENT_TYPE_ID_BACK_END_CSV: 22,
    EVENT_TYPE_ID_BACK_END_API: 23,
    EVENT_TYPE_ID_EDM_W_HTML: 24,
    EVENT_TYPE_ID_EDM_W_O_HTML: 25,
    EVENT_TYPE_ID_LANDING_PAGE_W_HTML: 26,
    EVENT_TYPE_ID_LANDING_PAGE_W_O_HTML: 27,
    EVENT_TYPE_ID_DATA_CLEANING: 28,
    EVENT_TYPE_ID_TESTING: 29,
    EVENT_TYPE_ID_PROJECT_MANAGEMENT: 30,
    EVENT_TYPE_ID_TRAVEL: 31,
    EVENT_TYPE_ID_MISCELLANEOUS: 32,
    EVENT_TYPE_ID_VIDEO_SENDS: 33,
    EVENT_TYPE_ID_ANIMATION: 34,
    EVENT_TYPE_ID_NON_BILLABLE: 35,
    EVENT_TYPE_ID_CHANGE_MANAGEMENT: 36,
    EVENT_TYPE_ID_SHOOTER_PRE_PRODUCTION: 37,
    EVENT_TYPE_ID_DIRECTOR: 38,
    EVENT_TYPE_ID_COLOUR_GRADING: 39,
    EVENT_TYPE_ID_DIRECTOR_OF_PHOTOGRAPHY: 40,
    EVENT_TYPE_ID_GAFFER: 41,
    EVENT_TYPE_ID_GRIP: 42,
    EVENT_TYPE_ID_SOUND_ENGINEER: 43,
    EVENT_TYPE_ID_PHOTOGRAPHER: 44,
    EVENT_TYPE_ID_ASSISTANT: 45,
    EVENT_TYPE_ID_RUNNER: 46,
    EVENT_TYPE_ID_PRE_PRODUCTION_CHANGE: 47,
    EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT: 48,
    EVENT_TYPE_ID_BAMBOO_EVENT: 49,
    EVENT_TYPE_ID_PUBLIC_HOLIDAYS: 50,
  },
  eventStatuses: {
    EVENT_STATUS_ID_SCHEDULED: 1,
    EVENT_STATUS_ID_UNSCHEDULED: 2,
    EVENT_STATUS_ID_ON_HOLD: 3,
  },
})
