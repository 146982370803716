import * as types from '@store/mutation-types'

/**
 * @module store/project/attachment/attachment-mutations
 */
export default {
  /**
   * Update loader for the project files
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_PROJECT_FILES](state, loading) {
    state.loading.projectFiles = loading
  },

  /**
   * Update loader for the project brand hub
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_BRAND_HUB](state, loading) {
    state.loading.brandHub = loading
  },

  /**
   * Update the project files list.
   * @param {object} state - the state of the current module.
   * @param {array} list - the array of the project files
   */
  [types.PROJECT_FILES_LIST](state, list) {
    state.projectFiles = list
  },

  /**
   * Update the project brand hub list.
   * @param {object} state - the state of the current module.
   * @param {array} list - the array of the project files
   */
  [types.PROJECT_BRAND_HUB_LIST](state, list) {
    state.brandHub = list
  },
}
