import Axios from '@plugins/axios'

export default (projectId, invoiceId) => {
  let url = `/api/v2/internal/projects/${projectId}/invoices/${invoiceId}`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.delete(url, config)
}
