import Axios from '@plugins/axios'

export default (projectId, orderJobId, payload) => {
  let url = `api/v2/internal/projects/${projectId}/jobs/shoots/${orderJobId}/call-sheet`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.put(url, payload, config)
}
