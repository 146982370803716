import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'

/**
 * Store actions for videos module.
 * @module videos/actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.VIDEOS_RESET_STATE)
  },

  /**
   * Action to set the video tags loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoTagsLoading({ commit }, loading) {
    commit(mutations.VIDEOS_LOADING_VIDEO_TAGS, loading)
  },

  /**
   * Action to set the video styles loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoStylesLoading({ commit }, loading) {
    commit(mutations.VIDEOS_LOADING_VIDEO_STYLES, loading)
  },

  /**
   * Action to set the video industries loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoIndustriesLoading({ commit }, loading) {
    commit(mutations.VIDEOS_LOADING_VIDEO_INDUSTRIES, loading)
  },

  /**
   * Action to set the video information loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoInformationLoading({ commit }, loading) {
    commit(mutations.VIDEOS_LOADING_VIDEO_INFORMATION, loading)
  },

  /**
   * Get video tags options
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  getVideoTags({ dispatch, commit, rootGetters }, payload = {}) {
    dispatch('setVideoTagsLoading', true)
    ProjectServices.tags.videos
      .list(payload)
      .then((response) => {
        const data =
          response.data.meta.current_page > 1
            ? [...rootGetters['videos/videoTagOptions'], ...response.data.data]
            : response.data.data

        commit(mutations.VIDEOS_VIDEO_TAGS, data)

        dispatch('setVideoTagsLoading', false)
      })
      .catch((error) => {
        dispatch('setVideoTagsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch('toggleVideoInformationShowModal')
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video tags.',
            severity: 'error',
            error: error.response ? error.response : error.message,
          },
          { root: true }
        )
      })
  },

  /**
   * Get video industry options
   *
   * @param context
   */
  getVideoIndustries({ dispatch, commit }) {
    dispatch('setVideoIndustriesLoading', true)

    ProjectServices.industryTypes
      .list()
      .then((response) => {
        commit(mutations.VIDEOS_VIDEO_INDUSTRY, response.data.data)
        dispatch('setVideoIndustriesLoading', false)
      })
      .catch((error) => {
        dispatch('setVideoIndustriesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch('toggleVideoInformationShowModal')
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video industry.',
            severity: 'error',
            error: error.response ? error.response : error.message,
          },
          { root: true }
        )
      })
  },

  /**
   * Get video style options
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  getVideoStyles({ dispatch, commit }, videoId) {
    dispatch('setVideoStylesLoading', true)

    ProjectServices.videos.videoStyles
      .list(videoId)
      .then((response) => {
        commit(mutations.VIDEOS_VIDEO_STYLE, response.data.data)
        dispatch('setVideoStylesLoading', false)
      })
      .catch((error) => {
        dispatch('setVideoStylesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch('toggleVideoInformationShowModal')
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video style.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              video_id: videoId,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Initialize video information modal
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   * @param {number} payload.videoId - the selected project ID to update
   * @param {array} payload.includes - array of include fields
   */
  initializeVideoInformationModal({ dispatch, commit }, payload) {
    dispatch('setVideoInformationLoading', true)
    ProjectServices.videos
      .get(payload)
      .then((response) => {
        dispatch('getVideoIndustries')
        dispatch('getVideoTags')
        dispatch('getVideoStyles', payload.videoId)
        commit(
          mutations.VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO_ID,
          response.data.data.id
        )
        commit(
          mutations.VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('toggleVideoInformationShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video information.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              video_id: payload.videoId,
            },
          },
          { root: true }
        )
      })
      .finally(() => {
        dispatch('setVideoInformationLoading', false)
      })
  },

  /**
   * Toggle video information modal show.
   * @param id - selected delivery id to be opened with default null
   * @param commit
   */
  toggleVideoInformationShowModal({ commit }, id = null) {
    commit(mutations.VIDEOS_VIDEO_INFORMATION_TOGGLE_SHOW)
    commit(mutations.VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO_ID, id)
  },

  /**
   * Update video delivery additional fields
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  updateVideoInformation({ dispatch }, payload) {
    dispatch(
      'common/displayToastAlert',
      {
        icon: 'info',
        title: `Update is being processed. We will notify you when ready.`,
      },
      { root: true }
    )

    dispatch('toggleVideoInformationShowModal')

    ProjectServices.videos
      .update(payload.videoId, payload)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `#${payload.videoId} update was successful!. `,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch('toggleVideoInformationShowModal')
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating video details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              video_id: payload.videoId,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update video star
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  updateVideoStar({ dispatch, rootGetters }, payload) {
    return new Promise((resolve) => {
      ProjectServices.videos
        .updateStar(payload.videoId, payload)
        .then((response) => {
          let toastString = ''

          if (response.data.data.is_starred) {
            toastString = `${response.data.data.title} is now a hero video.`

            dispatch(
              'project/setTotalHeroVideos',
              rootGetters['project/projectDetails'].total_hero_videos + 1,
              { root: true }
            )
          } else {
            toastString = `${response.data.data.title} has been removed as a hero video`

            dispatch(
              'project/setTotalHeroVideos',
              rootGetters['project/projectDetails'].total_hero_videos - 1,
              { root: true }
            )
          }

          dispatch(
            'common/displayToastAlert',
            {
              title: toastString,
            },
            { root: true }
          )
          resolve()
        })
        .catch((error) => {
          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: 'Error updating video star.',
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: {
                video_id: payload.videoId,
              },
            },
            { root: true }
          )
        })
    })
  },

  /**
   * Toggle videos preview description modal.
   *
   * @param {Object} context
   */
  toggleVideoPreviewDescriptionShowModal({ commit }) {
    commit(mutations.VIDEOS_MODAL_PREVIEW_DESCRIPTION_TOGGLE_SHOW)
  },

  /**
   * Toggle filters modal
   *
   * @param {Object} context
   * @param {String}} selectedFilterModal - the selected filter modal to show
   */
  toggleFilterListShowModal({ commit }, selectedFilterModal) {
    commit(mutations.VIDEOS_MODAL_FILTER_LIST_TOGGLE_SHOW, selectedFilterModal)
  },

  /**
   * Update favourite video of user.
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  updateFavouriteVideo({ dispatch }, payload) {
    return new Promise((resolve) => {
      ProjectServices.videos.favourite
        .update(payload.videoId, payload)
        .then(() => {
          const toastString = payload.is_favourite
            ? `Successfully marking video as favourite.`
            : `Successfully remove video from favourites`

          dispatch(
            'common/displayToastAlert',
            {
              title: toastString,
            },
            { root: true }
          )
          resolve()
        })
        .catch((error) => {
          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: 'Error updating video as favourite.',
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: {
                video_id: payload.videoId,
              },
            },
            { root: true }
          )
        })
    })
  },

  /**
   * Toggle the display state of video library filters
   *
   * @param context
   */
  toggleVideoLibraryFiltersShow({ commit }) {
    commit(mutations.VIDEOS_VIDEO_LIBRARY_FILTERS_TOGGLE_SHOW)
  },
}
