import Axios from '@plugins/axios'

export default (projectId, payload, customIncludes) => {
  let url = `/api/v2/internal/projects/${projectId}/events/shoots`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }
  return Axios.post(url, payload, config)
}
