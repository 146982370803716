import Axios from '@plugins/axios'

export default (videoId) => {
  let url = `/api/v2/internal/videos/${videoId}/video-styles`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.get(url, config)
}
