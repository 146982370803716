import Axios from '@plugins/axios'

export default (projectId, orderJobId, customIncludes, payload, perPage) => {
  let url = `/api/v2/internal/projects/${projectId}/jobs/shoots/${orderJobId}`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  if (perPage) {
    config.params.per_page = perPage
  }

  return Axios.put(url, payload, config)
}
