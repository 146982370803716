import * as types from '@store/mutation-types'

/**
 * @module store/common/mutations
 */

export default {
  /**
   * Update the window size
   * @param {Object} state - This module state.
   * @param {Number} payload.height - the new window height
   * @param {Number} payload.width - the new window width
   */
  [types.COMMON_WINDOW_RESIZED](state, payload) {
    state.window.height = payload.height
    state.window.width = payload.width
  },

  /**
   * Update the pagination per page limit
   * @param {Object} state - This module state.
   * @param {Number} perPage - the perPage limit
   */
  [types.COMMON_PAGINATION_PER_PAGE](state, perPage) {
    state.pagination.perPage = perPage
  },

  /**
   * Update the pagination page selected
   * @param {Object} state - This module state.
   * @param {Number} page - the page selected
   */
  [types.COMMON_PAGINATION_SELECTED_PAGE](state, page) {
    state.pagination.selectedPage = page
  },

  /**
   * Update the side menu open state
   * @param {Object} state - This module state.
   */
  [types.COMMON_SIDE_MENU_TOGGLE](state) {
    state.sideMenu.isOpen = !state.sideMenu.isOpen
  },

  /**
   * Update the side menu selected
   * @param {Object} state - This module state.
   * @param {String} selectedMenu - the menu selected
   */
  [types.COMMON_SIDE_MENU_SELECTED](state, selectedMenu) {
    state.sideMenu.selected = selectedMenu
  },
}
