import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'

/**
 * @module store/role/actions
 */

/**
 * Store actions for role module.
 * @module role/actions
 */
export default {
  /**
   * Reset state for current store module.
   * @param {object} - Context
   */
  resetState({ commit }) {
    commit(mutations.ROLE_RESET_STATE)
  },

  /**
   * Action to set the external roles loading state
   * @param commit
   * @param {object} loading - the loading state
   */
  setExternalRolesLoading({ commit }, loading) {
    commit(mutations.ROLE_LOADING_EXTERNAL_ROLES, loading)
  },

  /**
   * Action to get the external roles
   * @param commit
   * @param payload
   */
  getExternalRoles({ commit, dispatch }, payload) {
    dispatch('setExternalRolesLoading', true)

    ApiServices.roles
      .external(payload.includes)
      .then((response) => {
        commit(mutations.ROLE_EXTERNAL_ROLES, response.data.data)
        dispatch('setExternalRolesLoading', false)
      })
      .catch((error) => {
        dispatch('setExternalRolesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching external roles',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },
}
