import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/projects/${payload.projectId}/jobs/shoots/${payload.orderJobId}/call-sheet`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.get(url, config)
}
