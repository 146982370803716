import projectRoutes from './projects/projects-routes'
import authRoutes from './auth/auth-routes'
import errorRoutes from './error/error-routes'
import videoRoutes from './videos/video-routes'
import mediaManagerRoutes from './media-manager/media-manager-routes'
import calendarRoutes from './calendar/calendar-routes'

const miscRoutes = [
  {
    path: '*',
    redirect: { name: 'error.404' },
  },

  {
    path: '/',
    redirect: { name: 'auth.vms.token' },
  },
]

export default [
  ...miscRoutes,
  ...errorRoutes,
  ...authRoutes,
  ...projectRoutes,
  ...videoRoutes,
  ...mediaManagerRoutes,
  ...calendarRoutes,
]
