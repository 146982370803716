import * as types from '@store/mutation-types'

/**
 * @module store/footage-status-type/footage-status-type-mutations
 */

export default {
  /**
   * Update the footage status types list.
   *
   * @param state
   * @param {array} footageStatusTypes - the footage status types list
   */
  [types.FOOTAGE_STATUS_TYPES_LIST](state, footageStatusTypes) {
    state.footageStatusTypes = footageStatusTypes
  },

  /**
   * Update the footage status types loading state.
   *
   * @param state
   * @param {boolean} isLoading - the footage status types loading state
   */
  [types.FOOTAGE_STATUS_TYPES_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
}
