import actions from './calendar-actions'
import getters from './calendar-getters'
import mutations from './calendar-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/calendar
 */

/**
 * Get default state for calendar module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {array} selectedFilters - List of selected filters.
   * @property {object} selectedSavedFilter - Selected saved filter.
   * @property {array} selectedOwnedByIdFilters - Selected owned by id filters.
   * @property {object} filtersList - List of filters.
   * @property {array} filtersList.savedFilters - List of saved filters.
   * @property {array} filtersList.teamFilters - List of team filters.
   * @property {array} filtersList.eventFilters - List of event filters.
   * @property {array} filtersList.crewFilters - List of crew filters.
   * @property {array} filtersList.projectOwnerFilters - List of project owner filters.
   * @property {object} searchFiltersResult - Search filters result.
   * @property {object} modal - Modal state.
   * @property {boolean} modal.showAllFiltersModal - Show all filters modal.
   * @property {boolean} modal.saveFiltersModal - Save filters modal.
   * @property {boolean} modal.onHoldEventsModal - On hold events modal.
   * @property {boolean} modal.deletedEventsModal - Deleted events modal.
   * @property {boolean} modal.showEventsModal - Show events modal.
   * @property {boolean} modal.showEventsPlaceholdersCreateModal - Show events placeholders create modal.
   * @property {boolean} modal.showEventsDeliveriesCreateModal - Show events deliveries create modal.
   * @property {boolean} modal.showEventsFilmingDetailsCreateModal - Show events filming details create modal.
   * @property {boolean} modal.showEventsPreProductionsCreateModal - Show events pre productions create modal.
   * @property {boolean} modal.showEventsPlaceholdersEditModal - Show events placeholders edit modal.
   * @property {boolean} modal.showEventsDeliveriesEditModal - Show events deliveries edit modal.
   * @property {boolean} modal.showEventsFilmingDetailsEditModal - Show events filming details edit modal.
   * @property {boolean} modal.showEventsJobChangesEditModal - Show events job changes edit modal.
   * @property {boolean} modal.showEventsPreProductionsEditModal - Show events pre productions edit modal.
   * @property {boolean} modal.showEventsPreProductionChangesEditModal - Show events pre production changes edit modal.
   * @property {boolean} modal.showEventsBulkEditModal - Show bulk edit events modal.
   * @property {boolean} modal.showMobileAgendaModal - Show mobile agenda events modal.
   * @property {object} loading - Loading state.
   * @property {boolean} loading.eventsList - Events list.
   * @property {boolean} loading.eventDetails - Event details.
   * @property {boolean} loading.agendaList - Agenda list.
   * @property {boolean} loading.eventTypes - Event types.
   * @property {boolean} loading.savedFilters - Saved filters.
   * @property {boolean} loading.onHoldEvents - On hold events.
   * @property {boolean} loading.selectedOnHoldEvent - Selected on hold event.
   * @property {boolean} loading.eventForm - Event form.
   * @property {boolean} loading.projectsList - Projects list.
   * @property {boolean} loading.unHoldEvent - Loading state for unhold event.
   * @property {boolean} loading.deletedEvents - Loading state for deleted event.
   * @property {object} onHoldEvents - On hold events.
   * @property {array} onHoldEvents.data - On hold events data.
   * @property {object} onHoldEvents.meta - On hold events meta.
   * @property {object} onHoldEventsLastPayload - On hold events last payload.
   * @property {object} deletedEvents - Deleted events.
   * @property {array} eventsList - Events list.
   * @property {object} selectedEvent - Selected event.
   * @property {string} viewType - View type.
   * @property {array} agendaList - Agenda list.
   * @property {number} selectedOnHoldEventId - Selected on hold event id.
   * @property {object} selectedSchedules - Selected schedules.
   * @property {array} projectsList - Projects list.
   * @property {array} eventTypesList - Event types list.
   * @property {object} selectedCalendarDate - Selected calendar date.
   * @property {string} eventsSearchKeyword - Events search keyword
   * @property {object} eventsBulkAction - The events bulk action data
   * @property {array} eventsBulkAction.selectedEvents - Array of selected events
   * @property {boolean} eventsBulkAction.canReassignUser - Check for can reassign users
   * @property {object} eventsDataDateRange - the selected events date range
   * @property {boolean} canShowCalendarProgressBar - the can show calendar progress bar state
   * @property {boolean} showMobileFilterList - the state of mobile filters list modal
   * @property {object} localStorageSelectedFilters - the selected filters from localStorage saved in store
   * @property {object} customEventsListPayload - the custom payload use for events list
   * @property {object} eventActionHistory - the last event action
   */
  return {
    selectedFilters: [],
    selectedSavedFilter: null,
    selectedOwnedByIdFilters: [],
    filtersList: {
      savedFilters: [],
      teamFilters: [],
      eventFilters: [
        {
          label: 'Pre-Production',
          value: 'pre-production',
          id: 'pre-production',
          subFilters: [],
        },
        {
          label: 'Production',
          value: 'production',
          id: 'production',
          subFilters: [],
        },
        {
          label: 'Post Production',
          value: 'post-production',
          id: 'post-production',
          subFilters: [],
        },
        {
          label: 'Other',
          value: 'other',
          id: 'other',
          subFilters: [],
        },
      ],
      crewFilters: [],
      projectOwnerFilters: [],
    },
    searchFiltersResult: null,
    modal: {
      showAllFiltersModal: false,
      saveFiltersModal: false,
      onHoldEventsModal: false,
      deletedEventsModal: false,
      showEventsModal: false,
      // create event modals
      showEventsPlaceholdersCreateModal: false,
      showEventsDeliveriesCreateModal: false,
      showEventsFilmingDetailsCreateModal: false,
      showEventsPreProductionsCreateModal: false,
      // edit event modals
      showEventsPlaceholdersEditModal: false,
      showEventsDeliveriesEditModal: false,
      showEventsFilmingDetailsEditModal: false,
      showEventsJobChangesEditModal: false,
      showEventsPreProductionsEditModal: false,
      showEventsPreProductionChangesEditModal: false,
      showEventsBulkEditModal: false,
      // mobile agenda modals
      showMobileAgendaModal: false,
    },
    loading: {
      eventsList: false,
      eventDetails: false,
      agendaList: false,
      eventTypes: false,
      savedFilters: false,
      onHoldEvents: false,
      selectedOnHoldEvent: false,
      eventForm: false,
      projectsList: false,
      unHoldEvent: false,
      deletedEvents: false,
    },
    onHoldEvents: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 5,
        to: 1,
        total: 0,
      },
    },
    onHoldEventsLastPayload: {},
    deletedEvents: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 5,
        to: 1,
        total: 0,
      },
    },
    eventTypesList: [],
    eventsList: [],
    selectedEvent: null,
    viewType: 'Week',
    agendaList: [],
    selectedOnHoldEventId: null,
    selectedSchedules: null,
    projectsList: [],
    selectedCalendarDate: null,
    eventsSearchKeyword: '',
    eventsBulkAction: {
      selectedEvents: [],
      canReassignUser: false,
    },
    eventsDataDateRange: null,
    canShowCalendarProgressBar: false,
    showMobileFilterList: false,
    localStorageSelectedFilters: null,
    customEventsListPayload: null,
    eventActionHistory: null,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.CALENDAR_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
