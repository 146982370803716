/**
 * @module store/footage-status-type/footage-status-type-getters
 */

export default {
  /**
   * Get the list of offices
   *
   * @param {object} state - State for current module.
   * @returns {array} - The list of footage status types
   */
  footageStatusTypesList(state) {
    return state.footageStatusTypes
  },

  /**
   * Get the loading state for the footage status types
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state for the footage status types
   */
  isFootageStatusTypesLoading(state) {
    return state.isLoading
  },
}
