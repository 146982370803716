/**
 * @module store/videos/getters
 */

export default {
  /**
   * Get loading state for video tags.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoTagsLoading(state) {
    return state.loading.videoTags
  },

  /**
   * Get loading state for video styles.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoStylesLoading(state) {
    return state.loading.videoStyles
  },

  /**
   * Get loading state for video industries.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoIndustriesLoading(state) {
    return state.loading.videoIndustries
  },

  /**
   * Get loading state for video information.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoInformationLoading(state) {
    return state.loading.videoInformation
  },

  /**
   * Get the state of the modal additional fields video tag options
   *
   * @param {object} state - State for current module.
   * @returns {array} - The state of the modal video tags
   */
  videoTagOptions(state) {
    return state.modal.videoInformation.options.tags
  },

  /**
   * Get the state of the modal additional fields video industry options
   *
   * @param {object} state - State for current module.
   * @returns {array} - The state of the modal video industry
   */
  videoIndustryOptions(state) {
    return state.modal.videoInformation.options.industry
  },

  /**
   * Get the state of the modal additional fields video industry options
   *
   * @param {object} state - State for current module.
   * @returns {array} - The state of the modal video style
   */
  videoStyleOptions(state) {
    return state.modal.videoInformation.options.styles
  },

  /**
   * Get the state of the modal additional fields form show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showVideoInfoModal(state) {
    return state.modal.videoInformation.show
  },

  /**
   * Get state of preview description modal
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean} - state of the preview description modal
   */
  showPreviewDescriptionModal(state) {
    return state.modal.previewDescription
  },

  /**
   * Get state of filter list modal
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean} - state of the filter list modal
   */
  showFilterListModal(state) {
    return state.modal.filterList.show
  },

  /**
   * Get the selected filter type to display on the filter list modal
   *
   * @param {Object} state - State for this module.
   * @returns {String} - the label of the selected filter list modal
   */
  selectedFilterModal(state) {
    return state.modal.filterList.selectedFilterModal
  },

  /**
   * Get selected video id
   *
   * @param {Object} state - State for this module.
   * @returns {number} - the id of selected video.
   */
  selectedModalVideoId(state) {
    return state.modal.videoInformation.selectedVideoId
  },

  /**
   * Get selected video
   *
   * @param {Object} state - State for this module.
   * @returns {Object} - the intance of selected video.
   */
  selectedModalVideo(state) {
    return state.modal.videoInformation.selectedVideo
  },

  /**
   * Get the display state of main video filters
   *
   * @param {Object} state - State for this module
   * @returns {boolean} - the display state of video filters
   */
  showVideoLibraryFilters(state) {
    return state.videoLibraryFilters.show
  },
}
