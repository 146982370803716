import actions from '@store/project/pre-production/pre-production-actions'
import getters from '@store/project/pre-production/pre-production-getters'
import mutations from '@store/project/pre-production/pre-production-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/pre-production/pre-production-module
 */

/**
 * Get default state for pre-production module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {boolean} loading.preProductions - State for project pre-production loader.
   * @property  {boolean} loading.preProductionItem - Loader for pre-production item.
   * @property  {boolean} loading.preProductionChanges - State for the order job changes loader.
   * @property  {boolean} loading.preProductionChangeAttachments - State for the order job change attachments loader.
   * @property  {boolean} loading.preProductionFileVersions - Loader for pre-production file versions.
   * @property  {array}   preProductions - Array of objects of project pre-productions.
   * @property  {object}  projectForm.preProductions - Details related to the project form pre-productions.
   * @property  {array}   projectForm.preProductions.types - An array of pre-production types for use in add/edit pre-production
   * @property  {object}  projectForm.preProductions.selectedType - The selected pre-production type
   * @property  {Number}  projectForm.preProductions.selectedPreProductionId - The selected pre-production ID
   * @property  {boolean} projectForm.preProductions.addNewFormOpen - Check if the pre-production add new form is open
   * @property  {Number}  projectForm.preProductions.activeTabIndex - The active pre-production index. By default, the first pre-production index is selected
   * @property  {Number}  projectForm.preProductions.linkingJobId - The selected shoot or video job id to be linked with new pre-production.
   * @property {array} preProductionFileVersions - An array of pre-production file versions.
   * @property {Object} modal - States for modals related to pre-production.
   * @property {Boolean} modal.fileUpload - Toggle for file upload modal.
   * @property {Boolean} modal.fileVersions - Toggle for file versions modal.
   * @property {Object} changes - Details related to the job changes.
   * @property  {Array}  changes.list - The list of job changes.
   * @property  {Boolean} changes.isListOpen - Check if the job changes list is open.
   * @property  {Boolean} changes.isFormOpen - Check if the job changes form is open.
   * @property  {Object}  changes.selectedPreProductionChange - The active job change.
   * @property  {Number}  changes.selectedPreProductionId - The ID of the selected pre-production order.
   */
  return {
    loading: {
      preProductions: false,
      preProductionItem: false,
      preProductionFileVersions: false,
      preProductionChanges: false,
      preProductionChangeAttachments: false,
      preProductionDetails: false,
    },
    preProductions: [],
    preProductionDetails: null,
    projectForm: {
      preProductions: {
        types: [],
        selectedType: {},
        selectedPreProductionId: null,
        addNewFormOpen: false,
        editFormOpen: false,
        activeTabIndex: 0,
        linkingJobId: null,
      },
    },
    preProductionFileVersions: [],
    modal: {
      fileUpload: false,
      fileVersions: false,
    },
    changes: {
      selectedPreProductionId: '',
      selectedPreProductionChange: null,
      isListOpen: false,
      isFormOpen: false,
      list: [],
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_PRE_PRODUCTION_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
