import { find } from 'lodash'

/**
 * @module store/project/pre-production/pre-production-getters
 */

export default {
  /**
   * Get loading state for project pre-productions.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  preProductionsLoading(state) {
    return state.loading.preProductions
  },

  /**
   * Get loading state for pre-production item.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  preProductionItemLoading(state) {
    return state.loading.preProductionItem
  },

  /**
   * Get loading state for pre-production file versions.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  preProductionFileVersionsLoading(state) {
    return state.loading.preProductionFileVersions
  },

  /**
   * Get project pre-productions
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  preProductions(state) {
    return state.preProductions
  },

  /**
   * Get the pre-production types
   *
   * @param {object} state - State for current module.
   * @returns {object} - The pre-production types
   */
  preProductionTypes(state) {
    return state.projectForm.preProductions.types
  },

  /**
   * Get the selected pre-production type
   *
   * @param {object} state - State for current module.
   * @returns {object} - The selected pre-production type
   */
  selectedPreProductionType(state) {
    return state.projectForm.preProductions.selectedType
  },

  /**
   * Get the active pre-production tab index
   * @param {object} state - State for current module.
   * @returns {Number} - The index of the active tab
   */
  preProductionActiveTabIndex(state) {
    return state.projectForm.preProductions.activeTabIndex
  },

  /**
   * Get the selected pre-production
   *
   * @param {object} state - State for current module.
   * @returns {object} - The selected pre-production
   */
  selectedPreProduction(state) {
    return find(
      state.preProductions[state.projectForm.preProductions.activeTabIndex]
        ?.items,
      { id: state.projectForm.preProductions.selectedPreProductionId }
    )
  },

  /**
   * Get the state of the new pre-production form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the new pre-production form
   */
  preProductionNewFormOpen(state) {
    return state.projectForm.preProductions.addNewFormOpen
  },

  /**
   * Get the state of the edit pre-production form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the edit pre-production form
   */
  preProductionEditFormOpen(state) {
    return state.projectForm.preProductions.editFormOpen
  },

  /**
   * Get the active pre-production items
   *
   * @param {object} state - State for current module.
   * @returns {object} - The active pre-production items
   */
  preProductionActiveTabItems(state) {
    return state.preProductions[state.projectForm.preProductions.activeTabIndex]
  },

  /**
   * Get pre-production file versions.
   *
   * @param {object} state - State for this module.
   * @returns {array}
   */
  preProductionFileVersions(state) {
    return state.preProductionFileVersions
  },

  /**
   * Get show state of file upload modal.
   *
   * @param state
   * @returns {boolean} - show state of the modal
   */
  showFileUploadModal(state) {
    return state.modal.fileUpload
  },

  /**
   * Get show state of file versions modal.
   *
   * @param state
   * @returns {boolean} - show state of the modal
   */
  showFileVersionsModal(state) {
    return state.modal.fileVersions
  },

  /**
   * Get linking job id for new pre production
   *
   * @param {object} state - State for current module.
   * @returns {Number} - The linking job id for new pre-production
   */
  preProductionLinkingJob(state) {
    return state.projectForm.preProductions.linkingJobId
  },

  /**
   * Get the loading state of pre-production changes.
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state of pre-production changes
   */
  isPreProductionChangesLoading(state) {
    return state.loading.preProductionChanges
  },

  /**
   * Get the selected pre-production changes job id
   *
   * @param {object} state - State for current module.
   * @returns {number} - The selected pre-production changes job id
   */
  preProductionChangesSelectedPreProductionId(state) {
    return state.changes.selectedPreProductionId
  },

  /**
   * Get the state of pre-production changes list open
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of pre-production changes list open
   */
  isPreProductionChangesListOpen(state) {
    return state.changes.isListOpen
  },

  /**
   * Get the state of pre-production changes form open
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of pre-production changes form open
   */
  isPreProductionChangesFormOpen(state) {
    return state.changes.isFormOpen
  },

  /**
   * Get the pre-production changes list
   *
   * @param {object} state - State for current module.
   * @returns {array} - The pre-production changes list
   */
  preProductionChangesList(state) {
    return state.changes.list
  },

  /**
   * Get the selected pre-production change
   *
   * @param {object} state - State for current module.
   * @returns {object} - The selected pre-production change
   */
  selectedPreProductionChange(state) {
    return state.changes.selectedPreProductionChange
  },

  /**
   * Get the loading state of pre-production change attachments.
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state of pre-production change attachments
   */
  isPreProductionChangeAttachmentsLoading(state) {
    return state.loading.preProductionChangeAttachments
  },

  /**
   * Get the pre-production details
   *
   * @param {object} state - State for current module.
   * @returns {object} - The pre-production details
   */
  preProductionDetails(state) {
    return state.preProductionDetails
  },

  /**
   * Get the loading state of pre-production details.
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state of pre-production details
   */
  preProductionDetailsLoading(state) {
    return state.loading.preProductionDetails
  },
}
