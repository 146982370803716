import Axios from '@plugins/axios'
import { isNil } from 'lodash'
import VmsConstants from '@configs/vms-constants'
let getEventsListCancelToken

// eslint-disable-next-line complexity
export default (payload) => {
  let url = `/api/v2/internal/events`

  let config = {
    headers: {},
    params: {},
  }

  config.params.all = !isNil(payload.all) ? payload.all : 1

  if (payload.start) {
    config.params.start = payload.start
  }

  if (payload.end) {
    config.params.end = payload.end
  }

  if (payload.per_page) {
    config.params.per_page = payload.per_page
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (payload.project_ids) {
    config.params.project_ids = payload.project_ids
  }

  if (payload.filters) {
    if (payload.filters && payload.filters.unassigned) {
      config.params.unassigned = payload.filters.unassigned
    }

    if (payload.filters && payload.filters.include_freelancer_events) {
      config.params.include_freelancer_events =
        payload.filters.include_freelancer_events
    }

    if (payload.filters && payload.filters.assigned_to_me) {
      config.params.assigned_to_me = payload.filters.assigned_to_me
    }

    if (payload.filters && payload.filters.i_own) {
      config.params.i_own = payload.filters.i_own
    }

    if (payload.filters && payload.filters.event_type_ids) {
      config.params.event_type_ids = payload.filters.event_type_ids
    }

    if (payload.filters && payload.filters.assigned_to_ids) {
      config.params.assigned_to_ids = payload.filters.assigned_to_ids
    }

    if (payload.filters && payload.filters.search_term) {
      config.params.search_term = payload.filters.search_term
    }

    if (payload.filters && payload.filters.include_google_events) {
      config.params.include_google_events =
        payload.filters.include_google_events
    }

    if (payload.filters && payload.filters.include_bamboo_events) {
      config.params.include_bamboo_events =
        payload.filters.include_bamboo_events
    }

    if (payload.filters && payload.filters.owned_by_ids) {
      config.params.owned_by_ids = payload.filters.owned_by_ids
    }
  }

  if (payload.filters && payload.filters.event_status_ids) {
    config.params.event_status_ids = payload.filters.event_status_ids
  } else {
    config.params.event_status_ids = [
      VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
      VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
    ]
  }

  if (payload.filters && payload.filters.deleted_only) {
    config.params.deleted_only = payload.filters.deleted_only
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  // If cancel token exist call cancel function. This will cancel previous request.
  if (getEventsListCancelToken) {
    getEventsListCancelToken.cancel()
  }

  // Create new cancel token for the new request and add it to config.
  // Add notCancelable = true in your payload to make sure it doesn't get cancelled.
  if (!payload.notCancelable) {
    getEventsListCancelToken = Axios.CancelToken.source()
    config.cancelToken = getEventsListCancelToken.token
  }

  return Axios.get(url, config)
}
