export default [
  {
    path: '/projects',
    name: 'projects.list',
    component: () =>
      import(
        /* webpackChunkName: "projects.list" */ '@views/projects/projects-main'
      ),
    meta: {
      title: 'Projects',
    },
  },

  {
    path: '/projects/new',
    name: 'project.create',
    component: () =>
      import(
        /* webpackChunkName: "project.create" */ '@views/projects/new-project/new-project'
      ),
    meta: {
      title: 'New Project',
    },
  },

  {
    path: '/projects/:projectId',
    name: 'project.details',
    component: () =>
      import(
        /* webpackChunkName: "project.details" */ '@views/project/project-main'
      ),
    meta: {
      title: 'Project',
    },
    children: [
      {
        path: 'summary',
        name: 'project.details.summary',
        component: () =>
          import(
            /* webpackChunkName: "project.summary" */ '@views/project/summary/project-summary'
          ),
        meta: {
          title: 'Summary',
          scrollLeft: 0,
        },
      },
      {
        path: 'pre-production',
        name: 'project.details.pre-production',
        component: () =>
          import(
            /* webpackChunkName: "project.pre-production" */ '@views/project/pre-production/project-pre-production'
          ),
        meta: {
          title: 'Pre-Production',
          scrollLeft: 100,
        },
      },
      {
        path: 'filming-details',
        name: 'project.details.filming-details',
        component: () =>
          import(
            /* webpackChunkName: "project.filming-details" */ '@views/project/filming-details/project-filming-details'
          ),
        meta: {
          title: 'Filming Details',
          scrollLeft: 250,
        },
      },
      {
        path: 'deliveries',
        name: 'project.details.deliveries',
        component: () =>
          import(
            /* webpackChunkName: "project.deliveries" */ '@views/project/deliveries/project-deliveries'
          ),
        meta: {
          title: 'Deliveries',
          scrollLeft: 380,
        },
      },
      {
        path: 'schedule',
        name: 'project.details.schedule',
        component: () =>
          import(
            /* webpackChunkName: "project.schedule" */ '@views/project/schedule/project-schedule'
          ),
        meta: {
          title: 'Schedule',
          scrollLeft: 480,
        },
      },
      {
        path: 'uploaded-files',
        name: 'project.details.uploaded-files',
        redirect: { name: 'project.details.uploaded-files.project-files' },
        component: () =>
          import(
            /* webpackChunkName: "project.uploaded-files" */ '@views/project/uploaded-files/uploaded-files'
          ),
        meta: {
          title: 'Uploaded Files',
        },
        children: [
          {
            path: 'project-files',
            name: 'project.details.uploaded-files.project-files',
            component: () =>
              import(
                /* webpackChunkName: "project.details.uploaded-files.project-files" */ '@views/project/uploaded-files/project-files/project-files'
              ),
            meta: {
              title: 'Uploaded Files | Project Files',
              scrollLeft: 580,
            },
          },
          {
            path: 'brand-hub',
            name: 'project.details.uploaded-files.brand-hub',
            component: () =>
              import(
                /* webpackChunkName: "project.details.uploaded-files.brand-hub" */ '@views/project/uploaded-files/brand-hub/brand-hub'
              ),
            meta: {
              title: 'Uploaded Files | Brand Hub',
            },
          },
        ],
      },
      {
        path: 'activity-logs',
        name: 'project.details.activity-logs',
        component: () =>
          import(
            /* webpackChunkName: "project.activity-logs" */ '@views/project/logs/activity-logs'
          ),
        meta: {
          title: 'Activity Logs',
          scrollLeft: 650,
        },
      },
      {
        path: 'invoices',
        name: 'project.details.invoices',
        redirect: { name: 'project.details.invoices.overview' },
        component: () =>
          import(
            /* webpackChunkName: "project.invoices" */ '@views/project/invoices/project-invoices'
          ),
        meta: {
          title: 'Invoices',
        },
        children: [
          {
            path: 'overview',
            name: 'project.details.invoices.overview',
            component: () =>
              import(
                /* webpackChunkName: "project.invoices.overview" */ '@views/project/invoices/project-invoices-overview/project-invoices-overview'
              ),
            meta: {
              title: 'Invoices | Overview',
              scrollLeft: 700,
            },
          },
          {
            path: 'list',
            name: 'project.details.invoices.list',
            component: () =>
              import(
                /* webpackChunkName: "project.invoices.list" */ '@views/project/invoices/project-invoices-list/project-invoices-list'
              ),
            meta: {
              title: 'Invoices | List',
            },
          },
          {
            path: 'budget',
            name: 'project.details.invoices.budget',
            component: () =>
              import(
                /* webpackChunkName: "project.invoices.budget" */ '@views/project/invoices/project-invoices-budget/project-invoices-budget'
              ),
            meta: {
              title: 'Invoices | Budget',
            },
          },
          {
            path: 'hubspot-quotes',
            name: 'project.details.invoices.hubspot-quotes',
            component: () =>
              import(
                /* webpackChunkName: "project.invoices.hubspot-quotes" */ '@views/project/invoices/project-invoices-hubspot-quotes/project-invoices-hubspot-quotes'
              ),
            meta: {
              title: 'Invoices | HubSpot Quotes',
            },
          },
        ],
      },
      {
        path: 'reopen',
        name: 'project.details.reopen',
        component: () =>
          import(
            /* webpackChunkName: "project.summary" */ '@views/project/summary/project-summary'
          ),
        meta: {
          title: 'Reopen',
          scrollLeft: 0,
        },
      },
    ],
  },
]
