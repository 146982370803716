import Axios from '@plugins/axios'

export default (userId) => {
  let url = `/api/v2/internal/users/${userId}/google-calendar/authorise`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.get(url, config)
}
