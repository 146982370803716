export default Object.freeze({
  projectDashboard: [
    'project_jobs',
    'user_state',
    'project_office',
    'project_account_manager',
    'project_producer',
    'project_director',
    'project_production_coordinator',
    'project_product_type',
    'project_status',
    'project_notes',
    'project_orders',
  ],
  projectsList: [
    'project_health',
    'project_jobs',
    'user_state',
    'project_office',
    'project_account_manager',
    'project_producer',
    'project_director',
    'project_production_coordinator',
    'project_product_type',
    'project_status',
    'project_notes',
    'project_orders',
  ],
  projectDetails: [
    'project_office',
    'office_company',
    'project_account_manager',
    'project_producer',
    'project_director',
    'project_production_coordinator',
    'product_element_type',
    'video_job_video',
    'video_job_order_job_elements',
    'product_element',
    'created_by',
    'project_product_type',
    'project_contacts',
    'project_status',
    'project_orders',
    'contact_offices',
    'pre_production_jobs',
    'project_target_audience_types',
    'order_job_assignees',
    'project_production_team',
  ],
  deliveriesList: [
    'product_element_type',
    'video_job_video',
    'video_job_order_job_elements',
    'product_element',
    'video_style_types',
    'video_tags',
    'video_industry',
    'pre_production_jobs',
    'order_job_assignees',
  ],
  filmingDetailsList: [
    'product_element_type',
    'pre_production_jobs',
    'order_job_assignees',
  ],
  projectHealthList: ['project_health_details'],
  videoInformation: ['video_style_types', 'video_tags', 'video_industry_type'],
  user: ['offices', 'roles'],
  mediaManagerArchivedProjectList: [
    'project_office',
    'office_company',
    'company_footage_policy_documents',
    'project_producer',
  ],
  preProductionChanges: ['order_job_change_editor', 'order_job_change_event'],
  eventsList: ['event_user', 'event_type', 'event_state', 'user_teams'],
  eventsDetails: [
    'event_user',
    'event_type',
    'event_office',
    'event_project',
    'event_state',
    'project_account_manager',
    'project_producer',
    'project_production_team',
    'event_order_job_element',
    'order_job',
    'project_footage_policy_type',
    'order_job_changes',
    'order_job_full_address',
    'project_footage_status_type',
    'event_video',
    'event_project_first_production_order',
  ],
  onHoldEventsList: [
    'event_user',
    'event_type',
    'event_state',
    'event_project',
    'event_order_job_element',
  ],
  deletedEvents: [
    'event_user',
    'event_type',
    'event_state',
    'event_project',
    'event_order_job_element',
  ],
  teams: ['team_users'],
  eventsSearch: [
    'event_user',
    'event_type',
    'event_state',
    'event_project',
    'user_teams',
  ],
  eventsUser: ['user_teams'],
  projectEventsList: [
    'event_user',
    'event_type',
    'event_state',
    'event_project',
  ],
})
