import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/projects/${payload.projectId}/eod-notes/${payload.note.id}`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.patch(url, config)
}
