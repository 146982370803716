import VmsConstants from '@configs/vms-constants'

export default Object.freeze({
  projectStatus: [
    {
      id: 'default',
      container: 'vd-background-light-grey',
      label: 'vd-dark-grey',
      text: '',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
      container: 'vd-background-light-grey',
      label: 'vd-dark-grey',
      text: 'Draft',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED,
      container: 'vd-background-light-grey',
      label: 'vd-dark-grey',
      text: 'Archived',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED,
      container: 'vd-background-light-grey',
      label: 'vd-dark-grey',
      text: 'Cancelled',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_ACTIVE,
      container: 'vd-background-aqua',
      label: 'vd-blue',
      text: 'Active',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_PRE_PRODUCTION,
      container: 'vd-background-light-orange',
      label: 'vd-orange',
      text: 'Pre-Production',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_FILMING,
      container: 'vd-background-light-purple',
      label: 'vd-purple',
      text: 'Filming',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_POST_PRODUCTION,

      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
      text: 'Post Production',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_VIDEO_DELIVERED,
      container: 'vd-background-light-pink',
      label: 'vd-pink',
      text: 'Video Delivered',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_WAITING_APPROVAL,
      container: 'vd-background-light-red',
      label: 'vd-red',
      text: 'Awaiting Approval',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_CHANGES,
      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
      text: 'Changes',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_APPROVED,
      container: 'vd-background-light-green',
      label: 'vd-green',
      text: 'Approved',
    },
    {
      id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD,
      container: 'vd-background-cream',
      label: 'vd-yellow',
      text: 'On Hold',
    },
  ],

  deliveryStatusClasses: [
    {
      id: 'default',
      container: 'vd-background-light-grey',
      label: 'vd-dark-grey',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_BOOKED,
      container: 'vd-background-light-yellow',
      label: 'vd-yellow',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_DELIVERED,
      container: 'vd-background-aqua',
      label: 'vd-light-blue',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_ARCHIVED,
      container: 'vd-background-lighter-grey',
      label: 'vd-dark-grey',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_APPROVED,
      container: 'vd-background-light-green',
      label: 'vd-green',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_CHANGES_UPLOADED,
      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_CHANGES,
      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_VIDEO_UPLOADED,
      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_CHANGES_SCHEDULED,
      container: 'vd-background-dark-blue',
      label: 'vd-darker-blue',
    },
    {
      id: VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD,
      container: 'vd-background-cream',
      label: 'vd-yellow',
    },
  ],
})
