import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/projects/${payload.project_id}/footage-status`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.customIncludes) {
    config.params.include = `${payload.customIncludes.join(',')}`
  }

  const requestPayload = {
    footage_status_type_id: payload.footage_status_type_id,
  }

  return Axios.patch(url, requestPayload, config)
}
