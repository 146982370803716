/**
 * @module store/project/schedule/getters
 */
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { map } from 'lodash'

export default {
  /**
   * Get show other events state
   *
   * @param {Object} state - State of this module
   * @returns {Boolean} - show other events state
   */
  isShowOtherEvents(state) {
    return state.showOtherEvents
  },

  /**
   * Get the selected filters state
   *
   * @param {Object} state - State of this module
   * @returns {Object} - selected filters
   */
  selectedFilters(state) {
    return state.selectedFilters
  },

  /**
   * Get the selected date
   *
   * @param {Object} state - State of this module
   * @returns {String} - the selected date
   */
  selectedDate(state) {
    const schedulerSavedState = JSON.parse(
      localStorage.getItem('scheduleVmsProjectSchedule')
    )
    const selectedDate =
      schedulerSavedState?.selectedDate ||
      momentTz().startOf('month').format('YYYY-MM-DD HH:mm:ss')
    return (
      state.selectedDate || momentTz(selectedDate).format('YYYY-MM-DD HH:mm:ss')
    )
  },

  /**
   * Get the payload for fetching events list
   *
   * @param {Object} state - State of this module
   * @param {Object} getters - Getters of this module
   * @param {Object} rootState - State of the app
   * @param {Object} rootGetters -  Getters of the app
   * @returns {Object} - payload for fetching events list
   */
  projectEventsListPayload(state, getters, rootState, rootGetters) {
    const userIds = map(getters.usersList, (user) => user.id)

    return {
      all: 1,
      project_ids: getters.isShowOtherEvents
        ? undefined
        : [rootGetters['project/projectDetails'].id],
      start: momentTz(getters.selectedDate)
        .startOf('Month')
        .format('YYYY-MM-DD HH:mm:ss'),
      end: momentTz(getters.selectedDate)
        .endOf('Month')
        .format('YYYY-MM-DD HH:mm:ss'),
      filters: {
        ...getters.selectedFilters,
        assigned_to_ids: userIds,
        include_freelancer_events: 1,
      },
      include: ApiCustomIncludes.projectEventsList,
    }
  },

  /**
   * Get the list of users list
   *
   * @param {Object} state - State of this module
   * @returns {Array} - The users list
   */
  usersList(state) {
    return state.usersList
  },
}
