import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes/routes'
import * as hooks from './hooks'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

/**
 * Navigation guard after each route change.
 * @param {object} to - Destination route.
 * @param {object} from - Departing route.
 */
router.afterEach((to, from) => hooks.afterEach(to, from))

export default router
