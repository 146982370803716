/**
 * @module store/project/shoot/project-getters
 */

export default {
  /**
   * Get loading state for project shoots.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  filmingDetailsLoading(state) {
    return state.loading.filmingDetails
  },

  /**
   * Get loader for the available extras
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the loader for available extras
   */
  availableExtrasLoading(state) {
    return state.loading.availableExtras
  },

  /**
   * Get project filming details
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  filmingDetails(state) {
    return state.filmingDetails
  },

  /**
   * Get the selected filming details
   *
   * @param {object} state - State for current module.
   * @returns {object} - The selected filming details
   */
  selectedFilmingDetails(state) {
    return state.projectForm.filmingDetails.selected
  },

  /**
   * Get the state of the new filming details form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the new filming details form
   */
  filmingDetailsNewFormOpen(state) {
    return state.projectForm.filmingDetails.addNewFormOpen
  },

  /**
   * Get the state of the edit filming details form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the edit filming details form
   */
  filmingDetailsEditFormOpen(state) {
    return state.projectForm.filmingDetails.editFormOpen
  },

  /**
   * Get the list of all available extras for the filming details
   *
   * @param {object} state - State for current module.
   * @returns {Array} - The array of extras available
   */
  filmingDetailsExtras(state) {
    return state.projectForm.filmingDetails.extras
  },

  /**
   * Get loading state of hold shoot job
   *
   * @param  {Object} state
   * @return {boolean}
   */
  holdShootJobLoading(state) {
    return state.loading.holdShootJob
  },

  /**
   * Get call sheet loading state
   *
   * @param  {Object} state
   * @return {boolean}
   */
  callSheetLoading(state) {
    return state.loading.callSheet
  },

  /**
   * Get call sheet data
   *
   * @param  {Object} state
   * @return {Object}
   */
  callSheetData(state) {
    return state.callSheet.data
  },

  /**
   * Get call sheet form open state
   *
   * @param  {Object} state
   * @return {boolean}
   */
  callSheetFormOpen(state) {
    return state.callSheet.formOpen
  },

  /**
   * Get 'manual call sheet modal' open state
   *
   * @param  {Object} state
   * @return {boolean}
   */
  manualCallSheetModalOpen(state) {
    return state.modal.manualCallSheet.show
  },

  /**
   * Get 'manual call sheet modal' saving state
   *
   * @param  {Object} state
   * @return {boolean}
   */
  manualCallSheetModalLoading(state) {
    return state.loading.manualCallSheet
  },
}
