/**
 * @module store/project/attachment/attachment-getters
 */

export default {
  /**
   * Get loading state for project files.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectFilesLoading(state) {
    return state.loading.projectFiles
  },

  /**
   * Get loading state for project brand hub.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectBrandHubLoading(state) {
    return state.loading.brandHub
  },

  /**
   * Get project files list
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  projectFiles(state) {
    return state.projectFiles
  },

  /**
   * Get project brand hub
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  brandHub(state) {
    return state.brandHub
  },
}
