import * as types from '@store/mutation-types'
import { find } from 'lodash'

/**
 * @module store/project/delivery/delivery-mutations
 */
export default {
  /**
   * Update loader for the project deliveries
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_DELIVERIES_LIST](state, loading) {
    state.loading.deliveries = loading
  },

  /**
   * Update loader for the video product elements
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_DELIVERIES_PRODUCT_ELEMENTS](state, loading) {
    state.loading.videoProductElements = loading
  },

  /**
   * Update loader for the hold deliveries
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_DELIVERIES_HOLD](state, loading) {
    state.loading.holdDeliveries = loading
  },

  /**
   * Update loader for the project video products
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_DELIVERIES_VIDEO_PRODUCTS](state, loading) {
    state.loading.videoProducts = loading
  },

  /**
   * Update the project deliveries list
   * @param {object} state - the state of the current module.
   * @param {array} deliveries - the array of the project deliveries
   */
  [types.PROJECT_DELIVERIES_LIST](state, deliveries) {
    state.deliveries = deliveries
  },

  /**
   * Update the project deliveries video products list
   * @param {object} state - the state of the current module.
   * @param {array} videoProducts - the array of the project video products
   */
  [types.PROJECT_DELIVERIES_VIDEO_PRODUCTS](state, videoProducts) {
    state.videoProducts = videoProducts
  },

  /**
   * Show/hide video delivery job add form
   * @param {object} state - the state of the current module.
   * @param {boolean} show - the new state of the add form
   */
  [types.PROJECT_DELIVERIES_ADD_NEW_FORM_OPEN](state, show) {
    state.projectForm.addNewFormOpen = show
  },

  /**
   * Show/hide video delivery job edit form
   * @param {object} state - the state of the current module.
   * @param {boolean} show - the new state of the edit form
   */
  [types.PROJECT_DELIVERIES_EDIT_FORM_OPEN](state, show) {
    state.projectForm.editFormOpen = show
  },

  /**
   * Update the selected video product ID
   * @param {object} state - the state of the current module.
   * @param {Number} videoProductId - the ID of the selected video product
   */
  [types.PROJECT_DELIVERIES_SELECTED_VIDEO_PRODUCT_ID](state, videoProductId) {
    state.selectedVideoProductId = videoProductId
  },

  /**
   * Update the video product elements list for the selected video product
   * @param {object} state - the state of the current module.
   * @param {array} productElements - the product elements array
   */
  [types.PROJECT_DELIVERIES_PRODUCT_ELEMENTS](state, productElements) {
    state.projectForm.videoProductElements = productElements
  },

  /**
   * Update the selected video delivery job ID
   * @param {object} state - the state of the current module.
   * @param {array} videoDeliveryJobId - the selected video delivery job ID
   */
  [types.PROJECT_DELIVERIES_SELECTED_VIDEO_DELIVERY_JOB_ID](
    state,
    videoDeliveryJobId
  ) {
    state.projectForm.selectedVideoDeliveryJobId = videoDeliveryJobId
  },

  /**
   * Update a single video delivery job when found in the video delivery jobs list
   * @param {object} state - the state the current module
   * @param {object} videoDeliveryData - the updated video delivery job data
   */
  [types.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM](state, videoDeliveryData) {
    const selectedVideoDeliveryJob = find(state.deliveries.data, {
      id: videoDeliveryData.id,
    })

    if (selectedVideoDeliveryJob) {
      Object.assign(selectedVideoDeliveryJob, videoDeliveryData)
    }
  },

  /**
   * Toggle a single video delivery job is_starred value
   * @param {object} state - the state the current module
   * @param {number} jobId - the video job id
   */
  [types.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_STAR](state, jobId) {
    const selectedVideoDeliveryJob = find(state.deliveries.data, {
      id: jobId,
    })

    if (selectedVideoDeliveryJob) {
      selectedVideoDeliveryJob.video.is_starred = !selectedVideoDeliveryJob
        .video.is_starred
    }
  },

  /**
   * Update the select all deliveries state
   * @param {object} state - the state of the current module
   * @param {boolean} selectAll - the state of the select all deliveries
   */
  [types.PROJECT_DELIVERIES_SELECT_ALL](state, selectAll) {
    state.selectAllDeliveries = selectAll
  },

  /**
   * Update the array of selected delivery ids
   * @param {object} state - the state of the current module
   * @param {array} selectedDeliveryIds - array of selected delivery ids
   */
  [types.PROJECT_DELIVERIES_SELECTED_DELIVERIES](state, selectedDeliveryIds) {
    state.selectedDeliveryIds = selectedDeliveryIds
  },

  /**
   * Toggle bulk add videos modal
   * @param {Object} state
   */
  [types.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_TOGGLE_SHOW](state) {
    state.modal.bulkAddVideos.show = !state.modal.bulkAddVideos.show
  },

  /**
   * Set quantity for bulk add videos
   * @param {Object} state
   * @param {number} newValue - new quantity value
   */
  [types.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_QUANTITY](state, newValue) {
    state.modal.bulkAddVideos.amount = newValue
  },

  /**
   * Set names for bulk add videos
   * @param {Object} state
   * @param {array} newArray - new names array
   */
  [types.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_NAMES](state, newArray) {
    state.modal.bulkAddVideos.names = newArray
  },

  /**
   * Set confirmation for bulk add videos
   * @param {Object} state
   * @param {array} bool - true or false
   */
  [types.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_CONFIRM](state, bool) {
    state.modal.bulkAddVideos.confirm = bool
  },

  /**
   * Toggle video player modal
   * @param {Object} state
   */
  [types.PROJECT_DELIVERIES_VIDEO_PLAYER_TOGGLE_SHOW](state) {
    state.modal.videoPlayer.show = !state.modal.videoPlayer.show
  },

  /**
   * Assign Video id Selected
   * @param {Object} state
   * @param {Number} id
   */
  [types.PROJECT_DELIVERIES_MODAL_SELECTED_DELIVERY_ID](state, id) {
    state.selectedDeliveryId = id
  },

  /**
   * Assign Filters in delivieries page.
   * @param {Object} state
   * @param {Object} filters
   */
  [types.PROJECT_DELIVERIES_PAGE_FILTERS](state, filters) {
    state.deliveriesPage.filters = filters
  },
}
