import actions from '@store/project/activity-log/activity-log-actions'
import getters from '@store/project/activity-log/activity-log-getters'
import mutations from '@store/project/activity-log/activity-log-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/activity-log/activity-log-module
 */

/**
 * Get default state for activity-log module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {array}   activityLog - Array of objects of project activity logs.
   * @property  {object} loading - States for loaders within the activity log module.
   * @property  {boolean} loading.activityLogList - Activity log loading state.
   * @property  {boolean} projectHasActivityLogs - Project has activity logs state.
   */
  return {
    activityLogs: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      },
    },
    loading: {
      activityLogList: false,
    },
    projectHasActivityLogs: false,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_ACTIVITY_LOG_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
