import Axios from '@plugins/axios'

export default (customIncludes) => {
  let url = `/api/v2/internal/footage-status-types`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  return Axios.get(url, config)
}
