import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { filter, size, map, includes } from 'lodash'
import EventTypes from '@configs/event-types'
import router from '@router/router'

/**
 * @module store/calendar/actions
 */

/**
 * Store actions for calendar module.
 * @module calendar/actions
 */
export default {
  /**
   * Reset state for current store module.
   *
   * @param {object} - Context
   */
  resetState({ commit }) {
    commit(mutations.CALENDAR_RESET_STATE)
  },

  /**
   * Action to add filter to the selected filter array.
   *
   * @param {Object} - Context
   * @param {Object} selectedFilter - the selected filter object
   */
  addSelectedFilter({ commit }, selectedFilter) {
    commit(mutations.CALENDAR_ADD_SELECTED_FILTER, selectedFilter)
  },

  /**
   * Action to add or remove filters in the selected filter array.
   * @param {Object} - Context
   * @param {Array} selectedFilters - array of all selected filters
   */
  addOrRemoveSelectedFilters({ commit }, selectedFilters) {
    commit(mutations.CALENDAR_ADD_REMOVE_SELECTED_FILTERS, selectedFilters)
  },

  /**
   * Action to remove selected filter in the selected filter array.
   *
   * @param {Object} - Context
   * @param {Object} filter - the selected filter object
   */
  removeSelectedFilter({ commit }, filter) {
    commit(mutations.CALENDAR_REMOVE_SELECTED_FILTER, filter)
  },

  /**
   * Action to clear all selected filters.
   *
   * @param {Object} - Context
   * @param {Boolean} getEventsList - whether to get the events list after clearing filters
   */
  clearAllFilters({ commit, dispatch, rootGetters }, getEventsList) {
    commit(mutations.CALENDAR_CLEAR_ALL_FILTERS)

    dispatch('setSelectedSavedFilter', null)
    dispatch('setEventsSearchKeyword', '')
    dispatch('setSelectedOwnedByIdFilters', [])

    if (getEventsList) {
      dispatch('getEventsList', {
        payload: {
          start: rootGetters['calendar/selectedViewTypeDetails'].start,
          end: rootGetters['calendar/selectedViewTypeDetails'].end,
          include: ApiCustomIncludes.eventsList,
        },
        showLoader: true,
      })
    }
  },

  /**
   * Action to search for filters list
   *
   * @param {Object} - Context
   * @param {String} searchKeyword - the search keyword
   */
  filterSearch({ commit }, searchKeyword) {
    commit(mutations.CALENDAR_FILTER_SEARCH, searchKeyword)
  },

  /**
   * Action to set the show all filters modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowAllFiltersModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_SHOW_ALL_FILTERS, showModal)
  },

  /**
   * Action to set the save filters modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowSaveFiltersModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_SHOW_SAVE_FILTERS_MODAL, showModal)
  },

  /**
   * Action to set the on hold events modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowOnHoldEventsModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_SHOW_ON_HOLD_EVENTS, showModal)
  },

  /**
   * Action to set the deleted events modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowDeletedEventsModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_SHOW_DELETED_EVENTS, showModal)
  },

  /**
   * Action to set the on hold events state with data and metadata
   *
   * @param {Object} - Context
   * @param {Object} events - the on hold events data with metadata
   */
  setOnHoldEvents({ commit }, events) {
    commit(mutations.CALENDAR_ON_HOLD_EVENTS, events)
  },

  /**
   * Action to set the deleted events state
   *
   * @param {Object} - Context
   * @param {Object} events - the deleted events data
   */
  setDeletedEvents({ commit }, events) {
    commit(mutations.CALENDAR_DELETED_EVENTS, events)
  },

  /**
   * Action to set the events list loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the events list loading state
   */
  setEventsListLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_EVENTS_LIST, loading)
  },

  /**
   * Action to set the events list data
   *
   * @param {Object} - Context
   * @param {Object} events - the events list data
   */
  setEventsList({ commit }, events) {
    commit(mutations.CALENDAR_EVENTS_LIST, events)
  },

  /**
   * Action to get the events list data
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getEventsList({ dispatch }, payload) {
    dispatch('setEventsListLoading', payload.showLoader)
    dispatch('setShowProgressBar', true)

    ApiServices.events
      .list(payload.payload)
      .then((response) => {
        dispatch('setEventsListLoading', false)
        dispatch('setShowProgressBar', false)
        dispatch('setEventsList', response.data?.data || [])

        // update events data Date Range
        dispatch('setEventsDataDateRange', {
          fromDate: payload.payload.start,
          toDate: payload.payload.end,
        })
      })
      .catch((error) => {
        if (!error.isCancelled) {
          dispatch('setEventsListLoading', false)
          dispatch('setShowProgressBar', false)
          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: 'Error fetching all events',
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: payload,
            },
            { root: true }
          )
        }
      })
  },

  /**
   * Action to set the selected event state
   *
   * @param {Object} - Context
   * @param {Object} event - the selected event data
   */
  setSelectedEvent({ commit }, event) {
    commit(mutations.CALENDAR_SELECTED_EVENT, event)
  },

  /**
   * Action to set the event details loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the event details loading state
   */
  setEventDetailsLoading({ commit }, loading) {
    commit(mutations.CALENDAR_EVENT_DETAILS_LOADING, loading)
  },

  /**
   * Action to get the event details data
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getEventDetails({ dispatch }, payload) {
    dispatch('setEventDetailsLoading', true)
    dispatch('setSelectedEvent', null)

    ApiServices.events
      .get(payload.eventId, payload.include)
      .then((response) => {
        dispatch('setEventDetailsLoading', false)
        dispatch('setSelectedEvent', response.data.data)
      })
      .catch((error) => {
        if (!error.isCancelled) {
          dispatch('setEventDetailsLoading', false)

          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: 'Error fetching event details',
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: payload,
            },
            { root: true }
          )
        }
      })
  },

  /**
   * Action to set the view type of the scheduler
   *
   * @param {Object} - Context
   * @param {String} viewType - the view type
   */
  setViewType({ commit }, viewType) {
    commit(mutations.CALENDAR_VIEW_TYPE, viewType)
  },

  /**
   * Action to set the agenda list
   *
   * @param {Object} - Context
   * @param {Array} agendaList - the agenda list
   */
  setAgendaList({ commit }, agendaList) {
    commit(mutations.CALENDAR_AGENDA_LIST, agendaList)
  },

  /**
   * Action to set the agenda list loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the agenda list loading state
   */
  setAgendaListLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_AGENDA_LIST, loading)
  },

  /**
   * Action to get the agenda list data
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getAgendaList({ dispatch }, payload) {
    dispatch('setAgendaListLoading', true)

    ApiServices.events
      .list(payload)
      .then((response) => {
        dispatch('setAgendaListLoading', false)
        dispatch('setAgendaList', response.data?.data || [])
      })
      .catch((error) => {
        dispatch('setAgendaListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching my agenda list',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the team filters
   *
   * @param {Object} - Context
   * @param {Array} teamFilters - the team filters
   */
  setTeamFilters({ commit }, teamFilters) {
    commit(mutations.CALENDAR_FILTERS_TEAMS, teamFilters)
  },

  /**
   * Action to set the crew filters
   *
   * @param {Object} - Context
   * @param {Array} crewFilters - the crew filters
   */
  setCrewFilters({ commit }, crewFilters) {
    commit(mutations.CALENDAR_FILTERS_CREW, crewFilters)
  },

  /**
   * Action to set the project owners filters
   *
   * @param {Object} - Context
   * @param {Array} projectOwners - the project owner filters
   */
  setProjectOwnerFilters({ commit }, projectOwners) {
    commit(mutations.CALENDAR_FILTERS_PROJECT_OWNERS, projectOwners)
  },

  /**
   * Action to set the event types filters
   *
   * @param {Object} - Context
   * @param {Array} eventTypesFilters - the event types filters
   */
  setEventTypesFilters({ commit }, eventTypesFilters) {
    commit(mutations.CALENDAR_FILTERS_EVENT_TYPES, eventTypesFilters)
  },

  /**
   * Action to set the event types filters loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the event types filters loading state
   */
  setEventTypesLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_EVENT_TYPES, loading)
  },

  /**
   * Action to get the event types filters list
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getEventTypesFilterList({ dispatch }) {
    dispatch('setEventTypesLoading', true)

    ApiServices.events.types
      .list()
      .then((response) => {
        dispatch('setEventTypesLoading', false)
        dispatch('setEventTypesFilters', response.data.data)
        dispatch('setEventTypesList', response.data.data)
      })
      .catch((error) => {
        dispatch('setEventTypesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching event types filters',
            severity: 'error',
            error: error.response ? error.response : error.message,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the saved filters loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the saved filters loading state
   */
  setSavedFiltersLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_SAVED_FILTERS, loading)
  },

  /**
   * Action to create saved filters
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  createSavedFilter({ dispatch }, payload) {
    dispatch('setSavedFiltersLoading', true)

    ApiServices.events.savedFilters
      .create(payload.include, payload.payload)
      .then(() => {
        dispatch('setSavedFiltersLoading', false)

        // Load saved filters list on success
        dispatch('getSavedFilters')

        dispatch(
          'common/displayToastAlert',
          {
            title: `Filter saved!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setSavedFiltersLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving filter',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the saved filters list
   *
   * @param {Object} - Context
   * @param {Array} savedFilters - the saved filters list
   */
  setSavedFilters({ commit }, savedFilters) {
    commit(mutations.CALENDAR_FILTERS_SAVED_FILTERS, savedFilters)
  },

  /**
   * Action to get saved filters list
   *
   * @param {Object} - Context
   */
  getSavedFilters({ dispatch }) {
    dispatch('setSavedFiltersLoading', true)

    ApiServices.events.savedFilters
      .list()
      .then((response) => {
        dispatch('setSavedFiltersLoading', false)
        dispatch('setSavedFilters', response.data.data)
      })
      .catch((error) => {
        dispatch('setSavedFiltersLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching saved filters',
            severity: 'error',
            error: error.response ? error.response : error.message,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the selected saved filter
   *
   * @param {Object} - Context
   * @param {Object} savedFilter - the selected saved filter
   */
  setSelectedSavedFilter({ commit }, savedFilter) {
    commit(mutations.CALENDAR_FILTERS_SELECTED_SAVED_FILTER, savedFilter)
  },

  /**
   * Action to set the selected owned by id filters
   *
   * @param {Object} - Context
   * @param {Object} ownedByIdFilter - the selected owned by ID filter
   */
  setSelectedOwnedByIdFilters({ commit }, ownedByIdFilter) {
    commit(
      mutations.CALENDAR_FILTERS_SELECTED_OWNED_BY_ID_FILTER,
      ownedByIdFilter
    )
  },

  /**
   * Action to add id to the selected owner id array.
   *
   * @param {Object} - Context
   * @param {Object} selectedOwner - the selected filter object
   */
  addSelectedOwnedByIdFilters({ commit }, selectedOwner) {
    commit(mutations.CALENDAR_ADD_SELECTED_OWNED_BY_ID_FILTER, selectedOwner)
  },

  /**
   * Action to remove id in the selected owner id array.
   *
   * @param {Object} - Context
   * @param {Object} selectedOwner - the selected filter object
   */
  removeSelectedOwnedByIdFilters({ commit }, selectedOwner) {
    commit(mutations.CALENDAR_REMOVE_SELECTED_OWNED_BY_ID_FILTER, selectedOwner)
  },

  /**
   * Action to set on hold events list loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the on hold events list loading state
   */
  setOnHoldEventsListLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_ON_HOLD_EVENTS, loading)
  },

  /**
   * Action to set selected on hold event loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the selected on hold event loading state
   */
  setSelectedOnHoldEventLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_SELECTED_ON_HOLD_EVENT, loading)
  },

  /**
   * Action to set selected on hold event
   *
   * @param {Object} - Context
   * @param {Number} eventId - the selected on hold event id
   */
  setSelectedOnHoldEvent({ commit }, eventId) {
    commit(mutations.CALENDAR_SELECTED_ON_HOLD_EVENT, eventId)
  },

  /**
   * Action to get on hold events list
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getOnHoldEvents({ dispatch }, payload) {
    dispatch('setOnHoldEventsListLoading', true)
    dispatch('setOnHoldEventsLastPayload', payload.payload)

    ApiServices.events
      .list(payload.payload)
      .then((response) => {
        dispatch('setOnHoldEventsListLoading', false)
        dispatch('setOnHoldEvents', response.data)
      })
      .catch((error) => {
        dispatch('setOnHoldEventsListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching on hold events',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set event types list
   *
   * @param {Object} - Context
   * @param {Array} eventTypes - the event types list
   */
  setEventTypesList({ commit }, eventTypes) {
    commit(mutations.CALENDAR_EVENT_TYPES_LIST, eventTypes)
  },

  /**
   * Action to set the selected schedules
   *
   * @param {Object} - Context
   * @param {Array} schedules - the selected schedules
   */
  setSelectedSchedules({ commit }, schedules) {
    commit(mutations.CALENDAR_SELECTED_SCHEDULES, schedules)
  },

  /**
   * Action to set event form loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the event form loading state
   */
  setEventFormLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_EVENT_FORM, loading)
  },

  /**
   * Action to set the show events placeholders create modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsPlaceholdersCreateModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_PLACEHOLDERS_CREATE, showModal)
  },

  /**
   * Action to set the show events filming details create modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsFilmingDetailsCreateModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_FILMING_DETAILS_CREATE, showModal)
  },

  /**
   * Action to set the show events deliveries create modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsDeliveriesCreateModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_DELIVERIES_CREATE, showModal)
  },

  /**
   * Action to set the show events job changes create modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsPreProductionsCreateModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_CREATE, showModal)
  },

  /**
   * Action to set the show events pre-production changes create modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsPlaceholdersEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_PLACEHOLDERS_EDIT, showModal)
  },

  /**
   * Action to set the show events filming details edit modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsFilmingDetailsEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_FILMING_DETAILS_EDIT, showModal)
  },

  /**
   * Action to set the show events deliveries edit modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsDeliveriesEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_DELIVERIES_EDIT, showModal)
  },

  /**
   * Action to set the show events job changes edit modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsJobChangesEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_JOB_CHANGES_EDIT, showModal)
  },

  /**
   * Action to set the show events pre-production changes edit modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsPreProductionsEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_EDIT, showModal)
  },

  /**
   * Action to set the show events pre-production edit modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsPreProductionChangesEditModal({ commit }, showModal) {
    commit(
      mutations.CALENDAR_MODAL_EVENTS_PRE_PRODUCTION_CHANGES_EDIT,
      showModal
    )
  },

  /**
   * Action to set the show events modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - whether to show the modal or not
   */
  setShowEventsModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS, showModal)
  },

  /**
   * Action to set projects list loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the projects list loading state
   */
  setProjectsListLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_PROJECTS_LIST, loading)
  },

  /**
   * Action to set projects list
   *
   * @param {Object} - Context
   * @param {Array} projects - the projects list
   */
  setProjectsList({ commit }, projects) {
    commit(mutations.CALENDAR_PROJECTS_LIST, projects)
  },

  /**
   * Action to get projects list
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  getProjectsList({ dispatch }, payload) {
    dispatch('setProjectsListLoading', true)

    ApiServices.projects
      .list(payload)
      .then((response) => {
        dispatch('setProjectsListLoading', false)
        dispatch('setProjectsList', response.data.data)
      })
      .catch((error) => {
        dispatch('setProjectsListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching projects list',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to add events to the events list
   *
   * @param {Object} - Context
   * @param {Object} payload
   */
  addEventsToList({ dispatch, rootGetters }, payload) {
    const list = rootGetters['calendar/eventsList']
    const events = payload.events
    dispatch('setEventsList', [...list, ...events])
  },

  /**
   * Action to update event in the events list
   *
   * @param {Object} - Context
   * @param {Object} payload
   */
  updateEventFromList({ dispatch, rootGetters }, payload) {
    const list = rootGetters['calendar/eventsList']
    const updatedEvent = payload.event
    const index = list.findIndex((event) => event.id === updatedEvent.id)
    list[index] = updatedEvent
    dispatch('setEventsList', [...list])
  },

  /**
   * Action to add events to the events list
   *
   * @param {Object} - Context
   * @param {Object} payload
   */
  removeEventFromList({ dispatch, rootGetters }, payload) {
    const list = rootGetters['calendar/eventsList']
    const events = filter(list, (event) => event.id !== payload.eventId)
    dispatch('setEventsList', [...events])
  },

  /**
   * Action to create new event
   *
   * @param {Object} - Context
   * @param {Object} payload
   */
  createNewEvent({ dispatch }, payload) {
    dispatch('setEventFormLoading', true)
    const includes = payload.includes
    const eventPayload = payload.payload

    ApiServices.events
      .create(includes, eventPayload)
      .then((response) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsModal', false)

        dispatch('addEventsToList', {
          events: response.data.data,
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} created!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving new event from the calendar',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to update the selected event details
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  updateEventDetails({ dispatch, rootGetters }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events
      .update(payload.eventId, payload.payload, payload.includes)
      .then((response) => {
        dispatch('updateEventFromList', {
          event: response.data.data,
        })
        dispatch('setShowEventsModal', false)
        dispatch('setEventFormLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} was updated successfully!`,
          },
          { root: true }
        )

        if (
          includes(
            ['calendar.index', 'project.details.schedule'],
            router.currentRoute.name
          )
        ) {
          dispatch('getEventsList', {
            payload: rootGetters['calendar/eventsListPayload'],
            showLoader: false,
          })
        }
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating event details',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )

        // Get the events list on error to refresh the calendar
        dispatch('getEventsList', {
          payload: {
            start: rootGetters['calendar/selectedViewTypeDetails'].start,
            end: rootGetters['calendar/selectedViewTypeDetails'].end,
            include: ApiCustomIncludes.eventsList,
          },
          showLoader: true,
        })
      })
  },

  /**
   * Action to create new filming event
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  createNewFilmingEvent({ dispatch }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.shoots
      .create(payload.projectId, payload.payload, payload.includes)
      .then((response) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsModal', false)

        dispatch('addEventsToList', {
          events: response.data.data,
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} created!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving new filming event from the calendar.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to create new delivery event
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  createNewDeliveryEvent({ dispatch }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.videoDeliveries
      .create(payload.projectId, payload.payload, payload.includes)
      .then((response) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsModal', false)
        dispatch('addEventsToList', {
          events: response.data.data,
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} created!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving new edit event from the calendar.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to create new pre-production event
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  createNewPreProductionEvent({ dispatch }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.preProductions
      .create(payload.projectId, payload.payload, payload.includes)
      .then((response) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsModal', false)

        dispatch('addEventsToList', {
          events: response.data.data,
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} created!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving new pre-production event from the calendar.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to put job event on hold
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  holdJobEvent({ dispatch, rootGetters }, payload) {
    dispatch('setEventFormLoading', true)

    let actionType = null

    if (payload.event_job_type === EventTypes.jobTypes.DELIVERIES) {
      actionType = 'videoDeliveries'
    } else if (payload.event_job_type === EventTypes.jobTypes.FILMING_DETAILS) {
      actionType = 'shoots'
    } else if (payload.event_job_type === EventTypes.jobTypes.PRE_PRODUCTIONS) {
      actionType = 'preProduction'
    }

    if (actionType) {
      ApiServices.projects.jobs[actionType]
        .hold(payload.project_id, payload.order_job_id)
        .then((response) => {
          dispatch('setEventFormLoading', false)
          dispatch('setShowEventsModal', false)

          // Reload event list.
          dispatch('getEventsList', {
            payload: rootGetters['calendar/eventsListPayload'],
            showLoader: true,
          })

          dispatch(
            'common/displayToastAlert',
            {
              title: `${response.data.data.name} was put on hold successfully!`,
            },
            { root: true }
          )
        })
        .catch((error) => {
          dispatch('setEventFormLoading', false)
          dispatch('setShowEventsModal', false)

          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: `Error putting event on hold.`,
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: payload,
            },
            { root: true }
          )
        })
    } else {
      dispatch('setEventFormLoading', false)
      dispatch('setShowEventsModal', false)
      dispatch(
        'common/displayErrorMessage',
        {
          message: 'Event type not supported.',
        },
        {
          root: true,
        }
      )
    }
  },

  /**
   * Action to unhold job event.
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  unholdJobEvent({ dispatch, rootGetters }, payload) {
    let actionType = null

    if (payload.event_job_type === EventTypes.jobTypes.DELIVERIES) {
      actionType = 'videoDeliveries'
    } else if (payload.event_job_type === EventTypes.jobTypes.FILMING_DETAILS) {
      actionType = 'shoots'
    } else if (payload.event_job_type === EventTypes.jobTypes.PRE_PRODUCTIONS) {
      actionType = 'preProduction'
    }

    if (actionType) {
      if (payload.loadOnHoldEventsList) {
        dispatch('setOnHoldEventsListLoading', true)
      }

      if (payload.loadEventsList) {
        dispatch('setEventFormLoading', true)
      }

      dispatch('setUnHoldEventLoading', true)

      ApiServices.projects.jobs[actionType]
        .unhold(payload.project_id, payload.order_job_id)
        .then((response) => {
          dispatch('setUnHoldEventLoading', false)

          if (payload.loadOnHoldEventsList) {
            dispatch('getOnHoldEvents', {
              payload: payload.payload,
            })
          }

          if (payload.loadEventsList) {
            dispatch('setEventFormLoading', false)
            dispatch('setShowEventsModal', false)

            dispatch('getEventsList', {
              payload: rootGetters['calendar/eventsListPayload'],
              showLoader: true,
            })
          }

          dispatch(
            'common/displayToastAlert',
            {
              title: `${response.data.data.name} was updated successfully!`,
            },
            { root: true }
          )
        })
        .catch((error) => {
          dispatch('setShowOnHoldEventsModal', false)
          dispatch('setUnHoldEventLoading', false)
          dispatch('common/displayErrorMessage', error.response?.data, {
            root: true,
          })
          dispatch(
            'common/bugsnagNotify',
            {
              title: `Error putting event off hold.`,
              severity: 'error',
              error: error.response ? error.response : error.message,
              payload: payload,
            },
            { root: true }
          )
        })
    } else {
      dispatch(
        'common/displayErrorMessage',
        {
          message: 'Event type not supported.',
        },
        {
          root: true,
        }
      )
    }
  },

  /**
   * Action to delete event
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  deleteEvent({ dispatch }, payload) {
    dispatch('setEventFormLoading', true)
    dispatch('setEventsListLoading', true)
    ApiServices.events
      .delete(payload.eventId)
      .then(() => {
        dispatch('removeEventFromList', payload)
        dispatch('setEventFormLoading', false)
        dispatch('setEventsListLoading', false)
        dispatch('setShowEventsModal', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `Event was deleted successfully!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('setEventsListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error deleting event.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the selected calendar date
   *
   * @param {Object} - Context
   * @param {String} selectedDate - the selected date
   */
  setSelectedCalendarDate({ commit }, selectedDate) {
    commit(mutations.CALENDAR_SELECTED_CALENDAR_DATE, selectedDate)
  },

  /**
   * Action to set the events search keyword
   *
   * @param {Object} - Context
   * @param {String} searchKeyword - the search keyword
   */
  setEventsSearchKeyword({ commit }, searchKeyword) {
    commit(mutations.CALENDAR_EVENTS_SEARCH_KEYWORD, searchKeyword)
  },

  /**
   * Action to set the show bulk edit events modal state
   *
   * @param {Object} - Context
   * @param {Boolean} showModal - the state of the modal
   */
  setShowEventsBulkEditModal({ commit }, showModal) {
    commit(mutations.CALENDAR_MODAL_EVENTS_BULK_EDIT, showModal)
  },

  /**
   * Action to set the selected events for bulk action
   *
   * @param {Object} - Context
   * @param {Array} selectedEvents - the selected events array
   */
  setBulkActionSelectedEvents({ commit }, selectedEvents) {
    commit(mutations.CALENDAR_EVENTS_BULK_SELECTED_EVENTS, selectedEvents)
  },

  /**
   * Action to bulk update events
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload for updating bulk events
   */
  bulkUpdateEvents({ dispatch, rootGetters }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.bulk
      .update(payload.payload, payload.includes)
      .then(() => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])

        dispatch(
          'common/displayToastAlert',
          {
            title: `Events were updated successfully!`,
          },
          { root: true }
        )

        dispatch('getEventsList', {
          payload: rootGetters['calendar/eventsListPayload'],
          showLoader: false,
        })
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error updating events.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to bulk delete events
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload for bulk deleting events
   */
  bulkDeleteEvents({ dispatch, rootGetters }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.bulk
      .delete(payload.payload, payload.includes)
      .then(() => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])

        dispatch(
          'common/displayToastAlert',
          {
            title: `Events were deleted successfully!`,
          },
          { root: true }
        )

        dispatch('getEventsList', {
          payload: rootGetters['calendar/eventsListPayload'],
          showLoader: false,
        })
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error deleting events.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to bulk duplicate events
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload for bulk duplicate events
   */
  bulkDuplicateEvents({ dispatch, rootGetters }, payload) {
    dispatch('setEventFormLoading', true)

    ApiServices.events.bulk
      .duplicate(payload.payload, payload.includes)
      .then((response) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])

        dispatch('setEventActionHistory', {
          type: 'duplicate',
          originalPayload: {
            event_ids: map(response.data.data, 'id'),
          },
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `Events were duplicated successfully!`,
          },
          { root: true }
        )

        dispatch('getEventsList', {
          payload: rootGetters['calendar/eventsListPayload'],
          showLoader: false,
        })
      })
      .catch((error) => {
        dispatch('setEventFormLoading', false)
        dispatch('setShowEventsBulkEditModal', false)
        dispatch('setBulkActionSelectedEvents', [])

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error duplicating events.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the can bulk reassign user state
   *
   * @param {Object} - Context
   * @param {Boolean} canReassignUser - the state of the can bulk reassign user
   */
  setCanBulkReassignUser({ commit }, canReassignUser) {
    commit(mutations.CALENDAR_EVENTS_BULK_REASSIGN_USER, canReassignUser)
  },

  /**
   * Action to set events data date range
   *
   * @param {Object} - Context
   * @param {Object} - new date range
   */
  setEventsDataDateRange({ commit }, dateRange) {
    commit(mutations.CALENDAR_EVENTS_DATA_DATE_RANGE, dateRange)
  },

  /**
   * Action to set show progress bar state
   *
   * @param {Object} - Context
   * @param {Boolean} showProgressBar - the show progress bar state
   */
  setShowProgressBar({ commit }, showProgressBar) {
    commit(mutations.CALENDAR_SHOW_PROGRESS_BAR, showProgressBar)
  },

  /**
   * Action to set showMobileFilterList
   *
   * @param {Object} - Context
   * @param {Boolean} showMobileFilterList - the show mobile filter list state
   */
  setShowMobileFilterList({ commit }, showMobileFilterList) {
    commit(mutations.CALENDAR_SHOW_MOBILE_FILTER_LIST, showMobileFilterList)
  },

  /**
   * Action to set showMobileAgendaModal
   *
   * @param {Object} - Context
   * @param {Boolean} showMobileAgendaModal - the show mobile agenda modal state
   */
  setShowMobileAgendaModal({ commit }, showMobileAgendaModal) {
    commit(mutations.CALENDAR_SHOW_MOBILE_AGENDA_MODAL, showMobileAgendaModal)
  },

  /**
   * Action to set the localStorage selected filters
   *
   * @param {Object} - Context
   * @param {Object} selectedFilters - the selected filters
   */
  setLocalStorageSelectedFilters({ commit }, selectedFilters) {
    // Save the selected filters in local storage
    localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters))

    commit(mutations.CALENDAR_LOCAL_STORAGE_SELECTED_FILTERS, selectedFilters)
  },

  /**
   * Action to set last payload for fetching on hold events.
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload for the on hold events
   */
  setOnHoldEventsLastPayload({ commit }, payload) {
    commit(mutations.CALENDAR_ON_HOLD_EVENTS_LAST_PAYLOAD, payload)
  },

  /**
   * Action to set loading state for unhold event.
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the loading state
   */
  setUnHoldEventLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_UN_HOLD_EVENT, loading)
  },

  /**
   * Action to set custom payload for getEventsList
   *
   * @param {object} - Context
   * @param {object} - the custom payload
   */
  setCustomEventsListPayload({ commit }, customPayload) {
    commit(mutations.CALENDAR_CUSTOM_EVENTS_LIST_PAYLOAD, customPayload)
  },

  /**
   * Action to set deleted events list loading state
   *
   * @param {Object} - Context
   * @param {Boolean} loading - the deleted events list loading state
   */
  setDeletedEventsLoading({ commit }, loading) {
    commit(mutations.CALENDAR_LOADING_DELETED_EVENTS, loading)
  },

  /**
   * Action to fetch deleted events
   *
   * @param {object} - Context
   * @param {object} - the custom payload
   */
  getDeletedEvents({ dispatch }, payload) {
    dispatch('setDeletedEventsLoading', true)

    ApiServices.events
      .list(payload.payload)
      .then((response) => {
        dispatch('setDeletedEventsLoading', false)
        dispatch('setDeletedEvents', response.data)
      })
      .catch((error) => {
        dispatch('setDeletedEventsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to fetch deleted events.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to bulk restore deleted events.
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  bulkRestoreDeletedEvent({ dispatch, rootGetters }, payload) {
    dispatch('setDeletedEventsLoading', true)

    ApiServices.events.bulk
      .restore(payload.payload, payload.includes)
      .then(() => {
        dispatch('setDeletedEventsLoading', false)

        if (payload.getEventsList) {
          dispatch('getEventsList', {
            payload: rootGetters['calendar/eventsListPayload'],
            showLoader: false,
          })
        } else {
          dispatch('getDeletedEvents', {
            payload: payload.getDeletedEventsPayload,
          })
        }

        const alertMessage = payload.isBulkDelete
          ? `Successfully restored ${size(payload.payload.event_ids)} events`
          : `Event was restored successfully to ${payload.eventStartDate}`

        dispatch(
          'common/displayToastAlert',
          {
            title: alertMessage,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setDeletedEventsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error restoring events.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set event action history
   *
   * @param {Object} - Context
   * @param {Object} eventActionHistory - the event action history
   */
  setEventActionHistory({ commit }, eventActionHistory) {
    commit(mutations.CALENDAR_EVENT_ACTION_HISTORY, eventActionHistory)
  },

  /**
   * Action to delete selected saved filter
   *
   * @param {Object} - Context
   * @param {String} savedFilterId - the selected saved filter ID
   */
  deleteSavedFilter({ dispatch }, savedFilterId) {
    dispatch('setSavedFiltersLoading', true)

    ApiServices.events.savedFilters
      .delete(savedFilterId)
      .then(() => {
        dispatch('setSavedFiltersLoading', false)

        // Load saved filters list on success
        dispatch('getSavedFilters')

        dispatch(
          'common/displayToastAlert',
          {
            title: 'Saved filter removed successfully!',
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setSavedFiltersLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting saved filter',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              savedFilterId: savedFilterId,
            },
          },
          { root: true }
        )
      })
  },
}
