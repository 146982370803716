/**
 * @module store/media-manager/getters
 */

export default {
  /**
   * Get loading state for archived projects
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isArchivedProjectsLoading(state) {
    return state.loading.archivedProjects
  },

  /**
   * Get selected project
   * @param {Object} state - State for this module.
   * @returns {Object} - the selected project
   */
  selectedArchivedProject(state) {
    return state.selectedArchivedProject
  },

  /**
   * Get the show state of the archive project footage modal
   * @param {Object} state - State for this module.
   * @returns {Boolean} - the show state of the archive project footage modal
   */
  showArchiveProjectFootageModal(state) {
    return state.modal.archiveProjectFootage.show
  },

  /**
   * Get the loading state of the archive project footage
   * @param {Object} state - State for this module.
   * @returns {Boolean} - the loading state of the archive project footage
   */
  isArchiveProjectFootageLoading(state) {
    return state.loading.archiveProjectFootage
  },
}
