import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'

/**
 * @module store/company/actions
 */

/**
 * Store actions for company module.
 * @module company/actions
 */
export default {
  /**
   * Reset state for current store module.
   * @param {object} - Context
   */
  resetState({ commit }) {
    commit(mutations.COMPANY_RESET_STATE)
  },

  /**
   * Action to set the offices loading state
   * @param commit
   * @param {object} loading - the loading state
   */
  setOfficeLoading({ commit }, loading) {
    commit(mutations.COMPANY_LOADING_OFFICES, loading)
  },

  /**
   * Action to get company offices
   * @param commit
   * @param {object} payload
   */
  getCompanyOffices({ commit, dispatch }, payload) {
    dispatch('setOfficeLoading', true)

    ApiServices.companies.offices
      .list(payload.companyId, payload.includes)
      .then((response) => {
        commit(mutations.COMPANY_OFFICES, response.data.data)
        dispatch('setOfficeLoading', false)
      })
      .catch((error) => {
        dispatch('setOfficeLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching offices',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },
}
