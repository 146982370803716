import Sweetalert2 from 'sweetalert2/dist/sweetalert2.js'

/**
 * Global options.
 */
const options = {
  confirmButtonColor: '#00c5ff',
  cancelButtonColor: '#dddddd',
  reverseButtons: true,
}

export default Sweetalert2.mixin(options)
