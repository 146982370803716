import AppConfig from '@configs/app-config'

/**
 * @module store/common/getters
 */

export default {
  /**
   * Get inner height of the window.
   * @param {Object} state - State for this module.
   * @returns {Number}
   */
  windowHeight(state) {
    return state.window.height
  },

  /**
   * Get inner width of the window.
   * @param {Object} state - State for this module.
   * @returns {Number}
   */
  windowWidth(state) {
    return state.window.width
  },

  /**
   * Get the pagination page selected
   * @param {Object} state - State for this module.
   * @returns {Number}
   */
  selectedPage(state) {
    return state.pagination.selectedPage
  },

  /**
   * Get the state when to display medium screen view
   * 900px is estimated size when project detail tabs start to hit the add button
   * @param {Object} state - state of this module
   * @returns {Boolean}
   */
  isMediumScreen(state) {
    return state.window.width < 900
  },

  /**
   * Get the state when to display mobile view
   * 768 is the same breakpoint in projects table
   * @returns {Boolean}
   */
  isMobileScreen(state) {
    return state.window.width <= 768
  },

  /**
   * Check if user agent is using a mobile device
   */
  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  },

  /**
   * Check if side menu is open
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isSideMenuOpen(state) {
    return state.sideMenu.isOpen
  },

  /**
   * Set the selected side menu
   * @param {Object} state - State for this module.
   * @returns {String}
   */
  selectedSideMenu(state) {
    return state.sideMenu.selected
  },

  /**
   * Get default timezone
   *
   * @returns {String} - default timezone
   */
  defaultCalendarTimezone() {
    return AppConfig.defaultTimezone
  },
}
