/**
 * @module store/project/contact/contact-getters
 */

export default {
  /**
   * Get loading state for project contacts
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectContactsLoading(state) {
    return state.loading.contacts
  },

  /**
   * Get project contacts
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  projectContacts(state) {
    return state.projectContacts
  },

  /**
   * Get the add contacts list display state
   *
   * @param {object} state - the state of the current module
   * @returns {boolean} - The add contacts list display state
   */
  showAddContactsList(state) {
    return state.showAddContactsList
  },
}
