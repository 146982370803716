import Axios from '@plugins/axios'

export default (projectId, orderJobId, customIncludes, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/jobs/pre-production/${orderJobId}/restore-file-version`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  return Axios.put(url, payload, config)
}
