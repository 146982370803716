import Axios from '@plugins/axios'

export default (customIncludes, payload) => {
  let url = `/api/v2/internal/projects/archived`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  if (payload.sortBy) {
    config.params.sort_by = payload.sortBy
  }

  if (payload.sort) {
    config.params.sort = payload.sort
  }

  if (payload.search) {
    config.params.search = payload.search
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (payload.itemsPerPage) {
    config.params.per_page = payload.itemsPerPage
  }

  if (payload.filter) {
    if (payload.filter) {
      config.params.filter = payload.filter
    }
  }

  return Axios.get(url, config)
}
