import { has } from 'lodash'

/**
 * @module Define router hooks
 */

/**
 * Navigation guard after each route change.
 * @param {object} to - Destination route.
 * @param {object} from - Departing route.
 */
export function afterEach(to) {
  // By default update the window title with title from route meta.
  document.title = has(to, 'meta.title')
    ? `VMS | ${to.meta.title}`
    : 'VMS | Video Production Australia | Visual Domain'
}
