import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import AppConfig from '@configs/app-config'
import SweetAlert from '@plugins/sweet-alert'
import { find, isEmpty, isNull, size } from 'lodash'
import router from '@router/router'
import ApiCustomIncludes from '@configs/api-custom-includes'

/**
 * Store actions for invoice module.
 * @module project/invoice/invoice-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_INVOICE_RESET_STATE)
  },

  /**
   * Update loader for add po number.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateAddPoNumberLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_ADD_PO_NUMBER_MODAL, loading)
  },

  /**
   * Update loader for add production cost.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateAddProductionCostLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_ADD_PRODUCTION_COST, loading)
  },

  /**
   * Update loader for add disbursement cost.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateAddDisbursementCostLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_ADD_DISBURSEMENT_COST, loading)
  },

  /**
   * Update loader for edit job price.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateEditJobLoading({ commit }, loading) {
    commit(mutations.PROJECT_INVOICES_EDIT_JOB_LOADING, loading)
  },

  /**
   * Update loader for invoice overview.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateInvoiceOverviewLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_INVOICE_OVERVIEW, loading)
  },

  /**
   * Update loader for invoice details.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateInvoiceDetailsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_INVOICE_DETAILS, loading)
  },

  /**
   * Update loader for credit list loading.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateCreditListLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_CREDIT_LIST, loading)
  },

  /**
   * Update loader for invoice list loading.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateInvoiceListLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_INVOICE_LIST, loading)
  },

  /**
   * Update loader for link invoice loading.
   *
   * @param {Object} context
   * @param {boolean} loading - The loading state
   */
  updateLinkInvoiceLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_LINK_INVOICE, loading)
  },

  /**
   * Action to set the project invoices
   * @param {Object} context
   * @param {array} invoices - the invoices data
   */
  setProjectInvoicesList({ commit }, invoices) {
    commit(mutations.PROJECT_INVOICES_LIST, invoices)
  },

  /**
   * Toggle add po number show modal.
   *
   * @param {Object} context
   */
  toggleAddPoNumberShowModal({ commit }) {
    commit(mutations.PROJECT_MODAL_ADD_PO_NUMBER_TOGGLE_SHOW)
  },

  /**
   * Update the state of the add po number order id.
   *
   * @param {Object} context
   * @param {int} id - Order id
   */
  updateAddPoNumberOrderIdModal({ commit }, id) {
    commit(mutations.PROJECT_MODAL_ADD_PO_NUMBER_ORDER_ID, id)
  },

  /**
   * Add po number.
   *
   * @param {object} context
   * @param {object} payload - The payload for listing all the available extras
   * @param {string} payload.order_id - The order id.
   * @param {string} payload.reference_number - Reference number / po number.
   */
  addPoNumber({ commit, dispatch }, payload) {
    dispatch('updateAddPoNumberLoading', true)

    ProjectServices.projects.orders
      .addPoNumber(payload.order_id, payload)
      .then(() => {
        dispatch('updateAddPoNumberLoading', false)
        dispatch('toggleAddPoNumberShowModal')

        commit(mutations.PROJECT_INVOICE_UPDATE_PO_NUMBER, payload)

        dispatch(
          'common/displayToastAlert',
          { title: 'PO Number added successfully!' },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateAddPoNumberLoading', false)
        dispatch('toggleAddPoNumberShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding PO number.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              order_id: payload.order_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Toggle add production cost show modal.
   *
   * @param {Object} context
   */
  toggleAddProductionCostShowModal({ commit }) {
    commit(mutations.PROJECT_MODAL_ADD_PRODUCTION_COST_TOGGLE_SHOW)
  },

  /**
   * Add production cost.
   *
   * @param {object} context
   * @param {object} payload - The payload for listing all the available extras
   * @param {string} payload.name - The production cost name.
   * @param {string} payload.price - The production cost price.
   * @param {string} payload.project_id - The id of the project.
   */
  addProductionCost({ commit, dispatch }, payload) {
    dispatch('updateAddProductionCostLoading', true)

    ProjectServices.projects.orders.create
      .productionCost(payload.project_id, payload)
      .then((response) => {
        dispatch('updateAddProductionCostLoading', false)
        dispatch('toggleAddProductionCostShowModal')

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )

        dispatch(
          'common/displayToastAlert',
          { title: `${payload.name} successfully added!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateAddProductionCostLoading', false)
        dispatch('toggleAddProductionCostShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding Production Cost.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Toggle add disbursement cost show modal.
   *
   * @param {Object} context
   */
  toggleAddDisbursementCostShowModal({ commit }) {
    commit(mutations.PROJECT_MODAL_ADD_DISBURSEMENT_COST_TOGGLE_SHOW)
  },

  /**
   * Get list of disbursement product elements.
   *
   * @param {object} context
   * @param {object} payload - The payload for listing all the product elements
   * @param {object} payload.customIncludes - The inclusion you want to add in this payload
   */
  getDisbursementProductElements({ dispatch, commit }, payload) {
    dispatch('updateAddDisbursementCostLoading', true)

    ProjectServices.products.list
      .disbursementCostProductElements(payload.customIncludes)
      .then((response) => {
        dispatch('updateAddDisbursementCostLoading', false)

        commit(
          mutations.PROJECT_MODAL_ADD_DISBURSEMENT_COST_PRODUCT_ELEMENTS,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('updateAddDisbursementCostLoading', false)
        dispatch('toggleAddDisbursementCostShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving disbursement product elements.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: payload.customIncludes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Add disbursement cost.
   *
   * @param {object} context
   * @param {object} payload - The payload for listing all the available extras
   * @param {string} payload.name - The disbursement cost name.
   * @param {string} payload.price - The disbursement cost price.
   * @param {string} payload.product_element_id - The disbursement product element.
   * @param {string} payload.project_id - The id of the project.
   */
  addDisbursementCost({ commit, dispatch }, payload) {
    dispatch('updateAddDisbursementCostLoading', true)

    ProjectServices.projects.orders.create
      .disbursementCost(payload.project_id, payload)
      .then((response) => {
        dispatch('updateAddDisbursementCostLoading', false)
        dispatch('toggleAddDisbursementCostShowModal')

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        dispatch(
          'common/displayToastAlert',
          { title: `${payload.name} successfully added!` },
          { root: true }
        )

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('updateAddDisbursementCostLoading', false)
        dispatch('toggleAddDisbursementCostShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding Disbursement Cost.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Get project invoice overview.
   *
   * @param {object} context
   * @param {object} payload - The payload for getting project invoice overview.
   * @param {string} payload.projectId - The id of the project.
   */
  getProjectInvoiceOverview({ dispatch, commit }, payload) {
    dispatch('updateInvoiceOverviewLoading', true)

    ProjectServices.projects.invoices
      .list(payload.projectId)
      .then((response) => {
        dispatch('updateInvoiceOverviewLoading', false)

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)
      })
      .catch((error) => {
        dispatch('updateInvoiceOverviewLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error loading the project invoice overview details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Toggle edit job price show modal
   *
   * @param {Object} context
   */
  toggleEditJobShowModal({ commit }) {
    commit(mutations.PROJECT_INVOICES_EDIT_JOB_MODAL_TOGGLE)
  },

  /**
   * Edit job.
   *
   * @param {object} context
   * @param {object} payload - The payload for edit job price.
   * @param {string} payload.project_id - The id of the project.
   * @param {string} payload.order_job_id - The id of the order job.
   * @param {string} payload.price - The new price.
   */
  editJob({ dispatch, commit }, payload) {
    dispatch('updateEditJobLoading', true)

    ProjectServices.projects.jobs.productionCost
      .update(payload.project_id, payload.order_job_id, null, payload)
      .then((response) => {
        dispatch('updateEditJobLoading', false)
        dispatch('toggleEditJobShowModal')

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        // Reset selected invoice job ID.
        commit(mutations.PROJECT_INVOICES_SELECTED_JOB_ID, null)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )

        dispatch(
          'common/displayToastAlert',
          { title: `Job price successfully edited!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateEditJobLoading', false)
        dispatch('toggleEditJobShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error editing the production cost job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Delete job.
   *
   * @param {object} context
   * @param {object} payload - The payload for edit job price.
   * @param {string} payload.project_id - The id of the project.
   * @param {string} payload.order_job_id - The id of the order job.
   */
  deleteJob({ dispatch, commit }, payload) {
    dispatch('updateEditJobLoading', true)

    ProjectServices.projects.jobs.productionCost
      .delete(payload.project_id, payload.order_job_id, null)
      .then((response) => {
        dispatch('updateEditJobLoading', false)
        dispatch('toggleEditJobShowModal')

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        // Reset selected invoice job ID.
        commit(mutations.PROJECT_INVOICES_SELECTED_JOB_ID, null)

        dispatch(
          'common/displayToastAlert',
          { title: `Job successfully deleted!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateEditJobLoading', false)
        dispatch('toggleEditJobShowModal')
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting the production cost job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the store with selected invoice job.
   * @param {Object} context
   * @param {Number} id - ID of the job that was selected.
   */
  updateSelectedInvoiceJobId({ commit }, id) {
    commit(mutations.PROJECT_INVOICES_SELECTED_JOB_ID, id)
  },

  /**
   * Update the store with invoice contacts.
   * @param {Object} context
   * @param {array} list - invoice contact arrays.
   */
  updateInvoiceContacts({ commit }, list) {
    commit(mutations.PROJECT_INVOICES_INVOICE_CONTACT, list)
  },

  /**
   * Add new contact in the invoice contacts array.
   *
   * @param {Object} context
   * @param {object} contact - contact.
   * @param {number} contact.id - contact id.
   * @param {string} contact.full_name - contact full name.
   * @param {string} contact.email - contact email.
   * @param {boolean} contact.is_office_accountant - boolean to check if contact is office accountant.
   */
  addInvoiceContact({ commit, getters }, contact) {
    const invoiceContactInList = find(getters.invoiceContacts, {
      id: contact.id,
    })

    const secondaryContact = find(getters.invoiceContacts, {
      is_office_accountant: false,
    })

    if (isEmpty(invoiceContactInList)) {
      if (!contact.is_office_accountant && !isEmpty(secondaryContact)) {
        commit(
          mutations.PROJECT_INVOICES_INVOICE_CONTACT_REMOVE,
          secondaryContact
        )
      }
      commit(mutations.PROJECT_INVOICES_INVOICE_CONTACT_ADD, contact)
    }
  },

  /**
   * Remove contact from array.
   * @param {Object} context
   * @param {object} contact - contact.
   */
  removeInvoiceContact({ commit }, contact) {
    commit(mutations.PROJECT_INVOICES_INVOICE_CONTACT_REMOVE, contact)
  },

  /**
   * Update invoice details for the whole project.
   *
   * @param {object} context
   * @param {object} payload - The payload for update invoice details.
   * @param {string} payload.project_id - The id of the project.
   * @param {string} payload.payment_term_id - The id of the payment terms.
   * @param {string} payload.order_invoicing_term_id - The id of the invoicing terms.
   * @param {boolean} payload.third_party_payment - Boolean to see if it is coming from third party.
   * @param {string} payload.invoice_entity_name - Third party entity name.
   * @param {string} payload.invoice_entity_contact_name - Third party contact name.
   * @param {string} payload.invoice_entity_contact_email - Third party contact email.
   * @param {array} payload.invoice_contacts - List of invoice contacts.
   */
  updateInvoiceDetails({ dispatch, commit }, payload) {
    dispatch('updateInvoiceDetailsLoading', true)

    ProjectServices.projects.invoices.update
      .invoiceDetails(payload.project_id, payload, null)
      .then((response) => {
        dispatch('updateInvoiceDetailsLoading', false)

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        dispatch(
          'common/displayToastAlert',
          { title: `Invoice details successfully updated!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateInvoiceDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating the invoice details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the store for invoice detail changed.
   * @param {Object} context
   * @param {bool} bool - true or false.
   */
  updateInvoiceDetailChanged({ commit }, bool) {
    commit(mutations.PROJECT_INVOICES_INVOICE_DETAIL_CHANGED, bool)
  },

  /**
   * Send activate and confirmation email for project.
   *
   * @param commit
   * @param rootGetters
   */
  // eslint-disable-next-line complexity
  sendActivateAndConfirmationEmail({ rootGetters }) {
    const project = rootGetters['project/projectDetails']
    const projectContacts = rootGetters['project/contact/projectContacts']
    const preProductions = rootGetters['project/pre-production/preProductions']
    const filmingDetails = rootGetters['project/shoot/filmingDetails']
    const deliveries = rootGetters['project/delivery/deliveries']
    const hasProjectProductType = rootGetters['project/hasProjectProductType']
    const invoiceOrderDetail = rootGetters['project/invoice/invoiceOrderDetail']
    const hasProductionDateMissing =
      rootGetters['project/hasProductionDateMissing']
    const hideInvoiceDetails = rootGetters['project/invoice/hideInvoiceDetails']

    // TODO: Refactor this condition
    if (!hasProjectProductType) {
      SweetAlert.fire({
        title: 'Missing Product Type',
        html: `<br> Please select a Product Type before proceeding.`,
        showCancelButton: true,
        confirmButtonText: 'Okay',
      }).then((result) => {
        if (result.isConfirmed) {
          if (router.currentRoute.name !== 'project.details.summary') {
            router.push({
              name: 'project.details.summary',
              params: {
                projectId: project.id,
              },
            })
          }
        }
      })
    } else if (size(projectContacts) <= 0) {
      SweetAlert.fire({
        title: 'Missing Project Contacts!',
        html: `<br> Project has no contacts. <br /> Please assign at least one contact before proceeding.`,
        showCancelButton: false,
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          if (router.currentRoute.name !== 'project.details.summary') {
            router.push({
              name: 'project.details.summary',
              params: {
                projectId: project.id,
              },
            })
          }
        }
      })
    } else if (project.total_price === 0) {
      SweetAlert.fire({
        title: 'Warning!',
        html: `This project has no budget!<br>Add an invoice before sending the confirmation email.`,
        showCancelButton: false,
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            router.currentRoute.name !== 'project.details.invoices.overview'
          ) {
            router.push({
              name: 'project.details.invoices.overview',
              params: {
                projectId: project.id,
              },
            })
          }
        }
      })
    } else if (
      preProductions.length === 0 &&
      filmingDetails.meta.total === 0 &&
      deliveries.meta.total === 0
    ) {
      SweetAlert.fire({
        title: 'Warning!',
        html: `This project has nothing scheduled!<br>Add at least one production job before sending the confirmation email.`,
        showCancelButton: false,
        confirmButtonText: 'Continue',
      })
    } else if (hasProductionDateMissing) {
      SweetAlert.fire({
        title: 'Warning!',
        html: `One or more of the Production Jobs are missing a production date. Please update before sending the confirmation email.`,
        showCancelButton: false,
        confirmButtonText: 'Continue',
      })
    } else if (
      !hideInvoiceDetails &&
      (isNull(invoiceOrderDetail.payment_term_id) ||
        isNull(invoiceOrderDetail.order_invoicing_term_id) ||
        (invoiceOrderDetail.third_party_payment &&
          (isNull(invoiceOrderDetail.invoice_entity_name) ||
            isNull(invoiceOrderDetail.invoice_entity_contact_name) ||
            isNull(invoiceOrderDetail.invoice_entity_contact_email))) ||
        (!invoiceOrderDetail.third_party_payment &&
          size(invoiceOrderDetail.invoice_contacts) <= 0))
    ) {
      SweetAlert.fire({
        title: 'Warning!',
        html: `One or more invoice details are missing!<br>Please fix invoice details before sending the confirmation email.`,
        showCancelButton: false,
        confirmButtonText: 'Continue',
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            router.currentRoute.name !== 'project.details.invoices.overview'
          ) {
            router.push({
              name: 'project.details.invoices.overview',
              params: {
                projectId: project.id,
              },
            })
          }
        }
      })
    } else {
      window.location = `${AppConfig.vmsApiUrl}/projects/${project.id}/view-confirm-email/`
    }
  },

  /**
   * Update applied credits to project invoice.
   *
   * @param {object} context
   * @param {object} payload - The payload for update credits project invoice.
   *
   * @param {string} payload.project_id - The id of the project.
   * @param {object} payload.payload - Object containing list of credit used that is updated.
   */
  updateProjectInvoiceCredits({ dispatch, commit }, payload) {
    dispatch('updateCreditListLoading', true)

    ProjectServices.projects.invoices.update
      .credits(payload.project_id, payload.payload)
      .then((response) => {
        dispatch('updateCreditListLoading', false)

        // This will update the project resource total price
        dispatch(
          'project/updateProjectDetailsTotalPrice',
          response.data.data.total_price,
          {
            root: true,
          }
        )

        commit(mutations.PROJECT_INVOICES_OVERVIEW, response.data.data)

        dispatch(
          'common/displayToastAlert',
          { title: `Credits successfully saved!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateCreditListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating the project invoice credits.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Send invoice.
   *
   * @param {object} context
   * @param {object} payload - The payload for listing all the available extras
   * @param {string} payload.project_id - The id of the project.
   * @param {string} payload.order_id - The id of the order.
   * @param {string} payload.xero_invoice_id - The id of the xero invoice if there's any.
   */
  sendInvoice({ commit, dispatch }, payload) {
    dispatch('updateInvoiceListLoading', true)

    ProjectServices.projects.invoices
      .send(payload.project_id, payload.includes, payload)
      .then((response) => {
        // This will update the invoice list.
        commit(mutations.PROJECT_INVOICES_LIST, response.data.data.invoices)

        dispatch('updateInvoiceListLoading', false)
        dispatch(
          'common/displayToastAlert',
          { title: `Invoice successfully sent!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateInvoiceListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to send invoice.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Toggle link invoice show modal.
   *
   * @param {Object} context
   */
  toggleLinkInvoiceShowModal({ commit }) {
    commit(mutations.PROJECT_MODAL_LINK_INVOICE_TOGGLE_SHOW)
  },

  /**
   * Update the state of the add po number order id.
   *
   * @param {Object} context
   * @param {int} id - Order id
   */
  updateLinkInvoiceOrderIdModal({ commit }, id) {
    commit(mutations.PROJECT_MODAL_LINK_INVOICE_ORDER_ID, id)
  },

  /**
   *
   * @param context
   * @param payload - The payload for listing all the available extras
   * @param {string} payload.project_id - The id of the project.
   * @param {string} payload.order_id - The id of the order.
   * @param {string} payload.xero_identifier - The xero invoice identifier.
   */
  linkInvoice({ commit, dispatch }, payload) {
    dispatch('updateLinkInvoiceLoading', true)
    dispatch('updateInvoiceListLoading', true)

    ProjectServices.projects.invoices
      .link(payload.project_id, payload.includes, payload)
      .then((response) => {
        // This will update the invoice list.
        commit(mutations.PROJECT_INVOICES_LIST, response.data.data.invoices)

        dispatch('updateLinkInvoiceLoading', false)
        dispatch('updateInvoiceListLoading', false)
        dispatch('toggleLinkInvoiceShowModal')

        dispatch(
          'common/displayToastAlert',
          { title: `Invoice successfully linked!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateLinkInvoiceLoading', false)
        dispatch('updateInvoiceListLoading', false)
        dispatch('toggleLinkInvoiceShowModal')

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to link invoice.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              payload: payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Delete selected project invoice.
   *
   * @param {object} context
   * @param {object} payload - The payload for delete project invoice.
   * @param {number} payload.project_id - The id of the project.
   * @param {number} payload.invoice_id - The id of the selected invoice.
   */
  deleteProjectInvoice({ dispatch }, payload) {
    dispatch('updateInvoiceListLoading', true)

    ProjectServices.projects.invoices
      .delete(payload.project_id, payload.invoice_id)
      .then(() => {
        dispatch('updateInvoiceListLoading', false)

        dispatch('getProjectInvoiceOverview', {
          projectId: payload.project_id,
        })

        dispatch(
          'common/displayToastAlert',
          { title: 'Invoice deleted successfully!' },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updateInvoiceListLoading', false)
        dispatch('getProjectInvoiceOverview', {
          projectId: payload.project_id,
        })
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting invoice',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              invoice_id: payload.invoice_id,
            },
          },
          { root: true }
        )
      })
  },
}
