import actions from '@store/project/schedule/schedule-actions'
import getters from '@store/project/schedule/schedule-getters'
import mutations from '@store/project/schedule/schedule-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/schedule
 */

/**
 * Get default state for project schedule module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {Boolean} showOtherEvents - state to show other events
   * @property  {String}  selectedDate - state for timeline selected date
   * @property  {Object}  selectedFilters - filters use for fetching events list
   * @property  {Number}  selectedFilters.unassigned - specific filter to include unassigned events
   * @property  {Number}  selectedFilters.include_google_events - specific filter to include google events
   * @property  {Number}  selectedFilters.include_bamboo_events - specific filter to include bamboo events
   * @property  {Array}   usersList - list of users
   */
  return {
    showOtherEvents: false,
    selectedDate: '',
    selectedFilters: {
      unassigned: 1,
      include_google_events: 0,
      include_bamboo_events: 0,
    },
    usersList: [],
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_SCHEDULE_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
