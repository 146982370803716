import { omit } from 'lodash'

/**
 * @module store/auth/getters
 */

export default {
  /**
   * Get access token for authentication with VMS Api.
   * @param {Object} state
   * @returns {String}
   */
  accessToken(state) {
    return state.firebase.accessToken
  },

  /**
   * Get firebase user data without accessToken.
   * @param {Object} state
   * @returns {String}
   */
  firebaseUserData(state) {
    return omit(state.firebase, 'accessToken')
  },

  /**
   * Determine if user is authenticated with firebase.
   * @param {Object} state
   * @returns {Boolean}
   */
  isLoggedIn(state) {
    return state.firebase.accessToken !== ''
  },

  /**
   * Check if firebase token is loading
   * @param {Object} state
   * @returns {Boolean}
   */
  isFirebaseTokenLoading(state) {
    return state.firebase.loading
  },

  /**
   * Get VMS user details for logged in user.
   * @param {Object} state
   * @returns {Object}
   */
  getVmsUserData(state) {
    return state.vms
  },

  /**
   * Get the VMS user permissions
   * @param {Object} state
   * @returns {Array}
   */
  userPermissions(state) {
    return state.vms.permissions
  },

  /**
   * Get the user roles
   * @param {Object} context
   * @returns {Array}
   */
  userRoles(state, getters) {
    return getters.getVmsUserData.roles
  },
}
