import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import router from '@router/router'
import {
  isEmpty,
  cloneDeep,
  findIndex,
  find,
  forEach,
  pullAt,
  concat,
} from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import StatusesConfig from '@configs/statuses'
import VmsConstants from '@configs/vms-constants'

/**
 * Store actions for project module.
 * @module project/project-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_RESET_STATE)
  },

  /**
   * Action to set the project details loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProjectDetailsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PROJECT_DETAILS, loading)
  },

  /**
   * Action to set the create new project loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setCreateNewProjectLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_CREATE_NEW_PROJECT, loading)
  },

  /**
   * Action to set the product types loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProductTypesLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PRODUCT_TYPES, loading)
  },

  /**
   * Action to set the edit project loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEditProjectLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_EDIT_PROJECT, loading)
  },

  /**
   * Action to set the confirm contact loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setConfirmContactLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_CONFIRM_CONTACT, loading)
  },

  /**
   * Action to set the project details
   * @param commit
   * @param {array} payload - project details
   */
  setProjectDetailsList({ commit }, payload) {
    commit(mutations.PROJECT_DETAILS_LIST, payload)
  },

  /**
   * Action to get the project summary or project details.
   * @param {Object} payload
   * @param {Number} payload.projectId
   * @param {Array} payload.customIncludes - List of extra attributes to be included in the response.
   */
  getProjectDetails({ dispatch, rootGetters }, payload) {
    dispatch('setProjectDetailsLoading', true)
    // Allow new project to display default data from store.
    if (payload.projectId === 'new') {
      dispatch('setProjectDetailsLoading', false)

      // Redirect back to project create page if no contact is available in store.
      if (isEmpty(rootGetters['project/contact/projectContacts'])) {
        router.push({
          name: 'project.create',
        })
      }

      return
    }

    ProjectServices.projects
      .detail(payload.projectId, payload.customIncludes)
      .then((response) => {
        dispatch('setProjectDetailsLoading', false)

        dispatch('setProjectDetailsList', response.data.data)

        dispatch(
          'project/contact/setProjectContactsList',
          response.data.data.contacts,
          { root: true }
        )

        dispatch(
          'project/production-note/setProjectProductionNotesList',
          response.data.data.pre_productions,
          { root: true }
        )

        dispatch(
          'project/pre-production/setPreProductionsList',
          response.data.data.pre_productions,
          { root: true }
        )

        dispatch(
          'project/shoot/setProjectFilmingDetailsList',
          response.data.data.filming_details,
          { root: true }
        )

        dispatch(
          'project/delivery/setProjectDeliveriesList',
          response.data.data.deliveries,
          { root: true }
        )

        dispatch(
          'project/attachment/setProjectFilesList',
          response.data.data.project_files,
          { root: true }
        )

        dispatch(
          'project/attachment/setProjectBrandHubList',
          response.data.data.brand_hub,
          { root: true }
        )

        dispatch(
          'project/invoice/setProjectInvoicesList',
          response.data.data.invoices,
          { root: true }
        )

        dispatch(
          'project/invoice/getProjectInvoiceOverview',
          {
            projectId: response.data.data.id,
          },
          { root: true }
        )

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
        dispatch('setConfirmContactLoading', false)

        dispatch(
          'project/activity-log/getActivityLogList',
          {
            projectId: response.data.data.id,
            payload: {
              page: 1,
            },
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error loading the project details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              includes: payload.customIncludes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the state of the save button for the project form
   *
   * @param commit
   * @param {boolean} disabled - State of the save button
   */
  updateFormSaveBtnDisabled({ commit }, disabled) {
    commit(mutations.PROJECT_FORM_SAVE_BTN_DISABLED, disabled)
  },

  /**
   * Update the loading state of the save button for the project form
   *
   * @param commit
   * @param {boolean} disabled - State of the save button
   */
  updateFormSaveBtnLoading({ commit }, loading) {
    commit(mutations.PROJECT_FORM_SAVE_BTN_LOADING, loading)
  },

  /**
   * Update project details with any data we have from algolia contact.
   *
   * @param {object}
   * @param {object} algoliaPayload - Contact, office and company data from algolia.
   * @param {string} algoliaPayload.user_id - User ID as string.
   * @param {string} algoliaPayload.first_name
   * @param {string} algoliaPayload.last_name
   * @param {string} algoliaPayload.email
   * @param {string} algoliaPayload.photo_url
   * @param {string} algoliaPayload.phone
   * @param {object} algoliaPayload.office - Object containing office and company data.
   * @param {number} algoliaPayload.office.id - Office ID.
   * @param {string} algoliaPayload.office.name - Office name.
   * @param {number} algoliaPayload.office.company_id - Company id.
   * @param {string} algoliaPayload.office.company_name - Company name.
   */
  updateNewProjectDetailsFromAlgolia({ commit, dispatch }, algoliaPayload) {
    commit(mutations.PROJECT_NEW_UPDATE_DETAILS_FROM_ALGOLIA, algoliaPayload)

    dispatch(
      'project/contact/setNewProjectContactFromAlgolia',
      algoliaPayload,
      { root: true }
    )

    router.push({
      name: 'project.details.summary',
      params: { projectId: 'new' },
    })
  },

  /**
   * Action to set the project details
   * @param commit
   * @param {array} payload - project details
   */
  updateProjectDetailsTotalPrice({ commit }, price) {
    commit(mutations.PROJECT_DETAILS_TOTAL_PRICE, price)
  },

  /**
   * Update the new project form payload when creating a new project
   *
   * @param context
   * @param {string} payload.name - The name of the project
   * @param {number} payload.product_type_id - The ID of the product type selected
   * @param {number} payload.account_manager_id - The ID of the selected Account Manager
   * @param {number} payload.producer_id - The ID of the selected Producer
   * @param {number} payload.contact_id - The ID of the selected Contact
   * @param {number} payload.office_id - The ID of the selected Contact's office
   * @param {number} payload.video_privacy - The value of the selected Video Privacy
   * @param {number} payload.budgeted_hours - The value of the project Estimated Hours
   */
  updateNewProjectFormPayload({ commit }, payload) {
    commit(mutations.PROJECT_FORM_NEW_PROJECT_PAYLOAD_UPDATE, payload)
  },

  /**
   * Update the new project form payload
   *
   * @param context
   * @param {array} includes - array of includes
   */
  createNewProject({ dispatch, getters }, includes) {
    dispatch('setCreateNewProjectLoading', true)

    ProjectServices.projects
      .create(includes, getters.newProjectFormPayload)
      .then((response) => {
        const newProjectId = response.data.data.id

        router.push({
          name: 'project.details.summary',
          params: {
            projectId: newProjectId,
          },
        })

        dispatch('getProjectDetails', {
          projectId: response.data.data.id,
          customIncludes: ApiCustomIncludes.projectDetails,
        })

        dispatch('setCreateNewProjectLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} project was created successfully!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setCreateNewProjectLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when creating a new project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
              payload: getters.newProjectFormPayload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Get the available product types
   *
   * @param context
   * @param {array} includes - array of includes
   */
  getProductTypesList({ commit, dispatch }, includes) {
    dispatch('setProductTypesLoading', true)

    ProjectServices.productTypes
      .list(includes)
      .then((response) => {
        commit('PROJECT_PRODUCT_TYPES_LIST', response.data.data)
        dispatch('setProductTypesLoading', false)
      })
      .catch((error) => {
        dispatch('setProductTypesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving all Product Types list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the edit mode state
   * @param context
   * @param {boolean} isEditMode - the edit mode state
   */
  updateProjectSummaryEditMode({ commit }, isEditMode) {
    commit(mutations.PROJECT_SUMMARY_IS_EDIT_MODE, isEditMode)
  },

  /**
   * Update the edit project summary form payload based on user input
   *
   * @param context
   * @param {string} payload.name - the updated name of the project
   * @param {number} payload.account_manager_id - the updated id of the account manager
   * @param {number} payload.producer_id - the updated id of the producer
   * @param {number} payload.production_coordinator_id - the updated id of the production coordinator
   * @param {number} payload.director_id - the updated id of the director
   * @param {number} payload.budgeted_hours - the updated budgeted hours
   * @param {number} payload.start_date - the updated start date
   * @param {number} payload.due_date - the updated end date
   * @param {string} payload.budget - the updated budget
   * @param {string} payload.goals - the updated goals
   * @param {string} payload.platform - the updated platform
   * @param {string} payload.topic - the updated topic
   * @param {string} payload.target_audience - the updated target audience
   * @param {string} payload.video_example - the updated video example
   * @param {string} payload.additional_notes -  the updated additional notes
   * @param {number} payload.video_privacy - the updated video privacy
   */
  updateEditProjectSummaryFormPayload({ commit }, payload) {
    commit(mutations.PROJECT_SUMMARY_EDIT_PAYLOAD_INFO_UPDATE, payload)
  },

  /**
   * Update the project summary info form invalid state
   *
   * @param context
   * @param {boolean} invalid - the project summary info form invalid state
   */
  updateProjectInfoFormInvalid({ commit }, invalid) {
    commit(mutations.PROJECT_SUMMARY_INFO_INVALID_UPDATE, invalid)
  },

  /**
   * Update the project summary brief form invalid state
   *
   * @param context
   * @param {boolean} invalid - the project summary brief form invalid state
   */
  updateProjectBriefFormInvalid({ commit }, invalid) {
    commit(mutations.PROJECT_SUMMARY_BRIEF_INVALID_UPDATE, invalid)
  },

  /**
   * Update the project summary
   *
   * @param context
   * @param {number} payload.projectId - the selected project id
   * @param {array} payload.includes - the custom includes
   */
  updateProjectSummary({ dispatch, getters }, payload) {
    dispatch('setEditProjectLoading', true)

    const projectUpdatePayload = payload.payload
      ? payload.payload
      : getters.editProjectSummaryFormPayload

    ProjectServices.projects
      .update(payload.projectId, payload.includes, projectUpdatePayload)
      .then((response) => {
        dispatch('setEditProjectLoading', false)
        dispatch('setProjectDetailsList', response.data.data)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} project was updated successfully!`,
          },
          { root: true }
        )

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('setEditProjectLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while updating the project details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: projectUpdatePayload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Cancel selected project.
   *
   * @param dispatch
   * @param {object} payload - payload for processing the cancelProject service
   */
  cancelProject({ dispatch }, payload) {
    const projectId = payload.id
    const includes = payload.includes

    dispatch('setProjectDetailsLoading', true)

    ProjectServices.projects
      .cancel(projectId, includes)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} project was cancelled successfully!`,
          },
          { root: true }
        )

        dispatch('getProjectDetails', {
          projectId: projectId,
          customIncludes: ApiCustomIncludes.projectDetails,
        })
      })
      .catch((error) => {
        dispatch('setProjectDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error cancelling the Project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Confirm contact project.
   *
   * @param context
   * @param {object} payload - payload for processing the confirm contact service
   * @param {number} payload.id - the selected project id
   * @param {number} payload.office_id - selected project office id
   */
  confirmContactProject({ dispatch }, payload) {
    const projectId = payload.id

    dispatch('setConfirmContactLoading', true)
    dispatch('setProjectDetailsLoading', true)

    ProjectServices.projects
      .confirmContact(projectId, payload)
      .then(() => {
        dispatch('getProjectDetails', {
          projectId: projectId,
          customIncludes: ApiCustomIncludes.projectDetails,
        })
      })
      .catch((error) => {
        dispatch('setConfirmContactLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error confirming contacts of the project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.id,
              office_id: payload.office_id,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Reopen the existing project.
   *
   * @param context
   * @param {object} payload - the payload for the processing the reopen project action
   */
  reopenProject({ dispatch }, payload) {
    dispatch('setCreateNewProjectLoading', true)

    ProjectServices.projects
      .reopen(payload.projectId, payload.includes, payload.payload)
      .then((response) => {
        const newProjectId = response.data.data.id

        router.push({
          name: 'project.details.summary',
          params: {
            projectId: newProjectId,
          },
        })

        dispatch('setCreateNewProjectLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} project was reopened successfully!`,
          },
          { root: true }
        )

        dispatch('getProjectDetails', {
          projectId: response.data.data.id,
          customIncludes: ApiCustomIncludes.projectDetails,
        })
      })
      .catch((error) => {
        dispatch('setCreateNewProjectLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when reopening the project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Set the selected project status to archive when successful
   *
   * @param context
   * @param {Number} selectedProjectId - the selected project ID to update
   */
  setProjectArchivedStatus({ dispatch, rootGetters }, selectedProjectId) {
    const projectsListClone = cloneDeep(
      rootGetters['projects/projectsList'].data
    )

    const selectedProjectIndex = findIndex(projectsListClone, (project) => {
      return project.id === selectedProjectId
    })

    const archiveStatus = find(
      StatusesConfig.projectStatus,
      (projectStatus) => {
        return (
          projectStatus.id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED
        )
      }
    )

    if (selectedProjectIndex > -1) {
      projectsListClone[selectedProjectIndex].project_status_id =
        archiveStatus.id
      projectsListClone[selectedProjectIndex].project_status.id =
        archiveStatus.id
      projectsListClone[selectedProjectIndex].project_status.name =
        archiveStatus.text

      dispatch(
        'projects/setSelectedProject',
        projectsListClone[selectedProjectIndex],
        { root: true }
      )
    }
  },

  /**
   * Archive the existing project.
   *
   * @param context
   * @param {object} payload - the payload for the processing the archive project action
   */
  archiveProject({ dispatch }, payload) {
    dispatch('setProjectDetailsLoading', true)

    if (payload.updateProjectList) {
      // This value need to be set here for project list page archive process.
      dispatch('projects/setSelectedFieldsForUpdating', payload.selectedField, {
        root: true,
      })
      dispatch('setEditProjectLoading', true)
    }

    ProjectServices.projects
      .archive(payload, payload.includes)
      .then(() => {
        dispatch('setProjectDetailsLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} project was archived successfully!`,
          },
          { root: true }
        )

        if (payload.updateProjectList) {
          dispatch('setProjectArchivedStatus', payload.selectedField.project_id)

          dispatch(
            'projects/removeSelectedFieldsForUpdating',
            payload.selectedField,
            {
              root: true,
            }
          )

          dispatch('setEditProjectLoading', false)
        } else {
          dispatch('getProjectDetails', {
            projectId: payload.projectId,
            customIncludes: ApiCustomIncludes.projectDetails,
          })
        }
      })
      .catch((error) => {
        dispatch('setProjectDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when archiving the project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Hold the existing project.
   *
   * @param context
   * @param {object} payload - the payload for the processing the hold project action
   */
  holdProject({ dispatch }, payload) {
    dispatch('setProjectDetailsLoading', true)

    if (payload.updateProjectList) {
      // This value need to be set here for project list page hold/unhold process.
      forEach(payload.selectedFields, (selectedField) => {
        dispatch('projects/setSelectedFieldsForUpdating', selectedField, {
          root: true,
        })
      })

      dispatch('setEditProjectLoading', true)
    }

    ProjectServices.projects
      .hold(payload.projectId, payload.includes, {
        dashboard_resource: payload.updateProjectList,
      })
      .then((response) => {
        dispatch('setProjectDetailsLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} was put on hold successfully!`,
          },
          { root: true }
        )

        if (payload.updateProjectList) {
          dispatch('projects/setSelectedProject', response.data.data, {
            root: true,
          })

          forEach(payload.selectedFields, (selectedField) => {
            dispatch(
              'projects/removeSelectedFieldsForUpdating',
              selectedField,
              {
                root: true,
              }
            )
          })

          dispatch('setEditProjectLoading', false)
        } else {
          dispatch('getProjectDetails', {
            projectId: payload.projectId,
            customIncludes: ApiCustomIncludes.projectDetails,
          })
        }
      })
      .catch((error) => {
        dispatch('setProjectDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error when putting on hold the project.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Unhold the existing project.
   *
   * @param context
   * @param {object} payload - the payload for the processing the unhold project action
   */
  unholdProject({ dispatch }, payload) {
    dispatch('setProjectDetailsLoading', true)

    if (payload.updateProjectList) {
      // This value need to be set here for project list page unhold process.
      forEach(payload.selectedFields, (selectedField) => {
        dispatch('projects/setSelectedFieldsForUpdating', selectedField, {
          root: true,
        })
      })

      dispatch('setEditProjectLoading', true)
    }

    ProjectServices.projects
      .unhold(payload.projectId, payload.includes, {
        dashboard_resource: payload.updateProjectList,
      })
      .then((response) => {
        dispatch('setProjectDetailsLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} was taken off hold successfully!`,
          },
          { root: true }
        )

        if (payload.updateProjectList) {
          dispatch('projects/setSelectedProject', response.data.data, {
            root: true,
          })

          forEach(payload.selectedFields, (selectedField) => {
            dispatch(
              'projects/removeSelectedFieldsForUpdating',
              selectedField,
              {
                root: true,
              }
            )
          })

          dispatch('setEditProjectLoading', false)
        } else {
          dispatch('getProjectDetails', {
            projectId: payload.projectId,
            customIncludes: ApiCustomIncludes.projectDetails,
          })
        }
      })
      .catch((error) => {
        dispatch('setProjectDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: `Error when taking off hold the project.`,
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Set the total deliveries value for  project
   *
   * @param context
   * @param {number} total - the total deliveries
   */
  setTotalDeliveries({ commit }, total) {
    commit(mutations.PROJECT_DETAILS_TOTAL_DELIVERIES, total)
  },

  /**
   * Set the project menu scrollable state
   *
   * @param context
   * @param {boolean} isScrollable - the scrollable state
   */
  setProjectMenuScrollable({ commit }, isScrollable) {
    commit(mutations.IS_PROJECT_MENU_SCROLLABLE, isScrollable)
  },

  /*
   * Set target audience type loading state.
   *
   * @param context
   * @param {Boolean} isLoading
   */
  setTargetAudienceTypesLoading({ commit }, isLoading) {
    commit(mutations.TARGET_AUDIENCE_TYPES_LOADING, isLoading)
  },

  /**
   * Set target audience type loading state.
   *
   * @param context
   * @param {Array} targetAudienceTypes
   */
  setTargetAudienceTypes({ commit }, targetAudienceTypes) {
    commit(mutations.TARGET_AUDIENCE_TYPES, targetAudienceTypes)
  },

  /**
   * Get list of target audience types.
   *
   * @param  context
   * @param  {Array} includes
   */
  getTargetAudienceTypes({ dispatch }, includes) {
    dispatch('setTargetAudienceTypesLoading', true)

    ProjectServices.targetAudienceTypes
      .list(includes)
      .then((response) => {
        dispatch('setTargetAudienceTypesLoading', false)
        dispatch('setTargetAudienceTypes', response.data.data)
      })
      .catch((error) => {
        dispatch('setTargetAudienceTypesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while fetching target audience type list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Set the project summary form payload state.
   *
   * @param context
   * @param {Object} data
   */
  setEditProjectSummaryFormPayload({ commit }, data) {
    commit(mutations.EDIT_PROJECT_SUMMARY_FORM_PAYLOAD, data)
  },

  /**
   * Set the total hero videos value for  project
   *
   * @param context
   * @param {number} total - the total hero videos
   */
  setTotalHeroVideos({ commit }, total) {
    commit(mutations.PROJECT_DETAILS_TOTAL_HERO_VIDEOS, total)
  },

  /**
   * Set show state of archive project modal.
   *
   * @param context
   * @param {Boolean} show
   */
  toggleArchiveProjectModal({ commit }, show) {
    commit(mutations.PROJECT_ARCHIVE_MODAL_SHOW, show)
  },

  /**
   * Set show state of archive project modal.
   *
   * @param context
   * @param {Boolean} show
   */
  toggleEodNotesModal({ commit }, show) {
    commit(mutations.PROJECT_EOD_NOTES_MODAL_SHOW, show)
  },

  /**
   * Set loading status of footage status.
   *
   * @param context
   * @param {Boolena} loading
   */
  setFootageStatusLoading({ commit }, loading) {
    commit(mutations.PROJECT_FOOTAGE_STATUS_LOADING, loading)
  },

  /**
   * Update project footage status.
   *
   * @param {Object} context
   * @param {Object} payload
   */
  updateFootageStatus({ dispatch }, payload) {
    dispatch('setFootageStatusLoading', true)
    ProjectServices.projects
      .updateFootageStatus(payload)
      .then((response) => {
        dispatch('setFootageStatusLoading', false)
        dispatch('setProjectDetailsList', response.data.data)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.project_name} footage status was updated successfully!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setFootageStatusLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while updating footage status.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Update loading state of budget page/section.
   *
   * @param {object} context
   * @param {boolean} loading
   */
  setInvoiceBudgetLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_INVOICE_BUDGET, loading)
  },

  /**
   * Update approved budget of a project.
   *
   * @param {object} context
   * @param {object} payload
   * @param {number} payload.project_id
   * @param {number} payload.value
   */
  updateBudget({ commit, dispatch }, payload) {
    dispatch('setInvoiceBudgetLoading', true)

    ProjectServices.projects.budget
      .update(payload)
      .then((response) => {
        dispatch('setInvoiceBudgetLoading', false)
        commit(mutations.PROJECT_INVOICE_BUDGET, response.data.data)

        dispatch(
          'common/displayToastAlert',
          { title: 'Disbursement budget successfully updated.' },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setInvoiceBudgetLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to update invoice disbursement budget.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Get budget info of a project.
   *
   * @param {object} context
   * @param {object} payload
   * @param {number} payload.project_id
   * @param {number} payload.value
   */
  getBudget({ commit, dispatch }, payload) {
    dispatch('setInvoiceBudgetLoading', true)
    ProjectServices.projects.budget
      .get(payload)
      .then((response) => {
        dispatch('setInvoiceBudgetLoading', false)
        commit(mutations.PROJECT_INVOICE_BUDGET, response.data.data)
      })
      .catch((error) => {
        dispatch('setInvoiceBudgetLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to get invoice budget info of a project.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Create budget spreadsheet for project.
   *
   * @param {object} context
   * @param {object} payload
   * @param {number} payload.project_id
   */
  createBudgetSpreadsheet({ commit, dispatch }, payload) {
    dispatch('setInvoiceBudgetLoading', true)

    ProjectServices.projects.budget
      .createSpreadsheet(payload)
      .then((response) => {
        dispatch('setInvoiceBudgetLoading', false)

        commit(mutations.PROJECT_INVOICE_BUDGET, response.data.data)

        dispatch(
          'common/displayToastAlert',
          { title: 'Budget spreadsheet generated.' },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setInvoiceBudgetLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to create invoice budget spreadsheet.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Update the loading state for the hubspot quotes
   * @param {object} context
   * @param {boolean} loading - The loading state
   */
  setHubspotQuotesLoading({ commit }, loading) {
    commit(mutations.PROJECT_HUBSPOT_QUOTES_LOADING, loading)
  },

  /**
   * Set the hubspot quotes data
   * @param {object} context
   * @param {array} hubspotQuotes - The hubspot quotes data
   */
  setHubspotQuotes({ commit }, hubspotQuotes) {
    commit(mutations.PROJECT_HUBSPOT_QUOTES, hubspotQuotes)
  },

  /**
   * Action to get the hubspot quotes
   * @param {object} context
   * @param {number} projectId - The project ID
   */
  getHubspotQuotes({ dispatch }, projectId) {
    dispatch('setHubspotQuotesLoading', true)

    ProjectServices.projects.hubspot.quotes
      .get(projectId)
      .then((response) => {
        dispatch('setHubspotQuotesLoading', false)

        dispatch('setHubspotQuotes', response.data.data)
      })
      .catch((error) => {
        dispatch('setHubspotQuotesLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to get hubSpot quotes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set eod notes project loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEodNotesProjectLoading({ commit }, loading) {
    commit(mutations.EOD_NOTES_PROJECT_LOADING, loading)
  },

  /**
   * Action to set eod notes job loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEodNotesJobLoading({ commit }, loading) {
    commit(mutations.EOD_NOTES_JOB_LOADING, loading)
  },

  /**
   * Action to set eod notes pinned loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEodNotesPinnedLoading({ commit }, loading) {
    commit(mutations.EOD_NOTES_PINNED_LOADING, loading)
  },

  /**
   * Action to set eod notes job list loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEodNotesJobListLoading({ commit }, loading) {
    commit(mutations.EOD_NOTES_JOB_LIST_LOADING, loading)
  },

  /**
   * Action to set eod notes save button loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setEodNotesSaveBtnLoading({ commit }, loading) {
    commit(mutations.EOD_NOTES_SAVE_BTN_LOADING, loading)
  },

  /**
   * Set project jobs list.
   *
   * @param context
   * @param {Array} jobList
   */
  setProjectJobsList({ commit }, jobList) {
    commit(mutations.PROJECT_JOBS_LIST, jobList)
  },

  /**
   * Get the job list for eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getProjectJobsList({ dispatch }, payload) {
    dispatch('setEodNotesJobListLoading', true)

    ProjectServices.projects.jobs
      .list(payload)
      .then((response) => {
        dispatch('setEodNotesJobListLoading', false)

        if (response.data.data) {
          dispatch('setProjectJobsList', response.data.data)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesJobListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get project jobs list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set project eod notes.
   *
   * @param context
   * @param {Array} notes
   */
  setProjectEodNotes({ commit }, notes) {
    commit(mutations.PROJECT_EOD_NOTES, notes)
  },

  /**
   * Get the project level eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getProjectEodNotes({ dispatch }, payload) {
    dispatch('setEodNotesProjectLoading', true)

    ProjectServices.projects.eodNotes
      .project(payload)
      .then((response) => {
        dispatch('setEodNotesProjectLoading', false)

        if (response.data.data) {
          dispatch('setProjectEodNotes', response.data.data)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesProjectLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get project eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set jobs eod notes.
   *
   * @param context
   * @param {Array} notes
   */
  setJobsEodNotes({ commit }, notes) {
    commit(mutations.JOBS_EOD_NOTES, notes)
  },

  /**
   * Get the jobs eod notes on project level
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getProjectJobsEodNotes({ dispatch }, payload) {
    dispatch('setEodNotesJobLoading', true)

    ProjectServices.projects.eodNotes
      .projectJobs(payload)
      .then((response) => {
        dispatch('setEodNotesJobLoading', false)
        if (response.data.data) {
          dispatch('setJobsEodNotes', response.data.data)
          dispatch('setEodNotesNextPageLink', response.data.links.next)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesJobLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get project jobs eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Get the jobs eod notes on project level for next page
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getProjectJobsNextPageEodNotes({ dispatch, getters }, payload) {
    dispatch('setEodNotesJobLoading', true)

    ProjectServices.projects.eodNotes
      .projectJobs(payload)
      .then((response) => {
        dispatch('setEodNotesJobLoading', false)
        if (response.data.data) {
          let jobsEodNotesClone = cloneDeep(getters.jobsEodNotes)
          let newJobsEodNotes = concat(jobsEodNotesClone, response.data.data)

          dispatch('setJobsEodNotes', newJobsEodNotes)
          dispatch('setEodNotesNextPageLink', response.data.links.next)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesJobLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get project jobs next page eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set pinned eod notes.
   *
   * @param context
   * @param {Array} notes
   */
  setPinnedEodNotes({ commit }, notes) {
    commit(mutations.PINNED_EOD_NOTES, notes)
  },

  /**
   * Get pinned eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getPinnedEodNotes({ dispatch }, payload) {
    dispatch('setEodNotesPinnedLoading', true)

    ProjectServices.projects.eodNotes
      .pinned(payload)
      .then((response) => {
        dispatch('setEodNotesPinnedLoading', false)

        if (response.data.data) {
          dispatch('setPinnedEodNotes', response.data.data)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesPinnedLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get pinned eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Pin eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  pinEodNotes({ dispatch, getters }, payload) {
    ProjectServices.projects.eodNotes
      .toggle(payload)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `Note was pinned successfully`,
          },
          { root: true }
        )

        let noteId = payload.note.id

        // Add to pinned store
        let pinnedNotes = cloneDeep(getters.pinnedEodNotes)
        let payloadCopy = cloneDeep(payload.note)

        payloadCopy.is_pinned = true
        pinnedNotes.push(payloadCopy)

        dispatch('setPinnedEodNotes', pinnedNotes)

        // Pin from project eod store if any
        let projectNotesClone = cloneDeep(getters.projectEodNotes)

        const selectedNote = findIndex(projectNotesClone, (note) => {
          return note.id === noteId
        })

        if (selectedNote > -1) {
          projectNotesClone[selectedNote].is_pinned = true

          dispatch('setProjectEodNotes', projectNotesClone)
        }

        // Pin from jobs eod store if any
        let jobNotesClone = cloneDeep(getters.jobsEodNotes)

        forEach(jobNotesClone, (jobNotes) => {
          const selectedNote = findIndex(jobNotes.eod_notes, (note) => {
            return note.id === noteId
          })

          if (selectedNote > -1) {
            jobNotes.eod_notes[selectedNote].is_pinned = true
          }
        })

        dispatch('setJobsEodNotes', jobNotesClone)
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to pin eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Un-pin eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  unpinEodNotes({ dispatch, getters }, payload) {
    ProjectServices.projects.eodNotes
      .toggle(payload)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `Note was un-pinned successfully`,
          },
          { root: true }
        )

        let noteId = payload.note.id

        // Removed from pinned store
        let pinnedNotesClone = cloneDeep(getters.pinnedEodNotes)

        const selectedNoteIndex = findIndex(pinnedNotesClone, (note) => {
          return note.id === noteId
        })

        if (selectedNoteIndex > -1) {
          pullAt(pinnedNotesClone, [selectedNoteIndex])

          dispatch('setPinnedEodNotes', pinnedNotesClone)
        }

        // Unpin from project eod store if any
        let projectNotesClone = cloneDeep(getters.projectEodNotes)

        const selectedNote = findIndex(projectNotesClone, (note) => {
          return note.id === noteId
        })

        if (selectedNote > -1) {
          projectNotesClone[selectedNote].is_pinned = false

          dispatch('setProjectEodNotes', projectNotesClone)
        }

        // Unpin from jobs eod store if any
        let jobNotesClone = cloneDeep(getters.jobsEodNotes)

        forEach(jobNotesClone, (jobNotes) => {
          const selectedNote = findIndex(jobNotes.eod_notes, (note) => {
            return note.id === noteId
          })

          if (selectedNote > -1) {
            jobNotes.eod_notes[selectedNote].is_pinned = false
          }
        })

        dispatch('setJobsEodNotes', jobNotesClone)
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to un-pin eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Create project level eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  createProjectEodNotes({ dispatch, getters }, payload) {
    ProjectServices.projects.eodNotes
      .createProjectEod(payload)
      .then((response) => {
        if (response.data.data) {
          let projectNotesClone = cloneDeep(getters.projectEodNotes)
          let newProjectNotes = concat(response.data.data, projectNotesClone)

          dispatch('setProjectEodNotes', newProjectNotes)
        }
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to create project eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Create job level eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  createJobEodNotes({ dispatch, getters }, payload) {
    ProjectServices.projects.eodNotes
      .createJobEod(payload)
      .then((response) => {
        if (response.data.data) {
          let jobNotesClone = cloneDeep(getters.jobsEodNotes)

          forEach(jobNotesClone, (jobNotes) => {
            if (jobNotes.id === payload.jobId) {
              jobNotes.eod_notes = concat(
                response.data.data,
                jobNotes.eod_notes
              )
            }
          })

          dispatch('setJobsEodNotes', jobNotesClone)
        }
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to create job eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set selected eod note type id.
   *
   * @param context
   * @param {Array} notes
   */
  setSelectedEodNoteTypeId({ commit }, id) {
    commit(mutations.EOD_NOTES_SELECTED_TYPE_ID, id)
  },

  /**
   * Get job level eod notes
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  getJobEodNotes({ dispatch }, payload) {
    dispatch('setEodNotesJobLoading', true)

    ProjectServices.projects.eodNotes
      .job(payload)
      .then((response) => {
        dispatch('setEodNotesJobLoading', false)

        if (response.data.data) {
          dispatch('setJobsEodNotes', [response.data.data])
          dispatch('setEodNotesNextPageLink', null)
        }
      })
      .catch((error) => {
        dispatch('setEodNotesJobLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to get job eod notes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Update job additional data
   *
   * @param context
   * @param {array} payload - payload of the request
   */
  updateJobAdditionalData({ dispatch }, payload) {
    dispatch('setEodNotesSaveBtnLoading', true)

    ProjectServices.projects.eodNotes
      .updateJobAdditionalData(payload)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `Job additional data was updated successfully.`,
          },
          { root: true }
        )

        dispatch('getJobEodNotes', payload)
        dispatch('getProjectJobsList', payload)
        dispatch('setEodNotesSaveBtnLoading', false)
      })
      .catch((error) => {
        dispatch('setEodNotesSaveBtnLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to update job additional data.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set eod notes next page link.
   *
   * @param context
   * @param {Array} notes
   */
  setEodNotesNextPageLink({ commit }, link) {
    commit(mutations.EOD_NOTES_NEXT_PAGE_LINK, link)
  },

  /**
   * Set eod notes selected project.
   *
   * @param context
   * @param {Object} project
   */
  setEodNotesSelectedProject({ commit }, project) {
    commit(mutations.EOD_NOTES_SELECTED_PROJECT, project)
  },

  /**
   * Set project status.
   *
   * @param context
   * @param {Object} projectStatus
   */
  setProjectStatus({ commit }, projectStatus) {
    commit(mutations.PROJECT_DETAILS_PROJECT_STATUS, projectStatus)
  },
}
