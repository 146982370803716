/**
 *  Product type icons.
 */
import VmsConstants from '@configs/vms-constants'

export default Object.freeze({
  productTypes: [
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_MADE,
      icon: 'icon-buildings',
      text: 'Real Estate',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_DATACREATIVE,
      icon: 'icon-user-tag',
      text: 'Personalised / Interactive',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_SOCIAL_MEDIA,
      icon: 'icon-thumbs-up',
      text: 'Social Media',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_SERIES,
      icon: 'icon-folder-grid',
      text: 'Series',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_BRAND_STORIES,
      icon: 'icon-camcorder',
      text: 'Brand Stories',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_ANIMATION,
      icon: 'icon-rabbit-running',
      text: 'Animation',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_PRODUCT,
      icon: 'icon-shirt',
      text: 'Product',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_EVENTS,
      icon: 'icon-champagne-glasses',
      text: 'Events',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_TVC,
      icon: 'icon-tv',
      text: 'TVC',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_CASE_STUDIES_AND_TESTIMONIALS,
      icon: 'icon-presentation-screen',
      text: 'Case Studies & Testimonials',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_LIVE_STREAMING,
      icon: 'icon-screencast',
      text: 'Live Streaming',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_TRAINING_AND_HR,
      icon: 'icon-chalkboard-user',
      text: 'Training & HR',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_INTERACTIVE,
      icon: 'icon-hand-pointer',
      text: 'News Corp',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_CREATE,
      icon: 'icon-photo-film',
      text: 'Creative Content',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_MISCELLANEOUS,
      icon: 'icon-photo-film',
      text: 'Miscellaneous',
    },
    {
      id: VmsConstants.productTypes.PRODUCT_TYPE_VIDEO_AI,
      icon: 'icon-sparkles',
      text: 'Medium Rare',
    },
  ],
})
