import Axios from 'axios'
import store from '@store/store'
import AppConfig from '@configs/app-config'

// Add a request interceptor.
// See: https://github.com/axios/axios#request-config
Axios.interceptors.request.use(
  (config) => {
    // Set base URL.
    config.baseURL = AppConfig.vmsApiUrl

    // Set accepted content type.
    config.headers.common.Accept = 'application/json'

    // Set authorization header if we have an access token.
    config.headers.common.Authorization = `Bearer ${store.getters['auth/accessToken']}`

    return config
  },
  (error) => {
    // Must return error.
    return Promise.reject(error)
  }
)

Axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // If request was cancelled return with no error
    if (Axios.isCancel(error)) {
      return Promise.reject({ isCancelled: true })
    }

    if (!error.response || (error.response && error.response.status === 401)) {
      store.dispatch('auth/signOut')
    }

    return Promise.reject(error)
  }
)

export default Axios
