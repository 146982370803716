import Axios from '@plugins/axios'

export default (payload) => {
  const projectId = payload.project_id
  const shootJobId = payload.shoot_job_id

  let url = `api/v2/internal/projects/${projectId}/jobs/shoots/${shootJobId}/call-sheet/manual`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  return Axios.put(url, payload, config)
}
