/**
 * @module store/calendar/getters
 */

import momentTz from 'moment-timezone'
import {
  some,
  filter,
  forEach,
  map,
  orderBy,
  omit,
  union,
  isEmpty,
} from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import Colours from '@configs/colours'
import EventUserGroups from '@configs/event-user-groups'
import VmsConstants from '@configs/vms-constants'

export default {
  /**
   * Get the selected filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Selected filters
   */
  selectedFilters(state) {
    return state.selectedFilters
  },

  /**
   * Get the saved filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Saved filters
   */
  savedFilters(state) {
    return state.filtersList.savedFilters
  },

  /**
   * Get the owned by id filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Owned by id filters
   */
  selectedOwnedByIdFilters(state) {
    return state.selectedOwnedByIdFilters
  },

  /**
   * Get the owned by id filters array
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Owned by id filters
   */
  selectedOwnedByIdFiltersArray(state) {
    return map(state.selectedOwnedByIdFilters, 'id')
  },

  /**
   * Get the team filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Team filters
   */
  teamFilters(state) {
    return state.filtersList.teamFilters
  },

  /**
   * Get the event filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Event filters
   */
  eventFilters(state) {
    return state.filtersList.eventFilters
  },

  /**
   * Get the crew filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Crew filters
   */
  crewFilters(state) {
    return state.filtersList.crewFilters
  },

  /**
   * Get the project owner filters
   *
   * @param {Object} state - State for this module
   * @returns {Array} - project owner filters
   */
  projectOwnerFilters(state) {
    return state.filtersList.projectOwnerFilters
  },

  /**
   * Get the results of filters search
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Results of filters search
   */
  searchFiltersResult(state) {
    return state.searchFiltersResult
  },

  /**
   * Get show all filters modal state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show all filters modal state
   */
  canShowAllFiltersModal(state) {
    return state.modal.showAllFiltersModal
  },

  /**
   * Get show save filters modal state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show save filters modal state
   */
  canShowSaveFiltersModal(state) {
    return state.modal.saveFiltersModal
  },

  /**
   * Get show on hold events modal state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show on hold events modal state
   */
  canShowOnHoldEventsModal(state) {
    return state.modal.onHoldEventsModal
  },

  /**
   * Get show deleted events modal state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show deleted events modal state
   */
  canShowDeletedEventsModal(state) {
    return state.modal.deletedEventsModal
  },

  /**
   * Get the on hold events list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - On hold events list
   */
  onHoldEvents(state) {
    return state.onHoldEvents
  },

  /**
   * Get deleted events list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Deleted events list
   */
  deletedEvents(state) {
    return state.deletedEvents
  },

  /**
   * Get events list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Events list
   */
  eventsList(state) {
    return state.eventsList
  },

  /**
   * Get events list loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Events list loading state
   */
  isEventsListLoading(state) {
    return state.loading.eventsList
  },

  /**
   * Get selected event
   *
   * @param {Object} state - State for this module
   * @returns {Object} - Selected event
   */
  selectedEvent(state) {
    return state.selectedEvent
  },

  /**
   * Set selected view type
   *
   * @param {Object} state - State for this module
   * @returns {String} - Selected view type
   */
  selectedViewType(state) {
    return state.viewType
  },

  /**
   * Get scheduler saved state from local storage
   *
   * @returns {Object} - Scheduler saved state from local storage
   */
  schedulerSavedState() {
    return JSON.parse(localStorage.getItem('scheduleVmsCalendar'))
  },

  /**
   * Get selected view type details
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Object} - Selected view type details
   */
  selectedViewTypeDetails(state, getters) {
    const selectedViewType = getters.selectedViewType

    // Default selected date for start and end
    let startDate = momentTz()
    let endDate = momentTz()

    // If selected calendar date is available, use it for start and end date
    if (getters.selectedCalendarDate) {
      startDate = momentTz(getters.selectedCalendarDate)
      endDate = momentTz(getters.selectedCalendarDate)
    }

    // If scheduler saved state is available and no selected calendar date, use the selected date from the saved state
    if (getters.schedulerSavedState && !getters.selectedCalendarDate) {
      startDate = momentTz(getters.schedulerSavedState.selectedDate)
      endDate = momentTz(getters.schedulerSavedState.selectedDate)
    }

    let selectedStartDate, selectedEndDate

    switch (selectedViewType) {
      case 'Day':
        selectedStartDate = startDate.startOf('day')
        selectedEndDate = endDate.endOf('day')
        break
      case 'Week':
        selectedStartDate = startDate.startOf('week')
        selectedEndDate = endDate.endOf('week')
        break
      case 'Month':
        selectedStartDate = startDate.startOf('month').subtract(6, 'days')
        selectedEndDate = endDate.endOf('month').add(6, 'days')
        break
      case 'Agenda':
        selectedStartDate = startDate.startOf('year').subtract(1, 'years')
        selectedEndDate = endDate.endOf('year').add(4, 'years')
        break
      case 'TimelineMonth':
        selectedStartDate = startDate.startOf('month')
        selectedEndDate = endDate.endOf('month')
        break
      default:
        break
    }

    return {
      start: selectedStartDate.format('YYYY-MM-DD HH:mm:ss'),
      end: selectedEndDate.format('YYYY-MM-DD HH:mm:ss'),
    }
  },

  /**
   * Get localStorage selected filters saved in store
   *
   * @returns {Array} - Selected filters from local storage
   */
  localStorageSelectedFilters(state) {
    return state.localStorageSelectedFilters
      ? state.localStorageSelectedFilters
      : JSON.parse(localStorage.getItem('selectedFilters'))
  },

  /**
   * Get show events assigned to me filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show events assigned to me filter selected state
   */
  isShowEventsAssignedToMeFilterSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasAssignedToMeFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.assigned_to_me
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-events-assigned-to-me',
        label: 'Show events assigned to me',
        value: 'show-events-assigned-to-me',
      }) || hasAssignedToMeFilterSaved
    )
  },

  /**
   * Get show freelancer filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show freelancer filter selected state
   */
  isShowFreelancerFilterSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasFreelancerFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.include_freelancer_events
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-freelancer-events',
        label: 'Show freelancers',
        value: 'show-freelancer-events',
      }) || hasFreelancerFilterSaved
    )
  },

  /**
   * Get show unassigned filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show unassigned filter selected state
   */
  isShowUnassignedFilterSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasUnassignedFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.unassigned
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-unassigned-events',
        label: 'Show unassigned events',
        value: 'show-unassigned-events',
      }) || hasUnassignedFilterSaved
    )
  },

  /**
   * Get show events I own filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show events I own filter selected state
   */
  isShowEventsIOwnFilterSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasIOwnFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.i_own
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-events-i-own',
        label: 'Show events I own',
        value: 'show-events-i-own',
      }) || hasIOwnFilterSaved
    )
  },

  /**
   * Get show google/bamboo events filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show google/bamboo events filter selected state
   */
  isShowGoogleBambooEventsSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasShowGoogleBambooEventsFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.include_bamboo_events ||
        getters.selectedSavedFilter.data.include_google_events
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-google-bamboo-events',
        label: 'Show Google/Bamboo events',
        value: 'show-google-bamboo-events',
      }) || hasShowGoogleBambooEventsFilterSaved
    )
  },

  /**
   * Get show on hold events filter selected state
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - Show on hold events filter selected state
   */
  isShowOnHoldEventsSelected(state, getters) {
    // Check if this filter is in the selected save filter
    const hasShowOnHoldEventsFilterSaved = getters.selectedSavedFilter
      ? getters.selectedSavedFilter.data.include_on_hold_events
      : false

    return (
      some(getters.selectedFilters, {
        id: 'show-on-hold-events',
        label: 'Show on hold events',
        value: 'show-on-hold-events',
      }) || hasShowOnHoldEventsFilterSaved
    )
  },

  /**
   * Get selected assigned to filters
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Array} - Selected assigned to filters
   */
  selectedAssignedToIdFilters(state, getters) {
    const teamFiltersList = []

    forEach(getters.teamFilters, (teamFilter) => {
      forEach(teamFilter.subFilters, (subFilter) => {
        teamFiltersList.push(subFilter)
      })
    })

    // Check if the selected filter is in the team or crew filters
    const selectedUsers = filter(getters.selectedFilters, (selectedFilter) => {
      return (
        some(teamFiltersList, selectedFilter) ||
        some(getters.crewFilters, selectedFilter)
      )
    })

    const savedAssignedToIds = []

    // Check if the selected filter is in the saved filters
    if (getters.selectedSavedFilter) {
      const savedFilterAssignedTo =
        getters.selectedSavedFilter.data.assigned_to_ids

      // Get the selected assigned to ids from the saved filters
      if (savedFilterAssignedTo.length > 0) {
        forEach(savedFilterAssignedTo, (savedAssignedTo) => {
          savedAssignedToIds.push(savedAssignedTo)
        })
      }
    }

    const selectedAssignedToIds = map(selectedUsers, 'id')

    // Return the union of the selected assigned to ids from the team/crew filters and the saved filters
    return union(selectedAssignedToIds, savedAssignedToIds)
  },

  /**
   * Get selected event type filters
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @returns {Array} - Selected event type filters
   */
  selectedEventTypeIdFilters(state, getters) {
    const eventTypesFilterList = []

    forEach(getters.eventFilters, (eventFilter) => {
      forEach(eventFilter.subFilters, (subFilter) => {
        eventTypesFilterList.push(subFilter)
      })
    })

    // Check if the selected filter is in the event type filters
    const selectedEventTypes = filter(
      getters.selectedFilters,
      (selectedFilter) => {
        return some(eventTypesFilterList, selectedFilter)
      }
    )

    const savedEventTypeIds = []

    // Check if the selected filter is in the saved filters
    if (getters.selectedSavedFilter) {
      const savedFilterEventTypes =
        getters.selectedSavedFilter.data.event_type_ids

      // Get the selected event type ids from the saved filters
      if (savedFilterEventTypes.length > 0) {
        forEach(savedFilterEventTypes, (savedEventType) => {
          savedEventTypeIds.push(savedEventType)
        })
      }
    }

    const selectedEventTypeIds = map(selectedEventTypes, 'id')

    // Return the union of the selected event type ids from the event type filters and the saved filters
    return union(selectedEventTypeIds, savedEventTypeIds)
  },

  /**
   * Get filters list in order, where the selected ones first and the unselected ones last
   *
   * @param {Object} state - State for this module
   * @param {Object} getters - Getters for this module
   * @param {String} filterType - Type of filter
   * @param {Boolean} hasSubFilter - Whether the filter has sub filters
   * @returns {Array} - Filters list in order
   */
  filtersListOrdered: (state, getters) => (filterType, hasSubFilter) => {
    // order event type filters when event type filter is selected
    const mainFilters = []

    let selectedFilterType = null

    switch (filterType) {
      case 'eventFilters':
        selectedFilterType = getters.eventFilters
        break
      case 'crewFilters':
        selectedFilterType = getters.crewFilters
        break
      case 'teamFilters':
        selectedFilterType = getters.teamFilters
        break
      case 'savedFilters':
        selectedFilterType = getters.savedFilters
        break
      case 'projectOwnerFilters':
        selectedFilterType = getters.projectOwnerFilters
        break
      default:
        selectedFilterType = null
    }

    const mainFilterWithOrder = []
    const orderOmittedMainFilters = []

    forEach(selectedFilterType, (selectedFilterType) => {
      const subFiltersWithOrder = []

      if (hasSubFilter) {
        forEach(selectedFilterType.subFilters, (subFilter) => {
          // if filter is selected, order it first
          if (some(getters.selectedFilters, subFilter)) {
            subFiltersWithOrder.push({
              order: 1,
              ...subFilter,
            })
          } else {
            subFiltersWithOrder.push({
              order: 2,
              ...subFilter,
            })
          }
        })
      } else {
        if (filterType === 'savedFilters') {
          mainFilterWithOrder.push({
            order: some(getters.selectedFilters, selectedFilterType) ? 1 : 2,
            id: selectedFilterType.id,
            label: selectedFilterType.label,
            value: selectedFilterType.value,
            data: selectedFilterType.data,
          })
        } else {
          const filterWithOrder = {
            order: some(getters.selectedFilters, ['id', selectedFilterType.id])
              ? 1
              : 2,
            id: selectedFilterType.id,
            label: selectedFilterType.label,
            value: selectedFilterType.value,
          }

          if (filterType === 'crewFilters') {
            filterWithOrder.status = selectedFilterType.status
          }

          if (filterType === 'projectOwnerFilters') {
            filterWithOrder.order = some(getters.selectedOwnedByIdFilters, [
              'id',
              selectedFilterType.id,
            ])
              ? 1
              : 2

            filterWithOrder.is_project_owner =
              selectedFilterType.is_project_owner
          }

          mainFilterWithOrder.push(filterWithOrder)
        }
      }

      if (hasSubFilter) {
        const orderedSubFilters = orderBy(subFiltersWithOrder, ['order', 'asc'])
        const orderOmittedSubFilters = []

        forEach(orderedSubFilters, (subFilter) => {
          // omit order property to keep the original object
          orderOmittedSubFilters.push(omit(subFilter, ['order']))
        })

        // push the main filter with the ordered sub filters
        mainFilters.push({
          ...selectedFilterType,
          subFilters: orderOmittedSubFilters,
        })
      }
    })

    if (!hasSubFilter) {
      const orderedMainFilter = orderBy(mainFilterWithOrder, ['order', 'asc'])

      forEach(orderedMainFilter, (mainFilter) => {
        // omit order property to keep the original object
        orderOmittedMainFilters.push(omit(mainFilter, ['order']))
      })

      // push the main filter with the ordered sub filters
      forEach(orderOmittedMainFilters, (orderOmittedMainFilter) => {
        mainFilters.push(orderOmittedMainFilter)
      })
    }

    return mainFilters
  },

  /**
   * Get the agenda list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Agenda list
   */
  agendaList(state) {
    return state.agendaList
  },

  /**
   * Get agenda list loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Agenda list loading state
   */
  isAgendaListLoading(state) {
    return state.loading.agendaList
  },

  /**
   * Get event types loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Event types loading state
   */
  isEventTypesLoading(state) {
    return state.loading.eventTypes
  },

  /**
   * Get selected saved filter
   *
   * @param {Object} state - State for this module
   * @returns {Object} - Selected saved filter
   */
  selectedSavedFilter(state) {
    return state.selectedSavedFilter
  },

  /**
   * Get on hold events list loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - On hold events list loading state
   */
  isOnHoldEventsListLoading(state) {
    return state.loading.onHoldEvents
  },

  /**
   * Get selected on hold event loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Selected on hold event loading state
   */
  isSelectedOnHoldEventLoading(state) {
    return state.loading.selectedOnHoldEvent
  },

  /**
   * Get selected on hold event ID
   *
   * @param {Object} state - State for this module
   * @returns {Number} - Selected on hold event ID
   */
  selectedOnHoldEventId(state) {
    return state.selectedOnHoldEventId
  },

  /**
   * Get the loading state of event details
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Loading state for event details
   */
  isEventDetailsLoading(state) {
    return state.loading.eventDetails
  },

  /**
   * Get the loading state of event form
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Loading state for event form
   */
  isEventFormLoading(state) {
    return state.loading.eventForm
  },

  /**
   * Get show events deliveries edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events deliveries edit modal
   */
  showEventsDeliveriesEditModal(state) {
    return state.modal.showEventsDeliveriesEditModal
  },

  /**
   * Get show events filming details edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events filming details edit modal
   */
  showEventsFilmingDetailsEditModal(state) {
    return state.modal.showEventsFilmingDetailsEditModal
  },

  /**
   * Get show events job changes edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events job changes edit modal
   */
  showEventsJobChangesEditModal(state) {
    return state.modal.showEventsJobChangesEditModal
  },

  /**
   * Get show events pre productions edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events pre productions edit modal
   */
  showEventsPreProductionsEditModal(state) {
    return state.modal.showEventsPreProductionsEditModal
  },

  /**
   * Get show events pre-production changes edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events pre-production changes edit modal
   */
  showEventsPreProductionChangesEditModal(state) {
    return state.modal.showEventsPreProductionChangesEditModal
  },

  /**
   * Get show events placeholders edit modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events placeholders edit modal
   */
  showEventsPlaceholdersEditModal(state) {
    return state.modal.showEventsPlaceholdersEditModal
  },

  /**
   * Get show events deliveries create modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events deliveries create modal
   */
  showEventsDeliveriesCreateModal(state) {
    return state.modal.showEventsDeliveriesCreateModal
  },

  /**
   * Get show events filming details create modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events filming details create modal
   */
  showEventsFilmingDetailsCreateModal(state) {
    return state.modal.showEventsFilmingDetailsCreateModal
  },

  /**
   * Get show events pre-productions changes create modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events pre-productions changes create modal
   */
  showEventsPreProductionsCreateModal(state) {
    return state.modal.showEventsPreProductionsCreateModal
  },

  /**
   * Get show events pre-production create modal
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events pre-production create modal
   */
  showEventsPlaceholdersCreateModal(state) {
    return state.modal.showEventsPlaceholdersCreateModal
  },

  /**
   * Get selected schedules
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Selected schedules
   */
  selectedSchedules(state) {
    return state.selectedSchedules
  },

  /**
   * Get the loading state of projects list
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Loading state for projects list
   */
  isProjectsListLoading(state) {
    return state.loading.projectsList
  },

  /**
   * Get projects list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Projects list
   */
  projectsList(state) {
    return state.projectsList
  },

  /**
   * Get event types list
   *
   * @param {Object} state - State for this module
   * @returns {Array} - Event types list
   */
  eventTypesList(state) {
    return state.eventTypesList
  },

  /**
   * Get show events modal state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - Show events modal state
   */
  showEventsModal(state) {
    return state.modal.showEventsModal
  },

  /**
   * Get the default payload for getEventsList action
   *
   * @param {Object} state - State for the current module
   * @param {Object} getters - Getters for this module
   * @returns {Object} - the default get events list payload
   */
  // eslint-disable-next-line complexity
  eventsListPayload(state, getters) {
    // return custom payload if it has a value
    if (!isEmpty(getters['customEventsListPayload'])) {
      return getters['customEventsListPayload']
    }

    let filters = {
      assigned_to_me: getters['isShowEventsAssignedToMeFilterSelected'] ? 1 : 0,
      unassigned: getters['isShowUnassignedFilterSelected'] ? 1 : 0,
      include_freelancer_events: getters['isShowFreelancerFilterSelected']
        ? 1
        : 0,
      i_own: getters['isShowEventsIOwnFilterSelected'] ? 1 : 0,
      include_google_events: getters['isShowGoogleBambooEventsSelected']
        ? 1
        : 0,
      include_bamboo_events: getters['isShowGoogleBambooEventsSelected']
        ? 1
        : 0,
      event_type_ids: getters['selectedEventTypeIdFilters'],
      assigned_to_ids: getters['selectedAssignedToIdFilters'],
      owned_by_ids: getters['selectedOwnedByIdFiltersArray'],
      search_term: getters['eventsSearchKeyWord'],
    }

    if (getters['isShowOnHoldEventsSelected']) {
      filters['event_status_ids'] = [
        VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
        VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
        VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
      ]
    }

    return {
      start: getters['selectedViewTypeDetails'].start,
      end: getters['selectedViewTypeDetails'].end,
      filters: filters,
      include:
        getters['hasEventsSearchKeyWord'] &&
        getters.selectedViewType === 'Agenda'
          ? ApiCustomIncludes.eventsSearch
          : ApiCustomIncludes.eventsList,
    }
  },

  searchEventsFilterPayload(state, getters) {
    return {
      unassigned: 1,
      include_google_events: 1,
      include_bamboo_events: 1,
      include_freelancer_events: 1,
      search_term: getters['eventsSearchKeyWord'],
      event_status_ids: [
        VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
        VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
        VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
      ],
    }
  },

  /**
   * Get the selected calendar date
   *
   * @param {Object} state - State for the current module
   * @returns {String} - the selected calendar date
   */
  selectedCalendarDate(state) {
    return state.selectedCalendarDate
  },

  /**
   * Get the events search keyword
   *
   * @param {Object} state - State for the current module
   * @returns {String} - the events search keyword
   */
  eventsSearchKeyWord(state) {
    return state.eventsSearchKeyword
  },

  /**
   * Check if the events search keyword is set
   *
   * @param {Object} state - State for the current module
   * @returns {Boolean} - the events search keyword is set
   */
  hasEventsSearchKeyWord(state) {
    return !isEmpty(state.eventsSearchKeyword)
  },

  /**
   * Check if the events bulk edit modal is open
   *
   * @param {Object} state - State for the current module
   * @returns {Boolean} - the events bulk edit modal state
   */
  showEventsBulkEditModal(state) {
    return state.modal.showEventsBulkEditModal
  },

  /**
   * Get the selected events for bulk action
   *
   * @param {Object} state - State for the current module
   * @returns {Array} - array of selected events for bulk action
   */
  bulkActionSelectedEvents(state) {
    return state.eventsBulkAction.selectedEvents
  },

  /**
   * Check if the user can be re-assigned for bulk action
   *
   * @param {Object} state - State for the current module
   * @returns {Boolean} - can bulk re-assign user state
   */
  canBulkReassignUser(state) {
    return state.eventsBulkAction.canReassignUser
  },

  /**
   * Get the events data date range
   *
   * @param {Object} state - State of the current module
   * @returns {Object} - object that contain the events data date range
   */
  eventsDataDateRange(state) {
    return state.eventsDataDateRange
  },

  /**
   * Get the show calendar progress bar state
   *
   * @param {Object} state - State of the current module
   * @returns {Boolean} - show calendar progress bar state
   */
  canShowCalendarProgressBar(state) {
    return state.canShowCalendarProgressBar
  },

  /**
   * Get the state of showMobileFilterList
   *
   * @param {Object} state - State of the current module
   * @returns {Boolean} - show mobile filter list state
   */
  showMobileFilterList(state) {
    return state.showMobileFilterList
  },

  /**
   * Get the state of mobile agenda modal
   *
   * @param {Object} state - State for the current module
   * @returns {Boolean} - the mobile agenda modal state
   */
  showMobileAgendaModal(state) {
    return state.modal.showMobileAgendaModal
  },

  /**
   * Get last payload  for getOnHoldEvents action
   *
   * @param {Object} state - State for the current module
   * @returns {Object} - the last payload for on hold events
   */
  getOnHoldEventsLastPayload(state) {
    return state.onHoldEventsLastPayload
  },

  /**
   * Get loading state for unhold event
   *
   * @param {Object} state - State for the current module
   * @returns {Boolean} - loading state for unhold event
   */
  isUnHoldEventLoading(state) {
    return state.loading.unHoldEvent
  },

  /**
   * Get the custom payload for getEventsList
   *
   * @param {Object} state - State of the current module
   * @returns {Object} - the custom payload for getEventsList
   */
  customEventsListPayload(state) {
    return state.customEventsListPayload
  },

  /**
   * Get the unassigned team group object
   *
   * @returns {Object} - the unassigned team group object
   */
  unassignedTeamGroup() {
    return {
      userId: EventUserGroups.UNASSIGNED,
      userName: 'Unassigned',
      data: {
        full_name: '-',
        calendar_color: Colours.eventColours.unassigned,
      },
      teamId: EventUserGroups.UNASSIGNED,
      teamName: 'Unassigned',
    }
  },

  /**
   * Get the custom payload for getEventsList
   *
   * @param {Object} state - State of the current module
   * @param {Object} getters - Getters of the current module
   * @returns {Boolean} - is selected event on hold or not
   */
  isSelectedEventOnHold(state, getters) {
    return (
      getters.selectedEvent?.event_status_id ===
      VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD
    )
  },

  /**
   * Get deleted events loading state
   *
   * @param {Object} state - State for this module
   * @returns {Boolean} - deleted events loading state
   */
  isDeletedEventsLoading(state) {
    return state.loading.deletedEvents
  },

  /**
   * Get event action history
   *
   * @param {Object} state - State for this module
   * @returns {Object} - event action history
   */
  eventActionHistory(state) {
    return state.eventActionHistory
  },
}
