/**
 * @module store/project/activity-log/activity-log-getters
 */

export default {
  /**
   * Get project activity log
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  activityLogs(state) {
    return state.activityLogs
  },

  /**
   * Returns true if the project has any recorded activity logs (It will not be false if search in activity logs page returns no result)
   * @param {Object} state
   * @returns {Boolean}
   */
  projectHasActivityLogs(state) {
    return state.projectHasActivityLogs
  },

  /**
   * Get activity log loading state
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  isActivityLogListLoading(state) {
    return state.loading.activityLogList
  },
}
