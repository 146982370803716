import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'

/**
 * @module store/project/schedule/actions
 */

/**
 * Store actions for project schedule module.
 * @module project/schedule/actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_SCHEDULE_RESET_STATE)
  },

  /**
   * Action to set the show other projects state
   *
   * @param {Object} - Context.
   * @param {Boolean} showOtherEvents - The selected menu object.
   */
  setShowOtherEvents({ commit }, showOtherEvents) {
    commit(mutations.PROJECT_SCHEDULE_SHOW_OTHER_EVENTS, showOtherEvents)
  },

  /**
   * Action to set the filters state
   *
   * @param {Object} - Context.
   * @param {Object} filters - The new filters state.
   */
  setSelectedFilters({ commit }, filters) {
    commit(mutations.PROJECT_SCHEDULE_SELECTED_FILTERS, filters)
  },

  /**
   * Action to set the selected date
   * @param {Object} - Context.
   * @param {Object} selectedDate - The new selected date.
   */
  setSelectedDate({ commit }, selectedDate) {
    commit(mutations.PROJECT_SCHEDULE_SELECTED_DATE, selectedDate)
  },

  /**
   * Action to get project events list
   *
   * @param {Object} - Context.
   */
  getProjectEventsList({ dispatch }, payload) {
    dispatch('calendar/setCustomEventsListPayload', payload.payload, {
      root: true,
    })

    dispatch(
      'calendar/getEventsList',
      {
        payload: payload.payload,
        showLoader: payload.showLoader,
      },
      {
        root: true,
      }
    )
  },

  /**
   * Action to set users list
   *
   * @param {Object} - Context.
   * @param {Array} - The new users list.
   */
  setUsersList({ commit }, usersList) {
    commit(mutations.PROJECT_SCHEDULE_USERS_LIST, usersList)
  },

  /**
   * Action to update the selected project event details
   *
   * @param {Object} - Context
   * @param {Object} payload - the payload object
   */
  updateProjectEventDetails({ dispatch, rootGetters }, payload) {
    dispatch('calendar/setEventFormLoading', true, { root: true })

    ApiServices.events
      .update(payload.eventId, payload.payload, payload.includes)
      .then((response) => {
        dispatch(
          'calendar/updateEventFromList',
          {
            event: response.data.data,
          },
          { root: true }
        )
        dispatch('calendar/setShowEventsModal', false, { root: true })
        dispatch('calendar/setEventFormLoading', false, { root: true })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} was updated successfully!`,
          },
          { root: true }
        )

        dispatch('getProjectEventsList', {
          payload: rootGetters['project/schedule/projectEventsListPayload'],
          showLoader: false,
        })
      })
      .catch((error) => {
        dispatch('calendar/setEventFormLoading', false, { root: true })
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating project event details',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )

        // Get the project events list on error to refresh the calendar
        dispatch('getProjectEventsList', {
          payload: rootGetters['project/schedule/projectEventsListPayload'],
          showLoader: false,
        })
      })
  },
}
