import Vue from 'vue'
import IdleVue from 'idle-vue'
import store from '@store/store'

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
})
