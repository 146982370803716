import * as types from '@store/mutation-types'
import { find } from 'lodash'

/**
 * @module store/project/pre-production/pre-production-mutations
 */
export default {
  /**
   * Update loader for pre-productions
   * @param state
   * @param loading - The new loader state
   */
  [types.PROJECT_LOADING_PRE_PRODUCTIONS](state, loading) {
    state.loading.preProductions = loading
  },

  /**
   * Update loader for pre-production item
   * @param state
   * @param loading - The new loader state
   */
  [types.PROJECT_LOADING_PRE_PRODUCTION_ITEM](state, loading) {
    state.loading.preProductionItem = loading
  },

  /**
   * Update loader for pre-production file versions
   * @param state - The state of this module
   * @param loading - The new loader state
   */
  [types.PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS_LOADING](state, loading) {
    state.loading.preProductionFileVersions = loading
  },

  /**
   * Update the project pre-production list
   * @param {object} state - the state the current module
   * @param {array} preProductions - the array of the project pre-productions
   */
  [types.PROJECT_PRE_PRODUCTIONS_LIST](state, preProductions) {
    state.preProductions = preProductions
  },

  /**
   * Update pre-production types list
   * @param state
   * @param types - The new types list
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_TYPES](state, types) {
    state.projectForm.preProductions.types = types
  },

  /**
   * Update the selected pre-production types
   * @param state
   * @param preProductionType - The selected pre-production type
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_TYPE](state, preProductionType) {
    state.projectForm.preProductions.selectedType = preProductionType
  },

  /**
   * Update the selected pre-production
   * @param state
   * @param preProduction - The selected pre-production
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_PRE_PRODUCTION_ID](
    state,
    preProductionId
  ) {
    state.projectForm.preProductions.selectedPreProductionId = preProductionId
  },

  /**
   * Open or close the add new pre-productions form
   * @param state
   * @param open - The state of the new pre-productions form
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_ADD_NEW_FORM_OPEN](state, open) {
    state.projectForm.preProductions.addNewFormOpen = open
  },

  /**
   * Open or close the edit pre-productions form
   * @param state
   * @param open - The state of the edit pre-productions form
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_EDIT_FORM_OPEN](state, open) {
    state.projectForm.preProductions.editFormOpen = open
  },

  /**
   * Update the active pre-production index
   * @param state
   * @param {number} preProductionIndex - The index of the selected pre-production
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_ACTIVE_TAB_INDEX](
    state,
    preProductionIndex
  ) {
    state.projectForm.preProductions.activeTabIndex = preProductionIndex
  },

  /**
   * Update a single pre-production item when found in the pre-production active tab items
   * @param {object} state - the state the current module
   * @param {object} payload - the payload used for updating the pre-production item. This consist of the pre-production items from the action and the new data to update
   */
  [types.PROJECT_UPDATE_PRE_PRODUCTION_ITEM](state, payload) {
    const preProductionItems = payload.preProductionItems
    const updatedPreProductionItemData = payload.data

    const selectedPreProductionItem = find(preProductionItems, {
      order_job_id: updatedPreProductionItemData.order_job_id,
    })

    Object.assign(selectedPreProductionItem, updatedPreProductionItemData)
  },

  /**
   * Update pre-production file versions list.
   *
   * @param {object} state - the state of the current module.
   * @param {array} payload - the array of the pre-production file versions.
   */
  [types.PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS](state, payload) {
    state.preProductionFileVersions = payload
  },

  /**
   * Toggle file upload modal state.
   *
   * @param state
   */
  [types.PROJECT_PRE_PRODUCTION_MODAL_FILE_UPLOAD_TOGGLE_SHOW](state) {
    state.modal.fileUpload = !state.modal.fileUpload
  },

  /**
   * Toggle file versions modal state.
   *
   * @param state
   */
  [types.PROJECT_PRE_PRODUCTION_MODAL_FILE_VERSIONS_TOGGLE_SHOW](state) {
    state.modal.fileVersions = !state.modal.fileVersions
  },

  /**
   * Update the linking job id for new pre production
   * @param state
   * @param {Number} linkingJobId - The linking job id
   */
  [types.PROJECT_FORM_PRE_PRODUCTIONS_LINKING_JOB](state, linkingJobId) {
    state.projectForm.preProductions.linkingJobId = linkingJobId
  },

  /**
   * Open or close the order job changes list
   * @param state
   * @param {boolean} isOpen - The state of the order job changes list
   */
  [types.PROJECT_PRE_PRODUCTION_CHANGES_IS_LIST_OPEN](state, isOpen) {
    state.changes.isListOpen = isOpen
  },

  /**
   * Open or close the order job changes form
   * @param state
   * @param {boolean} isOpen - The state of the order job changes form
   */
  [types.PROJECT_PRE_PRODUCTION_CHANGES_IS_FORM_OPEN](state, isOpen) {
    state.changes.isFormOpen = isOpen
  },

  /**
   * Update the order job changes loading state
   *
   * @param state
   * @param {boolean} isLoading - The new loading state
   */
  [types.PROJECT_LOADING_PRE_PRODUCTION_CHANGES](state, isLoading) {
    state.loading.preProductionChanges = isLoading
  },

  /**
   * Update the order job changes list
   *
   * @param state
   * @param {array} preProductionChangesList - The new order job changes list
   */
  [types.PROJECT_PRE_PRODUCTION_CHANGES_LIST](state, preProductionChangesList) {
    state.changes.list = preProductionChangesList
  },

  /**
   * Update the order job changes selected pre-production order job id
   * @param state
   * @param {number} orderJobId - The new selected pre-production order job id
   */
  [types.PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_ID](
    state,
    orderJobId
  ) {
    state.changes.selectedPreProductionId = orderJobId
  },

  /**
   * Update the selected pre-production change
   *
   * @param state
   * @param {object} preProductionChange - The new selected pre-production change
   */
  [types.PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_CHANGE](
    state,
    preProductionChange
  ) {
    state.changes.selectedPreProductionChange = preProductionChange
  },

  /**
   * Update the loading state of order job change attachments
   *
   * @param state
   * @param {boolean} isLoading - The new loading state
   */
  [types.PROJECT_LOADING_PRE_PRODUCTION_CHANGE_ATTACHMENTS](state, isLoading) {
    state.loading.preProductionChangeAttachments = isLoading
  },

  /**
   * Update the pre-production details
   *
   * @param state
   * @param {object} preProductionDetails - The new pre-production details
   */
  [types.PROJECT_PRE_PRODUCTION_DETAILS](state, preProductionDetails) {
    state.preProductionDetails = preProductionDetails
  },

  /**
   * Update the loading state of pre-production details
   *
   * @param state
   * @param {boolean} isLoading - The new loading state
   */
  [types.PROJECT_PRE_PRODUCTION_DETAILS_LOADING](state, isLoading) {
    state.loading.preProductionDetails = isLoading
  },
}
