import Vue from 'vue'
import App from './app.vue'
import appConfig from '@configs/app-config'

// Core libraries
import router from '@router/router'
import store from '@store/store'
import momentTz from 'moment-timezone'

// Plugins
import '@plugins/bugsnag'
import firebase from '@plugins/firebase'
import NProgress from '@plugins/nprogress'
import vuetify from '@plugins/vuetify'
import '@plugins/vue-meta'
import '@plugins/vue-idle'

// Syncfusion
import { registerLicense } from '@syncfusion/ej2-base'
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE_KEY)

Vue.config.productionTip = process.env.VUE_APP_RELEASE_STAGE !== 'production'
Vue.config.devtools = process.env.VUE_APP_RELEASE_STAGE !== 'production'

let app

momentTz.tz.setDefault(appConfig.defaultTimezone)

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    if (!store.getters['auth/isLoggedIn']) {
      store.dispatch('auth/setFirebaseTokenLoading', true)
      // Show page load progress.
      NProgress.start()
      // Get access token.
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((accessToken) => {
          const commitPayload = {
            uid: firebase.auth().currentUser.uid,
            email: firebase.auth().currentUser.email,
            displayName: firebase.auth().currentUser.displayName,
            photoUrl: firebase.auth().currentUser.photoUrl,
            accessToken,
          }

          // Updated local store with user info from firebase session.
          store.dispatch('auth/updateFirebaseUserInfo', commitPayload)
        })
    }
  } else {
    const resolvedRoute = router.resolve(window.location.pathname)

    // Check if this route is not token authentication.
    if (
      resolvedRoute.route.name !== 'auth.vms.token' &&
      resolvedRoute.route.name !== 'auth.logout'
    ) {
      // Stop the page render because there's no signed in user.
      store.dispatch('auth/handleUnauthorizedUser')
    }
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#vms-app')
  }
})
