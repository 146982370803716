import actions from './media-manager-actions'
import getters from './media-manager-getters'
import mutations from './media-manager-mutations'
import * as types from '../mutation-types'
import { merge } from 'lodash'

/**
 * @module store/media-manager
 */

/**
 * Get default state for media-manager module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for shared loaders used in multiple components.
   * @property  {boolean}  loading.archivedProjects - States for projects loaders.
   * @property  {boolean}  loading.archiveProjectFootage - States for archiveProjectFootage loader.
   * @property  {object}  selectedArchivedProject - Selected archived project.
   * @property  {object}  modal - States for shared modals used in multiple components.
   * @property  {object}  modal.archiveProjectFootage - States for archiveProjectFootage modal.
   * @property  {boolean} modal.archiveProjectFootage.show - Show archiveProjectFootage modal.
   */
  return {
    loading: {
      archivedProjects: false,
      archiveProjectFootage: false,
    },
    modal: {
      archiveProjectFootage: {
        show: false,
      },
    },
    selectedArchivedProject: null,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.MEDIA_MANAGER_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
