import actions from '@store/project/project-health/project-health-actions'
import getters from '@store/project/project-health/project-health-getters'
import mutations from '@store/project/project-health/project-health-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/project-health/project-health-module
 */

/**
 * Get default state for project health module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {Number}   totalProductionCost - Production cost of a project.
   * @property  {Number}   totalDisbursementCost - Disbursement cost of a project.
   * @property  {Number}   totalPreProductionHours - Scheduled pre-production job duration of a project.
   * @property  {Number}   totalShootHours - Scheduled shoot job duration of a project.
   * @property  {Number}   totalDeliveryHours - Scheduled deliveries job duration of a project.
   * @property  {Number}   totalHoursWorked - Total harvest time spent on a project.
   * @property  {Number}   allocatedHours - Total budgeted time of a project.
   * @property  {Number}   totalProductionHours - Total scheduled event hours of a project.
   * @property  {Number}   totalScheduledHours - Total scheduled hours of a project.
   * @property  {Number}   preProductionScheduledHours - Scheduled pre-production hours
   * @property  {Number}   preProductionWorkedHours - Hours worked for pre-production
   * @property  {Number}   shootScheduledHours - Scheduled hours for shoot
   * @property  {Number}   shootWorkedHours - Hours worked for shoot
   * @property  {Number}   editingScheduledHours - Scheduled hours for editing
   * @property  {Number}   editingWorkedHours - Hours worked for editing
   * @property  {Number}   animationScheduledHours - Scheduled hours for animation
   * @property  {Number}   animationWorkedHours - Hours worked for animation
   * @property  {Number}   changesScheduledHours - Scheduled hours for project changes
   * @property  {Number}   changesWorkedHours - Hours worked for project changes
   * @property  {Object}   loading - States for loaders within the project health module.
   * @property  {Boolean}  loading.projectHealth - State for the project health loader.
   * @property  {Object}   modal - States for modals related to project health.
   * @property  {Boolean}  modal.projectHealth - toggle for project health modal.
   */
  return {
    totalProductionCost: 0,
    totalDisbursementCost: 0,
    totalPreProductionHours: 0,
    totalShootHours: 0,
    totalDeliveryHours: 0,
    totalHoursWorked: 0,
    allocatedHours: 0,
    totalProductionHours: 0,
    totalScheduledHours: 0,
    preProductionScheduledHours: 0,
    preProductionWorkedHours: 0,
    shootScheduledHours: 0,
    shootWorkedHours: 0,
    editingScheduledHours: 0,
    editingWorkedHours: 0,
    animationScheduledHours: 0,
    animationWorkedHours: 0,
    changesScheduledHours: 0,
    changesWorkedHours: 0,
    loading: {
      projectHealth: false,
    },
    modal: {
      projectHealth: false,
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_HEALTH_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
