export default [
  {
    path: '/videos',
    name: 'videos.library',
    component: () =>
      import(
        /* webpackChunkName: "video.library" */ '@views/videos/video-library/video-library'
      ),
    meta: {
      title: 'Video Library',
    },
  },

  {
    path: '/videos/:videoId',
    name: 'video.details',
    component: () =>
      import(
        /* webpackChunkName: "video.details" */ '@views/videos/video-preview/video-preview'
      ),
    meta: {
      title: 'Video Details',
    },
  },
]
