import Axios from '@plugins/axios'

/**
 * @param {Number} projectId
 * @param {Number} orderJobId
 * @param {Array} customIncludes
 */
export default (projectId, orderJobId, customIncludes) => {
  let url = `/api/v2/internal/projects/${projectId}/jobs/pre-production/${orderJobId}/approve`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  return Axios.put(url, config)
}
