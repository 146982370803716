import * as types from '@store/mutation-types'
import { includes, toLower, forEach, filter, find, orderBy, some } from 'lodash'
import EventTypeFilterGroups from '@configs/event-type-filter-groups'

/**
 * @module store/calendar/mutations
 */

export default {
  /**
   * Update the selected filters array by adding the selected filter if it doesn't exist in the array yet.
   *
   * @param {object} state - state of the current module
   * @param {object} selectedFilter - selected filter object
   */
  [types.CALENDAR_ADD_SELECTED_FILTER](state, selectedFilter) {
    if (!some(state.selectedFilters, selectedFilter)) {
      state.selectedFilters.push(selectedFilter)
    }
  },

  /**
   * Update the selected filters array by adding the selected filters if they don't exist in the array yet.
   *
   * @param {object} state - state of the current module
   * @param {array} selectedFilters - the selected filters array
   */
  [types.CALENDAR_ADD_REMOVE_SELECTED_FILTERS](state, selectedFilters) {
    forEach(selectedFilters, (selectedFilter) => {
      if (!some(state.selectedFilters, selectedFilter)) {
        state.selectedFilters.push(selectedFilter)
      } else {
        state.selectedFilters = filter(state.selectedFilters, (filter) => {
          return filter.id !== selectedFilter.id
        })
      }
    })
  },

  /**
   * Update the selected filters array by removing the selected filter if it exists in the array.
   *
   * @param {object} state - state of the current module
   * @param {object} filterToRemove - filter to remove object
   */
  [types.CALENDAR_REMOVE_SELECTED_FILTER](state, filterToRemove) {
    state.selectedFilters = filter(state.selectedFilters, (filter) => {
      return filter.id !== filterToRemove.id
    })
  },

  /**
   * Update the selected filters array by removing all selected filters.
   *
   * @param {object} state - state of the current module
   */
  [types.CALENDAR_CLEAR_ALL_FILTERS](state) {
    state.selectedFilters = []
  },

  /**
   * Update the search filters result object by adding the search filters result if it doesn't exist in the object yet.
   *
   * @param {object} state - state of the current module
   * @param {string} searchKeyword - search filter keyword
   */
  [types.CALENDAR_FILTER_SEARCH](state, searchKeyword) {
    const searchFiltersList = {
      savedFilters: [],
      teamFilters: [],
      eventFilters: [],
      crewFilters: [],
      projectOwnerFilters: [],
    }

    forEach(state.filtersList, (filtersList, index) => {
      forEach(filtersList, (filterList) => {
        if (includes(toLower(filterList.label), toLower(searchKeyword))) {
          searchFiltersList[index].push(filterList)
        }

        if (filterList.subFilters) {
          forEach(filterList.subFilters, (subFilterList) => {
            if (
              includes(toLower(subFilterList.label), toLower(searchKeyword))
            ) {
              searchFiltersList[index].push({
                label: filterList.label,
                value: filterList.value,
                id: filterList.id,
                subFilters: [subFilterList],
              })
            }
          })
        }
      })
    })

    state.searchFiltersResult = searchKeyword ? searchFiltersList : null
  },

  /**
   * Update the show all filters modal state.
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show all filters modal state
   */
  [types.CALENDAR_MODAL_SHOW_ALL_FILTERS](state, showModal) {
    state.modal.showAllFiltersModal = showModal
  },

  /**
   * Update the show save filters modal state.
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show save filters modal state
   */
  [types.CALENDAR_MODAL_SHOW_SAVE_FILTERS_MODAL](state, showModal) {
    state.modal.saveFiltersModal = showModal
  },

  /**
   * Update the show on hold events modal state.
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show on hold events modal state
   */
  [types.CALENDAR_MODAL_SHOW_ON_HOLD_EVENTS](state, showModal) {
    state.modal.onHoldEventsModal = showModal
  },

  /**
   * Update the show deleted events modal state.
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show deleted events modal state
   */
  [types.CALENDAR_MODAL_SHOW_DELETED_EVENTS](state, showModal) {
    state.modal.deletedEventsModal = showModal
  },

  /**
   * Update the on hold events object with data and metadata
   *
   * @param {object} state - state of the current module
   * @param {object} onHoldEvents - on hold events object
   */
  [types.CALENDAR_ON_HOLD_EVENTS](state, onHoldEvents) {
    state.onHoldEvents = onHoldEvents
  },

  /**
   * Update the deleted events array
   * @param {object} state - state of the current module
   * @param {array} deletedEvents - deleted events array
   */
  [types.CALENDAR_DELETED_EVENTS](state, deletedEvents) {
    state.deletedEvents = deletedEvents
  },

  /**
   * Update the events list
   *
   * @param {object} state - state of the current module
   * @param {array} events - events list
   */
  [types.CALENDAR_EVENTS_LIST](state, events) {
    state.eventsList = events
  },

  /**
   * Update the loading events list state
   * @param {object} state - state of the current module
   * @param {boolean} loading - loading events list state
   */
  [types.CALENDAR_LOADING_EVENTS_LIST](state, loading) {
    state.loading.eventsList = loading
  },

  /**
   * Update the selected event object
   *
   * @param {object} state - state of the current module
   * @param {object} event - selected event object
   */
  [types.CALENDAR_SELECTED_EVENT](state, event) {
    state.selectedEvent = event
  },

  /**
   * Update the event details loading state
   * @param {object} state - state of the current module
   * @param {boolean} loading - event details loading state
   */
  [types.CALENDAR_EVENT_DETAILS_LOADING](state, loading) {
    state.loading.eventDetails = loading
  },

  /**
   * Update the calendar view type
   *
   * @param {object} state - state of the current module
   * @param {string} viewType - calendar view type
   */
  [types.CALENDAR_VIEW_TYPE](state, viewType) {
    state.viewType = viewType
  },

  /**
   * Update the agenda list
   *
   * @param {object} state - state of the current module
   * @param {array} agendaList - agenda list
   */
  [types.CALENDAR_AGENDA_LIST](state, agendaList) {
    state.agendaList = agendaList
  },

  /**
   * Update the loading agenda list state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - loading agenda list state
   */
  [types.CALENDAR_LOADING_AGENDA_LIST](state, loading) {
    state.loading.agendaList = loading
  },

  /**
   * Update the teams filters list
   *
   * @param {object} state - state of the current module
   * @param {array} teams - teams filters list
   */
  [types.CALENDAR_FILTERS_TEAMS](state, teams) {
    state.filtersList.teamFilters = teams
  },

  /**
   * Update the crew filters list
   *
   * @param {object} state - state of the current module
   * @param {array} crewList - crew filters list
   */
  [types.CALENDAR_FILTERS_CREW](state, crewList) {
    state.filtersList.crewFilters = crewList
  },

  /**
   * Update the project owner filters list
   *
   * @param {object} state - state of the current module
   * @param {array} projectOwners - project owner filters list
   */
  [types.CALENDAR_FILTERS_PROJECT_OWNERS](state, projectOwners) {
    state.filtersList.projectOwnerFilters = projectOwners
  },

  /**
   * Update the event types filters list
   *
   * @param {object} state - state of the current module
   * @param {array} eventTypes - event types filters list
   */
  [types.CALENDAR_FILTERS_EVENT_TYPES](state, eventTypes) {
    const preProductionSubFilters = []
    const productionSubFilters = []
    const postProductionSubFilters = []
    const otherSubFilters = []

    // Loop through event types and add them to the correct sub filter array
    forEach(eventTypes, (eventType) => {
      // Check if the event type is in the pre-production event types array
      if (
        includes(EventTypeFilterGroups.preProductionEventTypes, eventType.id)
      ) {
        preProductionSubFilters.push({
          label: eventType.name,
          value: eventType.id,
          id: eventType.id,
        })
      }

      // Check if the event type is in the production event types array
      if (includes(EventTypeFilterGroups.productionEventsTypes, eventType.id)) {
        productionSubFilters.push({
          label: eventType.name,
          value: eventType.id,
          id: eventType.id,
        })
      }

      // Check if the event type is in the post-production event types array
      if (
        includes(EventTypeFilterGroups.postProductionEventTypes, eventType.id)
      ) {
        postProductionSubFilters.push({
          label: eventType.name,
          value: eventType.id,
          id: eventType.id,
        })
      }

      // Check if the event type is in the other event types array
      if (includes(EventTypeFilterGroups.otherEventTypes, eventType.id)) {
        otherSubFilters.push({
          label: eventType.name,
          value: eventType.id,
          id: eventType.id,
        })
      }
    })

    // Update the event filters list with the sub filters
    state.filtersList.eventFilters = [
      {
        ...find(state.filtersList.eventFilters, (eventFilter) => {
          return eventFilter.id === 'pre-production'
        }),
        subFilters: orderBy(preProductionSubFilters, 'label', 'asc'),
      },
      {
        ...find(state.filtersList.eventFilters, (eventFilter) => {
          return eventFilter.id === 'production'
        }),
        subFilters: orderBy(productionSubFilters, 'label', 'asc'),
      },
      {
        ...find(state.filtersList.eventFilters, (eventFilter) => {
          return eventFilter.id === 'post-production'
        }),
        subFilters: orderBy(postProductionSubFilters, 'label', 'asc'),
      },
      {
        ...find(state.filtersList.eventFilters, (eventFilter) => {
          return eventFilter.id === 'other'
        }),
        subFilters: orderBy(otherSubFilters, 'label', 'asc'),
      },
    ]
  },

  /**
   * Update the loading event types state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - loading event types state
   */
  [types.CALENDAR_LOADING_EVENT_TYPES](state, loading) {
    state.loading.eventTypes = loading
  },

  /**
   * Update the loading saved filters state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - loading saved filters state
   */
  [types.CALENDAR_LOADING_SAVED_FILTERS](state, loading) {
    state.loading.savedFilters = loading
  },

  /**
   * Update the saved filters list
   *
   * @param {object} state - state of the current module
   * @param {array} savedFilters - saved filters list
   */
  [types.CALENDAR_FILTERS_SAVED_FILTERS](state, savedFilters) {
    const savedFiltersFormat = []

    // Loop through saved filters and format them with the data provided for each saved filter
    forEach(savedFilters, (savedFilter) => {
      savedFiltersFormat.push({
        label: savedFilter.name,
        value: savedFilter.id,
        id: savedFilter.id,
        data: { ...savedFilter },
      })
    })

    state.filtersList.savedFilters = savedFiltersFormat
  },

  /**
   * Update the selected saved filter object
   *
   * @param {object} state - state of the current module
   * @param {object} savedFilter - selected saved filter object
   */
  [types.CALENDAR_FILTERS_SELECTED_SAVED_FILTER](state, savedFilter) {
    state.selectedSavedFilter = savedFilter
  },

  /**
   * Update the selected owned by id filter object
   *
   * @param {object} state - state of the current module
   * @param {object} ownedByIdFilter - selected owned by id filter object
   */
  [types.CALENDAR_FILTERS_SELECTED_OWNED_BY_ID_FILTER](state, ownedByIdFilter) {
    state.selectedOwnedByIdFilters = ownedByIdFilter
  },

  /**
   * Update the selected owned by id filter by adding new id.
   *
   * @param {object} state - state of the current module
   * @param {object} ownedByIdFilter - selected id
   */
  [types.CALENDAR_ADD_SELECTED_OWNED_BY_ID_FILTER](state, ownedByIdFilter) {
    if (!some(state.selectedOwnedByIdFilters, ownedByIdFilter)) {
      state.selectedOwnedByIdFilters.push(ownedByIdFilter)
    }
  },

  /**
   * Update the selected owned by id filter by removing existing id
   *
   * @param {object} state - state of the current module
   * @param {array} ownedByIdFilter - the selected id
   */
  [types.CALENDAR_REMOVE_SELECTED_OWNED_BY_ID_FILTER](state, ownedByIdFilter) {
    state.selectedOwnedByIdFilters = filter(
      state.selectedOwnedByIdFilters,
      (filter) => {
        return filter.id !== ownedByIdFilter.id
      }
    )
  },

  /**
   * Update the on hold events loading state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - on hold events loading state
   */
  [types.CALENDAR_LOADING_ON_HOLD_EVENTS](state, loading) {
    state.loading.onHoldEvents = loading
  },

  /**
   * Update the selected on hold event loading state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - selected on hold event loading state
   */
  [types.CALENDAR_LOADING_SELECTED_ON_HOLD_EVENT](state, loading) {
    state.loading.selectedOnHoldEvent = loading
  },

  /**
   * Update the selected on hold event
   *
   * @param {object} state - state of the current module
   * @param {number} onHoldEventId - selected on hold event ID
   */
  [types.CALENDAR_SELECTED_ON_HOLD_EVENT](state, onHoldEventId) {
    state.selectedOnHoldEventId = onHoldEventId
  },

  /**
   * Update the loading state of the event form
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - event form loading state
   */
  [types.CALENDAR_LOADING_EVENT_FORM](state, loading) {
    state.loading.eventForm = loading
  },

  /**
   * Update show events placeholders create modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events placeholders create modal state
   */
  [types.CALENDAR_MODAL_EVENTS_PLACEHOLDERS_CREATE](state, showModal) {
    state.modal.showEventsPlaceholdersCreateModal = showModal
  },

  /**
   * Update show events filming details create modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events filming details create modal state
   */
  [types.CALENDAR_MODAL_EVENTS_FILMING_DETAILS_CREATE](state, showModal) {
    state.modal.showEventsFilmingDetailsCreateModal = showModal
  },

  /**
   * Update show events deliveries create modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events deliveries create modal state
   */
  [types.CALENDAR_MODAL_EVENTS_DELIVERIES_CREATE](state, showModal) {
    state.modal.showEventsDeliveriesCreateModal = showModal
  },

  /**
   * Update show events pre-productions changes create modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events pre-productions changes create modal state
   */
  [types.CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_CREATE](state, showModal) {
    state.modal.showEventsPreProductionsCreateModal = showModal
  },

  /**
   * Update show events filming details edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events filming details edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_FILMING_DETAILS_EDIT](state, showModal) {
    state.modal.showEventsFilmingDetailsEditModal = showModal
  },

  /**
   * Update show events deliveries edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events deliveries edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_DELIVERIES_EDIT](state, showModal) {
    state.modal.showEventsDeliveriesEditModal = showModal
  },

  /**
   * Update show events job changes edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events job changes edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_JOB_CHANGES_EDIT](state, showModal) {
    state.modal.showEventsJobChangesEditModal = showModal
  },

  /**
   * Update show events pre-productions edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events pre-productions edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_EDIT](state, showModal) {
    state.modal.showEventsPreProductionsEditModal = showModal
  },

  /**
   * Update show events pre-productions changes edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events pre-productions changes edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_PRE_PRODUCTION_CHANGES_EDIT](state, showModal) {
    state.modal.showEventsPreProductionChangesEditModal = showModal
  },

  /**
   * Update show events placeholders edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events placeholders edit modal state
   */
  [types.CALENDAR_MODAL_EVENTS_PLACEHOLDERS_EDIT](state, showModal) {
    state.modal.showEventsPlaceholdersEditModal = showModal
  },

  /**
   * Update the selected schedules
   *
   * @param {object} state - state of the current module
   * @param {object} schedules - the selected schedules
   */
  [types.CALENDAR_SELECTED_SCHEDULES](state, schedules) {
    state.selectedSchedules = schedules
  },

  /**
   * Update the projects list state
   *
   * @param {object} state - state of the current module
   * @param {array} projects - projects list
   */
  [types.CALENDAR_PROJECTS_LIST](state, projects) {
    state.projectsList = projects
  },

  /**
   * Update the loading state of projects list
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - loading projects list state
   */
  [types.CALENDAR_LOADING_PROJECTS_LIST](state, loading) {
    state.loading.projectsList = loading
  },

  /**
   * Update the event types list state
   *
   * @param {object} state - state of the current module
   * @param {array} eventTypes - event types list
   */
  [types.CALENDAR_EVENT_TYPES_LIST](state, eventTypes) {
    state.eventTypesList = eventTypes
  },

  /**
   * Update show events modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - show events modal state
   */
  [types.CALENDAR_MODAL_EVENTS](state, showModal) {
    state.modal.showEventsModal = showModal
  },

  /**
   * Update the selected calendar date
   *
   * @param {object} state - state of the current module
   * @param {string} selectedDate - selected calendar date
   */
  [types.CALENDAR_SELECTED_CALENDAR_DATE](state, selectedDate) {
    state.selectedCalendarDate = selectedDate
  },

  /**
   * Update the calendar events search keyword
   *
   * @param {object} state - state of the current module
   * @param {string} searchKeyword - calendar events search keyword
   */
  [types.CALENDAR_EVENTS_SEARCH_KEYWORD](state, searchKeyword) {
    state.eventsSearchKeyword = searchKeyword
  },

  /**
   * Update show events bulk edit modal state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showModal - the bulk edit events modal state
   */
  [types.CALENDAR_MODAL_EVENTS_BULK_EDIT](state, showModal) {
    state.modal.showEventsBulkEditModal = showModal
  },

  /**
   * Update the selected events for bulk action
   *
   * @param {object} state - state of the current module
   * @param {array} selectedEvents - the selected events for bulk action
   */
  [types.CALENDAR_EVENTS_BULK_SELECTED_EVENTS](state, selectedEvents) {
    state.eventsBulkAction.selectedEvents = selectedEvents
  },

  /**
   * Update the can reassign user state
   *
   * @param {object} state - state of the current module
   * @param {boolean} canReassignUser - the can reassign user state
   */
  [types.CALENDAR_EVENTS_BULK_REASSIGN_USER](state, canReassignUser) {
    state.eventsBulkAction.canReassignUser = canReassignUser
  },

  /**
   * Update the can events data date range state
   *
   * @param {object} state - state of the current module
   * @param {object} dateRange - the new date range
   */
  [types.CALENDAR_EVENTS_DATA_DATE_RANGE](state, dateRange) {
    state.eventsDataDateRange = dateRange
  },

  /**
   * Update the calendar progress bar state
   *
   * @param {object} state - state of the current module
   * @param {boolean} showProgressBar - the show progress bar state
   */
  [types.CALENDAR_SHOW_PROGRESS_BAR](state, showProgressBar) {
    state.canShowCalendarProgressBar = showProgressBar
  },

  /**
   * Update the showMobileFilterList state
   *
   * @param {object} state - state of the current modulec
   * @param {Boolean} showFilter - the new state
   */
  [types.CALENDAR_SHOW_MOBILE_FILTER_LIST](state, showFilter) {
    state.showMobileFilterList = showFilter
  },

  /**
   * Update the CALENDAR_SHOW_MOBILE_AGENDA_MODAL state
   *
   * @param {object} state - state of the current module
   * @param {Boolean} showFilter - the new state
   */
  [types.CALENDAR_SHOW_MOBILE_AGENDA_MODAL](state, showFilter) {
    state.modal.showMobileAgendaModal = showFilter
  },

  /**
   * Update the selected filters from localStorage
   *
   *  @param {object} state - state of the current module
   * @param {object} selectedFilters - the selected filters from localStorage
   */
  [types.CALENDAR_LOCAL_STORAGE_SELECTED_FILTERS](state, selectedFilters) {
    state.localStorageSelectedFilters = selectedFilters
  },

  /**
   * Update on hold events last payload.
   *
   * @param {object} state - state of the current module
   * @param {object} payload - the last on hold events payload
   */
  [types.CALENDAR_ON_HOLD_EVENTS_LAST_PAYLOAD](state, payload) {
    state.onHoldEventsLastPayload = payload
  },

  /**
   * Update loading state of unhold event.
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loading state for unhold event
   */
  [types.CALENDAR_LOADING_UN_HOLD_EVENT](state, loading) {
    state.loading.unHoldEvent = loading
  },

  /**
   * Update the custom payload for getEventsList
   *
   * @param {object} state - state of the current module
   * @param {object} customPayload - the custom payload for getEventsList
   */
  [types.CALENDAR_CUSTOM_EVENTS_LIST_PAYLOAD](state, customPayload) {
    state.customEventsListPayload = customPayload
  },

  /**
   * Update deleted events loading state
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - the deleted events loading state
   */
  [types.CALENDAR_LOADING_DELETED_EVENTS](state, loading) {
    state.loading.deletedEvents = loading
  },

  /**
   * Update event action history state
   *
   * @param {object} state - state of the current module
   * @param {object} eventActionHistory - the event action history state
   */
  [types.CALENDAR_EVENT_ACTION_HISTORY](state, eventActionHistory) {
    state.eventActionHistory = eventActionHistory
  },
}
