/**
 * @module store/company/getters
 */

export default {
  /**
   * Get the loading state of the company offices
   *
   * @param {Object} state - State for current module.
   * @returns {Boolean} - The loading state
   */
  isCompanyOfficesLoading(state) {
    return state.loading.offices
  },

  /**
   * Get the list of offices
   *
   * @param {Object} state - State for current module.
   * @returns {Array} - The list of offices
   */
  companyOffices(state) {
    return state.offices
  },
}
