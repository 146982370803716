<template>
  <router-view v-if="!isFirebaseTokenLoading"></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from '@plugins/firebase'

export default {
  data() {
    return {
      idleTime: 0,
      lastActiveTime: Date.now(),
      idleInterval: null,
    }
  },

  computed: {
    ...mapGetters({
      isFirebaseTokenLoading: 'auth/isFirebaseTokenLoading',
    }),

    /**
     * Check if timeout exceeded 15 mins or 900 seconds.
     */
    isTokenTimeoutExceeded() {
      return this.idleTime > 900
    },
  },

  watch: {
    isTokenTimeoutExceeded() {
      if (this.isTokenTimeoutExceeded) {
        this.handleFirebaseAuthStateChange()
        this.resetIdleTimer()
      }
    },
  },

  methods: {
    ...mapActions({
      updateFirebaseUserInfo: 'auth/updateFirebaseUserInfo',
      handleUnauthorizedUser: 'auth/handleUnauthorizedUser',
      setFirebaseTokenLoading: 'auth/setFirebaseTokenLoading',
    }),

    resetIdleTimer() {
      this.idleTime = 0
      this.lastActiveTime = Date.now()
    },

    handleFirebaseAuthStateChange() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((accessToken) => {
              const commitPayload = {
                uid: firebase.auth().currentUser.uid,
                email: firebase.auth().currentUser.email,
                displayName: firebase.auth().currentUser.displayName,
                photoUrl: firebase.auth().currentUser.photoUrl,
                accessToken,
              }

              this.updateFirebaseUserInfo(commitPayload)
            })
        } else {
          this.handleUnauthorizedUser()
        }
      })
    },
  },

  onIdle() {
    // Set user's idle time when user becomes inactive/idle
    this.idleInterval = setInterval(() => {
      this.idleTime = Math.floor((Date.now() - this.lastActiveTime) / 1000)
    }, 1000) // Check every second
  },

  onActive() {
    // Reset token or handle unauthorized user
    this.handleFirebaseAuthStateChange()

    // Reset idle timer
    this.resetIdleTimer()

    // Clear existing interval for setting user's idle time.
    clearInterval(this.idleInterval)
  },
}
</script>

<style lang="scss">
@import '@styles/app';
</style>
