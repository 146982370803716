import NProgress from 'nprogress'

NProgress.configure({
  minimum: 0.3,
  showSpinner: false,
  trickleSpeed: 150,
  easing: 'ease',
  speed: 800,
})

export default NProgress
