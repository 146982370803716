import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/users`

  let config = {
    headers: {},
    params: {},
  }

  if (payload) {
    if (payload.includes) {
      config.params.include = payload.includes.join(',')
    }

    if (payload.paginate) {
      // flag to enable pagination
      config.params.paginate = payload.paginate
    }

    if (payload.page) {
      config.params.page = payload.page
    }

    if (payload.perPage) {
      config.params.per_page = payload.perPage
    }

    if (payload.search_term) {
      config.params.search_term = payload.search_term
    }
  }

  return Axios.get(url, config)
}
