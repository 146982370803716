import actions from '@store/project/shoot/shoot-actions'
import getters from '@store/project/shoot/shoot-getters'
import mutations from '@store/project/shoot/shoot-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/shoot/project-module
 */

/**
 * Get default state for shoot module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {boolean} loading.filmingDetails - State for the project filming details loader.
   * @property  {boolean} loading.availableExtras - Loader for filming details' available extras.
   * @property  {boolean} loading.availableExtras - Loader for filming details' available extras
   * @property  {boolean} loading.callSheet - The loading state of call sheet
   * @property  {object}  filmingDetails - Filming details with data array and meta.
   * @property  {array}   filmingDetails.data - Array of objects of project filming details.
   * @property  {object}  filmingDetails.meta - The filming details meta.
   * @property  {number}  filmingDetails.meta.current_page - The current page of the filming details.
   * @property  {number}  filmingDetails.meta.from - The filming details from page.
   * @property  {number}  filmingDetails.meta.last_page - The filming details last page.
   * @property  {number}  filmingDetails.meta.per_page - The filming details per page.
   * @property  {number}  filmingDetails.meta.to - The filming details to page.
   * @property  {number}  filmingDetails.meta.total - The total count of filming details.
   * @property 	{object} 	projectForm.filmingDetails - Details related to the project form filming details.
   * @property 	{array}   projectForm.filmingDetails.extras - An array of extras available for use in add/edit filming details
   * @property 	{object}  projectForm.filmingDetails.selected - The selected filming detail
   * @property 	{boolean} projectForm.filmingDetails.addNewFormOpen - Check if the filming details' add new form is open
   * @property 	{boolean} projectForm.filmingDetails.editFormOpen - Check if the filming details' edit form is open
   * @property  {object}  callSheet.formOpen - The call sheet form open state
   * @property  {object}  callSheet.data - The data object for call sheet
   */
  return {
    loading: {
      filmingDetails: false,
      availableExtras: false,
      holdShootJob: false,
      callSheet: false,
      manualCallSheet: false,
    },
    filmingDetails: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      },
    },
    projectForm: {
      filmingDetails: {
        extras: [],
        selected: null,
        addNewFormOpen: false,
        editFormOpen: false,
      },
    },
    callSheet: {
      data: {},
      formOpen: false,
    },
    modal: {
      manualCallSheet: {
        show: false,
      },
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_SHOOT_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
