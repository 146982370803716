import * as types from '@store/mutation-types'
import { find } from 'lodash'

/**
 * @module store/project/shoot/project-mutations
 */
export default {
  /**
   * Update loader for the project shoots
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_PROJECT_FILMING_DETAILS](state, loading) {
    state.loading.filmingDetails = loading
  },

  /**
   * Update loader for available extras
   * @param state
   * @param loading - The new loader state
   */
  [types.PROJECT_LOADING_AVAILABLE_EXTRAS](state, loading) {
    state.loading.availableExtras = loading
  },

  /**
   * Update the project filming details list
   * @param {object} state - the state the current module
   * @param {array} filmingDetails - the array of the project filming details
   */
  [types.PROJECT_FILMING_DETAILS_LIST](state, filmingDetails) {
    state.filmingDetails.data = filmingDetails.data
    state.filmingDetails.meta = filmingDetails.meta
  },

  /**
   * Update a single filming detail when found in the filming details list
   * @param {object} state - the state the current module
   * @param {array} filmingDetailsData - the update filming details data
   */
  [types.PROJECT_UPDATE_FILMING_DETAILS](state, updatedFilmingDetailData) {
    const selectedFilmingDetail = find(state.filmingDetails.data, {
      id: updatedFilmingDetailData.id,
    })

    if (selectedFilmingDetail) {
      Object.assign(selectedFilmingDetail, updatedFilmingDetailData)
    }
  },

  /**
   * Update filming details extras list
   * @param state
   * @param extras - The new extras list
   */
  [types.PROJECT_FILMING_DETAILS_EXTRAS](state, extras) {
    state.projectForm.filmingDetails.extras = extras
  },

  /**
   * Updated the selected filming details
   * @param state
   * @param filmingDetail - The selected filming details
   */
  [types.PROJECT_FILMING_DETAILS_SELECTED](state, filmingDetail) {
    state.projectForm.filmingDetails.selected = filmingDetail
  },

  /**
   * Open or close the add new filming details form
   * @param state
   * @param open - The state of the new filming details form
   */
  [types.PROJECT_FILMING_DETAILS_ADD_NEW_FORM_OPEN](state, open) {
    state.projectForm.filmingDetails.addNewFormOpen = open
  },

  /**
   * Open or close the edit filming details form
   * @param state
   * @param open - The state of the edit filming details form
   */
  [types.PROJECT_FILMING_DETAILS_EDIT_FORM_OPEN](state, open) {
    state.projectForm.filmingDetails.editFormOpen = open
  },

  /**
   * Set loading state of hold shoot job.
   * @param state
   * @param isLoading - the loading state
   */
  [types.PROJECT_SHOOT_JOB_HOLD_LOADING](state, isLoading) {
    state.loading.holdShootJob = isLoading
  },

  /**
   * Set call sheet loading state.
   *
   * @param state
   * @param isLoading - the loading state
   */
  [types.PROJECT_CALL_SHEET_LOADING](state, isLoading) {
    state.loading.callSheet = isLoading
  },

  /**
   * Set call sheet data
   *
   * @param state
   * @param callSheet - the call sheet data
   */
  [types.PROJECT_CALL_SHEET_DATA](state, callSheet) {
    state.callSheet.data = callSheet
  },

  /**
   * Set call sheet form open state.
   *
   * @param state
   * @param isOpen - the open state
   */
  [types.PROJECT_CALL_SHEET_FORM_OPEN](state, isOpen) {
    state.callSheet.formOpen = isOpen
  },

  /**
   * Set 'manual call sheet' modal saving state.
   *
   * @param state
   * @param isSaving - isSaving state
   */
  [types.PROJECT_LOADING_MANUAL_CALL_SHEET](state, isSaving) {
    state.loading.manualCallSheet = isSaving
  },

  /**
   * Set 'manual call sheet' modal show state.
   *
   * @param state
   * @param State - the show state
   */
  [types.PROJECT_MANUAL_CALL_SHEET_MODAL_TOGGLE_SHOW](state, openState) {
    state.modal.manualCallSheet.show = openState
  },
}
