import actions from '@store/project/contact/contact-actions'
import getters from '@store/project/contact/contact-getters'
import mutations from '@store/project/contact/contact-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/contact/contact-module
 */

/**
 * Get default state for project contact module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the project module.
   * @property  {boolean} loading.contacts - State for the project contacts loaded.
   * @property  {array}   projectContacts - Array of project contacts data
   * @property 	{boolean} showAddContactsList - The add contacts list display state
   */
  return {
    loading: {
      contacts: false,
    },
    projectContacts: [],
    showAddContactsList: false,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_CONTACT_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
