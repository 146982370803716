import Axios from '@plugins/axios'

export default (companyId, attachmentId) => {
  let url = `/api/v2/internal/companies/${companyId}/attachments/${attachmentId}`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.delete(url, config)
}
