import Axios from '@plugins/axios'
let getEventDetailsCancelToken

export default (eventId, customIncludes) => {
  let url = `/api/v2/internal/events/${eventId}`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  // If cancel token exist call cancel function.
  // this will cancel previous request
  if (getEventDetailsCancelToken) {
    getEventDetailsCancelToken.cancel()
  }

  // Create new cancel token for the new request and add it to config
  getEventDetailsCancelToken = Axios.CancelToken.source()
  config.cancelToken = getEventDetailsCancelToken.token

  return Axios.get(url, config)
}
