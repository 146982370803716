import Axios from '@plugins/axios'

export default (projectId, attachmentId) => {
  let url = `/api/v2/internal/projects/${projectId}/attachments/${attachmentId}/add-to-brand-hub`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.post(url, config)
}
