import Axios from '@plugins/axios'

export default (orderId, payload) => {
  let url = `/api/v2/internal/orders/${orderId}/po-number`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.put(url, payload, config)
}
