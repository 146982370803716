import * as types from '@store/mutation-types'

/**
 * @module store/project/project-health/project-health-mutations
 */
export default {
  /**
   * Update the project health form payload
   *
   * @param state
   * @param {object} payload - the project health payload
   */
  [types.PROJECT_HEALTH_LIST](state, payload) {
    state.totalProductionCost = payload.total_production_cost
    state.totalDisbursementCost = payload.total_disbursement_cost
    state.totalPreProductionHours = payload.total_preproduction_hours
    state.totalShootHours = payload.total_shoot_hours
    state.totalDeliveryHours = payload.total_delivery_hours
    state.totalHoursWorked = payload.total_hours_worked
    state.allocatedHours = payload.allocated_hours
    state.totalScheduledHours = payload.total_scheduled_hours
    state.totalProductionHours = payload.total_production_hours
    state.preProductionScheduledHours = payload.pre_production_scheduled_hours
    state.preProductionWorkedHours = payload.pre_production_worked_hours
    state.shootScheduledHours = payload.shoot_scheduled_hours
    state.shootWorkedHours = payload.shoot_worked_hours
    state.editingScheduledHours = payload.editing_scheduled_hours
    state.editingWorkedHours = payload.editing_worked_hours
    state.animationScheduledHours = payload.animation_scheduled_hours
    state.animationWorkedHours = payload.animation_worked_hours
    state.changesScheduledHours = payload.changes_scheduled_hours
    state.changesWorkedHours = payload.changes_worked_hours
  },

  /**
   * Update the project health load state.
   *
   * @param state
   * @param {Boolean} loading - the load state of project health
   */
  [types.PROJECT_HEALTH_LOADING](state, loading) {
    state.loading.projectHealth = loading
  },

  /**
   * Update the project health modal state.
   * @param state
   */
  [types.PROJECT_HEALTH_MODAL_TOGGLE_SHOW](state) {
    state.modal.projectHealth = !state.modal.projectHealth
  },
}
