import Axios from '@plugins/axios'

export default (customIncludes, payload) => {
  let url = `/api/v2/internal/users/pre-productions`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.state_id) {
    config.params.state_id = payload.state_id
  }

  if (payload.sort) {
    config.params.sort = payload.sort
  }

  if (payload.sortBy) {
    config.params.sortBy = payload.sortBy
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  return Axios.get(url, config)
}
