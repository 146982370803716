/**
 * @module store/project/project-health/project-health-getters
 */

export default {
  /**
   * The default GST value used for calculating the project management value
   * @returns {Number}
   */
  defaultGstValue() {
    return 11
  },

  /**
   * Get project health summary
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  /* rootGetters need to be 4th parameter */
  projectHealthSummary(state, getters) {
    // Default project management is all production cost jobs divided 11
    // see https://gst.calculatorsaustralia.com.au/ for GST calculation
    const totalProjectManagementValue =
      state.totalProductionCost / getters.defaultGstValue

    // Total of all production cost jobs minus totalProjectManagementValue
    const totalProductionCost =
      state.totalProductionCost - totalProjectManagementValue

    // Production cost divided by total hours worked. If total hours worked is 0, hourly rate is $0.00
    const totalHourlyRate =
      state.totalHoursWorked > 0
        ? totalProductionCost / state.totalHoursWorked
        : 0

    return {
      totalProjectManagement: totalProjectManagementValue,
      totalProductionCost: totalProductionCost,
      totalDisbursementCost: state.totalDisbursementCost,
      totalHoursWorked: state.totalHoursWorked,
      allocatedHours: state.allocatedHours ?? 0,
      scheduledHours: state.totalScheduledHours,
      hourlyRate: totalHourlyRate,
      preProductionScheduledHours: state.preProductionScheduledHours,
      preProductionWorkedHours: state.preProductionWorkedHours,
      shootScheduledHours: state.shootScheduledHours,
      shootWorkedHours: state.shootWorkedHours,
      editingScheduledHours: state.editingScheduledHours,
      editingWorkedHours: state.editingWorkedHours,
      animationScheduledHours: state.animationScheduledHours,
      animationWorkedHours: state.animationWorkedHours,
      changesScheduledHours: state.changesScheduledHours,
      changesWorkedHours: state.changesWorkedHours,
    }
  },

  /**
   * Get loading state for project health.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isProjectHealthLoading(state) {
    return state.loading.projectHealth
  },

  /**
   * Get show state of project health modal.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  showProjectHealthModal(state) {
    return state.modal.projectHealth
  },

  /**
   * Get project health hidden state based windowWidth
   * @param {Object} rootGetters - use to access getters from other module
   * @returns {Boolean}
   */
  isProjectHealthHidden(state, getters, rootState, rootGetters) {
    const windowWidth = rootGetters['common/windowWidth']
    // 1220 is based on the space available when we title is at max text length
    return windowWidth < 1220
  },
}
