import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'

export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_HEALTH_RESET_STATE)
  },

  /**
   * Action to get project health details.
   * @param dispatch
   * @param commit
   * @param rootGetters
   */
  getProjectHealthList({ dispatch, commit, rootGetters }, payload) {
    const projectId = rootGetters['project/projectDetails'].id
    commit(mutations.PROJECT_HEALTH_LOADING, true)

    ProjectServices.projects.health
      .list(projectId, payload.includes)
      .then((response) => {
        commit(mutations.PROJECT_HEALTH_LIST, response.data.data)
        commit(mutations.PROJECT_HEALTH_LOADING, false)
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error loading the project health.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
            },
          },
          { root: true }
        )
        commit(mutations.PROJECT_HEALTH_LOADING, false)
      })
  },

  /**
   * Action to update project health modal state
   * @param commit
   */
  toggleProjectHealthModalShow({ commit }) {
    commit(mutations.PROJECT_HEALTH_MODAL_TOGGLE_SHOW)
  },
}
