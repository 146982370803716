import * as types from '@store/mutation-types'
import firebase from '@plugins/firebase'
import Bugsnag from '@plugins/bugsnag'
import AppConfig from '@configs/app-config'
import ApiRequest from '@services/services'
import NProgress from '@plugins/nprogress'

/**
 * @module store/auth/actions
 */

/**
 * Store actions for auth module.
 * @module auth/actions
 */
export default {
  /**
   * Reset state for current store module.
   * @param {Object} - Context
   */
  resetState({ commit }) {
    commit(types.AUTH_RESET_STATE)
  },

  /**
   * Update firebase loading state
   * @param {Object} - Context
   * @param {Boolean} loading - the firebase loading state
   */
  setFirebaseTokenLoading({ commit }, loading) {
    commit(types.AUTH_FIREBASE_TOKEN_LOADING, loading)
  },

  /**
   * Update firebase user info.
   * @param {Object} - Context.
   * @param {Object} payload - Locally stored properties from firebase user.
   * @param {String} payload.uid
   * @param {String} payload.email
   * @param {String} payload.displayName
   * @param {String} payload.photoUrl
   * @param {String} payload.accessToken
   */
  updateFirebaseUserInfo({ commit, dispatch }, payload) {
    // Update store.
    commit(types.AUTH_UPDATE_INFO_FROM_FIREBASE, payload)

    // Updated bugsnag.
    Bugsnag.setUser(payload.uid, payload.email, payload.displayName)

    dispatch('updateVmsUser')
  },

  /**
   * Handle unauthorized user event.
   */
  handleUnauthorizedUser({ dispatch }) {
    // Reset store.
    dispatch('resetStateGlobally', null, { root: true })

    // See: https://developer.mozilla.org/en-US/docs/Web/API/Location#result
    let currentHref =
      window.location.pathname + window.location.search + window.location.hash

    // Create redirect query for current route.
    const redirectUrl = encodeURIComponent(
      Buffer.from(currentHref).toString('base64')
    )

    // Redirect to VMS on continue and append redirect URL encoded as base64.
    window.location.assign(
      `${AppConfig.vmsApiUrl}/auth/vmsx?redirect=${redirectUrl}`
    )
  },

  /**
   * Firebase sign out and redirect to login page.
   * @param {Object} context
   */
  signOut({ dispatch }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Reset store.
        dispatch('resetStateGlobally', undefined, { root: true }).then(() => {
          // Reset local storage.
          localStorage.clear()

          // Redirect back to login in VMS.
          window.location.assign(`${AppConfig.vmsApiUrl}/auth/login`)
        })
      })
  },

  /**
   * Get and update auth user data.
   * @param {Object} context
   */
  updateVmsUser({ commit, dispatch }) {
    ApiRequest.auth.user
      .list(['roles', 'permissions'])
      .then((response) => {
        commit(types.AUTH_UPDATE_USER_FROM_VMS, response.data.data)
        dispatch('setFirebaseTokenLoading', false)
        NProgress.done()
      })
      .catch(() => {
        NProgress.done()
      })
  },
}
