import Axios from '@plugins/axios'

export default (payload) => {
  const url = `/api/v2/internal/videos/${payload.videoId}`

  const config = {
    headers: {},
    params: {},
  }

  if (payload && payload.include) {
    config.params.include = payload.include.join(',')
  }

  return Axios.get(url, config)
}
