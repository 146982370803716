/**
 * @module store/project/production-note/production-note-getters
 */

export default {
  /**
   * Get project production notes
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  productionNotes(state) {
    return state.productionNotes
  },
}
