import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import { forEach, size } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'

/**
 * Store actions for pre-production module.
 * @module project/pre-production/pre-production-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_PRE_PRODUCTION_RESET_STATE)
  },

  /**
   * Action to set the project pre-productions loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setPreProductionsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PRE_PRODUCTIONS, loading)
  },

  /**
   * Update loader for pre-production item
   *
   * @param commit
   * @param {boolean} loading - The loading state
   */
  updatePreProductionItemLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PRE_PRODUCTION_ITEM, loading)
  },

  /**
   * Update loader for pre-production file versions
   *
   * @param commit
   * @param {boolean} loading - The loading state
   */
  updatePreProductionFileVersionsLoading({ commit }, loading) {
    commit(
      mutations.PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS_LOADING,
      loading
    )
  },

  /**
   * Action to set the project pre-productions list
   * @param commit
   * @param {array} preProductions - the pre-productions data
   */
  setPreProductionsList({ commit }, preProductions) {
    commit(mutations.PROJECT_PRE_PRODUCTIONS_LIST, preProductions)
  },

  /**
   * Action to get the project pre-productions
   * TODO: use get pre-productions service once available instead of getting details from the project details/summary object
   * @param commit
   * @param getters
   */
  getProjectPreProductionsList({ commit, getters }) {
    commit(
      mutations.PROJECT_PRE_PRODUCTIONS_LIST,
      getters.projectDetails.pre_productions
    )
  },

  /**
   * Update the pre-production types list
   *
   * @param commit
   */
  updatePreProductionTypesList({ commit, dispatch }, payload) {
    dispatch('setPreProductionsLoading', true)

    ProjectServices.products.list
      .preProductionProductElements(payload.projectId, payload.include)
      .then((response) => {
        dispatch('setPreProductionsLoading', false)

        const productElements = []

        forEach(response.data.data, (preProductionProducts) => {
          productElements.push(preProductionProducts)
        })

        commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_TYPES, productElements)
      })
      .catch((error) => {
        dispatch('setPreProductionsLoading', false)
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving the pre-production product elements.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              include: payload.include,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the selected pre-production type
   *
   * @param commit
   * @param {object} preProductionType - The selected pre-production type
   */
  updateSelectedPreProductionType({ commit }, preProductionType) {
    commit(
      mutations.PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_TYPE,
      preProductionType
    )
  },

  /**
   * Update the selected pre-production ID
   *
   * @param commit
   * @param {Number} preProductionId - The selected pre-production ID
   */
  updateSelectedPreProductionId({ commit }, preProductionId) {
    commit(
      mutations.PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_PRE_PRODUCTION_ID,
      preProductionId
    )
  },

  /**
   * Update the add new pre-productions form state
   *
   * @param commit
   * @param {boolean} show - The state of the add pre-productions form
   */
  showAddNewPreProductionsForm({ commit }, show) {
    commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_LINKING_JOB, null)
    commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_ADD_NEW_FORM_OPEN, show)
  },

  /**
   * Update the linking shoot or video job id for new pre-production
   *
   * @param commit
   * @param {Number} linkingJobId - The state of the linking job Id.
   */
  showAddNewPreProductionsFormWithLinkingJob({ commit }, linkingJobId) {
    commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_ADD_NEW_FORM_OPEN, true)
    commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_LINKING_JOB, linkingJobId)
  },

  /**
   * Update the edit pre-productions form state
   *
   * @param commit
   * @param {object} payload - Payload consisting of show state and edit pre-production form
   */
  showEditPreProductionsForm({ commit }, payload) {
    commit(mutations.PROJECT_FORM_PRE_PRODUCTIONS_EDIT_FORM_OPEN, payload.show)
  },

  /**
   * Update the pre-production active tab's item
   *
   * @param commit
   * @param {Number} preProductionIndex - The active pre-production index
   */
  updatePreProductionActiveTabIndex({ commit }, preProductionIndex) {
    commit(
      mutations.PROJECT_FORM_PRE_PRODUCTIONS_ACTIVE_TAB_INDEX,
      preProductionIndex
    )
  },

  /**
   * Upload pre-production document.
   *
   * @param {Object} - Vuex context.
   * @param {Object} payload - Payload object to upload pre-production document.
   * @param {Number} payload.project_id - selected project id
   * @param {Number} payload.order_job_id - selected order job id
   * @param {array} payload.includes - custom includes
   * @param {object} payload.payload - object containing payload for pre production upload.
   */
  uploadPreProductionDocument({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    ProjectServices.projects.jobs.preProduction
      .upload(projectId, orderJobId, includes, payload.payload)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })
        dispatch('toggleFileUploadModalShow')
        dispatch('updateSelectedPreProductionId', null)
        dispatch(
          'common/displayToastAlert',
          {
            icon: 'info',
            title: `File uploaded successfully.`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('toggleFileUploadModalShow')
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error uploading pre-production document.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the pre-production file versions.
   *
   * @param commit
   * @param {Number} perProductionFileVersions - The active pre-production index
   */
  updatePreProductionFileVersions({ commit }, perProductionFileVersions) {
    commit(
      mutations.PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS,
      perProductionFileVersions
    )
  },

  /**
   * Get pre-production file versions.
   *
   * @param dispatch
   * @param commit
   * @param {Object} payload - Payload object to upload pre-production document.
   * @param {Number} payload.project_id - selected project id
   * @param {Number} payload.order_job_id - selected order job id
   * @param {array} payload.includes - custom includes
   */
  getPreProductionFileVersions({ dispatch }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionFileVersionsLoading', true)

    ProjectServices.projects.jobs.preProduction
      .fileVersions(projectId, orderJobId, includes)
      .then((response) => {
        dispatch('updatePreProductionFileVersions', response.data.data)
      })
      .catch((error) => {
        dispatch('toggleFileVersionsModalShow')
        dispatch('updatePreProductionFileVersions', [])
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error getting pre-production file versions.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
      .finally(() => {
        dispatch('updatePreProductionFileVersionsLoading', false)
      })
  },

  /**
   * Restore pre-production file version.
   *
   * @param dispatch
   * @param commit
   * @param getters
   * @param {Object} payload - Payload object to upload pre-production document.
   * @param {Number} payload.project_id - selected project id
   * @param {Number} payload.order_job_id - selected order job id
   * @param {array} payload.includes - custom includes
   * @param {array} payload.payload - teh payload used for restoring file version.
   */
  restorePreProductionFileVersion({ dispatch, commit, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionFileVersionsLoading', true)

    ProjectServices.projects.jobs.preProduction
      .restoreFileVersion(projectId, orderJobId, includes, payload.payload)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })
        dispatch(
          'common/displayToastAlert',
          {
            icon: 'info',
            title: `File version restored successfully.`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error restoring pre-production file version.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
      .finally(() => {
        dispatch('updatePreProductionFileVersionsLoading', false)
        dispatch('toggleFileVersionsModalShow')
        dispatch('updateSelectedPreProductionId', null)
        dispatch('updatePreProductionFileVersions', [])
      })
  },

  /**
   * Update pre-production job
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param {object} payload - Payload for processing the updatePreProduction service
   */
  updatePreProductionJob({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const preProductionPayload = payload.payload

    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })

    ProjectServices.projects.jobs.preProduction
      .update(projectId, orderJobId, includes, preProductionPayload)
      .then((response) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showEditPreProductionsForm', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${preProductionPayload.name} updated successfully!`,
          },
          { root: true }
        )

        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })

        dispatch('updateSelectedPreProductionId', null)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('updateSelectedPreProductionId', null)
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showEditPreProductionsForm', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating the pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
              pre_production_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Approve or unapprove pre-production item.
   *
   * @param {Object} - Vuex context.
   * @param {Object} payload - Configuration for approvePreProduction service.
   * @param {Number} payload.project_id
   * @param {Number} payload.order_job_id
   * @param {Array} payload.includes - Additional data to be included in the response.
   * @param {Boolean} payload.approve - Determine if the job should be approved or not.
   */
  approvePreProduction({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const approve = payload.approve

    let preProductionService

    if (approve) {
      preProductionService = ProjectServices.projects.jobs.preProduction.approve(
        projectId,
        orderJobId,
        includes
      )
    } else {
      preProductionService = ProjectServices.projects.jobs.preProduction.unapprove(
        projectId,
        orderJobId,
        includes
      )
    }

    dispatch('updatePreProductionItemLoading', true)

    preProductionService
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })

        dispatch('updatePreProductionItemLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} document ${
              approve ? 'approved' : 'unapproved'
            }!`,
          },
          { root: true }
        )

        dispatch('updateSelectedPreProductionId', null)
      })
      .catch((error) => {
        dispatch('updatePreProductionItemLoading', false)
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error approving the pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
              approve: payload.approve,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Delete the selected pre-production job
   *
   * @param dispatch
   * @param {object} payload - payload for processing the deletePreProduction service
   */
  deletePreProduction({ dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionItemLoading', true)

    ProjectServices.projects.jobs.preProduction
      .delete(projectId, orderJobId, includes)
      .then(() => {
        dispatch('updatePreProductionItemLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} deleted!`,
          },
          { root: true }
        )

        dispatch('updateSelectedPreProductionId', null)

        // Assigning active tab index to be zero as deleting the only preProd in the current tab will make it dissappear.
        if (size(getters['preProductionActiveTabItems'].items) === 1) {
          dispatch('updatePreProductionActiveTabIndex', 0)
        }

        // TODO: remove pre-production from list
        dispatch(
          'project/getProjectDetails',
          {
            projectId: projectId,
            customIncludes: ApiCustomIncludes.projectDetails,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('updatePreProductionItemLoading', false)
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting the pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Create new pre-production job
   *
   * @param commit
   * @param {object} payload - Payload for processing the createNewPreProductionJob service
   */
  createNewPreProductionJob({ dispatch, rootGetters }, payload) {
    const projectId = payload.project_id
    const includes = payload.includes
    const preProductionPayload = payload.payload
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })
    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('showAddNewPreProductionsForm', false)

    dispatch(
      'common/displayToastAlert',
      {
        icon: 'info',
        title: `${preProductionPayload.name} is being processed. We will notify you when ready.`,
      },
      { root: true }
    )

    ProjectServices.projects.jobs.preProduction
      .create(projectId, includes, preProductionPayload)
      .then(() => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${preProductionPayload.name} added successfully!`,
          },
          { root: true }
        )

        if (
          rootGetters['project/isProjectArchived'] ||
          rootGetters['project/isProjectApproved']
        ) {
          dispatch(
            'project/getProjectDetails',
            {
              projectId: projectId,
              customIncludes: ApiCustomIncludes.projectDetails,
            },
            { root: true }
          )
        } else {
          ProjectServices.projects
            .detail(projectId, [
              'project_office',
              'office_company',
              'project_account_manager',
              'project_producer',
              'product_element_type',
            ])
            .then((response) => {
              dispatch(
                'setPreProductionsList',
                response.data.data.pre_productions
              )
              // Fetch project health data
              dispatch(
                'project/project-health/getProjectHealthList',
                {
                  includes: ApiCustomIncludes.projectHealthList,
                },
                {
                  root: true,
                }
              )
            })
        }
      })
      .catch((error) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error creating a new pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              include: payload.includes,
              pre_production_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Archive the selected pre-production job
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param {object} payload - payload for processing the archivePreProduction service
   */
  archivePreProduction({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionItemLoading', true)

    ProjectServices.projects.jobs.preProduction
      .archive(projectId, orderJobId, includes)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })

        dispatch('updatePreProductionItemLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} document was successfully archived!`,
          },
          { root: true }
        )

        dispatch('updateSelectedPreProductionId', null)
      })
      .catch((error) => {
        dispatch('updatePreProductionItemLoading', false)
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error archiving the pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Approve all pre-productions
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param {object} payload - payload for processing the approveAllPreProductions service
   */
  approveAllPreProductions({ dispatch }, payload) {
    const projectId = payload.project_id
    const includes = payload.includes
    const preProductionPayload = payload.payload

    dispatch('project/setProjectDetailsLoading', true, { root: true })

    ProjectServices.projects.jobs.preProduction
      .approveAll(projectId, includes, preProductionPayload)
      .then(() => {
        dispatch('project/setProjectDetailsLoading', false, { root: true })

        dispatch(
          'common/displayToastAlert',
          {
            title: `Successfully approved all pre-production documents for ${payload.name}!`,
          },
          { root: true }
        )

        dispatch(
          'project/getProjectDetails',
          {
            projectId: projectId,
            customIncludes: ApiCustomIncludes.projectDetails,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('project/setProjectDetailsLoading', false, { root: true })
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error approving all pre-production jobs.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              include: payload.includes,
              pre_production_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to toggle file upload modal state.
   *
   * @param commit
   */
  toggleFileUploadModalShow({ commit }) {
    commit(mutations.PROJECT_PRE_PRODUCTION_MODAL_FILE_UPLOAD_TOGGLE_SHOW)
  },

  /**
   * Action to toggle file versions modal state.
   *
   * @param commit
   */
  toggleFileVersionsModalShow({ commit }) {
    commit(mutations.PROJECT_PRE_PRODUCTION_MODAL_FILE_VERSIONS_TOGGLE_SHOW)
  },

  /**
   * Action to show or hide pre-production changes list.
   *
   * @param {Object} context
   * @param {boolean} show - show or hide the pre-production changes list
   */
  showPreProductionChangesList({ commit }, show) {
    commit(mutations.PROJECT_PRE_PRODUCTION_CHANGES_IS_LIST_OPEN, show)
  },

  /**
   * Action to show or hide pre-production changes form.
   *
   * @param {Object} context
   * @param {boolean} show - show or hide the pre-production changes form
   */
  showPreProductionChangesForm({ commit }, show) {
    commit(mutations.PROJECT_PRE_PRODUCTION_CHANGES_IS_FORM_OPEN, show)
  },

  /**
   * Action to set pre-production changes loading state.
   *
   * @param {Object} context
   * @param {boolean} isLoading - loading state
   */
  setPreProductionChangesLoading({ commit }, isLoading) {
    commit(mutations.PROJECT_LOADING_PRE_PRODUCTION_CHANGES, isLoading)
  },

  /**
   * Action to set pre-production change attachments loading state.
   *
   * @param {Object} context
   * @param {boolean} isLoading - loading state
   */
  setPreProductionChangeAttachmentsLoading({ commit }, isLoading) {
    commit(
      mutations.PROJECT_LOADING_PRE_PRODUCTION_CHANGE_ATTACHMENTS,
      isLoading
    )
  },

  /**
   * Action to set pre-production changes list.
   *
   * @param {Object} context
   * @param {array} list - pre-production changes list
   */
  setPreProductionChangesList({ commit }, list) {
    commit(mutations.PROJECT_PRE_PRODUCTION_CHANGES_LIST, list)
  },

  /**
   * Action to set pre-production changes selected job id.
   *
   * @param {Object} context
   * @param {number} jobId - job id
   */
  setPreProductionChangesSelectedPreProductionId({ commit }, jobId) {
    commit(
      mutations.PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_ID,
      jobId
    )
  },

  resetPreProductionChanges({ dispatch }) {
    dispatch('setPreProductionChangesList', [])
    dispatch('showPreProductionChangesForm', false)
    dispatch('setPreProductionChangesSelectedPreProductionId', '')
    dispatch('setSelectedPreProductionChange', null)
  },

  /**
   * Action to set pre-production changes selected pre-production change.
   *
   * @param {Object} context
   * @param {object} preProductionChange - pre-production change object
   */
  setSelectedPreProductionChange({ commit }, preProductionChange) {
    commit(
      mutations.PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_CHANGE,
      preProductionChange
    )
  },

  /**
   * Action to set attachments for selected pre-production change
   *
   * @param {Object} context
   * @param {array} attachments - attachments array
   */
  setSelectedPreProductionChangeAttachments({ commit, getters }, attachments) {
    const preProductionChange = {
      ...getters.selectedPreProductionChange,
      attachments,
    }

    commit(
      mutations.PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_CHANGE,
      preProductionChange
    )
  },

  /**
   * Action to get and update pre-production changes list.
   *
   * @param {Object} context
   * @param payload - payload for processing the getPreProductionChanges service
   */
  getPreProductionChanges({ dispatch }, payload) {
    dispatch('setPreProductionChangesLoading', true)

    ProjectServices.projects.jobs.preProduction.changes
      .list(
        payload.projectId,
        payload.orderJobId,
        payload.includes,
        payload.payload
      )
      .then((response) => {
        dispatch('setPreProductionChangesList', response.data.data)
        dispatch('setPreProductionChangesLoading', false)
      })
      .catch((error) => {
        dispatch('setPreProductionChangesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error getting pre-production changes.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to create pre-production change.
   *
   * @param {Object} context
   * @param payload - payload for processing the createPreProductionChange service
   */
  createPreProductionChange({ dispatch }, payload) {
    dispatch('setPreProductionChangesLoading', true)

    ProjectServices.projects.jobs.preProduction.changes
      .create(payload.projectId, payload.orderJobId)
      .then((response) => {
        dispatch('setSelectedPreProductionChange', response.data.data)
        dispatch('getPreProductionChanges', {
          projectId: payload.projectId,
          orderJobId: payload.orderJobId,
          includes: payload.includes,
          payload: {
            sort: payload.payload.sort,
            sortBy: payload.payload.sortBy,
          },
        })
      })
      .catch((error) => {
        dispatch('setPreProductionChangesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error creating pre-production change.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to update pre-production change.
   *
   * @param {Object} context
   * @param payload - payload for processing the updatePreProductionChange service
   */
  updatePreProductionChange({ dispatch }, payload) {
    dispatch('setPreProductionChangesLoading', true)

    ProjectServices.projects.jobs.preProduction.changes
      .update(
        payload.projectId,
        payload.orderJobId,
        payload.orderJobChangeId,
        payload.payload
      )
      .then(() => {
        dispatch('showPreProductionChangesForm', false)
        dispatch('showPreProductionChangesList', true)
        dispatch('setSelectedPreProductionChange', null)
        dispatch('getPreProductionChanges', {
          projectId: payload.projectId,
          orderJobId: payload.orderJobId,
          includes: payload.includes,
          payload: {
            sort: payload.payload.sort,
            sortBy: payload.payload.sortBy,
          },
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `Pre-production change successfully updated!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setPreProductionChangesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating pre-production change.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to delete pre-production change.
   *
   * @param {Object} context
   * @param payload - payload for processing the deletePreProductionChange service
   */
  deletePreProductionChange({ dispatch }, payload) {
    dispatch('setPreProductionChangesLoading', true)

    ProjectServices.projects.jobs.preProduction.changes
      .delete(payload.projectId, payload.orderJobId, payload.orderJobChangeId)
      .then(() => {
        dispatch('getPreProductionChanges', {
          projectId: payload.projectId,
          orderJobId: payload.orderJobId,
          includes: payload.includes,
          payload: {
            sort: payload.payload.sort,
            sortBy: payload.payload.sortBy,
          },
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `Pre-production change successfully deleted!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setPreProductionChangesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting pre-production change.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to get and update pre-production change attachments.
   *
   * @param {Object} context
   * @param payload - payload for processing the preProductionChangeAttachmentsList service
   */
  getPreProductionChangeAttachments({ dispatch }, payload) {
    dispatch('setPreProductionChangeAttachmentsLoading', true)

    ProjectServices.projects.jobs.preProduction.changes.attachments
      .list(payload.projectId, payload.orderJobId, payload.orderJobChangeId)
      .then((response) => {
        dispatch('setPreProductionChangeAttachmentsLoading', false)
        dispatch(
          'setSelectedPreProductionChangeAttachments',
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('setPreProductionChangeAttachmentsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error getting pre-production change attachments.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to delete pre-production change attachment.
   *
   * @param {Object} context
   * @param payload - payload for processing the deletePreProductionChangeAttachment service
   */
  deletePreProductionChangeAttachment({ dispatch }, payload) {
    dispatch('setPreProductionChangeAttachmentsLoading', true)

    ProjectServices.projects.jobs.preProduction.changes.attachments
      .delete(
        payload.projectId,
        payload.orderJobId,
        payload.orderJobChangeId,
        payload.attachmentId
      )
      .then(() => {
        dispatch('setPreProductionChangeAttachmentsLoading', false)

        dispatch('getPreProductionChangeAttachments', {
          projectId: payload.projectId,
          orderJobId: payload.orderJobId,
          orderJobChangeId: payload.orderJobChangeId,
        })

        dispatch(
          'common/displayToastAlert',
          {
            title: `Pre-production change attachment successfully deleted!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setPreProductionChangeAttachmentsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting pre-production change attachment.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Hold pre-production job.
   *
   * @param {Object} - Vuex context.
   * @param {Object} payload - Configuration for holdPreProduction service.
   * @param {Number} payload.project_id
   * @param {Number} payload.order_job_id
   * @param {Array} payload.includes - Additional data to be included in the response.
   * @param {string} payload.name - the name of the selected job
   */
  holdPreProduction({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionItemLoading', true)

    ProjectServices.projects.jobs.preProduction
      .hold(projectId, orderJobId, includes)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })

        dispatch('updatePreProductionItemLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been successfully put on hold`,
          },
          { root: true }
        )

        dispatch('updateSelectedPreProductionId', null)
      })
      .catch((error) => {
        dispatch('updatePreProductionItemLoading', false)
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to hold pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Unhold pre-production job.
   *
   * @param {Object} - Vuex context.
   * @param {Object} payload - Configuration for unholdPreProduction service.
   * @param {Number} payload.project_id
   * @param {Number} payload.order_job_id
   * @param {Array} payload.includes - Additional data to be included in the response.
   */
  unholdPreProduction({ commit, dispatch, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('updatePreProductionItemLoading', true)

    ProjectServices.projects.jobs.preProduction
      .unhold(projectId, orderJobId, includes)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_PRE_PRODUCTION_ITEM, {
          preProductionItems: getters.preProductionActiveTabItems.items,
          data: response.data.data,
        })

        dispatch('updatePreProductionItemLoading', false)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been successfully taken off hold`,
          },
          { root: true }
        )

        dispatch('updateSelectedPreProductionId', null)
      })
      .catch((error) => {
        dispatch('updatePreProductionItemLoading', false)
        dispatch('updateSelectedPreProductionId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to take off hold pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * * Action to update pre-production details loading state
   *
   * @param {Object} context
   * @param {boolean} isLoading - loading state
   */
  setPreProductionDetailsLoading({ commit }, isLoading) {
    commit(mutations.PROJECT_PRE_PRODUCTION_DETAILS_LOADING, isLoading)
  },

  /**
   * Action to update pre-production details.
   *
   * @param {Object} context
   * @param {Object} preProductionDetails - pre-production details object
   */
  setPreProductionDetails({ commit }, preProductionDetails) {
    commit(mutations.PROJECT_PRE_PRODUCTION_DETAILS, preProductionDetails)
  },

  /**
   * Action to fetch pre-production job details
   *
   * @param {Object} context
   * @param payload - payload for processing the getPreProductionDetails service
   */
  getPreProductionDetails({ dispatch }, payload) {
    dispatch('setPreProductionDetailsLoading', true)

    ProjectServices.projects.jobs.preProduction
      .get(payload.includes, payload.projectId, payload.orderJobId)
      .then((response) => {
        dispatch('setPreProductionDetailsLoading', false)
        dispatch('setPreProductionDetails', response.data.data)
      })
      .catch((error) => {
        dispatch('setPreProductionDetailsLoading', false)
        dispatch('setPreProductionDetails', null)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching pre-production details.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },
}
