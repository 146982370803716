import * as types from '@store/mutation-types'

/**
 * @module store/project/production-note/production-note-mutations
 */
export default {
  /**
   * Update the project production notes list
   * @param {object} state - the state of the current module
   * @param {array} productionNotes - the array of project production notes
   */
  [types.PROJECT_PRODUCTION_NOTES_LIST](state, productionNotes) {
    state.productionNotes = productionNotes
  },
}
