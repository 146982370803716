import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/projects/${payload.projectId}/jobs/${payload.jobId}/eod-notes`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  return Axios.post(url, payload, config)
}
