import * as types from '@store/mutation-types'

/**
 * @module store/project/activity-log/activity-log-mutation
 */
export default {
  /**
   * Update the project activity log list
   * @param {object} state
   * @param {array} activityLogs - the array of project activity log
   */
  [types.PROJECT_ACTIVITY_LOGS_LIST](state, activityLogs) {
    state.activityLogs = activityLogs
  },
  /**
   * Update the project activity log list
   * @param {object} state
   * @param {boolean} loading - loader state
   */
  [types.PROJECT_ACTIVITY_LOGS_LOADING](state, loading) {
    state.loading.activityLogList = loading
  },

  /**
   * Update the project has activity logs state
   * @param {object} state
   * @param {boolean} hasActivityLogs - loader state
   */
  [types.PROJECT_HAS_ACTIVITY_LOGS](state, hasActivityLogs) {
    state.projectHasActivityLogs = hasActivityLogs
  },
}
