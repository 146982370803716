import * as types from '@store/mutation-types'

/**
 * @module store/role/mutations
 */

export default {
  /**
   * Update the external roles list loading state
   * @param state
   * @param {boolean} loading - the loading state
   */
  [types.ROLE_LOADING_EXTERNAL_ROLES](state, loading) {
    state.loading.externalRoles = loading
  },

  /**
   * Update the external roles list state
   * @param state
   * @param {array} roles - the roles list
   */
  [types.ROLE_EXTERNAL_ROLES](state, roles) {
    state.externalRoles = roles
  },
}
