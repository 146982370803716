import { find, filter, includes } from 'lodash'
import VmsConstants from '@configs/vms-constants'

/**
 * @module store/project/delivery/delivery-getters
 */

export default {
  /**
   * Get loading state for project deliveries.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  deliveriesListLoading(state) {
    return state.loading.deliveries
  },

  /**
   * Get loading state for video product elements.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoProductElementsLoading(state) {
    return state.loading.videoProductElements
  },

  /**
   * Get loading state for hold deliveries.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  holdDeliveriesLoading(state) {
    return state.loading.holdDeliveries
  },

  /**
   * Get loading state for project video products.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  videoProductsLoading(state) {
    return state.loading.videoProducts
  },

  /**
   * Get project deliveries
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  deliveries(state) {
    return state.deliveries
  },

  /**
   * Get project deliveries video products
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  videoProducts(state) {
    return state.videoProducts
  },

  /**
   * Get product elements for the selected video product
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  videoProductElements(state) {
    return state.projectForm.videoProductElements
  },

  /**
   * Get the state of the new video delivery job form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the new video delivery job form
   */
  videoDeliveryJobNewFormOpen(state) {
    return state.projectForm.addNewFormOpen
  },

  /**
   * Get the state of the edit video delivery job form
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the edit video delivery job form
   */
  videoDeliveryJobEditFormOpen(state) {
    return state.projectForm.editFormOpen
  },

  /**
   * Get the selected video product data
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  selectedVideoProduct(state) {
    return find(state.videoProducts, {
      product_id: state.selectedVideoProductId,
    })
  },

  /**
   * Get the selected video product data
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  selectedVideoDeliveryJob(state) {
    return find(state.deliveries.data, {
      id: state.projectForm.selectedVideoDeliveryJobId,
    })
  },

  /**
   * Get the select all deliveries state
   * @param {object} state - state for this module
   * @returns {boolean}
   */
  selectAllDeliveries(state) {
    return state.selectAllDeliveries
  },

  /**
   * Get the array of selected delivery ids
   * @param {object} state - state for this module
   * @returns {array}
   */
  selectedDeliveryIds(state) {
    return state.selectedDeliveryIds
  },

  /**
   * Get the delivered video jobs from the selected delivery ids
   * @param {object} state - state for this module
   * @returns {array}
   */
  deliveredJobs(state) {
    return filter(state.deliveries.data, (delivery) => {
      return (
        (delivery.status_id ===
          VmsConstants.orderJobStatuses.ORDER_JOB_DELIVERED ||
          delivery.status_id ===
            VmsConstants.orderJobStatuses.ORDER_JOB_CHANGES_UPLOADED ||
          delivery.status_id ===
            VmsConstants.orderJobStatuses.ORDER_JOB_VIDEO_UPLOADED) &&
        includes(state.selectedDeliveryIds, delivery.id)
      )
    })
  },

  /**
   * Get the non-delivered video jobs from the selected delivery ids
   * @param {object} state - state for this module
   * @returns {array}
   */
  nonDeliveredJobs(state) {
    return filter(state.deliveries.data, (delivery) => {
      return (
        delivery.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_DELIVERED &&
        delivery.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_CHANGES_UPLOADED &&
        delivery.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_VIDEO_UPLOADED &&
        includes(state.selectedDeliveryIds, delivery.id)
      )
    })
  },

  /**
   * Get the state of the modal video player show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showVideoPlayerModal(state) {
    return state.modal.videoPlayer.show
  },

  /**
   * Get the state of the selected delivery id.
   *
   * @param {object} state - State for current module.
   * @returns {Number} - The state of the selected delivery id.
   */
  selectedDeliveryId(state) {
    return state.selectedDeliveryId
  },

  /**
   * Get the state of the modal bulk add videos show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showBulkAddVideosModal(state) {
    return state.modal.bulkAddVideos.show
  },

  /**
   * Get the state of the modal bulk add videos quantity
   *
   * @param {object} state - State for current module.
   * @returns {number} - The state of the modal bulk add videos quantity
   */
  bulkAddVideosQuantity(state) {
    return state.modal.bulkAddVideos.amount
  },

  /**
   * Get the state of the modal bulk add videos names
   *
   * @param {object} state - State for current module.
   * @returns {array} - The state of the modal bulk add videos names
   */
  bulkAddVideosNames(state) {
    return state.modal.bulkAddVideos.names
  },

  /**
   * Get the state of the modal bulk add videos confirmation
   *
   * @param {object} state - State for current module.
   * @returns {array} - The state of the modal bulk add videos names
   */
  bulkAddVideosConfirm(state) {
    return state.modal.bulkAddVideos.confirm
  },

  /**
   * Get the state of the deliveries page filters.
   *
   * @param {object} state - State for current module.
   * @returns {object} - The state of the deliveries page filters.
   */
  deliveriesPageFilters(state) {
    return state.deliveriesPage.filters
  },
}
