import * as types from '@store/mutation-types'

/**
 * @module store/project/schedule/mutations
 */

export default {
  /**
   * Update the show other events state
   * @param {Object} state - This module state.
   * @param {Boolean} showOtherEvents - The new state for showOtherEvents
   */
  [types.PROJECT_SCHEDULE_SHOW_OTHER_EVENTS](state, showOtherEvents) {
    state.showOtherEvents = showOtherEvents
  },

  /**
   * Update the selectedFilters state
   * @param {Object} state - This module state.
   * @param {Object} filters - The new filters state
   */
  [types.PROJECT_SCHEDULE_SELECTED_FILTERS](state, filters) {
    state.selectedFilters = filters
  },

  /**
   * Update the selected date
   * @param {Object} state - This module state.
   * @param {String} selectedDate - The new selected date
   */
  [types.PROJECT_SCHEDULE_SELECTED_DATE](state, selectedDate) {
    state.selectedDate = selectedDate
  },

  /**
   * Update users list
   *
   * @param {Object} state - This module state.
   * @param {Array} usersList - The new users list
   */
  [types.PROJECT_SCHEDULE_USERS_LIST](state, usersList) {
    state.usersList = usersList
  },
}
