export default [
  {
    path: '/auth/vms',
    name: 'auth.vms.token',
    component: () => import('@views/auth/auth-vms-token'),
    meta: {
      title: 'Login',
    },
    props: (route) => ({
      firebaseToken: route.query.fbt,
      redirect: route.query.redirect,
    }),
  },

  {
    path: '/logout',
    name: 'auth.logout',
    component: () => import('@views/auth/auth-logout'),
    meta: {
      title: 'Logout',
    },
  },
]
