import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'
import router from '@router/router'
import { parseInt } from 'lodash'

/**
 * Store actions for attachment module.
 * @module project/attachment/attachment-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_ATTACHMENT_RESET_STATE)
  },

  /**
   * Action to set the project files loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProjectFilesLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PROJECT_FILES, loading)
  },

  /**
   * Action to set the brand hub loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setBrandHubLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_BRAND_HUB, loading)
  },

  /**
   * Action to set the project files list
   *
   * @param commit
   * @param {array} list - the file list data
   */
  setProjectFilesList({ commit }, list) {
    commit(mutations.PROJECT_FILES_LIST, list)
  },

  /**
   * Action to set the project brand hub list
   *
   * @param commit
   * @param {array} list - the brand hub list data
   */
  setProjectBrandHubList({ commit }, list) {
    commit(mutations.PROJECT_BRAND_HUB_LIST, list)
  },

  /**
   * Action to get the project files list
   *
   * @param commit
   * @param getters
   */
  getProjectFilesList({ dispatch, commit, getters }, payload) {
    dispatch('setProjectFilesLoading', true)

    if (!payload.perPage) {
      payload.perPage = getters['projectFiles'].meta.per_page
    }

    ApiServices.projects.attachments
      .list(payload)
      .then((response) => {
        dispatch('setProjectFilesLoading', false)

        commit(mutations.PROJECT_FILES_LIST, response.data)
      })
      .catch((error) => {
        dispatch('setProjectFilesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving Project Files.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to get the brand hub list
   *
   * @param commit
   * @param getters
   */
  getBrandHubList({ dispatch, commit, getters }, payload) {
    dispatch('setBrandHubLoading', true)

    if (!payload.perPage) {
      payload.perPage = getters['brandHub'].meta.per_page
    }

    ApiServices.projects.brandHub
      .list(payload)
      .then((response) => {
        dispatch('setBrandHubLoading', false)

        commit(mutations.PROJECT_BRAND_HUB_LIST, response.data)
      })
      .catch((error) => {
        dispatch('setBrandHubLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving Brand Hub list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to delete project files attachment.
   *
   * @param context
   * @param payload
   */
  deleteProjectFilesAttachment({ dispatch, getters }, payload) {
    let page = 1
    const currentPageQuery = parseInt(router.currentRoute.query.page)
    const currentProjectFiles = getters.projectFiles

    // Only update page route if there is "page" query on url and if it is not on page 1
    if (router.currentRoute.query && currentPageQuery > 1) {
      page =
        currentProjectFiles.data.length === 1
          ? currentPageQuery - 1
          : currentPageQuery
    }

    dispatch('setProjectFilesLoading', true)

    ApiServices.projects.attachments
      .delete(payload.project_id, payload.attachment_id)
      .then(() => {
        dispatch('setProjectFilesLoading', false)

        dispatch('getProjectFilesList', {
          projectId: payload.project_id,
          page: page,
          include: ['created_by'],
        })

        if (router.currentRoute.query.page && currentPageQuery !== page) {
          router.replace({
            query: {
              page: page,
            },
          })
        }

        dispatch(
          'common/displayToastAlert',
          { title: `Attachment successfully deleted!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setProjectFilesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting project files.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              attachment_id: payload.attachment_id,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to add project files attachment to brand hub.
   *
   * @param dispatch
   * @param payload
   */
  addAttachmentToBrandHub({ dispatch }, payload) {
    dispatch('setProjectFilesLoading', true)

    ApiServices.projects.attachments
      .addToBrandHub(payload.project_id, payload.attachment_id)
      .then(() => {
        dispatch('setProjectFilesLoading', false)

        dispatch('getBrandHubList', {
          projectId: payload.project_id,
          page: 1,
          include: ['created_by'],
        })

        dispatch(
          'common/displayToastAlert',
          { title: `Attachment successfully added to Brand Hub!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setProjectFilesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding attachment to Brand Hub.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              attachment_id: payload.attachment_id,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to delete brand hub attachment.
   *
   * @param context
   * @param payload
   */
  deleteBrandHubAttachment({ dispatch, getters }, payload) {
    let page = 1
    const currentPageQuery = parseInt(router.currentRoute.query.page)
    const currentBrandHub = getters.brandHub

    // Only update page route if there is "page" query on url and if it is not on page 1
    if (router.currentRoute.query && currentPageQuery > 1) {
      page =
        currentBrandHub.data.length === 1
          ? currentPageQuery - 1
          : currentPageQuery
    }

    dispatch('setBrandHubLoading', true)

    ApiServices.projects.brandHub
      .delete(payload.project_id, payload.attachment_id)
      .then(() => {
        dispatch('setBrandHubLoading', false)

        dispatch('getBrandHubList', {
          projectId: payload.project_id,
          page: page,
          include: ['created_by'],
        })

        if (router.currentRoute.query.page && currentPageQuery !== page) {
          router.replace({
            query: {
              page: page,
            },
          })
        }

        dispatch(
          'common/displayToastAlert',
          { title: `Attachment successfully deleted!` },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setBrandHubLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting Brand Hub attachment.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              attachment_id: payload.attachment_id,
            },
          },
          { root: true }
        )
      })
  },
}
