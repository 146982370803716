import VmsConstants from '@configs/vms-constants'
import StatusesConfig from '@configs/statuses'
import ProductTypesConfig from '@configs/product-types'
import { isEmpty, size, filter, includes, map, split } from 'lodash'
import VueCookies from 'vue-cookies'

/**
 * @module store/projects/projects-getters
 */

export default {
  /**
   * Get loading state for projects list
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectsListLoading(state) {
    return state.loading.projectsList
  },

  /**
   * Get loading state for project health
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectHealthLoading(state) {
    return state.loading.projectHealth
  },

  /**
   * Get the list of all projects
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the list of all projects with meta info
   */
  projectsList(state) {
    return state.projects
  },

  /**
   * Get the list of filters selected
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the list of selected filters
   */
  projectsFilter(state) {
    return state.projectsFilter
  },

  /**
   * Get the selected status filter
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the selected status filter
   */
  selectedStatusesFilter(state) {
    if (isEmpty(state.projectsFilter.statuses)) {
      return {
        text: 'All Statuses',
        selectedStatuses: filter(StatusesConfig.projectStatus, (status) => {
          return (
            status.id !== 'default' &&
            status.id !==
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT &&
            status.id !==
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED &&
            status.id !==
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED
          )
        }),
      }
    }

    if (size(state.projectsFilter.statuses) >= 12) {
      return {
        text: 'All Statuses',
        selectedStatuses: filter(StatusesConfig.projectStatus, (status) => {
          return status.id !== 'default'
        }),
      }
    }

    if (size(state.projectsFilter.statuses) === 1) {
      const selectedStatus = filter(StatusesConfig.projectStatus, (status) => {
        return status.id === state.projectsFilter.statuses[0]
      })

      return {
        text: selectedStatus[0]?.text,
        selectedStatuses: selectedStatus,
      }
    } else {
      const selectedStatus = filter(StatusesConfig.projectStatus, (status) => {
        return includes(state.projectsFilter.statuses, status.id)
      })

      return {
        text: 'Multiple Statuses',
        selectedStatuses: selectedStatus,
      }
    }
  },

  /**
   * Get the selected status filter ID
   *
   * @param {object} state - the state of the current module
   *
   * @returns {array} - returns the array of status ID
   */
  selectedStatusesFilterId(state) {
    if (isEmpty(state.projectsFilter.statuses)) {
      return null
    } else {
      return state.projectsFilter.statuses
    }
  },

  /**
   * Get the selected product type filter
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the selected product type filter
   */
  selectedProductTypeFilter(state) {
    if (
      isEmpty(state.projectsFilter.product_types) ||
      size(state.projectsFilter.product_types) >= 15
    ) {
      return {
        text: 'All Product Types',
        icon: 'icon-tags',
        selectedProductTypes: map(ProductTypesConfig.productTypes, 'id'),
      }
    } else {
      if (size(state.projectsFilter.product_types) === 1) {
        const selectedProductType = filter(
          ProductTypesConfig.productTypes,
          (productType) => {
            return productType.id === state.projectsFilter.product_types[0]
          }
        )

        return {
          text: selectedProductType[0]?.text,
          icon: selectedProductType[0]?.icon,
          selectedProductTypes: [selectedProductType[0]?.id],
        }
      } else {
        return {
          text: 'Multiple Product Types',
          icon: 'icon-tags',
          selectedProductTypes: state.projectsFilter.product_types,
        }
      }
    }
  },

  /**
   * Get the selected product type filter ID
   *
   * @param {object} state - the state of the current module
   *
   * @returns {array} - returns the array of product type ID
   */
  selectedProductTypeFilterId(state) {
    if (
      isEmpty(state.projectsFilter.product_types) ||
      size(state.projectsFilter.product_types) >= 15
    ) {
      return null
    } else {
      return state.projectsFilter.product_types
    }
  },

  /**
   * Get the selected user filter
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the selected user filter
   */
  selectedUserFilter(state) {
    if (isEmpty(state.projectsFilter.users)) {
      return {
        text: 'Select a User',
        users: [],
      }
    } else {
      if (size(state.projectsFilter.users) === 1) {
        return {
          text: state.projectsFilter.users[0].full_name,
          users: state.projectsFilter.users,
        }
      } else {
        return {
          text: 'Multiple Users',
          users: state.projectsFilter.users,
        }
      }
    }
  },

  /**
   * Get the selected user filter ID
   *
   * @param {object} state - the state of the current module
   *
   * @returns {array} - returns the array of user ID
   */
  selectedUserFilterId(state) {
    if (isEmpty(state.projectsFilter.users)) {
      return null
    } else {
      return map(state.projectsFilter.users, 'id')
    }
  },

  /**
   * Check if my projects filter is selected
   *
   * @param {object} state - the state of the current module
   *
   * @returns {boolean} - returns true if my_projects is selected
   */
  isMyProjectFilterSelected(state) {
    return state.projectsFilter.project_type === 'my_projects'
  },

  /**
   * Get the selected project type
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected project type
   */
  selectedProjectType(state) {
    return state.projectsFilter.project_type
  },

  /**
   * Get the selected search keyword
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected search keyword
   */
  searchKeyword(state) {
    return state.projectsFilter.search
  },

  /**
   * Get the selected sort
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected sort
   */
  selectedSort(state) {
    return state.projectsFilter.sort
  },

  /**
   * Get the selected sort by
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected sort by
   */
  selectedSortBy(state) {
    return state.projectsFilter.sortBy
  },

  /**
   * Get the selected sort filter.
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns the object for sorting consist of sortBy and the direction.
   */
  selectedSortFilter(state) {
    if (
      isEmpty(state.projectsFilter.sort) ||
      isEmpty(state.projectsFilter.sortBy)
    ) {
      return null
    } else {
      return {
        sortBy: state.projectsFilter.sortBy,
        direction: state.projectsFilter.sort,
      }
    }
  },

  /**
   * Get the selected from date
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected from date
   */
  selectedFromDate(state) {
    return state.projectsFilter.from
  },

  /**
   * Get the selected to date
   *
   * @param {object} state - the state of the current module
   *
   * @returns {string} - the selected to date
   */
  selectedToDate(state) {
    return state.projectsFilter.to
  },

  /**
   * Get the selected created date.
   *
   * @param {object} state - the state of the current module
   *
   * @returns {object} - returns an object consisting of start and end dates
   */
  selectedCreatedDate(state) {
    if (
      isEmpty(state.projectsFilter.from) ||
      isEmpty(state.projectsFilter.to)
    ) {
      return null
    } else {
      return {
        start: state.projectsFilter.from,
        end: state.projectsFilter.to,
      }
    }
  },

  /**
   * Get the selected per page
   * @param {object} state - the state of the current module
   * @returns {number} - the selected per page
   */
  selectedPerPage(state) {
    return state.projectsFilter.perPage
  },

  /**
   * The current page of project list
   *
   * @param {object} state - the state of the current module
   * @returns {number} - the current page of project list
   */
  currentPage(state) {
    return state.currentPage
  },

  /**
   * The selected project ID
   *
   * @param {object} state - the state of the current module
   *
   * @returns {number} - the selected project ID
   */
  selectedProjectId(state) {
    return state.selectedProjectId
  },

  /**
   * The selected fields per project that are currently saving
   *
   * @param {object} state - the state of the current module
   *
   * @returns {array} - returns an array of all fields currently saving
   */
  selectedFieldsForUpdating(state) {
    return state.projectFieldsUpdating
  },

  /**
   * The project dashboard filters set in cookies
   *
   * @returns {object} - returns the value of vmsx-dashboard-filters from the cookies
   */
  defaultSelectedProjectsFilter() {
    return VueCookies.get('vmsx-dashboard-filters')
  },

  /**
   * Check if My Projects is set in cookies.
   *
   * @param context
   * @returns {boolean} - returns true if my_projects is set in cookies for vmsx-dashboard-project-type
   */
  isMyProjectsFiltered(state, getters) {
    return getters.defaultSelectedProjectType === 'my_projects'
  },

  /**
   * The selected project dashboard project type set in cookies.
   *
   * @param context
   * @returns {string} - the selected project type
   */
  defaultSelectedProjectType(state, getters) {
    return getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.projectType
      : null
  },

  /**
   * The selected project dashboard created date filter set in cookies.
   *
   * @param context
   * @returns {object} - returns an object consisting of to and from dates
   */
  defaultSelectedCreatedDateFilter(state, getters) {
    return getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.createdDate
      : null
  },

  /**
   * The selected project dashboard users set in cookies.
   *
   * @param context
   * @returns {array} - returns array of int if there are any users selected, otherwise return null.
   */
  defaultSelectedUsersFilter(state, getters) {
    const selectedUsers = getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.users
      : null

    return !isEmpty(selectedUsers)
      ? split(selectedUsers, ',').map(Number)
      : null
  },

  /**
   * The selected project dashboard statuses set in cookies.
   *
   * @param context
   * @returns {array} - returns array of int if there are any statuses selected, otherwise return null.
   */
  defaultSelectedStatusFilter(state, getters) {
    const selectedStatuses = getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.statuses
      : null

    return !isEmpty(selectedStatuses)
      ? split(selectedStatuses, ',').map(Number)
      : null
  },

  /**
   * The selected project dashboard product types set in cookies.
   *
   * @param context
   * @returns {array} - returns array of int if there are any product types selected, otherwise return null.
   */
  defaultSelectedProductTypesFilter(state, getters) {
    const selectedProductTypes = getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.productTypes
      : null

    return !isEmpty(selectedProductTypes)
      ? split(selectedProductTypes, ',').map(Number)
      : null
  },

  /**
   * The selected project dashboard per page set in cookies.
   *
   * @param context
   * @returns {number} - returns the selected per page if set, otherwise we default to 25 per page
   */
  defaultSelectedPerPageFilter(state, getters) {
    const selectedPerPage = getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.perPage
      : null

    return selectedPerPage ? parseInt(selectedPerPage) : 25
  },

  /**
   * The selected project dashboard sort data set in cookies.
   *
   * @param context
   * @returns {object} - returns an object consisting of sortBy and direction
   */
  defaultSelectedSortFilter(state, getters) {
    return getters.defaultSelectedProjectsFilter
      ? getters.defaultSelectedProjectsFilter.sort
      : null
  },

  /**
   * Get archived project
   *
   * @param {Object} state - State for this module.
   * @returns {Array} - the list of archived projects
   */
  archivedProjectsList(state) {
    return state.archivedProjects.data
  },

  /**
   * Get project list meta
   *
   * @param {Object} state - State for this module.
   * @returns {Object} - the meta data for the archived projects
   */
  archivedProjectsMeta(state) {
    return state.archivedProjects.meta
  },
}
