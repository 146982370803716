import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/tags/video`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.searchTerm) {
    config.params.searchTerm = payload.searchTerm
  }
  if (payload.page) {
    config.params.page = payload.page
  }

  return Axios.get(url, config)
}
