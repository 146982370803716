import Axios from '@plugins/axios'

export default (projectId, orderJobId, customIncludes, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/jobs/pre-production/${orderJobId}/changes`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = customIncludes.join(',')
  }

  if (payload?.sort) {
    config.params.sort = payload.sort
  }
  if (payload?.sortBy) {
    config.params.sortBy = payload.sortBy
  }

  return Axios.get(url, config)
}
