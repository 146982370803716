import Axios from '@plugins/axios'

export default (payload) => {
  let url = `/api/v2/internal/projects/${payload.projectId}/brand-hub/attachments`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (payload.perPage) {
    config.params.per_page = payload.perPage
  }

  return Axios.get(url, config)
}
