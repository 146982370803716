import * as mutations from '@store/mutation-types'
import SweetAlert from '@plugins/sweet-alert'
import Bugsnag from '@plugins/bugsnag'
import { get, isEmpty, forOwn, capitalize, forEach } from 'lodash'

/**
 * @module store/common/actions
 */

/**
 * Store actions for common module.
 * @module common/actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.COMMON_RESET_STATE)
  },

  /**
   * Update window sizes.
   * @param {Object} - Context.
   * @param {Object} - Contains height and width properties.
   */
  windowResized({ commit }, payload) {
    commit(mutations.COMMON_WINDOW_RESIZED, payload)
  },

  /**
   * Display a toast type of sweet alert.
   * @param {Object} - Context.
   * @param {object} alertData The config for sweet alert.
   * @param {string} [alertData.position=top-end] Toast position. Options: https://sweetalert2.github.io/#position
   * @param {string} [alertData.icon=success] Toast icon. Options: https://sweetalert2.github.io/#icons
   * @param {string} [alertData.title=Success!] Toast title content.
   * @param {string} [alertData.subtitle] Toast custom message (can be HTML) displayed under title.
   */
  /* eslint-disable no-empty-pattern */
  displayToastAlert({}, alertData) {
    if (SweetAlert.isVisible()) {
      SweetAlert.close()
    }
    SweetAlert.fire({
      toast: true,
      position: get(alertData, 'position', 'top-end'),
      showConfirmButton: false,
      showCloseButton: true,
      icon: get(alertData, 'icon', 'success'),
      title: get(alertData, 'title', 'Success!'),
      html: get(alertData, 'subtitle', ''),
      customClass: {
        popup: 'vms-alert__toast',
      },
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
      },
    })
  },

  /**
   * Update default bugsnag metadata.
   * @param {object} errorData - The error data object
   * @param {string} errorData.title - The error title
   * @param {object} [errorData.payload] - Optional. The payload to be added in bugsnag metadata.
   * @param {string} [errorData.severity] - Optional. The bugsnag severity.
   * @param {object} errorData.error -  The axios error response
   */
  /* eslint-disable no-empty-pattern */
  bugsnagNotify({}, errorData) {
    Bugsnag.notify(new Error(errorData.title), (event) => {
      if (errorData.payload) {
        event.addMetadata('payload', errorData.payload)
      }

      event.addMetadata('error', {
        status: errorData.error.status,
        statusText: errorData.error.statusText,
        message: errorData.error.data.message,
      })

      event.addMetadata('request', {
        url: errorData.error.config.url,
        method: errorData.error.config.method,
        data: errorData.error.config.data,
        headers: errorData.error.config.headers,
      })

      if (errorData.severity) {
        event.severity = errorData.severity
      }
    })
  },

  /**
   * Display a modal for error message
   *
   * @param {Object} - Context
   * @param {Object} errorData - Error object
   * @param {Object} error.data - Object that contains array of errors
   * @param {String} error.message - Error message when error data is not available
   * @param {String} error.title - Modal title
   */
  displayErrorMessage({}, errorData) {
    // deconstruct errorData
    const data = get(errorData, 'data', {})
    const title = capitalize(
      get(errorData, 'title', "Oops, let's get you rolling again")
    )
    const message = get(
      errorData,
      'message',
      'If this continues, please report it to the tech team.'
    )

    let content = ''

    // get error message or error data
    if (isEmpty(data)) {
      // display error message if there's no error data
      content += `<div>${message}</div>`
    } else {
      // combine all error data
      forOwn(data, function (item) {
        forEach(item, function (message) {
          content += `<div>${message}</div>`
        })
      })
    }

    // display the error modal
    SweetAlert.fire({
      confirmButtonText: 'Continue',
      imageUrl: '/images/icons/error-roll.svg',
      imageWidth: '200px',
      title: `<div class="vd-h3 vd-margin-bottom-10"><strong>${title}</strong></div>`,
      html: `<div class="vd-padding-20 vd-background-light-red vd-border-radius-10 small">${content}</div>`,
      heightAuto: false,
      scrollbarPadding: false,
    })
  },

  /**
   * Toggle the side menu open state.
   * @param {Object} - Context.
   */
  toggleSideMenu({ commit }) {
    commit(mutations.COMMON_SIDE_MENU_TOGGLE)
  },

  /**
   * Set the selected menu
   * @param {Object} - Context.
   * @param {Object} selectedMenu - The selected menu object.
   */
  setSelectedSideMenu({ commit }, selectedMenu) {
    commit(mutations.COMMON_SIDE_MENU_SELECTED, selectedMenu)
  },
}
