import actions from '@store/videos/videos-actions'
import getters from '@store/videos/videos-getters'
import mutations from '@store/videos/videos-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/videos/videos-module
 */

/**
 * Get default state for videos module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the delivery module.
   * @property  {boolean} loading.videoTags - State for the video tags loader.
   * @property  {boolean} loading.videoStyles - State for the video styles loader.
   * @property  {boolean} loading.videoIndustries - State for the video industries loader.
   * @property  {boolean} loading.videoInformation - State for the video information loader.
   * @property  {object} selectedVideo - Object of selected video.
   * @property  {object}  modal - Object of modal options.
   * @property  {object}  modal.videoInformation - Object of video information modal options.
   * @property  {boolean}  modal.videoInformation.show - State of additional options modal if shown.
   * @property  {object}  modal.videoInformation.options - Object of video information modal dropdown options.
   * @property  {array}  modal.videoInformation.options.tags - Array of video information modal dropdown options.
   * @property  {array}  modal.videoInformation.options.industry - Array of video information modal dropdown options.
   * @property  {array}  modal.videoInformation.options.style - Array of video information modal dropdown options.
   * @property  {number}  modal.videoInformation.selectedVideoId - Video id of modal to display.
   * @property  {boolean} modal.previewDescription - State of preview description modal
   * @property  {object}  modal.filterList - Object of filters list modal options
   * @property  {boolean} modal.filterList.show - State of the filter list modal
   * @property  {string}  modal.filterList.selectedFilterModal - The selected filter modal type
   * @property  {Object}  videoLibraryFilters - Object of video library filters
   * @property  {boolean}  videoLibraryFilters.show - display state of video library filter
   */

  return {
    loading: {
      videoTags: false,
      videoStyles: false,
      videoIndustries: false,
      videoInformation: false,
    },
    modal: {
      videoInformation: {
        show: false,
        options: {
          tags: [],
          industry: [],
          styles: [],
        },
        selectedVideo: {},
        selectedVideoId: null,
      },
      previewDescription: false,
      filterList: {
        show: false,
        selectedFilterModal: '',
      },
    },
    videoLibraryFilters: {
      show: false,
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.VIDEOS_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
