import actions from '@store/project/production-note/production-note-actions'
import getters from '@store/project/production-note/production-note-getters'
import mutations from '@store/project/production-note/production-note-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/production-note/production-note-module
 */

/**
 * Get default state for production-notes module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {array}   productionNotes - Array of objects of project production notes.
   */
  return {
    productionNotes: [],
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_PRODUCTION_NOTE_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
