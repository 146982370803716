import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import ApiCustomIncludes from '@configs/api-custom-includes'
import SweetAlert from '@plugins/sweet-alert'
import router from '@router/router'

/**
 * Store actions for shoot module.
 * @module project/shoot/project-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_SHOOT_RESET_STATE)
  },

  /**
   * Action to set the project filming details loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setFilmingDetailsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PROJECT_FILMING_DETAILS, loading)
  },

  /**
   * Update loader for available extras for filming details
   *
   * @param commit
   * @param {boolean} loading - The loading state
   */
  updateAvailableExtrasLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_AVAILABLE_EXTRAS, loading)
  },

  /**
   * Action to set the project filming details list
   * @param commit
   * @param {array} shoots - the filming details data
   */
  setProjectFilmingDetailsList({ commit }, shoots) {
    commit(mutations.PROJECT_FILMING_DETAILS_LIST, shoots)
  },

  /**
   * Action to get the project filming details
   *
   * @param commit
   * @param getters
   */
  getProjectFilmingDetailsList({ dispatch, commit, getters }, payload) {
    dispatch('setFilmingDetailsLoading', true)

    if (!payload.perPage) {
      payload.perPage = getters['filmingDetails'].meta.per_page
    }

    ProjectServices.projects.jobs.shoots
      .list(payload)
      .then((response) => {
        dispatch('setFilmingDetailsLoading', false)

        commit(mutations.PROJECT_FILMING_DETAILS_LIST, response.data)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('setFilmingDetailsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving filming details list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Update the selected filming details
   *
   * @param commit
   * @param {object} filmingDetails - The selected filming details object
   */
  updateSelectedFilmingDetails({ commit }, filmingDetails) {
    commit(mutations.PROJECT_FILMING_DETAILS_SELECTED, filmingDetails)
  },

  /**
   * Update filming detail with payload data
   *
   * @param dispatch
   * @param commit
   * @param {object} payload - Payload consisting of project_id, order_job_id and payload data
   */
  updateFilmingDetails({ dispatch, commit, getters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const shootJobPayload = payload.payload
    const perPage = payload.perPage || getters['filmingDetails'].meta.per_page

    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })

    ProjectServices.projects.jobs.shoots
      .update(projectId, orderJobId, includes, shootJobPayload, perPage)
      .then((response) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showEditFilmingDetailsForm', false)
        dispatch('updateSelectedFilmingDetails', null)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} updated!`,
          },
          { root: true }
        )

        commit(mutations.PROJECT_UPDATE_FILMING_DETAILS, response.data.data)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showEditFilmingDetailsForm', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating the filming detail job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
              order_job_id: orderJobId,
              include: includes,
              shoot_job_payload: shootJobPayload,
            },
          },
          { root: true }
        )
      })
  },

  saveNewFilmingDetails({ dispatch, rootGetters }, payload) {
    const projectId = payload.project_id
    const includes = payload.includes
    const shootJobPayload = payload.payload

    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })

    ProjectServices.projects.jobs.shoots
      .create(projectId, includes, shootJobPayload)
      .then(() => {
        dispatch('getProjectFilmingDetailsList', {
          projectId: projectId,
          page: 1,
          include: includes,
        })
        dispatch('showAddNewFilmingDetailsForm', false)
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} created!`,
          },
          { root: true }
        )

        if (
          rootGetters['project/isProjectArchived'] ||
          rootGetters['project/isProjectApproved']
        ) {
          dispatch(
            'project/getProjectDetails',
            {
              projectId: projectId,
              customIncludes: ApiCustomIncludes.projectDetails,
            },
            { root: true }
          )
        }

        // Set the current page to page 1
        if (
          router.currentRoute.query.page &&
          parseInt(router.currentRoute.query.page) !== 1
        ) {
          router.replace({
            query: {
              page: 1,
            },
          })
        }
      })
      .catch((error) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showAddNewFilmingDetailsForm', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error saving new filming detail.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
              include: includes,
              shoot_job_payload: shootJobPayload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the add new filming details form state
   *
   * @param commit
   * @param {boolean} show - The state of the add filming details form
   */
  showAddNewFilmingDetailsForm({ commit }, show) {
    commit(mutations.PROJECT_FILMING_DETAILS_ADD_NEW_FORM_OPEN, show)
  },

  /**
   * Update the edit filming details form state
   *
   * @param commit
   * @param {boolean} show - The state of the edit filming details form
   */
  showEditFilmingDetailsForm({ commit }, show) {
    commit(mutations.PROJECT_FILMING_DETAILS_EDIT_FORM_OPEN, show)
  },

  /**
   * Update the filming details extras list
   *
   * @param commit
   * @param {array} extras - The array of extras available for filming details
   */
  updateFilmingDetailsExtras({ commit }, extras) {
    commit(mutations.PROJECT_FILMING_DETAILS_EXTRAS, extras)
  },

  /**
   * List all available extras
   *
   * @param dispatch
   * @param {object} payload - The payload for listing all the available extras
   */
  getShootProductElements({ dispatch }, payload) {
    dispatch('updateAvailableExtrasLoading', true)

    ProjectServices.products.list
      .shootProductElements(payload.project_id, payload.includes)
      .then((response) => {
        dispatch('updateFilmingDetailsExtras', response.data.data)

        dispatch('updateAvailableExtrasLoading', false)
      })
      .catch((error) => {
        dispatch('updateAvailableExtrasLoading', false)
        // close new filming details form
        dispatch('showAddNewFilmingDetailsForm', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving shoot product elements.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Delete the selected project shoot job
   *
   * @param dispatch
   * @param {object} payload - payload for processing the deleteProjectShootJob service
   */
  deleteFilmingDetails({ dispatch, getters }, payload) {
    const projectId = payload.project_id
    const shootJobId = payload.shoot_job_id
    const includes = payload.includes

    SweetAlert.fire({
      title: 'Warning!',
      html: `<br> Are you sure you want to delete <br><br> <strong>${payload.name}</strong>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ff3333',
      heightAuto: false,
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let page = 1
        const currentPageQuery = parseInt(router.currentRoute.query.page)
        const currentFilmingDetails = getters.filmingDetails

        // Only update page route if there is "page" query on url and if it is not on page 1
        if (router.currentRoute.query && currentPageQuery > 1) {
          page =
            currentFilmingDetails.data.length === 1
              ? currentPageQuery - 1
              : currentPageQuery
        }

        ProjectServices.projects.jobs.shoots
          .delete(projectId, shootJobId, includes)
          .then(() => {
            dispatch(
              'common/displayToastAlert',
              {
                title: `${payload.name} deleted!`,
              },
              { root: true }
            )

            dispatch('showEditFilmingDetailsForm', false)
            dispatch('updateSelectedFilmingDetails', null)

            if (router.currentRoute.query.page && currentPageQuery !== page) {
              router.replace({
                query: {
                  page: page,
                },
              })
            }

            dispatch('getProjectFilmingDetailsList', {
              projectId: projectId,
              page: page,
              include: ApiCustomIncludes.filmingDetailsList,
            })
          })
          .catch((error) => {
            dispatch('showEditFilmingDetailsForm', false)
            dispatch('common/displayErrorMessage', error.response?.data, {
              root: true,
            })
            dispatch(
              'common/bugsnagNotify',
              {
                title: 'Error deleting the Shoot job.',
                severity: 'error',
                error: error.response ? error.response : error.message,
                payload: {
                  project_id: payload.project_id,
                  shoot_job_id: payload.shoot_job_id,
                  include: payload.includes,
                },
              },
              { root: true }
            )
          })
      }
    })
  },

  /**
   * Link the pre production to a shoot job
   *
   * @param {Object} context
   * @param {Object} params - params for unlink.
   * @param {number} params.project_id - project ID
   * @param {string} params.name - name of the project
   * @param {number} params.shoot_job_id - shoot job ID
   * @param {array} params.includes - array of includes
   * @param {array} params.pre_production_ids - array of pre-production job id
   */
  linkPreProduction({ dispatch, commit }, params) {
    const projectId = params.project_id
    const shootJobId = params.shoot_job_id
    const includes = params.includes ?? []
    const jobName = params.name
    const payload = {
      pre_production_ids: params.pre_production_ids,
    }

    ProjectServices.projects.jobs.shoots
      .linkPreProduction(projectId, shootJobId, includes, payload)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_FILMING_DETAILS, response.data.data)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${jobName} updated!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error linking pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: params,
          },
          { root: true }
        )
      })
  },

  /**
   * Unlink the pre production from a shoot job
   * @param {Object} context
   * @param {Object} payload - payload for unlink.
   * @param {number} payload.project_id - project ID
   * @param {string} payload.name - name of the project
   * @param {number} payload.order_job_id - order job ID
   * @param {array} payload.includes - array of includes
   * @param {object} payload.payload - payload object inside payload
   * @param {array} payload.payload.pre_production_ids - array of pre-production job id
   */
  unlinkPreProduction({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const shootJobPayload = payload.payload

    ProjectServices.projects.jobs.shoots
      .unlinkPreProd(projectId, orderJobId, includes, shootJobPayload)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_FILMING_DETAILS, response.data.data)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} updated!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error removing the linked pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
              order_job_id: orderJobId,
              include: includes,
              shoot_job_payload: shootJobPayload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Put the shoot job on hold
   *
   * @param {Object} context
   * @param {Object} payload - payload for shoot job.
   * @param {number} payload.project_id - project ID
   * @param {Object} payload.order_job - order job
   * @param {array} payload.includes - array of includes
   */
  holdShootJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJob = payload.order_job
    const includes = payload.includes

    commit(mutations.PROJECT_SHOOT_JOB_HOLD_LOADING, true)
    dispatch('updateSelectedFilmingDetails', orderJob)

    ProjectServices.projects.jobs.shoots
      .hold(projectId, orderJob.order_job_id, includes)
      .then((response) => {
        commit(mutations.PROJECT_UPDATE_FILMING_DETAILS, response.data.data)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${orderJob.name} updated!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error in holding shoot job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
              order_job_id: orderJob.order_job_id,
              include: includes,
            },
          },
          { root: true }
        )
      })
      .finally(() => {
        commit(mutations.PROJECT_SHOOT_JOB_HOLD_LOADING, false)
        dispatch('updateSelectedFilmingDetails', null)
      })
  },

  /**
   * Get the call sheet data
   *
   * @param {Object} context
   * @param {Object} payload - payload for call sheet.
   * @param {Object} payload.projectId - project id.
   * @param {Object} payload.orderJobId - order job id.
   */
  getCallSheet({ dispatch, commit }, payload) {
    commit(mutations.PROJECT_CALL_SHEET_LOADING, true)
    commit(mutations.PROJECT_CALL_SHEET_DATA, {})

    ProjectServices.projects.jobs.shoots
      .getCallSheet(payload)
      .then((response) => {
        commit(mutations.PROJECT_CALL_SHEET_DATA, response.data.data)
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to get call sheet.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              order_job_id: payload.orderJobId,
            },
          },
          { root: true }
        )
        dispatch('updateCallSheetFormOpen', false)
      })
      .finally(() => {
        commit(mutations.PROJECT_CALL_SHEET_LOADING, false)
      })
  },

  /**
   * Update the call sheet data
   *
   * @param {Object} context
   * @param {Object} payload - state to open or close the call sheet form.
   * @param {Object} payload.projectId - project id
   * @param {Object} payload.orderJobId - order job id
   * @param {Object} payload.orderJobName - order job name
   * @param {Object} payload.includes - array of includes
   */
  updateCallSheet({ dispatch, commit }, payload) {
    commit(mutations.PROJECT_CALL_SHEET_LOADING, true)
    const projectId = payload.projectId
    const orderJobId = payload.orderJobId
    const orderJobName = payload.orderJobName
    const includes = payload.includes

    ProjectServices.projects.jobs.shoots
      .updateCallSheet(projectId, orderJobId, includes)
      .then(() => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${orderJobName} call sheet updated!`,
          },
          { root: true }
        )
        dispatch('updateCallSheetFormOpen', false)
        commit(mutations.PROJECT_CALL_SHEET_DATA, {})
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to update call sheet.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
              order_job_id: orderJobId,
              include: includes,
            },
          },
          { root: true }
        )
        dispatch('updateCallSheetFormOpen', false)
      })
      .finally(() => {
        commit(mutations.PROJECT_CALL_SHEET_LOADING, false)
      })
  },

  /**
   * Close or open the call sheet form
   *
   * @param {Object} context
   * @param {Object} payload - state to open or close the call sheet form.
   */
  updateCallSheetFormOpen({ commit }, payload) {
    commit(mutations.PROJECT_CALL_SHEET_FORM_OPEN, payload)
  },

  /**
   * Toggle manual call sheet modal open state.
   *
   * @param {Object} context
   */
  toggleManualCallSheetModal({ commit, getters }) {
    commit(
      mutations.PROJECT_MANUAL_CALL_SHEET_MODAL_TOGGLE_SHOW,
      !getters.manualCallSheetModalOpen
    )
  },

  /**
   * Update manual call sheet
   *
   * @param {Object} context
   * @param {Object} payload.
   * @param {Number} payload.projectId
   * @param {Number} payload.shootJobId
   * @param {String} payload.url
   * @param {Array} payload.include
   */
  updateManualCallSheet({ commit, dispatch }, payload) {
    commit(mutations.PROJECT_LOADING_MANUAL_CALL_SHEET, true)

    ProjectServices.projects.jobs.shoots
      .updateManualCallSheet(payload)
      .then((response) => {
        const filmingDetails = response.data.data
        dispatch(
          'common/displayToastAlert',
          {
            title: `${filmingDetails.name} call sheet updated!`,
          },
          { root: true }
        )
        commit(mutations.PROJECT_UPDATE_FILMING_DETAILS, filmingDetails)
        dispatch('toggleManualCallSheetModal')
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error when trying to update manual call sheet.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload,
          },
          { root: true }
        )
      })
      .finally(() => {
        commit(mutations.PROJECT_LOADING_MANUAL_CALL_SHEET, false)
      })
  },
}
