/**
 * @module store/mutation-types
 */

/**
 * Common Module
 */
export const COMMON_RESET_STATE = 'COMMON_RESET_STATE'
export const COMMON_WINDOW_RESIZED = 'COMMON_WINDOW_RESIZED'
export const COMMON_PAGINATION_PER_PAGE = 'COMMON_PAGINATION_PER_PAGE'
export const COMMON_PAGINATION_SELECTED_PAGE = 'COMMON_PAGINATION_SELECTED_PAGE'
export const COMMON_SIDE_MENU_TOGGLE = 'COMMON_SIDE_MENU_TOGGLE'
export const COMMON_SIDE_MENU_SELECTED = 'COMMON_SIDE_MENU_SELECTED'

/**
 * User Module
 */
export const USER_RESET_STATE = 'USER_RESET_STATE'
export const USER_LOADING_PRODUCERS_LIST = 'USER_LOADING_PRODUCERS_LIST'
export const USER_LOADING_ACCOUNT_MANAGERS_LIST =
  'USER_LOADING_ACCOUNT_MANAGERS_LIST'
export const USER_LOADING_PRE_PRODUCTION_USERS =
  'USER_LOADING_PRE_PRODUCTION_USERS'
export const USER_PRODUCERS_LIST = 'USER_PRODUCERS_LIST'
export const USER_ACCOUNT_MANAGERS_LIST = 'USER_ACCOUNT_MANAGERS_LIST'
export const USER_PROJECT_OWNERS_LIST = 'USER_PROJECT_OWNERS_LIST'
export const USER_LOADING_USER_DETAILS = 'USER_LOADING_USER_DETAILS'
export const USER_LOADING_CONTACT = 'USER_LOADING_CONTACT'
export const USER_DETAILS = 'USER_DETAILS'
export const USER_SELECTED_USER_ID = 'USER_SELECTED_USER_ID'
export const USER_MODAL_CONTACT_FORM_SHOW = 'USER_MODAL_CONTACT_FORM_SHOW'
export const USER_PRE_PRODUCTION_USERS = 'USER_PRE_PRODUCTION_USERS'
export const USER_TEAMS = 'USER_TEAMS'
export const USER_LOADING_TEAMS = 'USER_LOADING_TEAMS'
export const USER_LOADING_INTERNAL_USERS = 'USER_LOADING_INTERNAL_USERS'
export const USER_INTERNAL_USERS = 'USER_INTERNAL_USERS'
export const USER_LOADING_GOOGLE_AUTHENTICATION =
  'USER_LOADING_GOOGLE_AUTHENTICATION'

/**
 * Project Module
 */
export const PROJECT_RESET_STATE = 'PROJECT_RESET_STATE'
export const PROJECT_PRE_PRODUCTION_RESET_STATE =
  'PROJECT_PRE_PRODUCTION_RESET_STATE'
export const PROJECT_SHOOT_RESET_STATE = 'PROJECT_SHOOT_RESET_STATE'
export const PROJECT_ATTACHMENT_RESET_STATE = 'PROJECT_ATTACHMENT_RESET_STATE'
export const PROJECT_DELIVERY_RESET_STATE = 'PROJECT_DELIVERY_RESET_STATE'
export const PROJECT_PRODUCTION_NOTE_RESET_STATE =
  'PROJECT_PRODUCTION_NOTE_RESET_STATE'
export const PROJECT_ACTIVITY_LOG_RESET_STATE =
  'PROJECT_ACTIVITY_LOG_RESET_STATE'
export const PROJECT_INVOICE_RESET_STATE = 'PROJECT_INVOICE_RESET_STATE'
export const PROJECT_CONTACT_RESET_STATE = 'PROJECT_CONTACT_RESET_STATE'
export const PROJECT_LOADING_PROJECT_DETAILS = 'PROJECT_LOADING_PROJECT_DETAILS'
export const PROJECT_LOADING_CREATE_NEW_PROJECT =
  'PROJECT_LOADING_CREATE_NEW_PROJECT'
export const PROJECT_LOADING_PRODUCT_TYPES = 'PROJECT_LOADING_PRODUCT_TYPES'
export const PROJECT_LOADING_EDIT_PROJECT = 'PROJECT_LOADING_EDIT_PROJECT'
export const PROJECT_LOADING_CONFIRM_CONTACT = 'PROJECT_LOADING_CONFIRM_CONTACT'
export const PROJECT_LOADING_PROJECT_FILMING_DETAILS =
  'PROJECT_LOADING_PROJECT_FILMING_DETAILS'
export const PROJECT_LOADING_AVAILABLE_EXTRAS =
  'PROJECT_LOADING_AVAILABLE_EXTRAS'
export const PROJECT_LOADING_PRE_PRODUCTIONS = 'PROJECT_LOADING_PRE_PRODUCTIONS'
export const PROJECT_LOADING_PROJECT_FILES = 'PROJECT_LOADING_PROJECT_FILES'
export const PROJECT_LOADING_BRAND_HUB = 'PROJECT_LOADING_BRAND_HUB'
export const PROJECT_LOADING_PROJECT_CONTACTS =
  'PROJECT_LOADING_PROJECT_CONTACTS'
export const PROJECT_DETAILS_LIST = 'PROJECT_DETAILS_LIST'
export const PROJECT_PRODUCTION_NOTES_LIST = 'PROJECT_PRODUCTION_NOTES_LIST'
export const PROJECT_PRE_PRODUCTIONS_LIST = 'PROJECT_PRE_PRODUCTIONS_LIST'
export const PROJECT_FILMING_DETAILS_LIST = 'PROJECT_FILMING_DETAILS_LIST'
export const PROJECT_UPDATE_FILMING_DETAILS = 'PROJECT_UPDATE_FILMING_DETAILS'
export const PROJECT_DELIVERIES_LIST = 'PROJECT_DELIVERIES_LIST'
export const PROJECT_FILES_LIST = 'PROJECT_FILES_LIST'
export const PROJECT_BRAND_HUB_LIST = 'PROJECT_BRAND_HUB_LIST'
export const PROJECT_INVOICES_LIST = 'PROJECT_INVOICES_LIST'
export const PROJECT_FORM_SAVE_BTN_DISABLED = 'PROJECT_FORM_SAVE_BTN_DISABLED'
export const PROJECT_FORM_SAVE_BTN_LOADING = 'PROJECT_FORM_SAVE_BTN_LOADING'
export const PROJECT_FILMING_DETAILS_EXTRAS = 'PROJECT_FILMING_DETAILS_EXTRAS'
export const PROJECT_FILMING_DETAILS_SELECTED =
  'PROJECT_FILMING_DETAILS_SELECTED'
export const PROJECT_FILMING_DETAILS_ADD_NEW_FORM_OPEN =
  'PROJECT_FILMING_DETAILS_ADD_NEW_FORM_OPEN'
export const PROJECT_FILMING_DETAILS_EDIT_FORM_OPEN =
  'PROJECT_FILMING_DETAILS_EDIT_FORM_OPEN'
export const PROJECT_MODAL_ADD_PO_NUMBER_TOGGLE_SHOW =
  'PROJECT_MODAL_ADD_PO_NUMBER_TOGGLE_SHOW'
export const PROJECT_MODAL_ADD_PO_NUMBER_ORDER_ID =
  'PROJECT_MODAL_ADD_PO_NUMBER_ORDER_ID'
export const PROJECT_LOADING_ADD_PO_NUMBER_MODAL =
  'PROJECT_LOADING_ADD_PO_NUMBER_MODAL'
export const PROJECT_INVOICE_UPDATE_PO_NUMBER =
  'PROJECT_INVOICE_UPDATE_PO_NUMBER'
export const PROJECT_MODAL_ADD_PRODUCTION_COST_TOGGLE_SHOW =
  'PROJECT_MODAL_ADD_PRODUCTION_COST_TOGGLE_SHOW'
export const PROJECT_LOADING_ADD_PRODUCTION_COST =
  'PROJECT_LOADING_ADD_PRODUCTION_COST'
export const PROJECT_FORM_PRE_PRODUCTIONS_TYPES =
  'PROJECT_FORM_PRE_PRODUCTIONS_TYPES'
export const PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_TYPE =
  'PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_TYPE'
export const PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_PRE_PRODUCTION_ID =
  'PROJECT_FORM_PRE_PRODUCTIONS_SELECTED_PRE_PRODUCTION_ID'
export const PROJECT_FORM_PRE_PRODUCTIONS_ADD_NEW_FORM_OPEN =
  'PROJECT_FORM_PRE_PRODUCTIONS_ADD_NEW_FORM_OPEN'
export const PROJECT_FORM_PRE_PRODUCTIONS_EDIT_FORM_OPEN =
  'PROJECT_FORM_PRE_PRODUCTIONS_EDIT_FORM_OPEN'
export const PROJECT_FORM_PRE_PRODUCTIONS_LINKING_JOB =
  'PROJECT_FORM_PRE_PRODUCTIONS_LINKING_JOB'
export const PROJECT_MODAL_ADD_DISBURSEMENT_COST_TOGGLE_SHOW =
  'PROJECT_MODAL_ADD_DISBURSEMENT_COST_TOGGLE_SHOW'
export const PROJECT_LOADING_ADD_DISBURSEMENT_COST =
  'PROJECT_LOADING_ADD_DISBURSEMENT_COST'
export const PROJECT_MODAL_ADD_DISBURSEMENT_COST_PRODUCT_ELEMENTS =
  'PROJECT_MODAL_ADD_DISBURSEMENT_COST_PRODUCT_ELEMENTS'
export const PROJECT_FORM_PRE_PRODUCTIONS_ACTIVE_TAB_INDEX =
  'PROJECT_FORM_PRE_PRODUCTIONS_ACTIVE_TAB_INDEX'
export const PROJECT_UPDATE_PRE_PRODUCTION_ITEM =
  'PROJECT_UPDATE_PRE_PRODUCTION_ITEM'
export const PROJECT_LOADING_PRE_PRODUCTION_ITEM =
  'PROJECT_LOADING_PRE_PRODUCTION_ITEM'
export const PROJECT_PRE_PRODUCTION_MODAL_FILE_UPLOAD_TOGGLE_SHOW =
  'PROJECT_PRE_PRODUCTION_MODAL_FILE_UPLOAD_TOGGLE_SHOW'
export const PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS =
  'PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS'
export const PROJECT_PRE_PRODUCTION_MODAL_FILE_VERSIONS_TOGGLE_SHOW =
  'PROJECT_PRE_PRODUCTION_MODAL_FILE_VERSIONS_TOGGLE_SHOW'
export const PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS_LOADING =
  'PROJECT_UPDATE_PRE_PRODUCTION_FILE_VERSIONS_LOADING'
export const PROJECT_INVOICES_OVERVIEW = 'PROJECT_INVOICES_OVERVIEW'
export const PROJECT_INVOICES_EDIT_JOB_MODAL_TOGGLE =
  'PROJECT_INVOICES_EDIT_JOB_MODAL_TOGGLE'
export const PROJECT_INVOICES_EDIT_JOB_LOADING =
  'PROJECT_INVOICES_EDIT_JOB_LOADING'
export const PROJECT_LOADING_INVOICE_OVERVIEW =
  'PROJECT_LOADING_INVOICE_OVERVIEW'
export const PROJECT_INVOICES_SELECTED_JOB_ID =
  'PROJECT_INVOICES_SELECTED_JOB_ID'
export const PROJECT_NEW_UPDATE_DETAILS_FROM_ALGOLIA =
  'PROJECT_NEW_UPDATE_DETAILS_FROM_ALGOLIA'
export const PROJECT_LOADING_DELIVERIES_LIST = 'PROJECT_LOADING_DELIVERIES_LIST'
export const PROJECT_LOADING_DELIVERIES_PRODUCT_ELEMENTS =
  'PROJECT_LOADING_DELIVERIES_PRODUCT_ELEMENTS'
export const PROJECT_LOADING_DELIVERIES_HOLD = 'PROJECT_LOADING_DELIVERIES_HOLD'
export const PROJECT_LOADING_DELIVERIES_VIDEO_PRODUCTS =
  'PROJECT_LOADING_DELIVERIES_VIDEO_PRODUCTS'
export const PROJECT_DELIVERIES_VIDEO_PRODUCTS =
  'PROJECT_DELIVERIES_VIDEO_PRODUCTS'
export const PROJECT_DELIVERIES_ADD_NEW_FORM_OPEN =
  'PROJECT_DELIVERIES_ADD_NEW_FORM_OPEN'
export const PROJECT_DELIVERIES_EDIT_FORM_OPEN =
  'PROJECT_DELIVERIES_EDIT_FORM_OPEN'
export const PROJECT_DELIVERIES_SELECTED_VIDEO_PRODUCT_ID =
  'PROJECT_DELIVERIES_SELECTED_VIDEO_PRODUCT_ID'
export const PROJECT_DELIVERIES_PRODUCT_ELEMENTS =
  'PROJECT_DELIVERIES_PRODUCT_ELEMENTS'
export const PROJECT_DELIVERIES_SELECTED_VIDEO_DELIVERY_JOB_ID =
  'PROJECT_DELIVERIES_SELECTED_VIDEO_DELIVERY_JOB_ID'
export const PROJECT_DELIVERIES_SELECT_ALL = 'PROJECT_DELIVERIES_SELECT_ALL'
export const PROJECT_DELIVERIES_SELECTED_DELIVERIES =
  'PROJECT_DELIVERIES_SELECTED_DELIVERIES'
export const PROJECT_DELIVERIES_BULK_ADD_VIDEOS_TOGGLE_SHOW =
  'PROJECT_DELIVERIES_BULK_ADD_VIDEOS_TOGGLE_SHOW'
export const PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_QUANTITY =
  'PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_QUANTITY'
export const PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_NAMES =
  'PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_NAMES'
export const PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_CONFIRM =
  'PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_CONFIRM'
export const PROJECT_DELIVERIES_VIDEO_PLAYER_TOGGLE_SHOW =
  'PROJECT_DELIVERIES_VIDEO_PLAYER_TOGGLE_SHOW'
export const PROJECT_DELIVERIES_MODAL_SELECTED_DELIVERY_ID =
  'PROJECT_DELIVERIES_MODAL_SELECTED_DELIVERY_ID'
export const PROJECT_DELIVERIES_PAGE_FILTERS = 'PROJECT_DELIVERIES_PAGE_FILTERS'
export const PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM =
  'PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM'
export const PROJECT_UPDATE_VIDEO_DELIVERY_JOB_STAR =
  'PROJECT_UPDATE_VIDEO_DELIVERY_JOB_STAR'
export const PROJECT_INVOICES_INVOICE_CONTACT =
  'PROJECT_INVOICES_INVOICE_CONTACT'
export const PROJECT_INVOICES_INVOICE_CONTACT_ADD =
  'PROJECT_INVOICES_INVOICE_CONTACT_ADD'
export const PROJECT_INVOICES_INVOICE_CONTACT_REMOVE =
  'PROJECT_INVOICES_INVOICE_CONTACT_REMOVE'
export const PROJECT_LOADING_INVOICE_DETAILS = 'PROJECT_LOADING_INVOICE_DETAILS'
export const PROJECT_INVOICES_INVOICE_DETAIL_CHANGED =
  'PROJECT_INVOICES_INVOICE_DETAIL_CHANGED'
export const PROJECT_DETAILS_TOTAL_PRICE = 'PROJECT_DETAILS_TOTAL_PRICE'
export const PROJECT_DETAILS_TOTAL_DELIVERIES =
  'PROJECT_DETAILS_TOTAL_DELIVERIES'
export const PROJECT_DETAILS_TOTAL_HERO_VIDEOS =
  'PROJECT_DETAILS_TOTAL_HERO_VIDEOS'
export const PROJECT_FORM_NEW_PROJECT_PAYLOAD_UPDATE =
  'PROJECT_FORM_NEW_PROJECT_PAYLOAD_UPDATE'
export const PROJECT_PRODUCT_TYPES_LIST = 'PROJECT_PRODUCT_TYPES_LIST'
export const PROJECT_LOADING_CREDIT_LIST = 'PROJECT_LOADING_CREDIT_LIST'
export const PROJECT_LOADING_INVOICE_LIST = 'PROJECT_LOADING_INVOICE_LIST'
export const PROJECT_LOADING_LINK_INVOICE = 'PROJECT_LOADING_LINK_INVOICE'
export const PROJECT_INVOICES_CREDIT_LIST = 'PROJECT_INVOICES_CREDIT_LIST'
export const PROJECT_SUMMARY_IS_EDIT_MODE = 'PROJECT_SUMMARY_IS_EDIT_MODE'
export const PROJECT_SUMMARY_INFO_INVALID_UPDATE =
  'PROJECT_SUMMARY_INFO_INVALID_UPDATE'
export const PROJECT_SUMMARY_BRIEF_INVALID_UPDATE =
  'PROJECT_SUMMARY_BRIEF_INVALID_UPDATE'
export const PROJECT_SUMMARY_EDIT_PAYLOAD_INFO_UPDATE =
  'PROJECT_SUMMARY_EDIT_PAYLOAD_INFO_UPDATE'
export const PROJECT_CONTACTS_LIST = 'PROJECT_CONTACTS_LIST'
export const PROJECT_SUMMARY_SHOW_ADD_CONTACTS_LIST =
  'PROJECT_SUMMARY_SHOW_ADD_CONTACTS_LIST'
export const PROJECT_NEW_ALGOLIA_CONTACT_SELECTED =
  'PROJECT_NEW_ALGOLIA_CONTACT_SELECTED'
export const PROJECT_HEALTH_LIST = 'PROJECT_HEALTH_LIST'
export const PROJECT_HEALTH_RESET_STATE = 'PROJECT_HEALTH_RESET_STATE'
export const PROJECT_HEALTH_LOADING = 'PROJECT_HEALTH_LOADING'
export const PROJECT_HEALTH_MODAL_TOGGLE_SHOW =
  'PROJECT_HEALTH_MODAL_TOGGLE_SHOW'
export const PROJECT_ACTIVITY_LOGS_LIST = 'PROJECT_ACTIVITY_LOGS_LIST'
export const PROJECT_ACTIVITY_LOGS_LOADING = 'PROJECT_ACTIVITY_LOGS_LOADING'
export const PROJECT_HAS_ACTIVITY_LOGS = 'PROJECT_HAS_ACTIVITY_LOGS'
export const PROJECT_MODAL_LINK_INVOICE_TOGGLE_SHOW =
  'PROJECT_MODAL_LINK_INVOICE_TOGGLE_SHOW'
export const PROJECT_MODAL_LINK_INVOICE_ORDER_ID =
  'PROJECT_MODAL_LINK_INVOICE_ORDER_ID'
export const IS_PROJECT_MENU_SCROLLABLE = 'IS_PROJECT_MENU_SCROLLABLE'
export const PROJECT_SHOOT_JOB_HOLD_LOADING = 'PROJECT_SHOOT_JOB_HOLD_LOADING'
export const PROJECT_CALL_SHEET_LOADING = 'PROJECT_CALL_SHEET_LOADING'
export const PROJECT_CALL_SHEET_DATA = 'PROJECT_CALL_SHEET_DATA'
export const PROJECT_CALL_SHEET_FORM_OPEN = 'PROJECT_CALL_SHEET_FORM_OPEN'
export const PROJECT_MANUAL_CALL_SHEET_MODAL_TOGGLE_SHOW =
  'PROJECT_MANUAL_CALL_SHEET_MODAL_TOGGLE_SHOW'
export const PROJECT_LOADING_MANUAL_CALL_SHEET =
  'PROJECT_LOADING_MANUAL_CALL_SHEET'
export const PROJECT_LOADING_INVOICE_BUDGET = 'PROJECT_LOADING_INVOICE_BUDGET'
export const PROJECT_INVOICE_BUDGET = 'PROJECT_INVOICE_BUDGET'
export const PROJECT_ARCHIVE_MODAL_SHOW = 'PROJECT_ARCHIVE_MODAL_SHOW'
export const PROJECT_EOD_NOTES_MODAL_SHOW = 'PROJECT_EOD_NOTES_MODAL_SHOW'
export const PROJECT_FOOTAGE_STATUS_LOADING = 'PROJECT_FOOTAGE_STATUS_LOADING'
export const PROJECT_HUBSPOT_QUOTES_LOADING = 'PROJECT_HUBSPOT_QUOTES_LOADING'
export const PROJECT_HUBSPOT_QUOTES = 'PROJECT_HUBSPOT_QUOTES'
export const PROJECT_PRE_PRODUCTION_CHANGES_IS_LIST_OPEN =
  'PROJECT_PRE_PRODUCTION_CHANGES_IS_LIST_OPEN'
export const PROJECT_PRE_PRODUCTION_CHANGES_IS_FORM_OPEN =
  'PROJECT_PRE_PRODUCTION_CHANGES_IS_FORM_OPEN'
export const PROJECT_LOADING_PRE_PRODUCTION_CHANGES =
  'PROJECT_LOADING_PRE_PRODUCTION_CHANGES'
export const PROJECT_PRE_PRODUCTION_CHANGES_LIST =
  'PROJECT_PRE_PRODUCTION_CHANGES_LIST'
export const PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_ID =
  'PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_ID'
export const PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_CHANGE =
  'PROJECT_PRE_PRODUCTION_CHANGES_SELECTED_PRE_PRODUCTION_CHANGE'
export const PROJECT_LOADING_PRE_PRODUCTION_CHANGE_ATTACHMENTS =
  'PROJECT_LOADING_PRE_PRODUCTION_CHANGE_ATTACHMENTS'
export const PROJECT_PRE_PRODUCTION_DETAILS = 'PROJECT_PRE_PRODUCTION_DETAILS'
export const PROJECT_PRE_PRODUCTION_DETAILS_LOADING =
  'PROJECT_PRE_PRODUCTION_DETAILS_LOADING'
export const EOD_NOTES_PROJECT_LOADING = 'EOD_NOTES_PROJECT_LOADING'
export const EOD_NOTES_JOB_LOADING = 'EOD_NOTES_JOB_LOADING'
export const EOD_NOTES_PINNED_LOADING = 'EOD_NOTES_PINNED_LOADING'
export const EOD_NOTES_JOB_LIST_LOADING = 'EOD_NOTES_JOB_LIST_LOADING'
export const EOD_NOTES_SAVE_BTN_LOADING = 'EOD_NOTES_SAVE_BTN_LOADING'
export const PROJECT_JOBS_LIST = 'PROJECT_JOBS_LIST'
export const PROJECT_EOD_NOTES = 'PROJECT_EOD_NOTES'
export const JOBS_EOD_NOTES = 'JOBS_EOD_NOTES'
export const PINNED_EOD_NOTES = 'PINNED_EOD_NOTES'
export const EOD_NOTES_SELECTED_TYPE_ID = 'EOD_NOTES_SELECTED_TYPE_ID'
export const EOD_NOTES_NEXT_PAGE_LINK = 'EOD_NOTES_NEXT_PAGE_LINK'
export const EOD_NOTES_SELECTED_PROJECT = 'EOD_NOTES_SELECTED_PROJECT'
export const PROJECT_SCHEDULE_RESET_STATE = 'PROJECT_SCHEDULE_RESET_STATE'
export const PROJECT_SCHEDULE_SHOW_OTHER_EVENTS =
  'PROJECT_SCHEDULE_SHOW_OTHER_EVENTS'
export const PROJECT_SCHEDULE_SELECTED_FILTERS =
  'PROJECT_SCHEDULE_SELECTED_FILTERS'
export const PROJECT_SCHEDULE_SELECTED_DATE = 'PROJECT_SCHEDULE_SELECTED_DATE'
export const PROJECT_SCHEDULE_USERS_LIST = 'PROJECT_SCHEDULE_USERS_LIST'
export const PROJECT_DETAILS_PROJECT_STATUS = 'PROJECT_DETAILS_PROJECT_STATUS'

/**
 * Auth Module
 */
export const AUTH_RESET_STATE = 'AUTH_RESET_STATE'
export const AUTH_UPDATE_INFO_FROM_FIREBASE = 'AUTH_UPDATE_INFO_FROM_FIREBASE'
export const AUTH_UPDATE_USER_FROM_VMS = 'AUTH_UPDATE_USER_FROM_VMS'
export const AUTH_FIREBASE_TOKEN_LOADING = 'AUTH_FIREBASE_TOKEN_LOADING'

/**
 * Projects Module
 */
export const PROJECTS_RESET_STATE = 'PROJECTS_RESET_STATE'
export const PROJECTS_LOADING_PROJECTS_LIST = 'PROJECTS_LOADING_PROJECTS_LIST'
export const PROJECTS_LOADING_PROJECT_HEALTH = 'PROJECTS_LOADING_PROJECT_HEALTH'
export const PROJECTS_SELECTED_ID = 'PROJECTS_SELECTED_ID'
export const PROJECTS_LIST = 'PROJECTS_LIST'
export const PROJECTS_UPDATE_PROJECT_HEALTH = 'PROJECTS_UPDATE_PROJECT_HEALTH'
export const PROJECTS_LIST_FILTER_STATUSES_UPDATE =
  'PROJECTS_LIST_FILTER_STATUSES_UPDATE'
export const PROJECTS_LIST_FILTER_PRODUCT_TYPE_UPDATE =
  'PROJECTS_LIST_FILTER_PRODUCT_TYPE_UPDATE'
export const PROJECTS_LIST_FILTER_USER_UPDATE =
  'PROJECTS_LIST_FILTER_USER_UPDATE'
export const PROJECTS_LIST_FILTER_MY_PROJECTS =
  'PROJECTS_LIST_FILTER_MY_PROJECTS'
export const PROJECTS_LIST_FILTER_SEARCH = 'PROJECTS_LIST_FILTER_SEARCH'
export const PROJECTS_LIST_FILTER_SORT = 'PROJECTS_LIST_FILTER_SORT'
export const PROJECTS_LIST_FILTER_SORT_BY = 'PROJECTS_LIST_FILTER_SORT_BY'
export const PROJECTS_LIST_FILTER_FROM_DATE = 'PROJECTS_LIST_FILTER_FROM_DATE'
export const PROJECTS_LIST_FILTER_TO_DATE = 'PROJECTS_LIST_FILTER_TO_DATE'
export const PROJECTS_LIST_FILTER_PER_PAGE = 'PROJECTS_LIST_FILTER_PER_PAGE'
export const PROJECTS_LIST_CURRENT_PAGE = 'PROJECTS_LIST_CURRENT_PAGE'
export const PROJECTS_UPDATE_SELECTED_PROJECT =
  'PROJECTS_UPDATE_SELECTED_PROJECT'
export const PROJECTS_UPDATE_SELECTED_FIELDS = 'PROJECTS_UPDATE_SELECTED_FIELDS'
export const PROJECTS_REMOVE_SELECTED_FIELDS = 'PROJECTS_REMOVE_SELECTED_FIELDS'
export const PROJECTS_ARCHIVED_PROJECTS = 'PROJECTS_ARCHIVED_PROJECTS'

/**
 * Videos Module
 */
export const VIDEOS_RESET_STATE = 'VIDEOS_RESET_STATE'
export const VIDEOS_LOADING_VIDEO_TAGS = 'VIDEOS_LOADING_VIDEO_TAGS'
export const VIDEOS_LOADING_VIDEO_STYLES = 'VIDEOS_LOADING_VIDEO_STYLES'
export const VIDEOS_LOADING_VIDEO_INDUSTRIES = 'VIDEOS_LOADING_VIDEO_INDUSTRIES'
export const VIDEOS_LOADING_VIDEO_INFORMATION =
  'VIDEOS_LOADING_VIDEO_INFORMATION'
export const VIDEOS_VIDEO_TAGS = 'VIDEOS_VIDEO_TAGS'
export const VIDEOS_VIDEO_INDUSTRY = 'VIDEOS_VIDEO_INDUSTRY'
export const VIDEOS_VIDEO_STYLE = 'VIDEOS_VIDEO_STYLE'
export const VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO_ID =
  'VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO_ID'
export const VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO =
  'VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO'
export const VIDEOS_VIDEO_INFORMATION_TOGGLE_SHOW =
  'VIDEOS_VIDEO_INFORMATION_TOGGLE_SHOW'
export const VIDEOS_MODAL_PREVIEW_DESCRIPTION_TOGGLE_SHOW =
  'VIDEOS_MODAL_PREVIEW_DESCRIPTION_TOGGLE_SHOW'
export const VIDEOS_MODAL_FILTER_LIST_TOGGLE_SHOW =
  'VIDEOS_MODAL_FILTER_LIST_TOGGLE_SHOW'
export const VIDEOS_VIDEO_LIBRARY_FILTERS_TOGGLE_SHOW =
  'VIDEOS_VIDEO_LIBRARY_FILTERS_TOGGLE_SHOW'

export const TARGET_AUDIENCE_TYPES_LOADING = 'TARGET_AUDIENCE_TYPES_LOADING'
export const TARGET_AUDIENCE_TYPES = 'TARGET_AUDIENCE_TYPES'

export const EDIT_PROJECT_SUMMARY_FORM_PAYLOAD =
  'EDIT_PROJECT_SUMMARY_FORM_PAYLOAD'

/**
 * Role Module
 */
export const ROLE_RESET_STATE = 'ROLE_RESET_STATE'
export const ROLE_LOADING_EXTERNAL_ROLES = 'ROLE_LOADING_EXTERNAL_ROLES'
export const ROLE_EXTERNAL_ROLES = 'ROLE_EXTERNAL_ROLES'

/**
 * Company Module
 */
export const COMPANY_RESET_STATE = 'COMPANY_RESET_STATE'
export const COMPANY_LOADING_OFFICES = 'COMPANY_LOADING_OFFICES'
export const COMPANY_OFFICES = 'COMPANY_OFFICES'

/*
 * Footage status type module
 */
export const FOOTAGE_STATUS_TYPE_RESET_STATE = 'FOOTAGE_STATUS_TYPE_RESET_STATE'
export const FOOTAGE_STATUS_TYPES_LIST = 'FOOTAGE_STATUS_TYPES_LIST'
export const FOOTAGE_STATUS_TYPES_IS_LOADING = 'FOOTAGE_STATUS_TYPES_IS_LOADING'

/**
 * Media Manager Module
 */
export const MEDIA_MANAGER_RESET_STATE = 'MEDIA_MANAGER_RESET_STATE'
export const MEDIA_MANAGER_ARCHIVED_PROJECTS_LOADING =
  'MEDIA_MANAGER_ARCHIVED_PROJECTS_LOADING'
export const MEDIA_MANAGER_SELECTED_ARCHIVED_PROJECT =
  'MEDIA_MANAGER_SELECTED_ARCHIVED_PROJECT'
export const MEDIA_MANAGER_MODAL_ARCHIVE_PROJECT_FOOTAGE_SHOW =
  'MEDIA_MANAGER_MODAL_ARCHIVE_PROJECT_FOOTAGE_SHOW'
export const MEDIA_MANAGER_LOADING_ARCHIVE_PROJECT_FOOTAGE =
  'MEDIA_MANAGER_LOADING_ARCHIVE_PROJECT_FOOTAGE'

/**
 * Calendar Module
 */
export const CALENDAR_RESET_STATE = 'CALENDAR_RESET_STATE'
export const CALENDAR_ADD_SELECTED_FILTER = 'CALENDAR_ADD_SELECTED_FILTER'
export const CALENDAR_ADD_REMOVE_SELECTED_FILTERS =
  'CALENDAR_ADD_REMOVE_SELECTED_FILTERS'
export const CALENDAR_REMOVE_SELECTED_FILTER = 'CALENDAR_REMOVE_SELECTED_FILTER'
export const CALENDAR_CLEAR_ALL_FILTERS = 'CALENDAR_CLEAR_ALL_FILTERS'
export const CALENDAR_FILTER_SEARCH = 'CALENDAR_FILTER_SEARCH'
export const CALENDAR_MODAL_SHOW_ALL_FILTERS = 'CALENDAR_MODAL_SHOW_ALL_FILTERS'
export const CALENDAR_MODAL_SHOW_SAVE_FILTERS_MODAL =
  'CALENDAR_MODAL_SHOW_SAVE_FILTERS_MODAL'
export const CALENDAR_MODAL_SHOW_ON_HOLD_EVENTS =
  'CALENDAR_MODAL_SHOW_ON_HOLD_EVENTS'
export const CALENDAR_MODAL_SHOW_DELETED_EVENTS =
  'CALENDAR_MODAL_SHOW_DELETED_EVENTS'
export const CALENDAR_ON_HOLD_EVENTS = 'CALENDAR_ON_HOLD_EVENTS'
export const CALENDAR_DELETED_EVENTS = 'CALENDAR_DELETED_EVENTS'
export const CALENDAR_EVENTS_LIST = 'CALENDAR_EVENTS_LIST'
export const CALENDAR_LOADING_EVENTS_LIST = 'CALENDAR_LOADING_EVENTS_LIST'
export const CALENDAR_SELECTED_EVENT = 'CALENDAR_SELECTED_EVENT'
export const CALENDAR_EVENT_DETAILS_LOADING = 'CALENDAR_EVENT_DETAILS_LOADING'
export const CALENDAR_VIEW_TYPE = 'CALENDAR_VIEW_TYPE'
export const CALENDAR_AGENDA_LIST = 'CALENDAR_AGENDA_LIST'
export const CALENDAR_LOADING_AGENDA_LIST = 'CALENDAR_LOADING_AGENDA_LIST'
export const CALENDAR_FILTERS_TEAMS = 'CALENDAR_FILTERS_TEAMS'
export const CALENDAR_FILTERS_CREW = 'CALENDAR_FILTERS_CREW'
export const CALENDAR_FILTERS_PROJECT_OWNERS = 'CALENDAR_FILTERS_PROJECT_OWNERS'
export const CALENDAR_FILTERS_EVENT_TYPES = 'CALENDAR_FILTERS_EVENT_TYPES'
export const CALENDAR_LOADING_EVENT_TYPES = 'CALENDAR_LOADING_EVENT_TYPES'
export const CALENDAR_LOADING_SAVED_FILTERS = 'CALENDAR_LOADING_SAVED_FILTERS'
export const CALENDAR_FILTERS_SAVED_FILTERS = 'CALENDAR_FILTERS_SAVED_FILTERS'
export const CALENDAR_FILTERS_SELECTED_SAVED_FILTER =
  'CALENDAR_FILTERS_SELECTED_SAVED_FILTER'
export const CALENDAR_LOADING_ON_HOLD_EVENTS = 'CALENDAR_LOADING_ON_HOLD_EVENTS'
export const CALENDAR_LOADING_UN_HOLD_EVENT = 'CALENDAR_LOADING_UN_HOLD_EVENT'
export const CALENDAR_LOADING_SELECTED_ON_HOLD_EVENT =
  'CALENDAR_LOADING_SELECTED_ON_HOLD_EVENT'
export const CALENDAR_SELECTED_ON_HOLD_EVENT = 'CALENDAR_SELECTED_ON_HOLD_EVENT'
export const CALENDAR_LOADING_EVENT_FORM = 'CALENDAR_LOADING_EVENT_FORM'
export const CALENDAR_MODAL_EVENTS_PLACEHOLDERS_CREATE =
  'CALENDAR_MODAL_EVENTS_PLACEHOLDERS_CREATE'
export const CALENDAR_MODAL_EVENTS_FILMING_DETAILS_CREATE =
  'CALENDAR_MODAL_EVENTS_FILMING_DETAILS_CREATE'
export const CALENDAR_MODAL_EVENTS_DELIVERIES_CREATE =
  'CALENDAR_MODAL_EVENTS_DELIVERIES_CREATE'
export const CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_CREATE =
  'CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_CREATE'
export const CALENDAR_MODAL_EVENTS_FILMING_DETAILS_EDIT =
  'CALENDAR_MODAL_EVENTS_FILMING_DETAILS_EDIT'
export const CALENDAR_MODAL_EVENTS_DELIVERIES_EDIT =
  'CALENDAR_MODAL_EVENTS_DELIVERIES_EDIT'
export const CALENDAR_MODAL_EVENTS_JOB_CHANGES_EDIT =
  'CALENDAR_MODAL_EVENTS_JOB_CHANGES_EDIT'
export const CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_EDIT =
  'CALENDAR_MODAL_EVENTS_PRE_PRODUCTIONS_EDIT'
export const CALENDAR_MODAL_EVENTS_PRE_PRODUCTION_CHANGES_EDIT =
  'CALENDAR_MODAL_EVENTS_PRE_PRODUCTION_CHANGES_EDIT'
export const CALENDAR_MODAL_EVENTS_PLACEHOLDERS_EDIT =
  'CALENDAR_MODAL_EVENTS_PLACEHOLDERS_EDIT'
export const CALENDAR_SELECTED_SCHEDULES = 'CALENDAR_SELECTED_SCHEDULES'
export const CALENDAR_PROJECTS_LIST = 'CALENDAR_PROJECTS_LIST'
export const CALENDAR_LOADING_PROJECTS_LIST = 'CALENDAR_LOADING_PROJECTS_LIST'
export const CALENDAR_EVENT_TYPES_LIST = 'CALENDAR_EVENT_TYPES_LIST'
export const CALENDAR_MODAL_EVENTS = 'CALENDAR_MODAL_EVENTS'
export const CALENDAR_SELECTED_CALENDAR_DATE = 'CALENDAR_SELECTED_CALENDAR_DATE'
export const CALENDAR_EVENTS_SEARCH_KEYWORD = 'CALENDAR_EVENTS_SEARCH_KEYWORD'
export const CALENDAR_MODAL_EVENTS_BULK_EDIT = 'CALENDAR_MODAL_EVENTS_BULK_EDIT'
export const CALENDAR_EVENTS_BULK_SELECTED_EVENTS =
  'CALENDAR_EVENTS_BULK_SELECTED_EVENTS'
export const CALENDAR_EVENTS_BULK_REASSIGN_USER =
  'CALENDAR_EVENTS_BULK_REASSIGN_USER'
export const CALENDAR_EVENTS_DATA_DATE_RANGE = 'CALENDAR_EVENTS_DATA_DATE_RANGE'
export const CALENDAR_ON_HOLD_EVENTS_LAST_PAYLOAD =
  'CALENDAR_ON_HOLD_EVENTS_LAST_PAYLOAD'
export const CALENDAR_SHOW_PROGRESS_BAR = 'CALENDAR_SHOW_PROGRESS_BAR'
export const CALENDAR_SHOW_MOBILE_FILTER_LIST =
  'CALENDAR_SHOW_MOBILE_FILTER_LIST'
export const CALENDAR_SHOW_MOBILE_AGENDA_MODAL =
  'CALENDAR_SHOW_MOBILE_AGENDA_MODAL'
export const CALENDAR_LOCAL_STORAGE_SELECTED_FILTERS =
  'CALENDAR_LOCAL_STORAGE_SELECTED_FILTERS'
export const CALENDAR_CUSTOM_EVENTS_LIST_PAYLOAD =
  'CALENDAR_CUSTOM_EVENTS_LIST_PAYLOAD'
export const CALENDAR_LOADING_DELETED_EVENTS = 'CALENDAR_LOADING_DELETED_EVENTS'
export const CALENDAR_EVENT_ACTION_HISTORY = 'CALENDAR_EVENT_ACTION_HISTORY'
export const CALENDAR_FILTERS_SELECTED_OWNED_BY_ID_FILTER =
  'CALENDAR_FILTERS_SELECTED_OWNED_BY_ID_FILTER'
export const CALENDAR_ADD_SELECTED_OWNED_BY_ID_FILTER =
  'CALENDAR_ADD_SELECTED_OWNED_BY_ID_FILTER'
export const CALENDAR_REMOVE_SELECTED_OWNED_BY_ID_FILTER =
  'CALENDAR_REMOVE_SELECTED_OWNED_BY_ID_FILTER'
