import Axios from '@plugins/axios'

export default (payload, customIncludes) => {
  let url = `/api/v2/internal/projects/${payload.projectId}/archive`

  let config = {
    headers: {},
    params: {},
  }

  if (customIncludes) {
    config.params.include = `${customIncludes.join(',')}`
  }

  const requestPayload = {
    footage_policy_type_id: payload.footagePolicyId,
    footage_policy_notes: payload.footagePolicyReason,
  }

  return Axios.put(url, requestPayload, config)
}
