import * as mutations from '@store/mutation-types'

/**
 * Store actions for production-note module.
 * @module project/production-note/production-note-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_PRODUCTION_NOTE_RESET_STATE)
  },

  /**
   * Action to set the project production notes
   * @param commit
   * @param {array} productionNotes - the production notes data
   */
  setProjectProductionNotesList({ commit }, productionNotes) {
    commit(mutations.PROJECT_PRODUCTION_NOTES_LIST, productionNotes)
  },

  /**
   * Action to get the project production notes
   * TODO: use get production notes service once available instead of getting details from the project details/summary object
   * @param commit
   * @param getters
   */
  getProjectProductionNotesList({ commit, getters }) {
    commit(
      mutations.PROJECT_PRODUCTION_NOTES_LIST,
      getters.projectDetails.production_notes
    )
  },
}
