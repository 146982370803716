import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import { isEmpty, size } from 'lodash'
import router from '@router/router'

/**
 * Store actions for activity-log module.
 * @module project/activity-log/activity-log-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_ACTIVITY_LOG_RESET_STATE)
  },

  getActivityLogList({ commit, dispatch, getters }, payload) {
    commit(mutations.PROJECT_ACTIVITY_LOGS_LOADING, true)

    payload.payload.perPage =
      payload.payload.perPage || getters['activityLogs'].meta.per_page

    ProjectServices.projects.logs
      .list(payload.projectId, payload.payload)
      .then((response) => {
        if (size(response.data.data) > 0) {
          commit(mutations.PROJECT_HAS_ACTIVITY_LOGS, true)
        } else if (
          router.currentRoute.name === 'project.details.activity-logs' &&
          isEmpty(payload.payload.search)
        ) {
          // Redirect to 404 page if there are not activity logs when directly accessing the activity log route
          router.push({
            name: 'error.404',
          })
        }

        commit(mutations.PROJECT_ACTIVITY_LOGS_LIST, response.data)
        commit(mutations.PROJECT_ACTIVITY_LOGS_LOADING, false)
      })
      .catch((error) => {
        commit(mutations.PROJECT_ACTIVITY_LOGS_LOADING, false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving project logs.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },
}
