import Axios from '@plugins/axios'

export default (videoId, payload) => {
  let url = `/api/v2/internal/videos/${videoId}/favourite`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.post(url, payload, config)
}
