import updateFootageStatus from './projects/updateFootageStatus'
import getProjectDetails from './projects/getProjectDetails'
import getProjectShootJobs from './projects/jobs/shoots/getProjectShootJobs'
import createProjectShootJob from './projects/jobs/shoots/createProjectShootJob'
import updateProjectShootJob from './projects/jobs/shoots/updateProjectShootJob'
import deleteProjectShootJob from './projects/jobs/shoots/deleteProjectShootJob'
import holdProjectShootJob from './projects/jobs/shoots/holdProjectShootJob'
import unholdProjectShootJob from './projects/jobs/shoots/unholdProjectShootJob'
import addPoNumber from './projects/orders/addPoNumber'
import createProductionCost from './projects/orders/createProductionCost'
import createDisbursementCost from './projects/orders/createDisbursementCost'
import updatePreProduction from './projects/jobs/pre-production/updatePreProduction'
import getDisbursementProductElements from './products/getDisbursementProductElements'
import getPreProductionProductElements from './products/getPreProductionProductElements'
import getShootProductElements from './products/getShootProductElements'
import uploadPreProduction from './projects/jobs/pre-production/uploadPreProduction'
import approvePreProduction from './projects/jobs/pre-production/approvePreProduction'
import unapprovePreProduction from './projects/jobs/pre-production/unapprovePreProduction'
import deletePreProduction from './projects/jobs/pre-production/deletePreProduction'
import createPreProduction from './projects/jobs/pre-production/createPreProduction'
import archivePreProduction from './projects/jobs/pre-production/archivePreProduction'
import approveAllPreProduction from './projects/jobs/pre-production/approveAllPreProductions'
import getPreProductionFileVersions from './projects/jobs/pre-production/getPreProductionFileVersions'
import restorePreProductionFileVersion from './projects/jobs/pre-production/restorePreProductionFileVersion'
import getProjectInvoices from './projects/invoices/getProjectInvoices'
import getProjectHealth from './projects/project-health/getProjectHealth'
import getProjectVideoDeliveries from './projects/jobs/video-deliveries/getProjectVideoDeliveries'
import createVideoJob from './projects/jobs/video-deliveries/createVideoJob'
import getVideoProducts from './products/videos/getVideoProducts'
import getVideoTags from './tags/getVideoTags'
import getIndustryTypes from './industry-types/getIndustryTypes'
import getVideoStyles from './videos/video-styles/getVideoStyles'
import updateVideoInfo from './videos/updateVideoInfo'
import updateVideoStar from './videos/updateVideoStar'
import updateFavouriteVideo from './videos/favourite/updateFavouriteVideo'
import getVideoInfo from './videos/getVideoInfo'
import getVideoProductElements from './products/videos/getVideoProductElements'
import updateProjectInvoiceDetails from './projects/invoices/updateProjectInvoiceDetails'
import getBudget from './projects/budget/getBudget'
import createBudgetSpreadsheet from './projects/budget/createBudgetSpreadsheet'
import updateBudget from './projects/budget/updateBudget'
import updateVideoJob from './projects/jobs/video-deliveries/updateVideoJob'
import approveVideoJob from './projects/jobs/video-deliveries/approveVideoJob'
import deliverVideoJob from './projects/jobs/video-deliveries/deliverVideoJob'
import unapproveVideoJob from './projects/jobs/video-deliveries/unapproveVideoJob'
import deleteVideoJob from './projects/jobs/video-deliveries/deleteVideoJob'
import archiveVideoJob from './projects/jobs/video-deliveries/archiveVideoJob'
import createNewProject from './projects/createNewProject'
import getProductTypes from './product-types/getProductTypes'
import getProducers from './users/getProducers'
import getAccountManagers from './users/getAccountManagers'
import getProjectOwners from './users/getProjectOwners'
import getAuthUser from './auth/getAuthUser'
import updateProjectInvoiceCredits from './projects/invoices/updateProjectInvoiceCredits'
import editProductionCostJob from './projects/jobs/production-cost/editJob'
import deleteProductionCostJob from './projects/jobs/production-cost/deleteJob'
import getProjectAttachments from './projects/attachments/getProjectAttachments'
import getCompanyAttachments from './companies/attachments/getCompanyAttachments'
import getBrandHubAttachments from './projects/attachments/getBrandHubAttachments'
import deleteBrandHubAttachment from './projects/attachments/deleteBrandHubAttachment'
import updateProjectDetails from './projects/updateProjectDetails'
import addProjectContact from './contacts/projects/addProjectContact'
import removeProjectContact from './contacts/projects/removeProjectContact'
import deleteProjectAttachment from './projects/attachments/deletProjectAttachment'
import addAttachmentToBrandHub from './projects/attachments/addAttachmentToBrandHub'
import deleteCompanyAttachment from './companies/attachments/deleteCompanyAttachment'
import getActivityLogs from './projects/activity-logs/getActivityLogs'
import cancelProject from './projects/cancelProject'
import reopenProject from './projects/reopenProject'
import archiveProject from './projects/archiveProject'
import holdProject from './projects/holdProject'
import unholdProject from './projects/unholdProject'
import confirmProjectContact from './projects/confirmProjectContact'
import sendInvoice from './projects/invoices/sendInvoice'
import linkInvoice from './projects/invoices/linkInvoice'
import deleteProjectInvoice from './projects/invoices/deleteProjectInvoice'
import getAllProjects from './projects/getAllProjects'
import inlineUpdateProjectDetails from './projects/inlineUpdateProjectDetails'
import removeShootJobLinkedPreProduction from './projects/jobs/shoots/removeShootJobLinkedPreProduction'
import linkPreProductionToShootJob from './projects/jobs/shoots/linkPreProductionToShootJob'
import removeVideoJobLinkedPreProduction from './projects/jobs/video-deliveries/removeVideoJobLinkedPreProduction'
import linkPreProductionToVideoJob from './projects/jobs/video-deliveries/linkPreProductionToVideoJob'
import getTargetAudienceTypes from './target-audience-types/getTargetAudienceTypes'
import getCallSheet from './projects/jobs/shoots/getCallSheet'
import updateCallSheet from './projects/jobs/shoots/updateCallSheet'
import updateManualCallSheet from './projects/jobs/shoots/updateManualCallSheet'
import getUserDetails from './users/getUserDetails'
import createContact from './users/contact/createContact'
import updateContact from './users/contact/updateContact'
import getExternalRoles from './roles/getExternalRoles'
import getCompanyOffices from './companies/offices/getCompanyOffices'
import getFootageStatusTypes from './footage-status-types/getFootageStatusTypes'
import getArchivedProjects from './projects/getArchivedProjects'
import archiveFootage from './projects/archiveFootage'
import getHubspotQuotes from './projects/hubspot/quotes/getHubspotQuotes'
import getPreProductionChanges from './projects/jobs/pre-production/changes/getPreProductionChanges'
import createPreProductionChange from './projects/jobs/pre-production/changes/createPreProductionChange'
import updatePreProductionChange from './projects/jobs/pre-production/changes/updatePreProductionChange'
import deletePreProductionChange from './projects/jobs/pre-production/changes/deletePreProductionChange'
import getPreProductionChangeAttachments from './projects/jobs/pre-production/changes/attachments/getPreProductionChangeAttachments'
import deletePreProductionChangeAttachment from './projects/jobs/pre-production/changes/attachments/deletePreProductionChangeAttachment'
import getPreProductionUsers from './users/getPreProductionUsers'
import getEventsList from './events/getEventsList'
import getEventDetails from './events/getEventDetails'
import updateEventDetails from './events/updateEventDetails'
import getTeams from './teams/getTeams'
import getInternalUsers from './users/getInternalUsers'
import getEventTypes from './events/getEventTypes'
import createSavedFilter from './events/saved-filters/createSavedFilter'
import getSavedFilters from './events/saved-filters/getSavedFilters'
import holdPreProduction from './projects/jobs/pre-production/holdPreProduction'
import unholdPreProduction from './projects/jobs/pre-production/unholdPreProduction'
import holdVideoJob from './projects/jobs/video-deliveries/holdVideoJob'
import unholdVideoJob from './projects/jobs/video-deliveries/unholdVideoJob'
import createEvent from './events/createEvent'
import createPreProductionsEvent from './events/pre-productions/createPreProductionsEvent'
import createVideoDeliveriesEvent from './events/video-deliveries/createVideoDeliveriesEvent'
import createShootsEvent from './events/shoots/createShootsEvent'
import getPreProductionDetails from './projects/jobs/pre-production/getPreProductionDetails'
import deleteEvent from './events/deleteEvent'
import authoriseGoogleCalendar from './users/google-calendar/authoriseGoogleCalendar'
import getJobList from './projects/jobs/getJobList'
import getPinnedEodNotes from './projects/eod-notes/getPinnedEodNotes'
import getProjectEodNotes from './projects/eod-notes/getProjectEodNotes'
import getProjectJobsEodNotes from './projects/eod-notes/getProjectJobsEodNotes'
import togglePinEodNotes from './projects/eod-notes/togglePinEodNotes'
import createProjectEodNotes from './projects/eod-notes/createProjectEodNotes'
import createJobEodNotes from './projects/eod-notes/createJobEodNotes'
import getJobEodNotes from './projects/eod-notes/getJobEodNotes'
import updateJobAdditionalData from './projects/eod-notes/updateJobAdditionalData'
import bulkUpdateEvents from './events/bulkUpdateEvents'
import bulkDeleteEvents from './events/bulkDeleteEvents'
import bulkDuplicateEvents from './events/bulkDuplicateEvents'
import bulkRestoreEvents from './events/bulkRestoreEvents'
import deleteSavedFilter from './events/saved-filters/deleteSavedFilter'

export default {
  projects: {
    invoices: {
      list: getProjectInvoices,
      send: sendInvoice,
      link: linkInvoice,
      update: {
        credits: updateProjectInvoiceCredits,
        invoiceDetails: updateProjectInvoiceDetails,
      },
      delete: deleteProjectInvoice,
    },
    budget: {
      get: getBudget,
      createSpreadsheet: createBudgetSpreadsheet,
      update: updateBudget,
    },
    hubspot: {
      quotes: {
        get: getHubspotQuotes,
      },
    },
    jobs: {
      preProduction: {
        get: getPreProductionDetails,
        upload: uploadPreProduction,
        update: updatePreProduction,
        approve: approvePreProduction,
        unapprove: unapprovePreProduction,
        delete: deletePreProduction,
        create: createPreProduction,
        archive: archivePreProduction,
        approveAll: approveAllPreProduction,
        fileVersions: getPreProductionFileVersions,
        restoreFileVersion: restorePreProductionFileVersion,
        hold: holdPreProduction,
        unhold: unholdPreProduction,
        changes: {
          list: getPreProductionChanges,
          create: createPreProductionChange,
          update: updatePreProductionChange,
          delete: deletePreProductionChange,
          attachments: {
            list: getPreProductionChangeAttachments,
            delete: deletePreProductionChangeAttachment,
          },
        },
      },
      productionCost: {
        update: editProductionCostJob,
        delete: deleteProductionCostJob,
      },
      shoots: {
        list: getProjectShootJobs,
        create: createProjectShootJob,
        update: updateProjectShootJob,
        delete: deleteProjectShootJob,
        hold: holdProjectShootJob,
        unhold: unholdProjectShootJob,
        linkPreProduction: linkPreProductionToShootJob,
        unlinkPreProd: removeShootJobLinkedPreProduction,
        getCallSheet: getCallSheet,
        updateCallSheet: updateCallSheet,
        updateManualCallSheet: updateManualCallSheet,
      },
      videoDeliveries: {
        list: getProjectVideoDeliveries,
        create: createVideoJob,
        update: updateVideoJob,
        approve: approveVideoJob,
        deliver: deliverVideoJob,
        unapprove: unapproveVideoJob,
        delete: deleteVideoJob,
        archive: archiveVideoJob,
        linkPreProduction: linkPreProductionToVideoJob,
        unlinkPreProd: removeVideoJobLinkedPreProduction,
        hold: holdVideoJob,
        unhold: unholdVideoJob,
      },
      list: getJobList,
    },
    orders: {
      addPoNumber: addPoNumber,
      create: {
        disbursementCost: createDisbursementCost,
        productionCost: createProductionCost,
      },
    },
    attachments: {
      list: getProjectAttachments,
      delete: deleteProjectAttachment,
      addToBrandHub: addAttachmentToBrandHub,
    },
    health: {
      list: getProjectHealth,
    },
    logs: {
      list: getActivityLogs,
    },
    detail: getProjectDetails,
    create: createNewProject,
    update: updateProjectDetails,
    cancel: cancelProject,
    reopen: reopenProject,
    archiveList: getArchivedProjects,
    archive: archiveProject,
    hold: holdProject,
    unhold: unholdProject,
    confirmContact: confirmProjectContact,
    list: getAllProjects,
    inlineUpdate: inlineUpdateProjectDetails,
    updateFootageStatus,
    archiveFootage,
    brandHub: {
      list: getBrandHubAttachments,
      delete: deleteBrandHubAttachment,
    },
    eodNotes: {
      pinned: getPinnedEodNotes,
      project: getProjectEodNotes,
      projectJobs: getProjectJobsEodNotes,
      toggle: togglePinEodNotes,
      createProjectEod: createProjectEodNotes,
      createJobEod: createJobEodNotes,
      job: getJobEodNotes,
      updateJobAdditionalData: updateJobAdditionalData,
    },
  },
  products: {
    // TODO: update with resource folder structure
    list: {
      disbursementCostProductElements: getDisbursementProductElements,
      preProductionProductElements: getPreProductionProductElements,
      shootProductElements: getShootProductElements,
    },
    videos: {
      list: {
        videoProducts: getVideoProducts,
        videoProductElements: getVideoProductElements,
      },
    },
  },
  productTypes: {
    list: getProductTypes,
  },
  users: {
    preProductionUsers: {
      list: getPreProductionUsers,
    },
    get: getUserDetails,
    producers: {
      list: getProducers,
    },
    accountManagers: {
      list: getAccountManagers,
    },
    projectOwners: {
      list: getProjectOwners,
    },
    contact: {
      create: createContact,
      update: updateContact,
    },
    internal: {
      list: getInternalUsers,
    },
    googleCalendar: {
      authorise: authoriseGoogleCalendar,
    },
  },
  roles: {
    external: getExternalRoles,
  },
  auth: {
    user: {
      list: getAuthUser,
    },
  },
  companies: {
    attachments: {
      list: getCompanyAttachments,
      delete: deleteCompanyAttachment,
    },
    offices: {
      list: getCompanyOffices,
    },
  },
  contacts: {
    projects: {
      create: addProjectContact,
      delete: removeProjectContact,
    },
  },
  tags: {
    videos: {
      list: getVideoTags,
    },
  },
  industryTypes: {
    list: getIndustryTypes,
  },
  videos: {
    videoStyles: {
      list: getVideoStyles,
    },
    update: updateVideoInfo,
    updateStar: updateVideoStar,
    favourite: {
      update: updateFavouriteVideo,
    },
    get: getVideoInfo,
  },
  targetAudienceTypes: {
    list: getTargetAudienceTypes,
  },
  footageStatusTypes: {
    list: getFootageStatusTypes,
  },
  events: {
    create: createEvent,
    list: getEventsList,
    get: getEventDetails,
    update: updateEventDetails,
    delete: deleteEvent,
    bulk: {
      update: bulkUpdateEvents,
      delete: bulkDeleteEvents,
      duplicate: bulkDuplicateEvents,
      restore: bulkRestoreEvents,
    },
    types: {
      list: getEventTypes,
    },
    savedFilters: {
      create: createSavedFilter,
      list: getSavedFilters,
      delete: deleteSavedFilter,
    },
    preProductions: {
      create: createPreProductionsEvent,
    },
    videoDeliveries: {
      create: createVideoDeliveriesEvent,
    },
    shoots: {
      create: createShootsEvent,
    },
  },
  teams: {
    list: getTeams,
  },
}
