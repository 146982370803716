import * as types from '@store/mutation-types'

/**
 * @module store/user/mutations
 */

export default {
  /**
   * Update the producers list loading state
   * @param state
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_PRODUCERS_LIST](state, loading) {
    state.loading.producersList = loading
  },

  /**
   * Update the account manager list loading state
   * @param state
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_ACCOUNT_MANAGERS_LIST](state, loading) {
    state.loading.accountManagersList = loading
  },

  /**
   * Update the user details loading state
   * @param state
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_USER_DETAILS](state, loading) {
    state.loading.userDetails = loading
  },

  /**
   * Update the contact loading state
   * @param state
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_CONTACT](state, loading) {
    state.loading.contact = loading
  },

  /**
   * Update the producers list
   * @param state
   * @param {array} producers - array of objects of producers
   */
  [types.USER_PRODUCERS_LIST](state, producers) {
    state.producers = producers
  },

  /**
   * Update the account managers list
   * @param state
   * @param {array} accountManagers - array of objects of account managers
   */
  [types.USER_ACCOUNT_MANAGERS_LIST](state, accountManagers) {
    state.accountManagers = accountManagers
  },

  /**
   * Update the project owners list
   * @param state
   * @param {array} projectOwners - array of objects of project owners
   */
  [types.USER_PROJECT_OWNERS_LIST](state, projectOwners) {
    state.projectOwners = projectOwners
  },

  /**
   * Update the user details
   * @param state
   * @param {object} userDetails - the user details object
   */
  [types.USER_DETAILS](state, userDetails) {
    state.userDetails = userDetails
  },

  /**
   * Set display state for the contact form modal
   *
   * @param {object} state - state of the current module
   */
  [types.USER_MODAL_CONTACT_FORM_SHOW](state) {
    state.modal.contactForm.show = !state.modal.contactForm.show
  },

  /**
   * Set the selected user Id
   *
   * @param {object} state - state of the current module
   * @param {object} userId - the user id
   */
  [types.USER_SELECTED_USER_ID](state, userId) {
    state.selectedUserId = userId
  },

  /**
   * Set the loading state of the users list
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_PRE_PRODUCTION_USERS](state, loading) {
    state.loading.preProductionUsers = loading
  },

  /**
   * Set the users list
   *
   * @param {object} state - state of the current module
   * @param {array} users - the users list
   */
  [types.USER_PRE_PRODUCTION_USERS](state, users) {
    state.preProductionUsers = users
  },

  /**
   * Set the teams list
   * @param {object} state - state of the current module
   * @param {array} teams - the teams list
   */
  [types.USER_TEAMS](state, teams) {
    state.teams = teams
  },

  /**
   * Set the teams loading state
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_TEAMS](state, loading) {
    state.loading.teams = loading
  },

  /**
   * Set the internal users loading state
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_INTERNAL_USERS](state, loading) {
    state.loading.internalUsers = loading
  },

  /**
   * Set the internal users list
   * @param {object} state - state of the current module
   * @param {array} users - the internal users list
   */
  [types.USER_INTERNAL_USERS](state, users) {
    state.internalUsers = users
  },

  /**
   * Set the google authentication loading state
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loading state
   */
  [types.USER_LOADING_GOOGLE_AUTHENTICATION](state, loading) {
    state.loading.googleAuthentication = loading
  },
}
