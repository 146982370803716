/**
 * @module store/project/invoice/invoice-getters
 */

import { find, orderBy } from 'lodash'

export default {
  /**
   * Get loading state for project add po number.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  addPoNumberLoading(state) {
    return state.loading.addPoNumber
  },

  /**
   * Get loading state for project add production cost loading.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  addProductionCostLoading(state) {
    return state.loading.addProductionCost
  },

  /**
   * Get loading state for project add disbursement cost loading.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  addDisbursementCostLoading(state) {
    return state.loading.addDisbursementCost
  },

  /**
   * Get loading state for project edit job price loading.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  editJobLoading(state) {
    return state.loading.editJob
  },

  /**
   * Get loading state for project invoice invoiceOverview.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  invoiceOverviewLoading(state) {
    return state.loading.invoiceOverview
  },

  /**
   * Get loading state for project invoice details.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  invoiceDetailsLoading(state) {
    return state.loading.invoiceDetails
  },

  /**
   * Get loading state for credit list section.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  creditListLoading(state) {
    return state.loading.creditList
  },

  /**
   * Get loading state for invoice list section.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  invoiceListLoading(state) {
    return state.loading.invoiceList
  },

  /**
   * Get loading state for link invoice section.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  linkInvoiceLoading(state) {
    return state.loading.linkInvoice
  },

  /**
   * Get project invoices
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  invoiceList(state) {
    return state.invoices.list
  },

  /**
   * Get list of invoice jobs.
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  invoiceJobs(state) {
    return state.invoices.jobs
  },

  /**
   * Get how much has been paid with credits.
   * @param {Object} state - State for this module.
   * @returns String
   */
  invoicePaidWithCredits(state) {
    return state.invoices.paid_with_credits
  },

  /**
   * Get how much is the total that has been invoiced.
   * @param {Object} state - State for this module.
   * @returns String
   */
  invoiceTotalInvoiced(state) {
    return state.invoices.total_invoiced
  },

  /**
   * Get how much remained to be invoiced.
   * @param state
   * @returns String
   */
  invoiceRemainingToBeInvoiced(state) {
    return state.invoices.remaining_to_be_invoiced
  },

  /**
   * Get the state of the modal add po number show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showAddPoNumberModal(state) {
    return state.modal.addPoNumber.show
  },

  /**
   * Get the state of the add po number order id
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the add po number order id.
   */
  addPoNumberOrderId(state) {
    return state.modal.addPoNumber.orderId
  },

  /**
   * Get the state of the modal add production cost show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showAddProductionCostModal(state) {
    return state.modal.addProductionCost.show
  },

  /**
   * Get the state of the modal add disbursement cost show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showAddDisbursementCostModal(state) {
    return state.modal.addDisbursementCost.show
  },

  /**
   * Get disbursement product elements.
   *
   * @param {Object} state - State for this module
   * @returns {Object}
   */
  disbursementProductElements(state) {
    return state.modal.addDisbursementCost.productElements
  },

  /**
   * Get the state of the modal edit job price show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showEditJobModal(state) {
    return state.modal.editJob.show
  },

  /**
   * Get details of selected invoice job.
   * @param {Object} state
   * @returns {Object}
   */
  selectedInvoiceJob(state) {
    return find(state.invoices.jobs, { id: state.selectedInvoiceJobId })
  },

  /**
   * Get the invoice order detail.
   * @param state
   * @returns Object
   */
  invoiceOrderDetail(state) {
    return state.invoices.invoice_order_detail
  },

  /**
   * Get the invoice contact.
   * @param state
   * @returns array
   */
  invoiceContacts(state) {
    return state.invoices.invoiceContacts
  },

  /**
   * Get the boolean value of invoice detail changed.
   * @param state
   * @returns bool
   */
  invoiceDetailChanged(state) {
    return state.invoiceDetailChanged
  },

  /**
   * Get list of credits.
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  creditList(state) {
    return orderBy(state.invoices.credit_list, 'id')
  },

  /**
   * Check if invoice details can be displayed
   * @param {object} state - State for this module
   * @param {object} getters - Getters for this module
   * @param {object} rootState - States for the whole store actions
   * @returns {boolean}
   */
  hideInvoiceDetails(state, getters, rootState) {
    return (
      getters.invoicePaidWithCredits >=
      rootState.project.projectDetails.total_price
    )
  },

  /**
   * Get the state of the modal link invoice show
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the modal show
   */
  showLinkInvoiceModal(state) {
    return state.modal.linkInvoice.show
  },

  /**
   * Get the state of the link invoice order id
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the link invoice order id.
   */
  linkInvoiceOrderId(state) {
    return state.modal.linkInvoice.orderId
  },
}
