import actions from '@store/projects/projects-actions'
import getters from '@store/projects/projects-getters'
import mutations from '@store/projects/projects-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/projects/projects-module
 */

/**
 * Get default state for projects module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the project module.
   * @property  {boolean} loading.projectsList - States for projects list loader.
   * @property  {boolean} loading.projectHealth - States for project health loader.
   * @property  {number}  selectedProjectId - The selected project id.
   * @property  {object}  projects - List of projects with data array and meta.
   * @property  {array}   projects.data - Array of objects of projects.
   * @property  {object}  projects.meta - The projects meta.
   * @property  {number}  projects.meta.current_page - The current page of the projects.
   * @property  {number}  projects.meta.from - The projects from page.
   * @property  {number}  projects.meta.last_page - The projects last page.
   * @property  {number}  projects.meta.per_page - The projects per page.
   * @property  {number}  projects.meta.to - The projects to page.
   * @property  {number}  projects.meta.total - The total count of projects.
   * @property  {object}  projectsFilter - List of all projects list filter related.
   * @property  {string}  projectsFilter.search - The projects search filter.
   * @property  {string}  projectsFilter.sortBy - The projects sort by filter.
   * @property  {string}  projectsFilter.sort - The projects sort filter.
   * @property  {string}  projectsFilter.project_type - The projects project type filter.
   * @property  {string}  projectsFilter.from - The projects from filter.
   * @property  {string}  projectsFilter.to - The projects to filter.
   * @property  {array}  projectsFilter.users - The projects users filter.
   * @property  {array}  projectsFilter.statuses - The projects statuses filter.
   * @property  {array}  projectsFilter.product_types - The projects product types filter.
   * @property  {number}  currentPage - The projects current page.
   * @property  {Array}   archivedProjects - Details related to archived projects.
   * @property  {object}  archivedProjects.data - List of archived projects.
   * @property  {object}  archivedProjects.meta - The archived projects meta.
   */
  return {
    loading: {
      projectsList: false,
      projectHealth: false,
    },
    selectedProjectId: null,
    projectFieldsUpdating: [],
    projects: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 25,
        to: 1,
        total: 0,
      },
    },
    projectsFilter: {
      search: '',
      sortBy: null,
      sort: 'desc',
      project_type: 'all_projects',
      from: '',
      to: '',
      users: [],
      statuses: [],
      product_types: [],
      perPage: 25,
    },
    archivedProjects: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 25,
        to: 1,
        total: 0,
      },
    },
    currentPage: 1,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECTS_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
