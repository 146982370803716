import imageConstants from '@configs/image-constants'
import VmsConstants from '@configs/vms-constants'
import AppConfig from '@configs/app-config'
import { cloneDeep, isEmpty, isNil, size, find, orderBy } from 'lodash'

/**
 * @module store/project/project-getters
 */

export default {
  /**
   * Get loading state for project details.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  projectDetailsLoading(state) {
    return state.loading.projectDetails
  },

  /**
   * Get loading state for create new project.
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  createNewProjectLoading(state) {
    return state.loading.createNewProject
  },

  /**
   * Get loading state for product types
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  productTypesLoading(state) {
    return state.loading.productTypes
  },

  /**
   * Get loading state for edit project loading
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  editProjectLoading(state) {
    return state.loading.editProject
  },

  /**
   * Get loading state for confirm contact loading
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  confirmContactLoading(state) {
    return state.loading.confirmContact
  },

  /**
   * Get project details
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  projectDetails(state) {
    const projectDetails = cloneDeep(state.projectDetails)

    // Set default thumbnail if has none.
    if (isEmpty(projectDetails.thumbnail_url)) {
      projectDetails.thumbnail_url = imageConstants.projectDefaultThumbnailUrl
    }

    return projectDetails
  },

  /**
   * Get project invoice contacts
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  invoiceContacts(state) {
    return state.projectDetails.invoice_contacts
  },

  /**
   * Get project office contacts
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  officeContacts(state) {
    return state.projectDetails.office_contacts
  },

  /**
   * Get project office.
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  projectOffice(state) {
    return state.projectDetails.office
  },

  /**
   * Get project office url.
   * @param {Object} state - State for this module.
   * @returns {String} - Office Url String
   */
  projectOfficeUrl(state) {
    return `${AppConfig.vmsApiUrl}/offices/${state.projectDetails.office.id}`
  },

  /**
   * Get project company.
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  projectCompany(state) {
    return state.projectDetails.office.company
  },

  /**
   * Get project company url.
   * @param {Object} state - State for this module.
   * @returns {String} - Company Url String
   */
  projectCompanyUrl(state) {
    return `${AppConfig.vmsApiUrl}/companies/${state.projectDetails.office.company.id}`
  },

  /**
   * Get the state of the project form save button
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the save button
   */
  formSaveBtnDisabled(state) {
    return state.projectForm.saveBtnDisabled
  },

  /**
   * Get the state of the project form save button
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The state of the save button
   */
  formSaveBtnLoading(state) {
    return state.loading.formSaveBtn
  },

  /**
   * Get the payload set for the new project form
   *
   * @param {object} state - State for current module.
   * @returns {object} - The new project form payload
   */
  newProjectFormPayload(state) {
    return state.projectForm.newProject
  },

  /**
   * Get the available product types
   *
   * @param {object} state - State for current module.
   * @returns {array} - The list of all available product types
   */
  productTypesList(state) {
    return state.productTypes
  },

  /**
   * Get the edit mode state
   *
   * @param {object} state - the state of the current module
   * @returns {boolean} - the edit mode state
   */
  isEditMode(state) {
    return state.projectForm.isEditMode
  },

  /**
   * Get the project info form invalid state
   *
   * @param {object} state
   * @returns {boolean} - the project info form invalid state
   */
  isProjectInfoFormInvalid(state) {
    return state.projectForm.projectInfoFormInvalid
  },

  /**
   * Get the project brief form invalid state
   *
   * @param {object} state
   * @returns {boolean} - the project brief form invalid state
   */
  isProjectBriefFormInvalid(state) {
    return state.projectForm.projectBriefFormInvalid
  },

  /**
   * Get the payload set for the edit project summary form
   *
   * @param {object} state - the state of the current module
   * @returns {object} - the edit project form payload
   */
  editProjectSummaryFormPayload(state) {
    return state.projectForm.editProjectSummaryPayload
  },

  /**
   * Property to check if product type exist for the project
   * @param {object} state - the state of the current module
   * @returns {boolean} - check if product exist for the project
   */
  hasProjectProductType(state) {
    return (
      state.projectDetails.product_type &&
      state.projectDetails.product_type.id !== null
    )
  },

  /**
   * Check if the project is in draft
   * @param {object} state - the state of the current module
   * @returns {boolean} - check if the project is in draft status
   */
  isProjectDraft(state) {
    return (
      state.projectDetails.project_status.id ===
      VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT
    )
  },

  /**
   * Check if the project is archived
   * @param {object} state - the state of the current module
   * @returns {boolean} - return true if project is archived.
   */
  isProjectArchived(state) {
    return (
      state.projectDetails.project_status.id ===
      VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED
    )
  },

  /**
   * Check if the project is cancelled
   * @param {object} state - the state of the current module
   * @returns {boolean} - check if the project is in cancelled status
   */
  isProjectCancelled(state) {
    return (
      state.projectDetails.project_status.id ===
      VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED
    )
  },

  /**
   * Check if the project is approved
   * @param {object} state - the state of the current module
   * @returns {boolean} - check if the project is in approved status
   */
  isProjectApproved(state) {
    return (
      state.projectDetails.project_status.id ===
      VmsConstants.projectStatuses.PROJECT_STATUS_ID_APPROVED
    )
  },

  /**
   * Check if the project is on hold.
   * @param {object} state - the state of the current module
   * @returns {boolean} - check if the project is on hold status
   */
  isProjectOnHold(state) {
    return (
      state.projectDetails.project_status.id ===
      VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD
    )
  },

  /**
   * Check if production date is missing in any of jobs.
   *
   * @param {object} state - State for current module.
   * @param {array} getters - getters for current module.
   * @param {object} rootState - rootState for current module.
   *
   * @returns {boolean} - The state of missing production date.
   */
  hasProductionDateMissing(state, getters, rootState) {
    const preProductionProductionDateMissing =
      size(
        find(
          rootState['project/pre-production'].preProductions,
          (preProductionItem) => {
            return (
              size(
                find(preProductionItem.items, (item) => {
                  return isNil(item.production_date)
                })
              ) > 0
            )
          }
        )
      ) > 0

    const deliveryProductionDateMissing =
      size(
        find(rootState['project/delivery'].deliveries.data, (deliveryItem) => {
          return isNil(deliveryItem.production_date)
        })
      ) > 0

    const filmingDetailProductionDateMissing =
      size(
        find(
          rootState['project/shoot'].filmingDetails.data,
          (filmingDetailItem) => {
            return isNil(filmingDetailItem.production_date)
          }
        )
      ) > 0

    return (
      preProductionProductionDateMissing ||
      deliveryProductionDateMissing ||
      filmingDetailProductionDateMissing
    )
  },

  /**
   * Get the product type object of the project.
   *
   * @param {object} state - State for current module.
   * @returns {object} - Product Type object
   */
  projectProductType(state) {
    return state.projectDetails.product_type
  },

  /**
   * Check if the project can be reopened
   *
   * @param {object} state - the state of the current module
   * @param {array} getters - getters for current module.
   * @returns {boolean} - check if the project can be reopened
   */
  canReopenProject(state, getters) {
    return (
      getters.isProjectCancelled &&
      getters.projectProductType.id !==
        VmsConstants.productTypes.PRODUCT_TYPE_CREATE
    )
  },

  /**
   * Check if the budget field can be viewed.
   *
   * @param {object} state - the state of the current module
   * @param {array} getters - getters for current module.
   *
   * @returns {boolean} - return true if the budget field can be viewed.
   */
  canViewBudget(state, getters) {
    const hasBudget =
      !isNil(getters.projectDetails.budget) &&
      !isEmpty(getters.projectDetails.budget)

    return getters.isProjectDraft && hasBudget
  },

  /**
   * Check if project can be archive
   * @param {object} getters - getters for current module
   * @returns {boolean} return true if project can be archive
   */
  canArchiveProject(state, getters) {
    return !(
      getters.isProjectCancelled ||
      getters.isProjectArchived ||
      getters.isProjectDraft
    )
  },

  /**
   * Check if project can be put on hold.
   * @param {object} getters - getters for current module
   * @returns {boolean} return true if project can be put on hold
   */
  canHoldProject(state, getters) {
    return !(
      getters.isProjectCancelled ||
      getters.isProjectArchived ||
      getters.isProjectDraft ||
      getters.isProjectApproved
    )
  },

  /**
   * Check if project has orders
   * @param {object} state - the state of the current module
   * @returns {boolean} return true if project has orders
   */
  hasOrders(state) {
    return size(state.projectDetails.project_orders) > 0
  },

  /**
   * Get the total videos in the project.
   *
   * @param {object} state - the state of the current module
   *
   * @returns {number} - returns current total of the project
   */
  projectTotalVideos(state) {
    return state.projectDetails.total_video
  },

  /**
   * Get the state of project menu scrollable.
   *
   * @param {object} state - the state of the current module
   *
   * @returns {number} - returns the state of project menu scrollable
   */
  isProjectMenuScrollable(state) {
    return state.isProjectMenuScrollable
  },

  /*
   * Get loading state of target audience types.
   *
   * @param {Object} state - State for this module.
   *
   * @returns {Boolean}
   */
  targetAudienceTypesLoading(state) {
    return state.loading.targetAudienceTypes
  },

  /**
   * Get list of target audience types.
   *
   * @param {Object} state - State for this module.
   *
   * @returns {Array}
   */
  targetAudienceTypes(state) {
    return state.targetAudienceTypes
  },

  /**
   * Get show state of archive project modal.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isArchiveProjectModalShown(state) {
    return state.modal.archiveProject
  },

  /**
   * Get show state of eod notes project modal.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  showEodNotesModal(state) {
    return state.modal.eodNotes
  },

  /**
   * Get loading state of footage status.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isFootageStatusLoading(state) {
    return state.loading.footageStatus
  },

  /**
   * Get loading state for invoice budget section.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isInvoiceBudgetLoading(state) {
    return state.loading.invoiceBudget
  },

  /**
   * Get if project has budget spreadsheet.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  budgetInfo(state) {
    return state.budgetInfo
  },

  /**
   * Get loading state for hubspot quotes loading
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  isHubspotQuotesLoading(state) {
    return state.loading.hubspotQuotes
  },

  /**
   * Get hubspot quotes data in descending order based on quote created at
   *
   * @param {Object} state - State for this module.
   * @returns {Array}
   */
  hubspotQuotes(state) {
    return orderBy(state.hubspotQuotes, 'quote_created_at', 'desc')
  },

  /**
   * Get loading state for project eod notes.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  eodNotesProjectLoading(state) {
    return state.loading.eodNotesProject
  },

  /**
   * Get loading state for job eod notes.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  eodNotesJobLoading(state) {
    return state.loading.eodNotesJob
  },

  /**
   * Get loading state for pinned eod notes.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  eodNotesPinnedLoading(state) {
    return state.loading.eodNotesPinned
  },

  /**
   * Get loading state for eod job list.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  eodNotesJobListLoading(state) {
    return state.loading.eodNotesJobList
  },

  /**
   * Get loading state for eod save btn.
   *
   * @param {Object} state - State for this module.
   * @returns {Boolean}
   */
  eodNotesSaveBtnLoading(state) {
    return state.loading.eodNotesSaveBtn
  },

  /**
   * Get selected eod note type id.
   *
   * @param {Object} state - State for this module.
   * @returns {Number}
   */
  selectedEodNoteTypeId(state) {
    return state.eodNotes.selectedEodNoteTypeId
  },

  /**
   * Get project job list
   *
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  projectJobs(state) {
    return state.projectJobs
  },

  /**
   * Get project eod notes
   *
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  projectEodNotes(state) {
    return state.eodNotes.project
  },

  /**
   * Get job eod notes
   *
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  jobsEodNotes(state) {
    return state.eodNotes.jobs
  },

  /**
   * Get pinned eod notes
   *
   * @param {Object} state - State for this module.
   * @returns {array}
   */
  pinnedEodNotes(state) {
    return state.eodNotes.pinned
  },

  /**
   * Get eod notes next page url
   *
   * @param {Object} state - State for this module.
   * @returns {string}
   */
  eodNotesNextPageLink(state) {
    return state.eodNotes.nextPageLink
  },

  /**
   * Get eod notes selected project
   *
   * @param {Object} state - State for this module.
   * @returns {Object}
   */
  eodNotesSelectedProject(state) {
    return state.eodNotes.selectedProject
  },

  /**
   * Get production team
   */
  productionTeam(state, getters) {
    const projectDetails = getters.projectDetails

    return projectDetails.production_team || []
  },

  /**
   * Get the state of showEodModal
   *
   * @param {Object} state - State for the current module
   * @param {Object} getters - Getters for this module
   * @returns {Boolean} - the state of shoEodModal
   */
  showEodModal(state, getters) {
    return (
      getters['showEodNotesModal'] &&
      !isEmpty(getters['eodNotesSelectedProject'])
    )
  },
}
