import * as types from '@store/mutation-types'
import { find, remove } from 'lodash'

/**
 * @module store/projects/projects-mutations
 */
export default {
  /**
   * Update loader for the projects list
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECTS_LOADING_PROJECTS_LIST](state, loading) {
    state.loading.projectsList = loading
  },

  /**
   * Update loader for the projects health
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECTS_LOADING_PROJECT_HEALTH](state, loading) {
    state.loading.projectHealth = loading
  },

  /**
   * Update the list of all projects
   *
   * @param {object} state - the state of the current module
   * @param {object} projects - the list of all projects with meta info
   */
  [types.PROJECTS_LIST](state, projects) {
    state.projects = projects
  },

  /**
   * Update the project health
   * @param {object} state - the state of the current module
   * @param {object} payload - the project health payload
   */
  [types.PROJECTS_UPDATE_PROJECT_HEALTH](state, payload) {
    const project = find(state.projects.data, { id: payload.id })
    project.project_health = payload.health
  },

  /**
   * Update the selected status filters
   *
   * @param {object} state - the state of the current module
   * @param {array} filters - array of status filters
   */
  [types.PROJECTS_LIST_FILTER_STATUSES_UPDATE](state, filters) {
    state.projectsFilter.statuses = filters
  },

  /**
   * Update the selected product type filters
   *
   * @param {object} state - the state of the current module
   * @param {array} filters - array of product type filters
   */
  [types.PROJECTS_LIST_FILTER_PRODUCT_TYPE_UPDATE](state, filters) {
    state.projectsFilter.product_types = filters
  },

  /**
   * Update the selected user filters
   *
   * @param {object} state - the state of the current module
   * @param {array} filters - array of user filters
   */
  [types.PROJECTS_LIST_FILTER_USER_UPDATE](state, filters) {
    state.projectsFilter.users = filters
  },

  /**
   * Update the selected project type filter
   *
   * @param {object} state - the state of the current module
   * @param {string} projectType - the selected product type
   */
  [types.PROJECTS_LIST_FILTER_MY_PROJECTS](state, projectType) {
    state.projectsFilter.project_type = projectType
  },

  /**
   * Update the selected search filter
   *
   * @param {object} state - the state of the current module
   * @param {string} searchKeyword - the selected search keyword
   */
  [types.PROJECTS_LIST_FILTER_SEARCH](state, searchKeyword) {
    state.projectsFilter.search = searchKeyword
  },

  /**
   * Update the selected sort filter
   *
   * @param {object} state - the state of the current module
   * @param {string} sort - the selected sort
   */
  [types.PROJECTS_LIST_FILTER_SORT](state, sort) {
    state.projectsFilter.sort = sort
  },

  /**
   * Update the selected sort by filter
   *
   * @param {object} state - the state of the current module
   * @param {string} sortBy - the selected sort by
   */
  [types.PROJECTS_LIST_FILTER_SORT_BY](state, sortBy) {
    state.projectsFilter.sortBy = sortBy
  },

  /**
   * Update the selected from date filter
   *
   * @param {object} state - the state of the current module
   * @param {string} from - the selected from date
   */
  [types.PROJECTS_LIST_FILTER_FROM_DATE](state, from) {
    state.projectsFilter.from = from
  },

  /**
   * Update the selected to date filter
   *
   * @param {object} state - the state of the current module
   * @param {string} to - the selected to date
   */
  [types.PROJECTS_LIST_FILTER_TO_DATE](state, to) {
    state.projectsFilter.to = to
  },

  /**
   * Update the selected per page
   * @param {object} state - the state of the current module
   */
  [types.PROJECTS_LIST_FILTER_PER_PAGE](state, perPage) {
    state.projectsFilter.perPage = perPage
  },

  /**
   * Update the selected current page
   *
   * @param {object} state - the state of the current module
   */

  [types.PROJECTS_LIST_CURRENT_PAGE](state, currentPage) {
    state.currentPage = currentPage
  },

  /**
   * Set the selected project id
   * @param state
   * @param {number} projectId - the selected project ID
   */
  [types.PROJECTS_SELECTED_ID](state, projectId) {
    state.selectedProjectId = projectId
  },

  /**
   * Update a single project when found in the projects list
   * @param {object} state - the state the current module
   * @param {object} projectData - the updated project data
   */
  [types.PROJECTS_UPDATE_SELECTED_PROJECT](state, projectData) {
    const selectedProject = find(state.projects.data, {
      id: projectData.id,
    })

    if (selectedProject) {
      Object.assign(selectedProject, projectData)
    }
  },

  /**
   * Update the array of selected fields for updating inline
   *
   * @param {object} state - the state of the current module
   * @param {object} selectedFieldForUpdating - the payload for updating the array of selected fields for updating
   * @param {number} selectedFieldForUpdating.project_id - the selected project ID for the selected field
   * @param {string} selectedFieldForUpdating.field - the selected field for updating
   */
  [types.PROJECTS_UPDATE_SELECTED_FIELDS](state, selectedFieldForUpdating) {
    state.projectFieldsUpdating.push(selectedFieldForUpdating)
  },

  /**
   * Remove selected field for inline field update that finished saving
   *
   * @param {object} state - the state of the current module
   * @param {object} selectedFieldForUpdating - the payload for updating the array of selected fields for updating
   * @param {number} selectedFieldForUpdating.project_id - the selected project ID for the selected field
   * @param {string} selectedFieldForUpdating.field - the selected field to remove
   */
  [types.PROJECTS_REMOVE_SELECTED_FIELDS](state, selectedFieldForUpdating) {
    remove(state.projectFieldsUpdating, (selectedField) => {
      return (
        selectedField.project_id === selectedFieldForUpdating.project_id &&
        selectedField.field === selectedFieldForUpdating.field
      )
    })
  },

  /**
   * Update the archived project
   *
   * @param {Object} state - state of the current module
   * @param {Object} data - the archived projects data
   */
  [types.PROJECTS_ARCHIVED_PROJECTS](state, data) {
    state.archivedProjects = data
  },
}
