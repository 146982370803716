import firebase from 'firebase/app'
import 'firebase/auth'
import AppConfig from '@configs/app-config'

// Initialize Firebase
firebase.initializeApp(AppConfig.firebase)

// Set persistence for all operations. Ignore testing env.
// Docs: https://firebase.google.com/docs/auth/web/auth-state-persistence#modifying_the_auth_state_persistence
firebase
  .auth()
  .setPersistence(
    process.env.NODE_ENV === 'test'
      ? firebase.auth.Auth.Persistence.NONE
      : firebase.auth.Auth.Persistence.LOCAL
  )
  .catch(() => {
    // Try to set persistence as session.
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .catch(() => {
        // Lastly set persistence to none.
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
      })
  })

export default firebase
