import Axios from '@plugins/axios'

export default (projectId, orderJobId, orderJobChangeId, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/jobs/pre-production/${orderJobId}/changes/${orderJobChangeId}`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.patch(url, payload, config)
}
