import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import router from '@router/router'
import { cloneDeep, has, isNil, merge } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VmsConstants from '@configs/vms-constants'

/**
 * Store actions for deliveries module.
 * @module project/delivery/delivery-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_DELIVERY_RESET_STATE)
  },

  /**
   * Action to set the project deliveries loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setDeliveriesListLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_DELIVERIES_LIST, loading)
  },

  /**
   * Action to set the video product elements loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoProductElementsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_DELIVERIES_PRODUCT_ELEMENTS, loading)
  },

  /**
   * Action to set the hold deliveries loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setHoldDeliveriesLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_DELIVERIES_HOLD, loading)
  },

  /**
   * Action to set the project video products loading state
   *
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setVideoProductsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_DELIVERIES_VIDEO_PRODUCTS, loading)
  },

  /**
   * Action to set the project deliveries
   *
   * @param commit
   * @param {array} deliveries - the deliveries data
   */
  setProjectDeliveriesList({ commit }, deliveries) {
    commit(mutations.PROJECT_DELIVERIES_LIST, deliveries)
  },

  /**
   * Action to set the project video products
   *
   * @param commit
   * @param {array} videoProducts - the video products data
   */
  setProjectVideoProducts({ commit }, videoProducts) {
    commit(mutations.PROJECT_DELIVERIES_VIDEO_PRODUCTS, videoProducts)
  },

  /**
   * Action to set the project deliveries product elements
   *
   * @param commit
   * @param {array} videoProductElements - the video product elements data
   */
  setVideoProductElements({ commit }, videoProductElements) {
    commit(mutations.PROJECT_DELIVERIES_PRODUCT_ELEMENTS, videoProductElements)
  },

  /**
   * Action to get the project deliveries
   *
   * @param {object} commit
   * @param {object} payload - the payload for processing the get video deliveries service
   * @param {boolean} payload.showLoader - state to show or hide the page loader
   */
  getProjectDeliveriesList({ dispatch, getters }, payload) {
    dispatch(
      'setDeliveriesListLoading',
      !isNil(payload.showLoader) ? payload.showLoader : true
    )

    payload = merge(payload, getters['deliveriesPageFilters'])

    payload.perPage = payload.perPage || getters['deliveries'].meta.per_page

    ProjectServices.projects.jobs.videoDeliveries
      .list(payload)
      .then((response) => {
        dispatch('setDeliveriesListLoading', false)

        dispatch('setProjectDeliveriesList', response.data)

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('setDeliveriesListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving project deliveries.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Get video products list
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  getProjectVideoProducts({ dispatch }, payload) {
    dispatch('setVideoProductsLoading', true)

    ProjectServices.products.videos.list
      .videoProducts(payload.projectId, payload.include)
      .then((response) => {
        dispatch('setVideoProductsLoading', false)
        dispatch('setProjectVideoProducts', response.data.data)
      })
      .catch((error) => {
        dispatch('setVideoProductsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video products.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.include,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the add new video delivery job form state
   *
   * @param commit
   * @param {boolean} show - The state of the add video delivery job form
   */
  showAddNewVideoDeliveryJobForm({ commit }, show) {
    commit(mutations.PROJECT_DELIVERIES_ADD_NEW_FORM_OPEN, show)
  },

  /**
   * Update the edit video delivery job form state
   *
   * @param commit
   * @param {boolean} show - The state of the edit video delivery job form
   */
  showEditVideoDeliveryJobForm({ commit }, show) {
    commit(mutations.PROJECT_DELIVERIES_EDIT_FORM_OPEN, show)
  },

  /**
   * Set the selected video product ID
   *
   * @param commit
   * @param {number} videoProductId - The selected video product ID
   */
  setSelectedVideoProductId({ commit }, videoProductId) {
    commit(
      mutations.PROJECT_DELIVERIES_SELECTED_VIDEO_PRODUCT_ID,
      videoProductId
    )
  },

  /**
   * Update the selected video product ID
   *
   * @param commit
   * @param {number} videoProductId - The selected video product ID
   */
  updateSelectedVideoProductId({ dispatch, commit }, payload) {
    commit(
      mutations.PROJECT_DELIVERIES_SELECTED_VIDEO_PRODUCT_ID,
      payload.product_id
    )

    if (payload.product_id && payload.type === 'create') {
      dispatch('showAddNewVideoDeliveryJobForm', true)
    }
  },

  /**
   * Create new video delivery job action
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  createNewVideoDeliveryJob({ dispatch, rootGetters }, payload) {
    const projectId = payload.project_id
    const includes = payload.includes
    const deliveriesPayload = payload.payload

    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })
    dispatch('showAddNewVideoDeliveryJobForm', false)
    dispatch('setBulkAddVideosConfirm', false)

    dispatch(
      'common/displayToastAlert',
      {
        icon: 'info',
        title: `${deliveriesPayload.name} is being processed. We will notify you when ready.`,
      },
      { root: true }
    )

    ProjectServices.projects.jobs.videoDeliveries
      .create(projectId, includes, deliveriesPayload)
      .then(() => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${deliveriesPayload.name} added successfully!`,
          },
          { root: true }
        )

        if (
          rootGetters['project/isProjectArchived'] ||
          rootGetters['project/isProjectApproved']
        ) {
          dispatch(
            'project/getProjectDetails',
            {
              projectId: projectId,
              customIncludes: ApiCustomIncludes.projectDetails,
            },
            { root: true }
          )
        }

        // Set the current page to page 1
        if (
          router.currentRoute.query.page &&
          parseInt(router.currentRoute.query.page) !== 1
        ) {
          router.replace({
            query: {
              page: 1,
            },
          })
        }

        dispatch(
          'project/setTotalDeliveries',
          rootGetters['project/projectTotalVideos'] +
            (deliveriesPayload.bulk_add_videos_quantity ?? 1),
          {
            root: true,
          }
        )

        dispatch('getProjectDeliveriesList', {
          projectId: projectId,
          include: ApiCustomIncludes.deliveriesList,
        })
      })
      .catch((error) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('setDeliveriesListLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error creating new video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              include: payload.includes,
              deliveries_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to get the video product elements
   *
   * @param context
   * @param payload
   */
  getVideoProductElements({ dispatch }, payload) {
    dispatch('setVideoProductElementsLoading', true)

    ProjectServices.products.videos.list
      .videoProductElements(payload.product_id, payload.includes)
      .then((response) => {
        dispatch('setVideoProductElementsLoading', false)
        dispatch('setVideoProductElements', response.data.data)
      })
      .catch((error) => {
        dispatch('setVideoProductElementsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving video product elements.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Update the selected video delivery job ID
   *
   * @param commit
   * @param {Number} videoDeliveryJobId - The selected video delivery job ID
   */
  updateSelectedVideoDeliveryJobId({ commit }, videoDeliveryJobId) {
    commit(
      mutations.PROJECT_DELIVERIES_SELECTED_VIDEO_DELIVERY_JOB_ID,
      videoDeliveryJobId
    )
  },

  /**
   * Create new video delivery job action
   *
   * @param context
   * @param {object} payload - the payload for processing the service
   */
  updateVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const deliveriesPayload = payload.payload

    dispatch('project/updateFormSaveBtnDisabled', true, { root: true })
    dispatch('project/updateFormSaveBtnLoading', true, { root: true })

    ProjectServices.projects.jobs.videoDeliveries
      .update(projectId, orderJobId, includes, deliveriesPayload)
      .then((response) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })

        dispatch(
          'common/displayToastAlert',
          {
            title: `${deliveriesPayload.name} has been updated successfully!`,
          },
          { root: true }
        )

        dispatch('showEditVideoDeliveryJobForm', false)
        dispatch('updateSelectedVideoDeliveryJobId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )

        // Fetch project health data
        dispatch(
          'project/project-health/getProjectHealthList',
          {
            includes: ApiCustomIncludes.projectHealthList,
          },
          {
            root: true,
          }
        )
      })
      .catch((error) => {
        dispatch('project/updateFormSaveBtnDisabled', false, { root: true })
        dispatch('project/updateFormSaveBtnLoading', false, { root: true })
        dispatch('showEditVideoDeliveryJobForm', false)
        dispatch('updateSelectedVideoDeliveryJobId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              includes: payload.includes,
              deliveries_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Approve the selected video delivery job
   *
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  approveVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    ProjectServices.projects.jobs.videoDeliveries
      .approve(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been approved!`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error approving a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Un-approve the selected video delivery job
   *
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  unapproveVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    ProjectServices.projects.jobs.videoDeliveries
      .unapprove(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been unapproved!`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error unapproving a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Delete the selected video delivery job
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  deleteVideoDeliveryJob({ dispatch, getters, rootGetters }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    let filters = cloneDeep(getters['deliveriesPageFilters'])
    let page = 1

    const hasPageQuery = has(router.currentRoute.query, 'page')
    const currentPageQuery = hasPageQuery
      ? parseInt(router.currentRoute.query.page)
      : filters.page
    const currentDeliveries = getters.deliveries

    // Update page route if the current page is greater than 1.
    if (currentPageQuery > 1) {
      page = filters.page =
        currentDeliveries.data.length === 1
          ? currentPageQuery - 1
          : currentPageQuery

      dispatch('setDeliveriesPageFilters', filters)
    }

    ProjectServices.projects.jobs.videoDeliveries
      .delete(projectId, orderJobId, includes)
      .then(() => {
        dispatch(
          'project/setTotalDeliveries',
          rootGetters['project/projectTotalVideos'] - 1,
          {
            root: true,
          }
        )
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} deleted!`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)

        if (hasPageQuery && currentPageQuery !== page) {
          router.replace({
            query: {
              page: page,
            },
          })
        }

        dispatch('getProjectDeliveriesList', {
          projectId: projectId,
          include: ApiCustomIncludes.deliveriesList,
          showLoader: true,
        })
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error deleting a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Archive the selected video delivery job
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  archiveVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    ProjectServices.projects.jobs.videoDeliveries
      .archive(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been archived!`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error archiving a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Set the select all deliveries state
   * @param {object} context
   * @param {boolean} selectAll - the state of the deliveries select all
   */
  setDeliveriesSelectAll({ commit }, selectAll) {
    commit(mutations.PROJECT_DELIVERIES_SELECT_ALL, selectAll)
  },

  /**
   * Set the array of selected delivery ids
   * @param {object} commit
   * @param {array} selectedDeliveryIds - array of selected delivery ids
   */
  setSelectedDeliveryIds({ commit }, selectedDeliveryIds) {
    commit(
      mutations.PROJECT_DELIVERIES_SELECTED_DELIVERIES,
      selectedDeliveryIds
    )
  },

  /**
   * Toggle bulk add videos modal.
   *
   * @param {Object} context
   */
  toggleBulkAddVideosShowModal({ commit }) {
    commit(mutations.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_TOGGLE_SHOW)
  },

  /**
   * Action to set the project deliveries bulk add videos quantity
   *
   * @param commit
   * @param {number} value - quantity value
   */
  setBulkAddVideosQuantity({ commit }, value) {
    commit(mutations.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_QUANTITY, value)
  },

  /**
   * Action to set the project deliveries bulk add videos names
   *
   * @param commit
   * @param {array} array - new names array
   */
  setBulkAddVideosNames({ commit }, array) {
    commit(mutations.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_NAMES, array)
  },

  /**
   * Action to set the project deliveries bulk add videos confirmation
   *
   * @param commit
   * @param {boolean} confirm - true or false
   */
  setBulkAddVideosConfirm({ commit }, confirm) {
    commit(mutations.PROJECT_DELIVERIES_BULK_ADD_VIDEOS_SET_CONFIRM, confirm)
  },

  /**
   * Action to set the selected delivery id
   *
   * @param commit
   * @param {number} id - selected delivery id
   */
  setSelectedDeliveryId({ commit }, id) {
    commit(mutations.PROJECT_DELIVERIES_MODAL_SELECTED_DELIVERY_ID, id)
  },

  /**
   * Toggle video player modal.
   * @param id - selected delivery id to be opened with default null
   * @param commit
   */
  toggleVideoPlayerShowModal({ commit }, id = null) {
    commit(mutations.PROJECT_DELIVERIES_VIDEO_PLAYER_TOGGLE_SHOW)
    commit(mutations.PROJECT_DELIVERIES_MODAL_SELECTED_DELIVERY_ID, id)
  },

  /**
   * Set the deliveries page filters.
   * @param filters - filters for deliveries page.
   * @param commit
   */
  setDeliveriesPageFilters({ commit, getters }, filters) {
    const pageFilters = merge(
      cloneDeep(getters['deliveriesPageFilters']),
      filters
    )

    commit(mutations.PROJECT_DELIVERIES_PAGE_FILTERS, pageFilters)
  },

  /**
   * Link the pre production to a video job
   *
   * @param {Object} context
   * @param {Object} params - params for linking.
   * @param {number} params.project_id - project ID
   * @param {string} params.name - name of the project
   * @param {number} params.video_job_id - video job ID
   * @param {array} params.includes - array of includes
   * @param {array} params.pre_production_ids - array of pre-production job id
   */
  linkPreProduction({ dispatch, commit }, params) {
    const projectId = params.project_id
    const videoJobId = params.video_job_id
    const includes = params.includes ?? []
    const jobName = params.name
    const payload = { pre_production_ids: params.pre_production_ids }

    ProjectServices.projects.jobs.videoDeliveries
      .linkPreProduction(projectId, videoJobId, includes, payload)
      .then((response) => {
        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
        dispatch(
          'common/displayToastAlert',
          {
            title: `${jobName} has been updated!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error linking pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: params,
          },
          { root: true }
        )
      })
  },

  /**
   * Unlink the pre production from a delivery job
   * @param {Object} context
   * @param {Object} payload - payload for unlink.
   * @param {number} payload.project_id - project ID
   * @param {string} payload.name - name of the project
   * @param {number} payload.order_job_id - order job ID
   * @param {array} payload.includes - array of includes
   * @param {object} payload.payload - payload object inside payload
   * @param {array} payload.payload.pre_production_ids - array of pre-production job id
   */
  unlinkPreProduction({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes
    const deliveriesPayload = payload.payload

    ProjectServices.projects.jobs.videoDeliveries
      .unlinkPreProd(projectId, orderJobId, includes, deliveriesPayload)
      .then((response) => {
        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been updated successfully!`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error removing the linked pre-production job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              includes: payload.includes,
              deliveries_payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Hold the selected video delivery job
   *
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  holdVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('setHoldDeliveriesLoading', true)
    dispatch('setSelectedDeliveryId', orderJobId)

    ProjectServices.projects.jobs.videoDeliveries
      .hold(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been successfully put on hold`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)
        dispatch('setSelectedDeliveryId', null)
        dispatch('setHoldDeliveriesLoading', false)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('updateSelectedVideoDeliveryJobId', null)
        dispatch('setHoldDeliveriesLoading', false)
        dispatch('setSelectedDeliveryId', null)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to hold a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Unhold the selected video delivery job
   *
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  unholdVideoDeliveryJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    dispatch('setHoldDeliveriesLoading', true)
    dispatch('setSelectedDeliveryId', orderJobId)

    ProjectServices.projects.jobs.videoDeliveries
      .unhold(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been successfully take off hold`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)
        dispatch('setHoldDeliveriesLoading', false)
        dispatch('setSelectedDeliveryId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )
      })
      .catch((error) => {
        dispatch('updateSelectedVideoDeliveryJobId', null)
        dispatch('setHoldDeliveriesLoading', false)
        dispatch('setSelectedDeliveryId', null)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error trying to take off hold a video delivery job.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * This function is to mark video job as delivered without sending email to client.
   *
   * @param {object} context
   * @param {number} payload.project_id - the project ID of the selected video delivery job
   * @param {number} payload.order_job_id - the id of the selected video delivery job
   * @param {array} payload.includes - array of strings of all custom fields to include
   * @param {string} payload.name - the name of the selected video delivery job
   */
  deliverVideoJob({ dispatch, commit }, payload) {
    const projectId = payload.project_id
    const orderJobId = payload.order_job_id
    const includes = payload.includes

    ProjectServices.projects.jobs.videoDeliveries
      .deliver(projectId, orderJobId, includes)
      .then((response) => {
        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.name} has been marked as delivered!`,
          },
          { root: true }
        )

        dispatch('updateSelectedVideoDeliveryJobId', null)

        commit(
          mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_ITEM,
          response.data.data
        )

        dispatch(
          'project/setProjectStatus',
          {
            id: VmsConstants.projectStatuses.PROJECT_STATUS_ID_VIDEO_DELIVERED,
            name: 'Video Delivered',
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error marking video job as delivered.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.project_id,
              order_job_id: payload.order_job_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * This function is to toggle video start on video delivery section.
   * Notes: video library is not using this action.
   *
   * @param {object} context
   * @param {number} videoJobId - the id of video job
   */
  toggleVideoStar({ commit }, videoJobId) {
    commit(mutations.PROJECT_UPDATE_VIDEO_DELIVERY_JOB_STAR, videoJobId)
  },
}
