import * as types from '@store/mutation-types'

/**
 * @module store/company/mutations
 */

export default {
  /**
   * Update the company offices loading state
   * @param state
   * @param {Boolean} loading - the loading state
   */
  [types.COMPANY_LOADING_OFFICES](state, loading) {
    state.loading.offices = loading
  },

  /**
   * Update the offices list state
   * @param state
   * @param {Array} offices - the offices list
   */
  [types.COMPANY_OFFICES](state, offices) {
    state.offices = offices
  },
}
