export default [
  {
    path: '/calendar',
    name: 'calendar.index',
    component: () =>
      import(
        /* webpackChunkName: "calendar.index" */ '@views/calendar/calendar'
      ),
    meta: {
      title: 'Calendar',
    },
  },
]
