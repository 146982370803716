import * as types from '@store/mutation-types'

/**
 * @module store/project/contact/contact-mutations
 */
export default {
  /**
   * Update loader for the project contacts
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_PROJECT_CONTACTS](state, loading) {
    state.loading.contacts = loading
  },

  /**
   * Toggle the project add contacts list display state
   *
   * @param state
   */
  [types.PROJECT_SUMMARY_SHOW_ADD_CONTACTS_LIST](state) {
    state.showAddContactsList = !state.showAddContactsList
  },

  /**
   * Update the project contacts list
   *
   * @param state
   * @param {array} contacts - the project contacts array
   */
  [types.PROJECT_CONTACTS_LIST](state, contacts) {
    state.projectContacts = contacts
  },

  /**
   * Update the project contacts data based on the selected algolia user
   *
   * @param context
   * @param {object} contact - the contact details from algolia
   */
  [types.PROJECT_NEW_ALGOLIA_CONTACT_SELECTED](state, contact) {
    state.projectContacts[0] = {
      id: contact.user_id,
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
      phone: contact.phone,
      photo_url: contact.photo_url,
      // This contact can not be removed until project is saved.
      is_removable: false,
    }
  },
}
