import VmsConstants from '@configs/vms-constants'

export default Object.freeze({
  preProductionEventTypes: [
    VmsConstants.eventTypes.EVENT_TYPE_ID_PRE_PRODUCTION,
    VmsConstants.eventTypes.EVENT_TYPE_ID_PROJECT_MANAGEMENT,
    VmsConstants.eventTypes.EVENT_TYPE_ID_PRE_PRODUCTION_CHANGE,
  ],
  productionEventsTypes: [
    VmsConstants.eventTypes.EVENT_TYPE_ID_CAMERA_OPERATOR,
    VmsConstants.eventTypes.EVENT_TYPE_ID_SECOND_CAMERA_OPERATOR,
    VmsConstants.eventTypes.EVENT_TYPE_ID_CAMERA_ASSISTANT,
    VmsConstants.eventTypes.EVENT_TYPE_ID_STUDIO,
    VmsConstants.eventTypes.EVENT_TYPE_ID_FIELD_PRODUCER,
    VmsConstants.eventTypes.EVENT_TYPE_ID_DIRECTOR,
    VmsConstants.eventTypes.EVENT_TYPE_ID_DIRECTOR_OF_PHOTOGRAPHY,
    VmsConstants.eventTypes.EVENT_TYPE_ID_GAFFER,
    VmsConstants.eventTypes.EVENT_TYPE_ID_GRIP,
    VmsConstants.eventTypes.EVENT_TYPE_ID_SOUND_ENGINEER,
    VmsConstants.eventTypes.EVENT_TYPE_ID_ADDITIONAL_CAMERA,
    VmsConstants.eventTypes.EVENT_TYPE_ID_TRAVEL,
    VmsConstants.eventTypes.EVENT_TYPE_ID_SHOOTER_PRE_PRODUCTION,
    VmsConstants.eventTypes.EVENT_TYPE_ID_PHOTOGRAPHER,
    VmsConstants.eventTypes.EVENT_TYPE_ID_ASSISTANT,
    VmsConstants.eventTypes.EVENT_TYPE_ID_RUNNER,
  ],
  postProductionEventTypes: [
    VmsConstants.eventTypes.EVENT_TYPE_ID_EDIT,
    VmsConstants.eventTypes.EVENT_TYPE_ID_ANIMATION,
    VmsConstants.eventTypes.EVENT_TYPE_ID_COLOUR_GRADING,
    VmsConstants.eventTypes.EVENT_TYPE_ID_CHANGE,
  ],
  otherEventTypes: [
    VmsConstants.eventTypes.EVENT_TYPE_ID_CHANGE_MANAGEMENT,
    VmsConstants.eventTypes.EVENT_TYPE_ID_MISCELLANEOUS,
    VmsConstants.eventTypes.EVENT_TYPE_ID_NOT_AVAILABLE,
    VmsConstants.eventTypes.EVENT_TYPE_ID_NON_BILLABLE,
  ],
})
