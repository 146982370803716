import AppConfig from '@configs/app-config'

export default [
  {
    path: '/404',
    name: 'error.404',
    beforeEnter() {
      window.location.assign(`${AppConfig.vmsApiUrl}/404`)
    },
    meta: {
      title: 'Page not found',
    },
  },
]
