import actions from './company-actions'
import getters from './company-getters'
import mutations from './company-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/company
 */

/**
 * Get default state for company module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {Object}   loading - States for loaders within the company module.
   * @property {Boolean}  loading.offices - State for the offices loader
   * @property {Array}    offices - Array of objects of offices
   */
  return {
    loading: {
      offices: false,
    },
    offices: [],
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {Object} state - State for current module.
     */
    [types.COMPANY_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
