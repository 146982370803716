import Vue from 'vue'
import Bugsnag from '@bugsnag/browser'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import AppConfig from '@configs/app-config'

// Don't install error handler for testing env (jest).
if (process.env.NODE_ENV !== 'test') {
  Bugsnag.start({
    apiKey: AppConfig.bugsnagToken,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.VUE_APP_RELEASE_STAGE || 'local',
    enabledReleaseStages: ['production', 'staging'],
  })

  Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
}

export default Bugsnag
