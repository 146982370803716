export default Object.freeze({
  jobTypes: {
    FILMING_DETAILS: 'FILMING_DETAILS',
    PRE_PRODUCTIONS: 'PRE_PRODUCTIONS',
    DELIVERIES: 'DELIVERIES',
    JOB_CHANGES: 'JOB_CHANGES',
    PLACEHOLDERS: 'PLACEHOLDERS',
    PRE_PRODUCTION_CHANGES: 'PRE_PRODUCTION_CHANGES',
  },

  // event - jobType mapping
  events: [
    { id: 1, name: 'CAMERA_OPERATOR', jobType: 'FILMING_DETAILS' },
    { id: 2, name: 'EDIT', jobType: 'DELIVERIES' },
    { id: 3, name: 'CHANGES', jobType: 'JOB_CHANGES' },
    { id: 4, name: 'SECOND_CAMERA_OPERATOR', jobType: 'FILMING_DETAILS' },
    { id: 5, name: 'CAMERA_ASSISTANT', jobType: 'FILMING_DETAILS' },
    { id: 6, name: 'FIELD_PRODUCER', jobType: 'FILMING_DETAILS' },
    { id: 9, name: 'PRE_PRODUCTION', jobType: 'PRE_PRODUCTIONS' },
    { id: 16, name: 'NOT_AVAILABLE', jobType: 'PLACEHOLDERS' },
    { id: 17, name: 'STUDIO', jobType: 'FILMING_DETAILS' },
    { id: 18, name: 'ADDITIONAL_CAMERA', jobType: 'FILMING_DETAILS' },
    { id: 31, name: 'TRAVEL', jobType: 'FILMING_DETAILS' },
    { id: 37, name: 'SHOOTER_PRE_PRODUCTION', jobType: 'FILMING_DETAILS' },
    { id: 38, name: 'DIRECTOR', jobType: 'FILMING_DETAILS' },
    { id: 40, name: 'DIRECTOR_OF_PHOTOGRAPHY', jobType: 'FILMING_DETAILS' },
    { id: 41, name: 'GAFFER', jobType: 'FILMING_DETAILS' },
    { id: 30, name: 'PROJECT_MANAGEMENT', jobType: 'PLACEHOLDERS' },
    { id: 34, name: 'ANIMATION', jobType: 'DELIVERIES' },
    { id: 32, name: 'MISCELLANEOUS', jobType: 'PLACEHOLDERS' },
    { id: 35, name: 'NON_BILLABLE', jobType: 'PLACEHOLDERS' },
    { id: 36, name: 'CHANGE_MANAGEMENT', jobType: 'PLACEHOLDERS' },
    { id: 39, name: 'COLOUR_GRADING', jobType: 'DELIVERIES' },
    { id: 42, name: 'GRIP', jobType: 'FILMING_DETAILS' },
    { id: 43, name: 'SOUND_ENGINEER', jobType: 'FILMING_DETAILS' },
    { id: 44, name: 'PHOTOGRAPHER', jobType: 'FILMING_DETAILS' },
    { id: 45, name: 'ASSISTANT', jobType: 'FILMING_DETAILS' },
    { id: 46, name: 'RUNNER', jobType: 'FILMING_DETAILS' },
    {
      id: 47,
      name: 'PRE_PRODUCTION_CHANGE',
      jobType: 'PRE_PRODUCTION_CHANGES',
    },
    { id: 48, name: 'GOOGLE_CALENDAR_EVENT', jobType: 'PLACEHOLDERS' },
    { id: 49, name: 'BAMBOO', jobType: 'PLACEHOLDERS' },
  ],
})
