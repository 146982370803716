import * as types from '@store/mutation-types'
import { isEmpty } from 'lodash'

/**
 * @module store/project/project-mutations
 */
export default {
  /**
   * Update loader for the project details
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_PROJECT_DETAILS](state, loading) {
    state.loading.projectDetails = loading
  },

  /**
   * Update loader for the create new project
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_CREATE_NEW_PROJECT](state, loading) {
    state.loading.createNewProject = loading
  },

  /**
   * Update loader for the product type list
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_PRODUCT_TYPES](state, loading) {
    state.loading.productTypes = loading
  },

  /**
   * Update loader for the edit project
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_EDIT_PROJECT](state, loading) {
    state.loading.editProject = loading
  },

  /**
   * Update loader for the confirm contact
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.PROJECT_LOADING_CONFIRM_CONTACT](state, loading) {
    state.loading.confirmContact = loading
  },

  /**
   * Update the project details object
   * @param {object} state - state of the current module
   * @param {object} projectDetails - the project details
   */
  [types.PROJECT_DETAILS_LIST](state, projectDetails) {
    Object.assign(state.projectDetails, {
      id: projectDetails.id,
      name: projectDetails.name,
      due_date: projectDetails.due_date,
      is_manual_deadline: projectDetails.is_manual_deadline,
      is_hard_deadline: projectDetails.is_hard_deadline,
      budget: projectDetails.budget,
      video_type: projectDetails.video_type,
      platform: projectDetails.platform,
      topic: projectDetails.topic,
      target_audience: projectDetails.target_audience,
      target_audience_types: projectDetails.target_audience_types,
      video_example: projectDetails.video_example,
      goals: projectDetails.goals,
      additional_notes: projectDetails.additional_notes,
      owner: projectDetails.owner,
      total_video: projectDetails.total_video,
      total_hero_videos: projectDetails.total_hero_videos,
      thumbnail_url: projectDetails.thumbnail_url,
      total_price: projectDetails.total_price,
      is_rebookable: projectDetails.is_rebookable,
      has_draft_order: projectDetails.has_draft_order,
      created_at: projectDetails.created_at,
      invoice_contacts: projectDetails.invoice_contacts,
      office_contacts: projectDetails.office_contacts,
      office_id: projectDetails.office_id,
      account_manager_id: projectDetails.account_manager_id,
      producer_id: projectDetails.producer_id,
      director_id: projectDetails.director_id,
      production_coordinator_id: projectDetails.production_coordinator_id,
      conversation_id: projectDetails.conversation_id,
      start_date: projectDetails.start_date,
      budgeted_hours: projectDetails.budgeted_hours,
      notes: projectDetails.notes,
      is_project_brief: projectDetails.is_project_brief,
      project_brief_sent: projectDetails.project_brief_sent,
      project_status_id: projectDetails.project_status_id,
      product_type: projectDetails.product_type,
      is_contact_confirmed: projectDetails.is_contact_confirmed,
      video_privacy: projectDetails.video_privacy,
      footage_policy_type_id: projectDetails.footage_policy_type_id,
      footage_policy_notes: projectDetails.footage_policy_notes || '',
      footage_status_type_id: projectDetails.footage_status_type_id,
      hubspot_deal_url: projectDetails.hubspot_deal_url,
      has_eod_notes: projectDetails.has_eod_notes,
      created_by_id: projectDetails.created_by_id,
      updated_by_id: projectDetails.updated_by_id,
      deleted_by_id: projectDetails.deleted_by_id,
      updated_at: projectDetails.updated_at,
      deleted_at: projectDetails.deleted_at,
    })

    // Check if office is included in details before trying to update it.
    if (projectDetails.office) {
      state.projectDetails.office = projectDetails.office
    }

    // Check if project status is included in details before trying to update it.
    if (projectDetails.project_status) {
      state.projectDetails.project_status = projectDetails.project_status
    }

    // Check if project_orders is included in details before trying to update it.
    if (projectDetails.project_orders) {
      state.projectDetails.project_orders = projectDetails.project_orders
    }

    // Check if RM info is provided before updating the store.
    if (projectDetails.account_manager) {
      state.projectDetails.account_manager = projectDetails.account_manager
    }

    // Check if Producer info is provided before updating the store.
    if (projectDetails.producer) {
      state.projectDetails.producer = projectDetails.producer
    }

    // Check if Director info is provided before updating the store.
    if (projectDetails.director) {
      state.projectDetails.director = projectDetails.director
    }

    // Check if Production Coordinator info is provided before updating the store.
    if (projectDetails.production_coordinator) {
      state.projectDetails.production_coordinator =
        projectDetails.production_coordinator
    }

    // Check if Production team info is provided before updating the store.
    if (projectDetails.production_team) {
      state.projectDetails.production_team = projectDetails.production_team
    }
  },

  /**
   * Update the state of the save button in the project form
   * @param state
   * @param disabled - The state of the save button
   */
  [types.PROJECT_FORM_SAVE_BTN_DISABLED](state, disabled) {
    state.projectForm.saveBtnDisabled = disabled
  },

  /**
   * Update the state of the project form save button loading
   * @param state
   * @param disabled - The state of the save button
   */
  [types.PROJECT_FORM_SAVE_BTN_LOADING](state, loading) {
    state.loading.formSaveBtn = loading
  },

  [types.PROJECT_NEW_UPDATE_DETAILS_FROM_ALGOLIA](state, algoliaPayload) {
    state.projectDetails.office.id = algoliaPayload.office.id
    state.projectDetails.office.name = algoliaPayload.office.name
    state.projectDetails.office.company_id = algoliaPayload.office.company_id
    state.projectDetails.office.company.id = algoliaPayload.office.company_id
    state.projectDetails.office.company.name =
      algoliaPayload.office.company_name
  },

  /**
   * Update the total price on project details.
   * @param {object} state - state of the current module
   * @param {string} price - the new price
   */
  [types.PROJECT_DETAILS_TOTAL_PRICE](state, price) {
    state.projectDetails.total_price = price
  },

  /**
   * Update the new project form payload
   * @param state
   * @param payload - The new project form payload
   */
  [types.PROJECT_FORM_NEW_PROJECT_PAYLOAD_UPDATE](state, payload) {
    state.projectForm.newProject.name =
      payload.name || state.projectForm.newProject.name
    state.projectForm.newProject.product_type_id =
      payload.product_type_id || state.projectForm.newProject.product_type_id
    state.projectForm.newProject.account_manager_id =
      payload.account_manager_id ||
      state.projectForm.newProject.account_manager_id
    state.projectForm.newProject.producer_id =
      payload.producer_id || state.projectForm.newProject.producer_id
    state.projectForm.newProject.contact_id =
      payload.contact_id || state.projectForm.newProject.contact_id
    state.projectForm.newProject.office_id =
      payload.office_id || state.projectForm.newProject.office_id
    state.projectForm.newProject.budgeted_hours =
      payload.budgeted_hours || state.projectForm.newProject.budgeted_hours
    state.projectForm.newProject.video_privacy =
      payload.video_privacy ?? state.projectForm.newProject.video_privacy
  },

  /**
   * Update the product types array
   * @param state
   * @param productTypes - The array of product types
   */
  [types.PROJECT_PRODUCT_TYPES_LIST](state, productTypes) {
    state.productTypes = productTypes
  },

  /**
   * Update the edit mode state
   *
   * @param state
   * @param {boolean} isEditMode - the edit mode state
   */
  [types.PROJECT_SUMMARY_IS_EDIT_MODE](state, isEditMode) {
    state.projectForm.isEditMode = isEditMode
  },

  /**
   * Update the project summary info form invalid state
   *
   * @param state
   * @param {boolean} invalid - the state of the project info form
   */
  [types.PROJECT_SUMMARY_INFO_INVALID_UPDATE](state, invalid) {
    state.projectForm.projectInfoFormInvalid = invalid
  },

  /**
   * Update the project summary brief form invalid state
   * @param state
   * @param {boolean} invalid - the state of the project brief form
   */
  [types.PROJECT_SUMMARY_BRIEF_INVALID_UPDATE](state, invalid) {
    state.projectForm.projectBriefFormInvalid = invalid
  },

  /**
   * Update the project info edit form payload
   *
   * @param state
   * @param {object} payload - the project info edit payload
   */
  [types.PROJECT_SUMMARY_EDIT_PAYLOAD_INFO_UPDATE](state, payload) {
    state.projectForm.editProjectSummaryPayload.name =
      payload.name || state.projectForm.editProjectSummaryPayload.name

    state.projectForm.editProjectSummaryPayload.account_manager_id =
      payload.account_manager_id ||
      state.projectForm.editProjectSummaryPayload.account_manager_id

    state.projectForm.editProjectSummaryPayload.producer_id =
      payload.producer_id ||
      state.projectForm.editProjectSummaryPayload.producer_id

    // If budgeted_hours is set to 0, by default we will send the data as null
    if (
      isEmpty(payload.budgeted_hours) ||
      (payload.budgeted_hours && parseInt(payload.budgeted_hours) === 0)
    ) {
      state.projectForm.editProjectSummaryPayload.budgeted_hours = null
    }
  },

  /**
   * Update the project total video count
   *
   * @param state
   * @param {number} total - the new total videos in a project
   */
  [types.PROJECT_DETAILS_TOTAL_DELIVERIES](state, total) {
    state.projectDetails.total_video = total
  },

  /**
   * Update the project menu scrollable state
   *
   * @param state
   * @param {boolean} isScrollable - the new value to set if project menu is scrollable
   */
  [types.IS_PROJECT_MENU_SCROLLABLE](state, isScrollable) {
    state.isProjectMenuScrollable = isScrollable
  },

  /*
   * Set target audience type loading state.
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.TARGET_AUDIENCE_TYPES_LOADING](state, loading) {
    state.loading.targetAudienceTypes = loading
  },

  /**
   * Set target audience types.
   *
   * @param {object} state - state of the current module
   * @param {array} data - list of target audience types
   */
  [types.TARGET_AUDIENCE_TYPES](state, data) {
    state.targetAudienceTypes = data
  },

  /**
   * Set project summary form payload
   *
   * @param {object} state - state of the current module
   * @param {array} data - project form data
   */
  [types.EDIT_PROJECT_SUMMARY_FORM_PAYLOAD](state, data) {
    state.projectForm.editProjectSummaryPayload = data
  },

  /**
   * Update the project total hero videos count
   *
   * @param state
   * @param {number} total - the new total hero videos in a project
   */
  [types.PROJECT_DETAILS_TOTAL_HERO_VIDEOS](state, total) {
    state.projectDetails.total_hero_videos = total
  },

  /**
   * Update archive project modal show state.
   *
   * @param {Object} state
   * @param {Boolean} show
   */
  [types.PROJECT_ARCHIVE_MODAL_SHOW](state, show) {
    state.modal.archiveProject = show
  },

  /**
   * Update eod notes project modal show state.
   *
   * @param {Object} state
   * @param {Boolean} show
   */
  [types.PROJECT_EOD_NOTES_MODAL_SHOW](state, show) {
    state.modal.eodNotes = show
  },

  /**
   * Update archive project modal show state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.PROJECT_FOOTAGE_STATUS_LOADING](state, loading) {
    state.loading.footageStatus = loading
  },

  /**
   * Update loading state of invoice budget section.
   *
   * @param {Object} state
   * @param {boolean} isLoading
   */
  [types.PROJECT_LOADING_INVOICE_BUDGET](state, isLoading) {
    state.loading.invoiceBudget = isLoading
  },

  /**
   * Update budget info.
   *
   * @param {Object} state
   * @param {Object} budgetInfo
   */
  [types.PROJECT_INVOICE_BUDGET](state, budgetInfo) {
    state.budgetInfo = budgetInfo
  },

  /**
   * Update hubspot quotes loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.PROJECT_HUBSPOT_QUOTES_LOADING](state, loading) {
    state.loading.hubspotQuotes = loading
  },

  /**
   * Update hubspot quotes data.
   *
   * @param {Object} state
   * @param {Array} hubspotQuotes
   */
  [types.PROJECT_HUBSPOT_QUOTES](state, hubspotQuotes) {
    state.hubspotQuotes = hubspotQuotes
  },

  /**
   * Update project eod loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.EOD_NOTES_PROJECT_LOADING](state, loading) {
    state.loading.eodNotesProject = loading
  },

  /**
   * Update job eod loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.EOD_NOTES_JOB_LOADING](state, loading) {
    state.loading.eodNotesJob = loading
  },

  /**
   * Update pinned eod loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.EOD_NOTES_PINNED_LOADING](state, loading) {
    state.loading.eodNotesPinned = loading
  },

  /**
   * Update eod job list loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.EOD_NOTES_JOB_LIST_LOADING](state, loading) {
    state.loading.eodNotesJobList = loading
  },

  /**
   * Update eod save button loading state.
   *
   * @param {Object} state
   * @param {Boolean} loading
   */
  [types.EOD_NOTES_SAVE_BTN_LOADING](state, loading) {
    state.loading.eodNotesSaveBtn = loading
  },

  /**
   * Update project jobs list.
   *
   * @param {Object} state
   * @param {Array} jobList
   */
  [types.PROJECT_JOBS_LIST](state, jobList) {
    state.projectJobs = jobList
  },

  /**
   * Update project eod notes.
   *
   * @param {Object} state
   * @param {Array} notes
   */
  [types.PROJECT_EOD_NOTES](state, notes) {
    state.eodNotes.project = notes
  },

  /**
   * Update job eod notes.
   *
   * @param {Object} state
   * @param {Array} notes
   */
  [types.JOBS_EOD_NOTES](state, notes) {
    state.eodNotes.jobs = notes
  },

  /**
   * Update pinned eod notes.
   *
   * @param {Object} state
   * @param {Array} notes
   */
  [types.PINNED_EOD_NOTES](state, notes) {
    state.eodNotes.pinned = notes
  },

  /*
   * Update eod notes selected type id.
   *
   * @param {Object} state
   * @param {number} id
   */
  [types.EOD_NOTES_SELECTED_TYPE_ID](state, id) {
    state.eodNotes.selectedEodNoteTypeId = id
  },

  /*
   * Update eod next page link.
   *
   * @param {Object} state
   * @param {String} link
   */
  [types.EOD_NOTES_NEXT_PAGE_LINK](state, link) {
    state.eodNotes.nextPageLink = link
  },

  /*
   * Update eod selected project.
   *
   * @param {Object} state
   * @param {Object} project
   */
  [types.EOD_NOTES_SELECTED_PROJECT](state, project) {
    state.eodNotes.selectedProject = project
  },

  /*
   * Update project status.
   *
   * @param {Object} state
   * @param {Object} projectStatus
   */
  [types.PROJECT_DETAILS_PROJECT_STATUS](state, projectStatus) {
    state.projectDetails.project_status = projectStatus
    state.projectDetails.project_status_id = projectStatus.id
  },
}
