import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'
import { assign, map } from 'lodash'

/**
 * @module store/user/actions
 */

/**
 * Store actions for user module.
 * @module user/actions
 */
export default {
  /**
   * Reset state for current store module.
   * @param {Object} - Context
   */
  resetState({ commit }) {
    commit(mutations.USER_RESET_STATE)
  },

  /**
   * Action to set the producers list loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProducersListLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_PRODUCERS_LIST, loading)
  },

  /**
   * Action to set the account managers loading state
   * @param commit
   * @param {array} loading - the loading state
   */
  setAccountManagersListLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_ACCOUNT_MANAGERS_LIST, loading)
  },

  /**
   * Action to set the user details loading state
   * @param commit
   * @param {array} loading - the loading state
   */
  setUserDetailsLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_USER_DETAILS, loading)
  },

  /**
   * Action to set the contact loading state
   * @param commit
   * @param {array} loading - the loading state
   */
  setContactLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_CONTACT, loading)
  },

  /**
   * Update the producers list
   *
   * @param context
   * @param {array} includes - array of includes
   */
  getProducersList({ commit, dispatch }, includes) {
    dispatch('setProducersListLoading', true)

    ApiServices.users.producers
      .list(includes)
      .then((response) => {
        dispatch('setProducersListLoading', false)
        commit('USER_PRODUCERS_LIST', response.data.data)
      })
      .catch((error) => {
        dispatch('setProducersListLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving Producers list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Update the account managers
   *
   * @param context
   * @param {array} includes - array of includes
   */
  getAccountManagers({ commit, dispatch }, includes) {
    dispatch('setAccountManagersListLoading', true)

    ApiServices.users.accountManagers
      .list(includes)
      .then((response) => {
        dispatch('setAccountManagersListLoading', false)
        commit('USER_ACCOUNT_MANAGERS_LIST', response.data.data)
      })
      .catch((error) => {
        dispatch('setAccountManagersListLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving Account Managers list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to toggle the contact form modal display state
   *
   * @param context
   */
  toggleContactFormShow({ commit }) {
    commit(mutations.USER_MODAL_CONTACT_FORM_SHOW)
  },

  /**
   * Action to set selected user id
   *
   * @param context
   * @param {number} userId - user id
   */
  setSelectedUserId({ commit }, userId) {
    commit(mutations.USER_SELECTED_USER_ID, userId)
  },

  /**
   * Action to set the user details
   *
   * @param context
   * @param {object} user - user details object
   */
  setUserDetails({ commit }, user) {
    commit(mutations.USER_DETAILS, user)
  },

  /**
   * Action to get the user details
   *
   * @param context
   * @param {object} payload
   */
  getUserDetails({ dispatch }, payload) {
    dispatch('setUserDetailsLoading', true)

    ApiServices.users
      .get(payload.userId, payload.includes)
      .then((response) => {
        dispatch('setUserDetailsLoading', false)
        dispatch('setUserDetails', response.data.data)
      })
      .catch((error) => {
        dispatch('setUserDetailsLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching user details',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to create new contact
   *
   * @param context
   * @param {object} payload
   */
  createNewContact({ dispatch, rootGetters }, payload) {
    dispatch('setContactLoading', true)

    ApiServices.users.contact
      .create(payload.includes, payload.payload)
      .then((response) => {
        const contact = response.data.data

        // when projectId is available the new contact is also assigned to the project
        // This will update the project contact list
        if (payload.payload.project_id) {
          // add is_removable custom property
          assign(contact, {
            is_removable: true,
          })
          // get existing contact list
          const currentList = rootGetters['project/contact/projectContacts']
          // add contact to existing list
          const contactList = [...currentList, contact]
          // update the project contact list state
          dispatch('project/contact/setProjectContactsList', contactList, {
            root: true,
          })
        }

        dispatch('setContactLoading', false)
        dispatch(
          'common/displayToastAlert',
          {
            title: 'New contact was successfully added',
          },
          { root: true }
        )
        dispatch('toggleContactFormShow')
      })
      .catch((error) => {
        dispatch('setContactLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding a new project contact.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to update contact details
   *
   * @param context
   * @param {object} payload
   */
  updateContactDetails({ dispatch, rootGetters }, payload) {
    dispatch('setContactLoading', true)

    ApiServices.users.contact
      .update(payload.includes, payload.payload)
      .then((response) => {
        // when projectId is available the new contact is also assigned to the project
        // This will update the project contact list
        if (payload.payload.project_id) {
          const newContact = response.data.data
          const currentList = [
            ...rootGetters['project/contact/projectContacts'],
          ]
          // find the updated contact and replace it
          const contactList = map(currentList, (contact) => {
            return contact.id === newContact.id
              ? assign({}, contact, newContact)
              : contact
          })
          // update the project contact list state
          dispatch('project/contact/setProjectContactsList', contactList, {
            root: true,
          })
        }

        dispatch('setContactLoading', false)
        dispatch('setSelectedUserId', null)
        dispatch('setUserDetails', null)
        dispatch(
          'common/displayToastAlert',
          {
            title: 'Contact details were successfully updated',
          },
          { root: true }
        )
        dispatch('toggleContactFormShow')
      })
      .catch((error) => {
        dispatch('setContactLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error updating contact details',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the users list loading state
   *
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setPreProductionUsersLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_PRE_PRODUCTION_USERS, loading)
  },

  /**
   * Action to set the users list
   *
   * @param commit
   * @param {array} list - the users list
   */
  setPreProductionUsers({ commit }, list) {
    commit(mutations.USER_PRE_PRODUCTION_USERS, list)
  },

  /**
   * Action to get the users list
   *
   * @param context
   * @param {object} payload - payload used to fetch the users list
   */
  getPreProductionUsers({ dispatch }, payload) {
    dispatch('setPreProductionUsersLoading', true)

    ApiServices.users.preProductionUsers
      .list(payload.includes, payload.payload)
      .then((response) => {
        dispatch('setPreProductionUsersLoading', false)
        dispatch('setPreProductionUsers', response.data.data)
      })
      .catch((error) => {
        dispatch('setPreProductionUsersLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching pre-production users.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the loading teams state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setLoadingTeams({ commit }, loading) {
    commit(mutations.USER_LOADING_TEAMS, loading)
  },

  /**
   * Action to get the teams list
   * @param context
   * @param {object} payload - payload used to fetch the teams list
   */
  getTeams({ commit, dispatch }, payload) {
    dispatch('setLoadingTeams', true)

    ApiServices.teams
      .list(payload.include)
      .then((response) => {
        dispatch('setLoadingTeams', false)
        commit('USER_TEAMS', response.data.data)
      })
      .catch((error) => {
        dispatch('setLoadingTeams', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching teams.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the loading internal users state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setInternalUsersLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_INTERNAL_USERS, loading)
  },

  /**
   * Action to get the internal users list
   * @param context
   * @param {object} payload - payload used to fetch the internal users list
   */
  getInternalUsers({ commit, dispatch }, payload) {
    dispatch('setInternalUsersLoading', true)

    ApiServices.users.internal
      .list(payload)
      .then((response) => {
        dispatch('setInternalUsersLoading', false)
        commit(mutations.USER_INTERNAL_USERS, response.data.data)
      })
      .catch((error) => {
        dispatch('setInternalUsersLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching internal users.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the loading state of the user google authentication
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setUserGoogleAuthenticationLoading({ commit }, loading) {
    commit(mutations.USER_LOADING_GOOGLE_AUTHENTICATION, loading)
  },

  /**
   * Action to authorise the user's Google Calendar
   * @param context
   * @param {number} userId - the user id
   */
  authoriseGoogleCalendar({ dispatch }, userId) {
    dispatch('setUserGoogleAuthenticationLoading', true)

    ApiServices.users.googleCalendar
      .authorise(userId)
      .then((response) => {
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url
        } else {
          dispatch('setUserGoogleAuthenticationLoading', false)

          dispatch(
            'common/displayErrorMessage',
            {
              message:
                'There was an error when authenticating your Google Calendar.',
            },
            {
              root: true,
            }
          )
        }
      })
      .catch((error) => {
        dispatch('setUserGoogleAuthenticationLoading', false)

        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error authorising Google Calendar',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              user_id: userId,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Get list of users who own at least 1 project
   *
   * @param context
   * @param {array} includes - array of includes
   */
  getProjectOwners({ commit, dispatch }, includes) {
    ApiServices.users.projectOwners
      .list(includes)
      .then((response) => {
        commit('USER_PROJECT_OWNERS_LIST', response.data.data)
      })
      .catch((error) => {
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })

        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving project owners list.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              include: includes,
            },
          },
          { root: true }
        )
      })
  },
}
