import Axios from '@plugins/axios'

export default (projectId, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/orders/production-cost`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.post(url, payload, config)
}
