import Vue from 'vue'
import Vuex from 'vuex'
import CreateLogger from 'vuex/dist/logger'
import commonModule from './common/common-module'
import userModule from './user/user-module'
import projectModule from './project/project-module'
import preProductionModule from './project/pre-production/pre-production-module'
import projectHealthModule from './project/project-health/project-health-module'
import shootModule from './project/shoot/shoot-module'
import deliveryModule from './project/delivery/delivery-module'
import productionNoteModule from './project/production-note/production-note-module'
import activityLogModule from './project/activity-log/activity-log-module'
import attachmentModule from './project/attachment/attachment-module'
import invoiceModule from './project/invoice/invoice-module'
import authModule from './auth/auth-module'
import contactModule from './project/contact/contact-module'
import projectsModule from './projects/projects-module'
import videosModule from './videos/videos-module'
import companyModule from './company/company-module'
import roleModule from './role/role-module'
import footageStatusTypeModule from './footage-status-type/footage-status-type-module'
import mediaManagerModule from './media-manager/media-manager-module'
import calendarModule from './calendar/calendar-module'
import scheduleModule from './project/schedule/schedule-module'

Vue.use(Vuex)

const debug =
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'

/**
 * Modules part of this store.
 */
const modules = {
  common: commonModule,
  user: userModule,
  project: projectModule,
  'project/pre-production': preProductionModule,
  'project/project-health': projectHealthModule,
  'project/shoot': shootModule,
  'project/delivery': deliveryModule,
  'project/production-note': productionNoteModule,
  'project/activity-log': activityLogModule,
  'project/attachment': attachmentModule,
  'project/invoice': invoiceModule,
  'project/contact': contactModule,
  'project/schedule': scheduleModule,
  auth: authModule,
  projects: projectsModule,
  videos: videosModule,
  company: companyModule,
  role: roleModule,
  'footage-status-type': footageStatusTypeModule,
  'media-manager': mediaManagerModule,
  calendar: calendarModule,
}

export const storeConfig = {
  modules,
  actions: {
    ...{
      /**
       * Will reset store globally by looping each module and calling the reset action if exists.
       */
      resetStateGlobally({ dispatch }) {
        for (const currentModule in modules) {
          dispatch(`${currentModule}/resetState`)
        }
      },
    },
  },
  strict: debug,
  plugins: debug ? [CreateLogger()] : [],
}

export default new Vuex.Store(storeConfig)
