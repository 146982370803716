import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'

/**
 * @module store/footage-status-type/footage-status-type-actions
 */

/**
 * Store actions for footage-status-type module.
 * @module footage-status-type/actions
 */
export default {
  /**
   * Reset state for current store module.
   *
   * @param {object} - Context
   */
  resetState({ commit }) {
    commit(mutations.FOOTAGE_STATUS_TYPE_RESET_STATE)
  },

  /**
   * Set the loading state for the footage status types
   *
   * @param context
   * @param {boolean} isLoading - the loading state for the footage status types
   */
  setFootageStatusTypesLoading({ commit }, isLoading) {
    commit(mutations.FOOTAGE_STATUS_TYPES_IS_LOADING, isLoading)
  },

  /**
   * Action to get footage status types
   *
   * @param context
   */
  getFootageStatusTypes({ commit, dispatch }) {
    dispatch('setFootageStatusTypesLoading', true)

    ApiServices.footageStatusTypes
      .list()
      .then((response) => {
        dispatch('setFootageStatusTypesLoading', false)
        commit(mutations.FOOTAGE_STATUS_TYPES_LIST, response.data.data)
      })
      .catch((error) => {
        dispatch('setFootageStatusTypesLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error fetching footage status types',
            severity: 'error',
            error: error.response ? error.response : error.message,
          },
          { root: true }
        )
      })
  },
}
