export default [
  {
    path: '/media-manager',
    name: 'media-manager.list',
    component: () =>
      import(
        /* webpackChunkName: "media-manager.list" */ '@views/media-manager/media-manager'
      ),
    meta: {
      title: 'Media Manager',
    },
  },
]
