import * as types from '@store/mutation-types'

/**
 * @module store/videos/videos-mutations
 */
export default {
  /**
   * Update loader for the video tags
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.VIDEOS_LOADING_VIDEO_TAGS](state, loading) {
    state.loading.videoTags = loading
  },

  /**
   * Update loader for the video styles
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.VIDEOS_LOADING_VIDEO_STYLES](state, loading) {
    state.loading.videoStyles = loading
  },

  /**
   * Update loader for the video industries
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.VIDEOS_LOADING_VIDEO_INDUSTRIES](state, loading) {
    state.loading.videoIndustries = loading
  },

  /**
   * Update loader for the video information
   * @param {object} state
   * @param {boolean} loading - The new loader state
   */
  [types.VIDEOS_LOADING_VIDEO_INFORMATION](state, loading) {
    state.loading.videoInformation = loading
  },

  /**
   * Update selected modal video id
   * @param {object} state
   * @param {number} id - Id of selected video
   */
  [types.VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO_ID](state, id) {
    state.modal.videoInformation.selectedVideoId = id
  },

  /**
   * Update state of selected video
   * @param {Object} state - This module state.
   */
  [types.VIDEOS_VIDEO_INFORMATION_SELECTED_VIDEO](state, video) {
    state.modal.videoInformation.selectedVideo = video
    state.modal.videoInformation.selectedVideoId = video.id
  },

  /**
   * Update the video tags list
   * @param {object} state - the state of the current module.
   * @param {array} videoTags - the array of the project video tags
   */
  [types.VIDEOS_VIDEO_TAGS](state, videoTags) {
    state.modal.videoInformation.options.tags = videoTags
  },

  /**
   * Update the video industry list
   * @param {object} state - the state of the current module.
   * @param {array} videoIndustry - the array of the project video industry
   */
  [types.VIDEOS_VIDEO_INDUSTRY](state, videoIndustry) {
    state.modal.videoInformation.options.industry = videoIndustry
  },

  /**
   * Update the video styles list
   * @param {object} state - the state of the current module.
   * @param {array} videoStyles - the array of the project video styles
   */
  [types.VIDEOS_VIDEO_STYLE](state, videoStyles) {
    state.modal.videoInformation.options.styles = videoStyles
  },

  /**
   * Toggle video information modal
   * @param {Object} state
   */
  [types.VIDEOS_VIDEO_INFORMATION_TOGGLE_SHOW](state) {
    state.modal.videoInformation.show = !state.modal.videoInformation.show
  },

  /**
   * Update state of the video preview description modal
   * @param {Object} state - This module state.
   */
  [types.VIDEOS_MODAL_PREVIEW_DESCRIPTION_TOGGLE_SHOW](state) {
    state.modal.previewDescription = !state.modal.previewDescription
  },

  /**
   * Update the state of the filter list modal
   * @param {Object} state - This module state.
   * @param {String} selectedFilterModal - the selected filter type
   */
  [types.VIDEOS_MODAL_FILTER_LIST_TOGGLE_SHOW](state, selectedFilterModal) {
    state.modal.filterList.show = !state.modal.filterList.show
    state.modal.filterList.selectedFilterModal = selectedFilterModal
  },

  /**
   * Update display state of video library filters
   */
  [types.VIDEOS_VIDEO_LIBRARY_FILTERS_TOGGLE_SHOW](state) {
    state.videoLibraryFilters.show = !state.videoLibraryFilters.show
  },
}
