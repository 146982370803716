import actions from './auth-actions'
import getters from './auth-getters'
import mutations from './auth-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/auth
 */

/**
 * Get default state for auth module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {Object} firebase - Some useful user data from firebase.
   * @property {String} firebase.uid - Firebase UID.
   * @property {String} firebase.email
   * @property {String} firebase.displayName
   * @property {String} firebase.photoUrl
   * @property {String} firebase.accessToken - Token used for authentication.
   * @property {Boolean} firebase.loading - The firebase loading state
   * @property {Object} vms - Some useful user data from VMS.
   * @property {Number} vms.id - User ID.
   * @property {Number} vms.user_conversation_status_id - User conversation status ID.
   * @property {String} vms.first_name
   * @property {String} vms.last_name
   * @property {String} vms.full_name
   * @property {String} vms.email
   * @property {String} vms.phone
   * @property {Number} vms.state_id
   * @property {String} vms.staff_title
   * @property {String} vms.photo_url
   * @property {String} vms.calendar_color
   * @property {String} vms.status
   * @property {Boolean} vms.is_admin
   * @property {Number} vms.created_at
   * @property {Number} vms.updated_at
   * @property {Array} vms.roles - User roles.
   * @property {Array} vms.permissions - User permissions.
   * @property {Boolean} vms.has_google_calendar_integration - Whether the user has Google Calendar integration.
   */
  return {
    firebase: {
      uid: '',
      email: '',
      displayName: '',
      photoUrl: '',
      accessToken: '',
      loading: false,
    },
    vms: {
      id: null,
      user_conversation_status_id: null,
      first_name: '',
      last_name: '',
      full_name: '',
      email: '',
      phone: '',
      state_id: null,
      staff_title: '',
      photo_url: '',
      calendar_color: '',
      status: '',
      is_admin: false,
      created_at: null,
      updated_at: null,
      roles: [],
      permissions: [],
      has_google_calendar_integration: false,
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.AUTH_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
