/**
 * @module store/role/getters
 */

export default {
  /**
   * Get the loading state of the external roles list
   *
   * @param {object} state - State for current module.
   * @returns {Boolean} - The external roles loading state
   */
  isExternalRolesLoading(state) {
    return state.loading.externalRoles
  },

  /**
   * Get the list of external roles
   *
   * @param {object} state - State for current module.
   * @returns {array} - The list of roles
   */
  externalRoles(state) {
    return state.externalRoles
  },
}
