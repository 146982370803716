import Axios from '@plugins/axios'

export default (payload, perPage) => {
  let url = `/api/v2/internal/companies/${payload.companyId}/attachments`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (perPage) {
    config.params.per_page = perPage
  }

  return Axios.get(url, config)
}
