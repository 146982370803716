import * as mutations from '@store/mutation-types'
import ApiServices from '@services/services'

/**
 * Store actions for contact module.
 * @module project/contact-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECT_CONTACT_RESET_STATE)
  },

  /**
   * Action to set the project contacts loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProjectContactsLoading({ commit }, loading) {
    commit(mutations.PROJECT_LOADING_PROJECT_CONTACTS, loading)
  },

  /**
   * Action to set the project contacts
   * @param commit
   * @param {array} contacts - the contacts array
   */
  setProjectContactsList({ commit }, contacts) {
    commit(mutations.PROJECT_CONTACTS_LIST, contacts)
  },

  /**
   * Action to set the project contact from algolia
   *
   * @param context
   * @param {object} contact - the selected contact from algolia
   */
  setNewProjectContactFromAlgolia({ commit }, contact) {
    commit(mutations.PROJECT_NEW_ALGOLIA_CONTACT_SELECTED, contact)
  },

  /**
   * Toggle the project add contacts list display state
   *
   * @param context
   */
  toggleShowAddContactsList({ commit }) {
    commit(mutations.PROJECT_SUMMARY_SHOW_ADD_CONTACTS_LIST)
  },

  /**
   * Action to add new project contact
   *
   * @param dispatch
   * @param {number} payload.projectId - the selected project id
   * @param {array} payload.includes - array of custom includes
   * @param {number} payload.payload.contact_id - the selected contact id
   * @param {number} payload.payload.office_id - the selected contact's office id
   * @param {string} payload.payload.contact_name - the selected contact's name
   */
  addNewProjectContact({ dispatch }, payload) {
    dispatch('setProjectContactsLoading', true)

    const formPayload = {
      contact_id: payload.payload.contact_id,
      office_id: payload.payload.office_id,
    }

    ApiServices.contacts.projects
      .create(payload.projectId, payload.includes, formPayload)
      .then((response) => {
        dispatch('setProjectContactsLoading', false)

        dispatch('setProjectContactsList', response.data.data)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.payload.contact_name} was successfully added as a contact.`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setProjectContactsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error adding new project contact.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              form_payload: formPayload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to remove a selected project contact
   *
   * @param dispatch
   * @param {number} payload.projectId - the selected project id
   * @param {array} payload.includes - array of custom includes
   * @param {number} payload.contact_id - the selected contact id
   * @param {string} payload.contact_name - the selected contact's name
   */
  removeProjectContact({ dispatch }, payload) {
    dispatch('setProjectContactsLoading', true)

    ApiServices.contacts.projects
      .delete(payload.projectId, payload.contact_id, payload.includes)
      .then((response) => {
        dispatch('setProjectContactsLoading', false)

        dispatch('setProjectContactsList', response.data.data)

        dispatch(
          'common/displayToastAlert',
          {
            title: `${payload.contact_name} was successfully removed as a contact.`,
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setProjectContactsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error removing a project contact.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              contact_id: payload.contact_id,
              include: payload.includes,
            },
          },
          { root: true }
        )
      })
  },
}
