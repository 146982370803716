import Axios from '@plugins/axios'

export default (projectId, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/confirm-contacts`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.includes) {
    config.params.include = `${payload.includes.join(',')}`
  }

  return Axios.put(url, payload, config)
}
