import actions from './common-actions'
import getters from './common-getters'
import mutations from './common-mutations'
import * as types from '../mutation-types'
import { merge } from 'lodash'

/**
 * @module store/common
 */

/**
 * Get default state for common module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for shared loaders used in multiple components.
   * @property  {boolean}  loading.login - States for login loaders.
   * @property  {Object}  window - State for web page window.
   * @property  {Number}  window.height
   * @property  {Number}  window.width
   * @property  {Object}  pagination - the pagination object data
   * @property  {Number}  pagination.perPage - the default per page limit
   * @property  {Number}  pagination.selectedPage - the default page selected
   */
  return {
    loading: {
      login: false,
    },
    window: {
      height: 0,
      width: 0,
    },
    sideMenu: {
      isOpen: false,
      selected: null,
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.COMMON_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
