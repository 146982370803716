import * as types from '@store/mutation-types'
import { isEmpty } from 'lodash'

/**
 * @module store/auth/mutations
 */

export default {
  /**
   * Update firebase info.
   *
   * @param {Object} state - This module state.
   * @param {Object} payload - Data for firebase user.
   * @param {String} payload.uid
   * @param {String} payload.email
   * @param {String} payload.displayName
   * @param {String} payload.photoUrl
   * @param {String} payload.accessToken
   */
  [types.AUTH_UPDATE_INFO_FROM_FIREBASE](state, payload) {
    state.firebase.uid = payload.uid
    state.firebase.email = payload.email
    state.firebase.displayName = payload.displayName
    state.firebase.photoUrl = payload.photoUrl
    state.firebase.accessToken = payload.accessToken
    state.firebase.loading = !!isEmpty(payload.accessToken)
  },

  [types.AUTH_FIREBASE_TOKEN_LOADING](state, loading) {
    state.firebase.loading = loading
  },

  /**
   * Update user details from VMS.
   *
   * @param {Object} state - This module state.
   * @param {Object} payload - Data for VMS user.
   */
  [types.AUTH_UPDATE_USER_FROM_VMS](state, payload) {
    Object.assign(state.vms, {
      id: payload.id,
      user_conversation_status_id: payload.user_conversation_status_id,
      first_name: payload.first_name,
      last_name: payload.last_name,
      full_name: payload.full_name,
      email: payload.email,
      phone: payload.phone,
      state_id: payload.state_id,
      staff_title: payload.staff_title,
      photo_url: payload.photo_url,
      calendar_color: payload.calendar_color,
      status: payload.status,
      is_admin: payload.is_admin,
      created_at: payload.created_at,
      updated_at: payload.updated_at,
      has_google_calendar_integration: payload.has_google_calendar_integration,
    })

    if (payload.roles) {
      state.vms.roles = payload.roles
    }

    if (payload.permissions) {
      state.vms.permissions = payload.permissions
    }
  },
}
