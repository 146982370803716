import { merge, cloneDeep, filter, keyBy } from 'lodash'
/**
 * @module store/user/getters
 */

export default {
  /**
   * Get the loading state of the producers list
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state
   */
  producersListLoading(state) {
    return state.loading.producersList
  },

  /**
   * Get the loading state of the account managers list
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state
   */
  accountManagersListLoading(state) {
    return state.loading.accountManagersList
  },

  /**
   * Get the loading state of user details
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state
   */
  isUserDetailsLoading(state) {
    return state.loading.userDetails
  },

  /**
   * Get the loading state of contact
   *
   * @param {object} state - State for current module.
   * @returns {boolean} - The loading state
   */
  isContactLoading(state) {
    return state.loading.contact
  },

  /**
   * Get the list of producers
   *
   * @param {object} state - State for current module.
   * @returns {array}
   */
  producers(state) {
    return state.producers
  },

  /**
   * Get the list of account managers
   *
   * @param {object} state - State for current module.
   * @returns {array}
   */
  accountManagers(state) {
    return state.accountManagers
  },

  /**
   * Get the list of project owners
   *
   * @param {object} state - State for current module.
   * @returns {array}
   */
  projectOwners(state) {
    return state.projectOwners
  },

  /**
   * Get the list of all staff that are active
   *
   * @param {object} state - State for current module.
   * @returns {array}
   */
  staffList(state) {
    const users = merge(
      keyBy(cloneDeep(state.producers), 'id'),
      keyBy(cloneDeep(state.accountManagers), 'id')
    )

    return filter(users, (user) => {
      return user.status === 'Active'
    })
  },

  /**
   * Get the user details
   *
   * @param {object} state - State for current module.
   * @returns {array}
   */
  userDetails(state) {
    return state.userDetails
  },

  /**
   * Get the contact form show state
   *
   * @param {object} state - the state of the current module
   * @returns {object} - The contact form modal show state
   */
  showContactForm(state) {
    return state.modal.contactForm.show
  },

  /**
   * Get the selected user id
   *
   * @param {object} state - the state of the current module
   * @returns {number} - The contact form modal user id
   */
  selectedUserId(state) {
    return state.selectedUserId
  },

  /**
   * Get the loading state of the pre-production users list
   *
   * @param {object} state - the state of the current module
   * @returns {boolean} - The loading state
   */
  isPreProductionUsersLoading(state) {
    return state.loading.preProductionUsers
  },

  /**
   * Get the pre-production users list
   *
   * @param {object} state - the state of the current module
   * @returns {array} - The pre-production users list
   */
  preProductionUsers(state) {
    return state.preProductionUsers
  },

  /**
   * Get the teams loading state
   * @param {object} state - the state of the current module
   * @returns {boolean} - The loading state
   */
  isTeamsLoading(state) {
    return state.loading.teams
  },

  /**
   * Get the teams list
   * @param {object} state - the state of the current module
   * @returns {array} - The teams list
   */
  teamsList(state) {
    return state.teams
  },

  /**
   * Get the internal users loading state
   * @param {object} state - the state of the current module
   * @returns {boolean} - The loading state
   */
  isInternalUsersLoading(state) {
    return state.loading.internalUsers
  },

  /**
   * Get the internal users list
   * @param {object} state - the state of the current module
   * @returns {array} - The internal users list
   */
  internalUsersList(state) {
    return state.internalUsers
  },

  /**
   * Get the loading state of the user google authentication
   * @param {object} state - the state of the current module
   * @returns {boolean} - The loading state
   */
  isUserGoogleAuthenticationLoading(state) {
    return state.loading.googleAuthentication
  },
}
