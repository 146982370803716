import actions from '@store/project/attachment/attachment-actions'
import getters from '@store/project/attachment/attachment-getters'
import mutations from '@store/project/attachment/attachment-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/attachment/attachment-module
 */

/**
 * Get default state for attachment module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {boolean} loading.projectFiles - State for the project files loader.
   * @property  {boolean} loading.brandHub - State for the brand hub loader.
   * @property  {object}  projectFiles - Project files with data array and meta.
   * @property  {array}   projectFiles.data - Array of objects of files belong to project.
   * @property  {object}  projectFiles.meta - The project files meta.
   * @property  {number}  projectFiles.meta.current_page - The current page of the project files.
   * @property  {number}  projectFiles.meta.from - The project files from page.
   * @property  {number}  projectFiles.meta.last_page - The project files last page.
   * @property  {number}  projectFiles.meta.per_page - The project files per page.
   * @property  {number}  projectFiles.meta.to - The project files to page.
   * @property  {number}  projectFiles.meta.total - The total count of project files.
   * @property  {object}  brandHub - Brand hub files with data array and meta.
   * @property  {array}   brandHub.data - Array of objects of files belong to the company of the project.
   * @property  {object}  brandHub.meta - The brand hub meta.
   * @property  {number}  brandHub.meta.current_page - The current page of the brand hub.
   * @property  {number}  brandHub.meta.from - The brand hub from page.
   * @property  {number}  brandHub.meta.last_page - The brand hub last page.
   * @property  {number}  brandHub.meta.per_page - The brand hub per page.
   * @property  {number}  brandHub.meta.to - The brand hub to page.
   * @property  {number}  brandHub.meta.total - The total count of brand hub.
   */
  return {
    loading: {
      projectFiles: false,
      brandHub: false,
    },
    projectFiles: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      },
    },
    brandHub: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      },
    },
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_ATTACHMENT_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
