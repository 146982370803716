import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'

/**
 * @module store/media-manager/actions
 */

/**
 * Store actions for media-manager module.
 * @module media-manager/actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.MEDIA_MANAGER_RESET_STATE)
  },

  /**
   * Set the loading state for the archived projects
   *
   * @param context
   * @param {boolean} isLoading - the loading state for the archived projects
   */
  setArchivedProjectsLoading({ commit }, isLoading) {
    commit(mutations.MEDIA_MANAGER_ARCHIVED_PROJECTS_LOADING, isLoading)
  },

  /**
   * Set the loading state for the archive project footage
   *
   * @param context
   * @param {boolean} isLoading - the loading state for the archive project footage
   */
  setArchiveProjectFootageLoading({ commit }, isLoading) {
    commit(mutations.MEDIA_MANAGER_LOADING_ARCHIVE_PROJECT_FOOTAGE, isLoading)
  },

  /**
   * Get the list of all projects
   *
   * @param context
   * @param {object} payload - the payload used for retrieving the list of archived projects
   */
  getArchivedProjects({ dispatch }, payload) {
    dispatch('setArchivedProjectsLoading', true)

    ProjectServices.projects
      .archiveList(payload.customIncludes, payload.payload)
      .then((response) => {
        dispatch('projects/setArchivedProjects', response.data, { root: true })

        dispatch('setArchivedProjectsLoading', false)
      })
      .catch((error) => {
        dispatch('setArchivedProjectsLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while getting archived projects.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Action to Archive project footage
   *
   * @param context
   * @param {object} payload - the payload used for archiving project footage
   */
  archiveProjectFootage({ dispatch }, payload) {
    dispatch('setArchiveProjectFootageLoading', true)

    ProjectServices.projects
      .archiveFootage(payload.projectId, payload.payload)
      .then(() => {
        dispatch('setArchiveProjectFootageLoading', false)
        dispatch('toggleArchiveProjectFootageModal')
        dispatch(
          'common/displayToastAlert',
          {
            title: 'Project footage archived successfully.',
          },
          { root: true }
        )
      })
      .catch((error) => {
        dispatch('setArchiveProjectFootageLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error archiving project footage.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set selected project
   * @param context
   * @param {object} project - the selected project
   */
  setSelectedArchivedProject({ commit }, project) {
    commit(mutations.MEDIA_MANAGER_SELECTED_ARCHIVED_PROJECT, project)
  },

  /**
   * Toggle the show state of the action archived project modal
   * @param context
   */
  toggleArchiveProjectFootageModal({ commit }) {
    commit(mutations.MEDIA_MANAGER_MODAL_ARCHIVE_PROJECT_FOOTAGE_SHOW)
  },
}
