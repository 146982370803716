import actions from './user-actions'
import getters from './user-getters'
import mutations from './user-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/user
 */

/**
 * Get default state for user module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {object}   loading - States for loaders within the user module.
   * @property {boolean}  loading.producersList - State for the producers list loader
   * @property {boolean}  loading.accountManagersList - State for the account managers list loader
   * @property {boolean}  loading.userDetails - State for the user details loader
   * @property {boolean}  loading.preProductionUsers - State for the pre-production users loader
   * @property {boolean}  loading.contact - State for the contact loader
   * @property {boolean}  loading.teams - State for the teams loader
   * @property {boolean}  loading.internalUsers - State for the internal users loader
   * @property {boolean}  loading.googleAuthentication - State for the google authentication loader
   * @property {array}    producers - Array of objects of available producers
   * @property {array}    accountManagers - Array of objects of available account managers
   * @property {object}   userDetails - User details object
   * @property {number}   selectedUserId - The selected user id
   * @property {object}   modal - State of modals within user module.
   * @property {object}   modal.contactForm - state related to contact form modal
   * @property {boolean}  modal.contactForm.show - The display state of contact form modal
   * @property {Array}    preProductionUsers - List of available pre-production users
   * @property {Array}    teams - List of available teams
   * @property {Array}    internalUsers - List of available internal users
   * @property {Array}    projectOwners - List of user who owns a project
   */
  return {
    loading: {
      producersList: false,
      accountManagersList: false,
      userDetails: false,
      contact: false,
      preProductionUsers: false,
      teams: false,
      internalUsers: false,
      googleAuthentication: false,
    },
    producers: [],
    accountManagers: [],
    userDetails: null,
    selectedUserId: null,
    modal: {
      contactForm: {
        show: false,
      },
    },
    preProductionUsers: [],
    teams: [],
    internalUsers: [],
    projectOwners: [],
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.USER_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
