import * as types from '@store/mutation-types'
import { findIndex, set, reject } from 'lodash'

/**
 * @module store/project/invoice/invoice-mutations
 */
export default {
  /**
   * Update loader for add po number
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_ADD_PO_NUMBER_MODAL](state, loading) {
    state.loading.addPoNumber = loading
  },

  /**
   * Update loader for add production cost
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_ADD_PRODUCTION_COST](state, loading) {
    state.loading.addProductionCost = loading
  },

  /**
   * Update loader for add disbursement cost
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_ADD_DISBURSEMENT_COST](state, loading) {
    state.loading.addDisbursementCost = loading
  },

  /**
   * Update loader for edit job price
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_INVOICES_EDIT_JOB_LOADING](state, loading) {
    state.loading.editJob = loading
  },

  /**
   * Update loader for invoice summary
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_INVOICE_OVERVIEW](state, loading) {
    state.loading.invoiceOverview = loading
  },

  /**
   * Update loader for invoice details
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_INVOICE_DETAILS](state, loading) {
    state.loading.invoiceDetails = loading
  },

  /**
   * Update loader for invoice credit list.
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_CREDIT_LIST](state, loading) {
    state.loading.creditList = loading
  },

  /**
   * Update loader for invoice list.
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_INVOICE_LIST](state, loading) {
    state.loading.invoiceList = loading
  },

  /**
   * Update loader for link invoice.
   * @param {Object} state
   * @param {boolean} loading - The new loader state
   */
  [types.PROJECT_LOADING_LINK_INVOICE](state, loading) {
    state.loading.linkInvoice = loading
  },

  /**
   * Update the project invoices list
   * @param {object} state - the state the current module
   * @param {array} invoices - the array of the project invoices
   */
  [types.PROJECT_INVOICES_LIST](state, invoices) {
    state.invoices.list = invoices
  },

  /**
   * Update the project invoice overview.
   * @param {Object} state
   * @param {Object} payload
   * @param {Array} payload.list
   * @param {Array} payload.jobs
   * @param {Number} payload.paid_with_credits
   * @param {Number} payload.total_invoiced
   * @param {Number} payload.remaining_to_be_invoiced
   */
  [types.PROJECT_INVOICES_OVERVIEW](state, payload) {
    state.invoices.list = payload.invoice_list
    state.invoices.jobs = payload.invoice_jobs
    state.invoices.paid_with_credits = payload.paid_with_credits
    state.invoices.total_invoiced = payload.total_invoiced
    state.invoices.remaining_to_be_invoiced = payload.remaining_to_be_invoiced
    state.invoices.invoice_order_detail = payload.invoice_order_detail
    state.invoices.credit_list = payload.credit_list
  },

  /**
   * Toggle add po number modal
   * @param {Object} state
   */
  [types.PROJECT_MODAL_ADD_PO_NUMBER_TOGGLE_SHOW](state) {
    state.modal.addPoNumber.show = !state.modal.addPoNumber.show
  },

  /**
   * Update add po number modal order id
   * @param {Object} state
   * @param id - The order id
   */
  [types.PROJECT_MODAL_ADD_PO_NUMBER_ORDER_ID](state, id) {
    state.modal.addPoNumber.orderId = id
  },

  /**
   * Update the po number.
   * @param {object} state - the state the current module
   * @param {object} payload - the payload
   */
  [types.PROJECT_INVOICE_UPDATE_PO_NUMBER](state, payload) {
    const selectedOrder = findIndex(state.invoices.list, {
      order_id: payload.order_id,
    })

    set(
      state.invoices.list,
      `[${selectedOrder}].po_number`,
      payload.reference_number
    )
  },

  /**
   * Toggle add production cost modal
   * @param {Object} state
   */
  [types.PROJECT_MODAL_ADD_PRODUCTION_COST_TOGGLE_SHOW](state) {
    state.modal.addProductionCost.show = !state.modal.addProductionCost.show
  },

  /**
   * Toggle add disbursement cost modal
   * @param {Object} state
   */
  [types.PROJECT_MODAL_ADD_DISBURSEMENT_COST_TOGGLE_SHOW](state) {
    state.modal.addDisbursementCost.show = !state.modal.addDisbursementCost.show
  },

  /**
   * Update the disbursement product elements
   * @param {object} state - the state of the current module
   * @param {array} productElements - the array of disbursement product elements
   */
  [types.PROJECT_MODAL_ADD_DISBURSEMENT_COST_PRODUCT_ELEMENTS](
    state,
    productElements
  ) {
    state.modal.addDisbursementCost.productElements = productElements
  },

  /**
   * Toggle edit job price modal
   * @param {Object} state
   */
  [types.PROJECT_INVOICES_EDIT_JOB_MODAL_TOGGLE](state) {
    state.modal.editJob.show = !state.modal.editJob.show
  },

  /**
   * Update or reset the selected invoice job.
   * @param {Object} state
   * @param {Number} id
   */
  [types.PROJECT_INVOICES_SELECTED_JOB_ID](state, id) {
    state.selectedInvoiceJobId = id
  },

  /**
   * Set invoice contact array.
   * @param {Object} state
   * @param {array} contactList
   */
  [types.PROJECT_INVOICES_INVOICE_CONTACT](state, contactList) {
    state.invoices.invoiceContacts = contactList
  },

  /**
   * Add invoice contact to array.
   * @param {Object} state
   * @param {Object} contact
   */
  [types.PROJECT_INVOICES_INVOICE_CONTACT_ADD](state, contact) {
    state.invoices.invoiceContacts.push(contact)
  },

  /**
   * Remove invoice contact from array.
   * @param {Object} state
   * @param {Object} contact
   */
  [types.PROJECT_INVOICES_INVOICE_CONTACT_REMOVE](state, contact) {
    state.invoices.invoiceContacts = reject(state.invoices.invoiceContacts, {
      id: contact.id,
    })
  },

  /**
   * Update the invoice detail changed.
   * @param {Object} state
   * @param {boolean} invoiceDetailChanged
   */
  [types.PROJECT_INVOICES_INVOICE_DETAIL_CHANGED](state, invoiceDetailChanged) {
    state.invoiceDetailChanged = invoiceDetailChanged
  },

  /**
   * Update the project invoice credit list.
   * @param {object} state
   * @param {array} credits - credit list
   */
  [types.PROJECT_INVOICES_CREDIT_LIST](state, credits) {
    state.invoices.credit_list = credits
  },

  /**
   * Toggle link invoice show modal
   * @param {Object} state
   */
  [types.PROJECT_MODAL_LINK_INVOICE_TOGGLE_SHOW](state) {
    state.modal.linkInvoice.show = !state.modal.linkInvoice.show
  },

  /**
   * Update link invoice modal order id
   * @param {Object} state
   * @param id - The order id
   */
  [types.PROJECT_MODAL_LINK_INVOICE_ORDER_ID](state, id) {
    state.modal.linkInvoice.orderId = id
  },
}
