import Axios from '@plugins/axios'

// eslint-disable-next-line complexity
export default (payload) => {
  let url = `/api/v2/internal/projects`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.include) {
    config.params.include = `${payload.include.join(',')}`
  }

  if (payload.sortBy) {
    config.params.sort_by = payload.sortBy
  }

  if (payload.sort) {
    config.params.sort = payload.sort
  }

  if (payload.search) {
    config.params.search = payload.search
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (payload.perPage) {
    config.params.per_page = payload.perPage
  }

  if (payload.filter) {
    if (payload.filter.productTypes) {
      config.params.product_types = payload.filter.productTypes
    }

    if (payload.filter.users) {
      config.params.users = payload.filter.users
    }

    if (payload.filter.status) {
      config.params.status = payload.filter.status
    }

    if (payload.filter.from) {
      config.params.from = payload.filter.from
    }

    if (payload.filter.to) {
      config.params.to = payload.filter.to
    }

    if (payload.filter.is_my_projects) {
      config.params.is_my_projects = payload.filter.is_my_projects
    }
  }

  return Axios.get(url, config)
}
