import actions from './footage-status-type-actions'
import getters from './footage-status-type-getters'
import mutations from './footage-status-type-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/footage-status-type/footage-status-type-module
 */

/**
 * Get default state for footage-status-type module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {array}    footageStatusTypes - Array of objects of footage status types
   */
  return {
    footageStatusTypes: [],
    isLoading: false,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.FOOTAGE_STATUS_TYPE_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
