import actions from '@store/project/project-actions'
import getters from '@store/project/project-getters'
import mutations from '@store/project/project-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/project-module
 */

/**
 * Get default state for project module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the project module.
   * @property  {boolean} loading.projectDetails - State for the project details loader.
   * @property  {boolean} loading.createNewProject - State for the create new project loader.
   * @property  {boolean} loading.productTypes - State for the product types loader.
   * @property  {boolean} loading.editProject - State for the edit project loader.
   * @property  {boolean} loading.confirmContact - State for the confirm contact loader.
   * @property  {boolean} loading.formSaveBtn - State for the save button loader.
   * @property  {object}  projectDetails - State for the project details object.
   * @property  {number}  projectDetails.id - The ID of the project.
   * @property  {string}  projectDetails.name - The name of the project.
   * @property  {number}  projectDetails.project_status_id - The project status id.
   * @property  {object}  projectDetails.project_status - The project status object.
   * @property  {number}  projectDetails.project_status.id - The project status id.
   * @property  {string}  projectDetails.project_status.name - The project status name.
   * @property  {number}  projectDetails.due_date - The due date of the project in timestamp.
   * @property  {string}  projectDetails.budget - The budget of the project.
   * @property  {string}  projectDetails.video_type - The video type of the project.
   * @property  {string}  projectDetails.platform - The platform of the project.
   * @property  {string}  projectDetails.topic - The topic of the project.
   * @property  {string}  projectDetails.target_audience - The target audience of the project.
   * @property  {string}  projectDetails.video_example - The video example of the project.
   * @property  {string}  projectDetails.goals - The goals of the project.
   * @property  {string}  projectDetails.additional_notes - The additional notes or external notes of the project.
   * @property  {string}  projectDetails.owner - The full name of the project's owner.
   * @property  {number}  projectDetails.total_video - The total video count of the project.
   * @property  {number}  projectDetails.total_hero_videos - The total hero videos count of the project.
   * @property  {string}  projectDetails.thumbnail_url - The thumbnail url of the project.
   * @property  {number}  projectDetails.total_price - The total price of the project.
   * @property  {boolean} projectDetails.is_rebookable - The rebook state of the project.
   * @property  {number}  projectDetails.create_at - The created date of the project in timestamp.
   * @property  {array}   projectDetails.invoice_contacts - Array of objects of project invoice contacts.
   * @property  {array}   projectDetails.office_contacts - Array of objects of project office contacts.
   * @property  {Number}  projectDetails.office_id - Office ID.
   * @property  {Object}  projectDetails.office - Office details.
   * @property  {Number}  projectDetails.office.id - Office ID.
   * @property  {String}  projectDetails.office.name - Office name.
   * @property  {Number}  projectDetails.office.company_id - Company ID.
   * @property  {Object}  projectDetails.office.company - Company details.
   * @property  {Number}  projectDetails.office.company.id - Company ID.
   * @property  {String}  projectDetails.office.company.name - Company name.
   * @property  {Number}  projectDetails.office.company.harvest_client_id - Harvest ID for this company.
   * @property  {String}  projectDetails.office.company.website - Company website URL.
   * @property  {Number}  projectDetails.office.company.rating - Company rating 0-3.
   * @property  {Number}  projectDetails.office.company.target - Sales target in money.
   * @property  {String}  projectDetails.office.company.logo_url - Company logo URL.
   * @property  {String}  projectDetails.office.company.header_color - Company header color.
   * @property  {String}  projectDetails.office.company.footer_color - Company footer color.
   * @property  {String}  projectDetails.office.company.logo_color - Company logo color.
   * @property  {String}  projectDetails.office.company.background_color - Company background color.
   * @property  {String}  projectDetails.office.company.text_color - Company text color.
   * @property  {Number}  projectDetails.office.company.is_active - Determine if company is active.
   * @property  {Boolean} projectDetails.office.company.has_bad_office - Determine if company has a bad office.
   * @property  {Number}  projectDetails.office.company.created_by_id - User ID of creator.
   * @property  {Number}  projectDetails.office.company.updated_by_id - User ID of updater.
   * @property  {Number}  projectDetails.office.company.deleted_by_id - User ID of deleter.
   * @property  {Number}  projectDetails.office.company.created_at - Creation timestamp.
   * @property  {Number}  projectDetails.office.company.updated_at - Updated timestamp.
   * @property  {Number}  projectDetails.office.company.deleted_at - Deletion timestamp.
   * @property  {Number}  projectDetails.office.office_type_id - Office type ID.
   * @property  {Number}  projectDetails.office.state_id - State ID.
   * @property  {Number}  projectDetails.office.account_manager_id - RM ID.
   * @property  {Object}  projectDetails.office.account_manager - RM details.
   * @property  {Number}  projectDetails.office.account_manager.id - RM ID.
   * @property  {Number}  projectDetails.office.account_manager.user_conversation_status_id - RM status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.office.account_manager.first_name - RM first name.
   * @property  {String}  projectDetails.office.account_manager.last_name - RM last name.
   * @property  {String}  projectDetails.office.account_manager.full_name - RM full name.
   * @property  {String}  projectDetails.office.account_manager.email - RM email address.
   * @property  {String}  projectDetails.office.account_manager.phone - RM phone number.
   * @property  {Number}  projectDetails.office.account_manager.state_id - RM location state ID.
   * @property  {String}  projectDetails.office.account_manager.staff_title - RM position name.
   * @property  {String}  projectDetails.office.account_manager.photo_url - RM photo.
   * @property  {String}  projectDetails.office.account_manager.calendar_color - RM calendar color.
   * @property  {String}  projectDetails.office.account_manager.status - RM status: Active/Inactive.
   * @property  {Boolean}  projectDetails.office.account_manager.is_admin - Determine if RM is admin.
   * @property  {Number}  projectDetails.office.account_manager.create_at - Creation timestamp of RM user.
   * @property  {Number}  projectDetails.office.account_manager.update_at - Updated timestamp of RM user.
   * @property  {Number}  projectDetails.office.producer_id - Producer ID.
   * @property  {Object}  projectDetails.office.producer - Producer details.
   * @property  {Number}  projectDetails.office.producer.id - Producer ID.
   * @property  {Number}  projectDetails.office.producer.user_conversation_status_id - Producer status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.office.producer.first_name - Producer first name.
   * @property  {String}  projectDetails.office.producer.last_name - Producer last name.
   * @property  {String}  projectDetails.office.producer.full_name - Producer full name.
   * @property  {String}  projectDetails.office.producer.email - Producer email address.
   * @property  {String}  projectDetails.office.producer.phone - Producer phone number.
   * @property  {Number}  projectDetails.office.producer.state_id - Producer location state ID.
   * @property  {String}  projectDetails.office.producer.staff_title - Producer position name.
   * @property  {String}  projectDetails.office.producer.photo_url - Producer photo.
   * @property  {String}  projectDetails.office.producer.calendar_color - Producer calendar color.
   * @property  {String}  projectDetails.office.producer.status - Producer status: Active/Inactive.
   * @property  {Boolean}  projectDetails.office.producer.is_admin - Determine if Producer is admin.
   * @property  {Number}  projectDetails.office.producer.create_at - Creation timestamp of Producer user.
   * @property  {Number}  projectDetails.office.producer.update_at - Updated timestamp of Producer user.
   * @property  {Number}  projectDetails.office.canned_response_id - ID of canned response resource.
   * @property  {String}  projectDetails.office.google_drive_folder_id - ID of google drive folder.
   * @property  {String}  projectDetails.office.phone - Office contact number.
   * @property  {String}  projectDetails.office.address - Office address.
   * @property  {String}  projectDetails.office.quickbook_name - Office nickname.
   * @property  {String}  projectDetails.office.default_email_address - Office email address used by default.
   * @property  {String}  projectDetails.office.logo_url - Office logo URL.
   * @property  {String}  projectDetails.office.thumb_url - Office thumbnail URL.
   * @property  {Number}  projectDetails.office.client_term_id - ID of the term resource for this office/company.
   * @property  {Number}  projectDetails.office.accountant_user_id - User ID of the office accountant.
   * @property  {Boolean}  projectDetails.office.is_third_party_payment - Determine if the payment is made by a 3rd party.
   * @property  {String}  projectDetails.office.invoice_entity_name - Name on the invoice for this office.
   * @property  {String}  projectDetails.office.invoice_entity_contact_name - Contact name on the invoice for this office.
   * @property  {Boolean}  projectDetails.office.has_extended_payment_terms - Determine if this office has extended payment term.
   * @property  {Number}  projectDetails.office.extended_payment_term_id - ID of the term resource.
   * @property  {Boolean}  projectDetails.office.is_accounts_intro_email_sent - Determine if intro email was sent for this office.
   * @property  {Boolean}  projectDetails.office.can_download_video - Determine if this office can download the videos.
   * @property  {Boolean}  projectDetails.office.is_webm_required - Determine if this office needs webp format for videos.
   * @property  {Boolean}  projectDetails.office.is_approved_for_invoicing - Determine if this office was approved for invoicing.
   * @property  {Boolean}  projectDetails.office.is_active - Determine if this office is active (enabled/disabled).
   * @property  {String}  projectDetails.office.token - Office unique token used for video landing page.
   * @property  {Number}  projectDetails.office.created_by_id - Office creator ID.
   * @property  {Number}  projectDetails.office.updated_by_id - Office updater ID.
   * @property  {Number}  projectDetails.office.deleted_by_id - Office deleter ID.
   * @property  {Number}  projectDetails.office.created_at - Office creation timestamp.
   * @property  {Number}  projectDetails.office.updated_at - Office updated timestamp.
   * @property  {Number}  projectDetails.office.deleted_at - Office deletion timestamp.
   * @property  {Number}  projectDetails.account_manager_id - User ID for project RM.
   * @property  {Object}  projectDetails.account_manager - Project RM details.
   * @property  {Number}  projectDetails.account_manager.id - Project RM ID.
   * @property  {Number}  projectDetails.account_manager.user_conversation_status_id - Project RM status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.account_manager.first_name - Project RM first name.
   * @property  {String}  projectDetails.account_manager.last_name - Project RM last name.
   * @property  {String}  projectDetails.account_manager.full_name - Project RM full name.
   * @property  {String}  projectDetails.account_manager.email - Project RM email address.
   * @property  {String}  projectDetails.account_manager.phone - Project RM phone number.
   * @property  {Number}  projectDetails.account_manager.state_id - Project RM location state ID.
   * @property  {String}  projectDetails.account_manager.staff_title - Project RM position name.
   * @property  {String}  projectDetails.account_manager.photo_url - Project RM photo.
   * @property  {String}  projectDetails.account_manager.calendar_color - Project RM calendar color.
   * @property  {String}  projectDetails.account_manager.status - Project RM status: Active/Inactive.
   * @property  {Boolean}  projectDetails.account_manager.is_admin - Project Determine if RM is admin.
   * @property  {Number}  projectDetails.account_manager.create_at - Creation timestamp of project RM user.
   * @property  {Number}  projectDetails.account_manager.update_at - Updated timestamp of project RM user.
   * @property  {Number}  projectDetails.producer_id - User ID for project Producer.
   * @property  {Object}  projectDetails.producer - Project Producer details.
   * @property  {Number}  projectDetails.producer_id - User ID for project Producer.
   * @property  {Number}  projectDetails.producer.user_conversation_status_id - Project Producer status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.producer.first_name - Project Producer first name.
   * @property  {String}  projectDetails.producer.last_name - Project Producer last name.
   * @property  {String}  projectDetails.producer.full_name - Project Producer full name.
   * @property  {String}  projectDetails.producer.email - Project Producer email address.
   * @property  {String}  projectDetails.producer.phone - Project Producer phone number.
   * @property  {Number}  projectDetails.producer.state_id - Project Producer location state ID.
   * @property  {String}  projectDetails.producer.staff_title - Project Producer position name.
   * @property  {String}  projectDetails.producer.photo_url - Project Producer photo.
   * @property  {String}  projectDetails.producer.calendar_color - Project Producer calendar color.
   * @property  {String}  projectDetails.producer.status - Project Producer status: Active/Inactive.
   * @property  {Boolean}  projectDetails.producer.is_admin - Determine if Producer is admin.
   * @property  {Number}  projectDetails.producer.create_at - Creation timestamp of project  Producer user.
   * @property  {Number}  projectDetails.producer.update_at - Updated timestamp of project  Producer user.
   * @property  {Number}  projectDetails.director_id - User ID for project Director.
   * @property  {Object}  projectDetails.director - Project Director details.
   * @property  {Number}  projectDetails.director_id - User ID for project Director.
   * @property  {Number}  projectDetails.director.user_conversation_status_id - Project Director status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.director.first_name - Project Director first name.
   * @property  {String}  projectDetails.director.last_name - Project Director last name.
   * @property  {String}  projectDetails.director.full_name - Project Director full name.
   * @property  {String}  projectDetails.director.email - Project Director email address.
   * @property  {String}  projectDetails.director.phone - Project Director phone number.
   * @property  {Number}  projectDetails.director.state_id - Project Director location state ID.
   * @property  {String}  projectDetails.director.staff_title - Project Director position name.
   * @property  {String}  projectDetails.director.photo_url - Project Director photo.
   * @property  {String}  projectDetails.director.calendar_color - Project Director calendar color.
   * @property  {String}  projectDetails.director.status - Project Director status: Active/Inactive.
   * @property  {Boolean}  projectDetails.director.is_admin - Determine if Director is admin.
   * @property  {Number}  projectDetails.director.create_at - Creation timestamp of project  Director user.
   * @property  {Number}  projectDetails.director.update_at - Updated timestamp of project  Director user.
   * @property  {Number}  projectDetails.production_coordinator_id - User ID for project Production Coordinator.
   * @property  {Object}  projectDetails.production_coordinator - Project Production Coordinator details.
   * @property  {Number}  projectDetails.production_coordinator_id - User ID for project Production Coordinator.
   * @property  {Number}  projectDetails.production_coordinator.user_conversation_status_id - Project Production Coordinator status (on/off-line) in project conversation.
   * @property  {String}  projectDetails.production_coordinator.first_name - Project Production Coordinator first name.
   * @property  {String}  projectDetails.production_coordinator.last_name - Project Production Coordinator last name.
   * @property  {String}  projectDetails.production_coordinator.full_name - Project Production Coordinator full name.
   * @property  {String}  projectDetails.production_coordinator.email - Project Production Coordinator email address.
   * @property  {String}  projectDetails.production_coordinator.phone - Project Production Coordinator phone number.
   * @property  {Number}  projectDetails.production_coordinator.state_id - Project Production Coordinator location state ID.
   * @property  {String}  projectDetails.production_coordinator.staff_title - Project Production Coordinator position name.
   * @property  {String}  projectDetails.production_coordinator.photo_url - Project Production Coordinator photo.
   * @property  {String}  projectDetails.production_coordinator.calendar_color - Project Production Coordinator calendar color.
   * @property  {String}  projectDetails.production_coordinator.status - Project Production Coordinator status: Active/Inactive.
   * @property  {Boolean}  projectDetails.production_coordinator.is_admin - Determine if Production Coordinator is admin.
   * @property  {Number}  projectDetails.production_coordinator.create_at - Creation timestamp of project  Production Coordinator user.
   * @property  {Number}  projectDetails.production_coordinator.update_at - Updated timestamp of project  Production Coordinator user.
   * @property  {Object}  projectDetails.product_type - Product type details.
   * @property  {Number}  projectDetails.product_type.id - The ID of the project's product type.
   * @property  {String}  projectDetails.product_type.name - The name of the project's product type.
   * @property  {Number}  projectDetails.product_type.created_at - The created at timestamp of the project's product type.
   * @property  {Number}  projectDetails.product_type.updated_at - The updated at timestamp of the project's product type.
   * @property  {Number}  projectDetails.start_date - Project start timestamp.
   * @property  {Number}  projectDetails.end_date - Project completion timestamp.
   * @property  {array}  projectDetails.project_orders - Project array of associated order to project.
   * @property  {Number}  projectDetails.budgeted_hours - Project hours included in the budget.
   * @property  {String}  projectDetails.notes - Project notes.
   * @property  {Boolean}  projectDetails.is_project_brief - Determine if project has brief.
   * @property  {Boolean}  projectDetails.project_brief_sent - Determine if project brief was sent.
   * @property  {Number}  projectDetails.created_by_id - Project creator ID.
   * @property  {Number}  projectDetails.updated_by_id - Project updater ID.
   * @property  {Number}  projectDetails.deleted_by_id - Project deleter ID.
   * @property  {Number}  projectDetails.updated_at - Project updated timestamp.
   * @property  {Number}  projectDetails.deleted_at - Project deletion timestamp.
   * @property  {number}  projectDetails.video_privacy - The video privacy attribute
   * @property  {array}   uploadedFiles - Array of objects of project uploaded files.
   * @property 	{object} 	projectForm - Details containing all info related to the project form.
   * @property 	{boolean} projectForm.saveBtnDisabled - Save button disabled state.
   * @property 	{object} projectForm.newProject - Object payload for the new project form.
   * @property 	{string} projectForm.newProject.name - The new project name.
   * @property 	{number} projectForm.newProject.product_type_id - The new project name.
   * @property 	{number} projectForm.newProject.account_manager_id - The selected id for account manager for the new project.
   * @property 	{number} projectForm.newProject.producer_id - The selected id for producer for the new project.
   * @property 	{number} projectForm.newProject.contact_id - The selected id for contact for the new project.
   * @property 	{number} projectForm.newProject.office_id - The selected id for office for the new project.
   * @property  {number} projectForm.newProject.video_privacy - The video privacy attribute
   * @property 	{boolean} projectForm.isEditMode - The edit project summary edit mode state.
   * @property 	{boolean} projectForm.projectInfoFormInvalid - The edit project summary info form invalid state.
   * @property 	{boolean} projectForm.projectBriefFormInvalid - The edit project summary brief form invalid state.
   * @property 	{object} projectForm.editProjectSummaryPayload - The default object for edit project summary form.
   * @property 	{string} projectForm.editProjectSummaryPayload.name - The default object for edit project summary form.
   * @property 	{number} projectForm.editProjectSummaryPayload.account_manager_id - The selected account manager id for the edit project summary form.
   * @property 	{number} projectForm.editProjectSummaryPayload.producer_id - The selected producer id for the edit project summary form.
   * @property 	{number} projectForm.editProjectSummaryPayload.product_type_id - The selected product type id for the edit project summary form.
   * @property 	{array}   productTypes - Array of objects of all available product types
   * @property 	{boolean} showAddContactsList - The add contacts list display state
   * @property {boolean} isProjectMenuScrollable - The scrollable state of project manu tab
   */
  return {
    loading: {
      projectDetails: true,
      createNewProject: false,
      productTypes: false,
      editProject: false,
      confirmContact: false,
      targetAudienceTypes: false,
      footageStatus: false,
      invoiceBudget: false,
      hubspotQuotes: false,
      eodNotesProject: false,
      eodNotesJob: false,
      eodNotesPinned: false,
      eodNotesJobList: false,
      eodNotesSaveBtn: false,
      formSaveBtn: false,
    },
    modal: {
      archiveProject: false,
      eodNotes: false,
    },
    projectDetails: {
      id: null,
      name: '',
      project_status_id: null,
      project_status: {
        id: null,
        name: '',
      },
      project_orders: [],
      due_date: null,
      is_manual_deadline: null,
      is_hard_deadline: null,
      budget: '',
      video_type: '',
      platform: '',
      topic: '',
      target_audience: '',
      target_audience_types: [],
      video_example: '',
      goals: '',
      additional_notes: '',
      owner: '',
      total_video: 0,
      total_hero_videos: 0,
      thumbnail_url: '',
      total_price: 0,
      is_rebookable: false,
      created_at: null,
      invoice_contacts: [],
      office_contacts: [],
      office_id: null,
      office: {
        id: null,
        name: '',
        company_id: null,
        company: {
          id: null,
          name: '',
          harvest_client_id: null,
          website: '',
          rating: null,
          target: null,
          logo_url: null,
          header_color: null,
          footer_color: null,
          logo_color: null,
          background_color: null,
          title_color: null,
          text_color: null,
          is_active: null,
          has_bad_office: true,
          created_by_id: null,
          updated_by_id: null,
          deleted_by_id: null,
          created_at: null,
          updated_at: null,
          deleted_at: null,
        },
        office_type_id: null,
        state_id: null,
        account_manager_id: null,
        account_manager: {
          id: null,
          user_conversation_status_id: null,
          first_name: '',
          last_name: '',
          full_name: '',
          email: '',
          phone: '',
          state_id: null,
          staff_title: '',
          photo_url: null,
          calendar_color: '',
          status: '',
          is_admin: false,
          created_at: null,
          updated_at: null,
        },
        producer_id: null,
        producer: {
          id: null,
          user_conversation_status_id: null,
          first_name: '',
          last_name: '',
          full_name: '',
          email: '',
          phone: '',
          state_id: null,
          staff_title: '',
          photo_url: null,
          calendar_color: '',
          status: '',
          is_admin: false,
          created_at: null,
          updated_at: null,
        },
        canned_response_id: 0,
        google_drive_folder_id: null,
        phone: '',
        address: '',
        quickbook_name: null,
        default_email_address: null,
        logo_url: null,
        thumb_url: null,
        client_term_id: null,
        payment_term_id: null,
        accountant_user_id: null,
        is_third_party_payment: 0,
        invoice_entity_name: null,
        invoice_entity_contact_name: null,
        has_extended_payment_terms: 0,
        extended_payment_term_id: null,
        is_accounts_intro_email_sent: 0,
        can_download_video: 1,
        is_webm_required: 0,
        is_approved_for_invoicing: 0,
        is_active: 0,
        is_contact_confirmed: true,
        token: '',
        created_by_id: null,
        updated_by_id: null,
        deleted_by_id: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
      },
      account_manager_id: null,
      account_manager: {
        id: null,
        user_conversation_status_id: null,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        state_id: null,
        staff_title: '',
        photo_url: null,
        calendar_color: '',
        status: '',
        is_admin: false,
        created_at: null,
        updated_at: null,
      },
      producer_id: null,
      producer: {
        id: null,
        user_conversation_status_id: null,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        state_id: null,
        staff_title: '',
        photo_url: null,
        calendar_color: '',
        status: '',
        is_admin: false,
        created_at: null,
        updated_at: null,
      },
      director_id: null,
      director: {
        id: null,
        user_conversation_status_id: null,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        state_id: null,
        staff_title: '',
        photo_url: null,
        calendar_color: '',
        status: '',
        is_admin: false,
        created_at: null,
        updated_at: null,
      },
      production_coordinator_id: null,
      production_coordinator: {
        id: null,
        user_conversation_status_id: null,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        state_id: null,
        staff_title: '',
        photo_url: null,
        calendar_color: '',
        status: '',
        is_admin: false,
        created_at: null,
        updated_at: null,
      },
      product_type: {
        id: null,
        name: '',
        created_at: null,
        updated_at: null,
      },
      conversation_id: null,
      start_date: null,
      budgeted_hours: null,
      notes: '',
      is_project_brief: 0,
      project_brief_sent: 0,
      video_privacy: 0,
      footage_status_type_id: null,
      footage_policy_type_id: null,
      footage_policy_notes: '',
      hubspot_deal_url: '',
      created_by_id: null,
      updated_by_id: null,
      deleted_by_id: null,
      updated_at: null,
      deleted_at: null,
    },
    projectForm: {
      saveBtnDisabled: true,
      newProject: {
        name: '',
        product_type_id: null,
        account_manager_id: null,
        producer_id: null,
        contact_id: null,
        office_id: null,
        video_privacy: 0,
        budgeted_hours: 0,
      },
      isEditMode: false,
      projectInfoFormInvalid: false,
      projectBriefFormInvalid: false,
      editProjectSummaryPayload: {
        name: '',
        account_manager_id: null,
        producer_id: null,
        target_audiences: [],
      },
    },
    productTypes: [],
    isProjectMenuScrollable: false,
    targetAudienceTypes: [],
    budgetInfo: {},
    hubspotQuotes: [],
    eodNotes: {
      selectedEodNoteTypeId: 0,
      pinned: [],
      project: [],
      jobs: [],
      nextPageLink: null,
      selectedProject: null,
    },
    projectJobs: [],
    has_eod_notes: false,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
