import Axios from '@plugins/axios'

export default (payload, customIncludes) => {
  let url = `/api/v2/internal/events/bulk`

  let config = {
    headers: {},
    data: payload,
  }

  if (customIncludes) {
    config.data.include = `${customIncludes.join(',')}`
  }

  return Axios.delete(url, config)
}
