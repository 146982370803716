import actions from '@store/project/invoice/invoice-actions'
import getters from '@store/project/invoice/invoice-getters'
import mutations from '@store/project/invoice/invoice-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/project/invoice/invoice-module
 */

/**
 * Get default state for project module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property  {object}  loading - States for loaders within the invoice module.
   * @property	{boolean} loading.addPoNumber - Loader for add po number.
   * @property	{boolean} loading.addProductionCost - Loader for add production cost.
   * @property	{boolean} loading.addDisbursementCost - Loader for add production cost.
   * @property	{boolean} loading.invoiceOverview - Loader for invoice overview.
   * @property	{boolean} loading.editJob - Loader for edit job price.
   * @property	{boolean} loading.invoiceDetails - Loader for invoice details.
   * @property	{boolean} loading.creditList - Loader for credit list section.
   * @property	{boolean} loading.invoiceList - Loader for invoice list section.
   * @property  {object}  invoices - Details containing invoices.
   * @property  {array}   invoices.list - List of invoices.
   * @property  {array}   invoices.jobs - List of jobs.
   * @property  {Number}  invoices.paid_with_credits - Total amount paid with credits in the project.
   * @property  {Number}  invoices.total_invoiced - Total amount it was invoiced in the project.
   * @property  {Number}  invoices.remaining_to_be_invoiced - Total amount remaining to be invoiced.
   * @property  {object}  invoices.invoice_order_detail - Main order used for invoice related.
   * @property  {number}  invoices.invoice_order_detail.account_manager_id - ID of account manager.
   * @property  {object}  invoices.invoice_order_detail.account_manager_id - ID of account manager.
   * @property  {string}  invoices.invoice_order_detail.aim_of_video - Details about aim of video.
   * @property  {number}  invoices.invoice_order_detail.all_videos_public - Number to determine if videos are public.
   * @property  {number}  invoices.invoice_order_detail.allocated_production_hours - Number of production hours allocated.
   * @property  {string}  invoices.invoice_order_detail.brief - Details about brief.
   * @property  {number}  invoices.invoice_order_detail.cancel_sent - Number to check if cancellation has been sent.
   * @property  {number}  invoices.invoice_order_detail.confirm_sent - Number to check if confirmation has been sent.
   * @property  {number}  invoices.invoice_order_detail.created_at - Created at timestamp.
   * @property  {number}  invoices.invoice_order_detail.created_by - Created by user id.
   * @property  {string}  invoices.invoice_order_detail.creative_approach - Details about creative approach.
   * @property  {number}  invoices.invoice_order_detail.display_price - Price of the order.
   * @property  {string}  invoices.invoice_order_detail.google_drive_folder_id - Id of the google drive.
   * @property  {string}  invoices.invoice_order_detail.hubspot_deal_id - ID of the hubspot deal.
   * @property  {number}  invoices.invoice_order_detail.id - Order ID.
   * @property  {array}  invoices.invoice_order_detail.invoice_contacts - Array of invoice contacts.
   * @property  {string}  invoices.invoice_order_detail.invoice_entity_contact_email - Email of third party invoice.
   * @property  {string}  invoices.invoice_order_detail.invoice_entity_contact_name - Name of third party invoice contact.
   * @property  {string}  invoices.invoice_order_detail.invoice_entity_name - Name of third party invoice.
   * @property  {string}  invoices.invoice_order_detail.meta_data - Meta data.
   * @property  {string}  invoices.invoice_order_detail.name - Order name.
   * @property  {number}  invoices.invoice_order_detail.office_id - Id of the office.
   * @property  {number}  invoices.invoice_order_detail.order_invoice_status_id - Invoice status ID.
   * @property  {number}  invoices.invoice_order_detail.order_invoicing_term_id - Invoicing term ID.
   * @property  {number}  invoices.invoice_order_detail.order_status_id - Order status ID.
   * @property  {number}  invoices.invoice_order_detail.payment_term_id - Payment term ID.
   * @property  {number}  invoices.invoice_order_detail.producer_id - ID of the producer.
   * @property  {number}  invoices.invoice_order_detail.product_type_id - ID of the product type.
   * @property  {number}  invoices.invoice_order_detail.project_id - ID of the project.
   * @property  {number}  invoices.invoice_order_detail.quote_id - ID of the quote.
   * @property  {string}  invoices.invoice_order_detail.quote_ref - Reference number for the quote.
   * @property  {string}  invoices.invoice_order_detail.reference_number - Reference number for invoice.
   * @property  {string}  invoices.invoice_order_detail.shoot_key_details - Details about shoot.
   * @property  {string}  invoices.invoice_order_detail.standard_inclusion - Details about standard inclusion.
   * @property  {number}  invoices.invoice_order_detail.third_party_payment - Number to check if third party payment.
   * @property  {number}  invoices.invoice_order_detail.updated_at - Updated at timestamp.
   * @property  {number}  invoices.invoice_order_detail.updated_by - Updated by user ID.
   * @property  {array}   invoices.invoiceContacts - Array of contact for invoice.
   * @property  {array}   invoices.credit_list - List of credits.
   * @property 	{object} 	modal - Details containing all info related to the invoice modal.
   * @property 	{object} 	modal.addPoNumber - Details containing all info related to the add po number modal.
   * @property 	{boolean} modal.addPoNumber.show - Boolean to check if add po number modal should show or not.
   * @property 	{number}  modal.addPoNumber.orderId - The orderId of this add po number modal.
   * @property 	{object}  modal.addProductionCost - Details containing all info related to the add production cost modal.
   * @property 	{boolean} modal.addProductionCost.show - Boolean to check if add production cost modal should show or not.
   * @property 	{object}  modal.addDisbursementCost - Details containing all info related to the add disbursement cost modal.
   * @property 	{boolean} modal.addDisbursementCost.show - Boolean to check if add disbursement cost modal should show or not.
   * @property 	{array}   modal.addDisbursementCost.productElements - Array of product elements set up in this modal.
   * @property 	{object}  modal.editJob - Details about edit job price modal.
   * @property 	{boolean} modal.editJob.show - Boolean to check if modal show or not.
   * @property  {Number}  selectedInvoiceJobId - Selected job ID used for actions.
   * @property  {boolean} invoiceDetailChangesUnsaved - Boolean to check if there is changes unsaved in invoice details.
   */
  return {
    loading: {
      addPoNumber: false,
      addProductionCost: false,
      addDisbursementCost: false,
      invoiceOverview: false,
      editJob: false,
      invoiceDetails: false,
      creditList: false,
      invoiceList: false,
      linkInvoice: false,
    },
    invoices: {
      list: [],
      jobs: [],
      paid_with_credits: 0,
      total_invoiced: 0,
      remaining_to_be_invoiced: 0,
      invoice_order_detail: {
        account_manager_id: null,
        aim_of_video: '',
        all_videos_public: 0,
        allocated_production_hours: 0,
        brief: '',
        cancel_sent: 0,
        confirm_sent: 0,
        created_at: null,
        created_by: null,
        creative_approach: '',
        display_price: 0,
        google_drive_folder_id: null,
        hubspot_deal_id: null,
        id: null,
        invoice_contacts: [],
        invoice_entity_contact_email: '',
        invoice_entity_contact_name: '',
        invoice_entity_name: '',
        meta_data: '',
        name: '',
        office_id: null,
        order_invoice_status_id: null,
        order_invoicing_term_id: null,
        order_status_id: null,
        payment_term_id: null,
        producer_id: null,
        product_type_id: null,
        project_id: null,
        quote_id: null,
        quote_ref: null,
        reference_number: null,
        shoot_key_details: '',
        standard_inclusion: '',
        third_party_payment: 0,
        updated_at: null,
        updated_by: null,
      },
      invoiceContacts: [],
      credit_list: [],
    },
    modal: {
      addPoNumber: {
        show: false,
        orderId: null,
      },
      addProductionCost: {
        show: false,
      },
      addDisbursementCost: {
        show: false,
        productElements: [],
      },
      editJob: {
        show: false,
      },
      linkInvoice: {
        show: false,
        orderId: null,
      },
    },
    selectedInvoiceJobId: null,
    invoiceDetailChanged: false,
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.PROJECT_INVOICE_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
