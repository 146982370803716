import Axios from '@plugins/axios'

export default (projectId, payload) => {
  let url = `/api/v2/internal/projects/${projectId}/logs`

  let config = {
    headers: {},
    params: {},
  }

  if (payload.page) {
    config.params.page = payload.page
  }

  if (payload.perPage) {
    config.params.per_page = payload.perPage
  }

  if (payload.sortBy) {
    config.params.sort_by = payload.sortBy
  }

  if (payload.sort) {
    config.params.sort = payload.sort
  }

  if (payload.search) {
    config.params.search = payload.search
  }

  return Axios.get(url, config)
}
