import * as mutations from '@store/mutation-types'
import ProjectServices from '@services/services'
import { map } from 'lodash'
import VueCookies from 'vue-cookies'

/**
 * Store actions for projects module.
 * @module projects/projects-actions
 */
export default {
  /**
   * Reset state for current store module.
   */
  resetState({ commit }) {
    commit(mutations.PROJECTS_RESET_STATE)
  },

  /**
   * Get the list of all projects
   *
   * @param context
   * @param {object} payload - the payload used for retrieving the list of all projects
   */
  getAllProjects({ commit, dispatch, rootGetters }, payload) {
    commit(mutations.PROJECTS_LOADING_PROJECTS_LIST, true)

    const selectedStatuses = rootGetters['projects/selectedStatusesFilter']
    const selectedStatusIds = map(selectedStatuses.selectedStatuses, 'id').join(
      ','
    )

    const selectedProductTypes =
      rootGetters['projects/selectedProductTypeFilter'].selectedProductTypes
    const selectedProductTypeIds = selectedProductTypes.join(',')

    const selectedUsers = rootGetters['projects/selectedUserFilter']
    const selectedUserIds = map(selectedUsers.users, 'id').join(',')

    const showMyProjects = rootGetters['projects/isMyProjectFilterSelected']
    const search = rootGetters['projects/searchKeyword']
    const sortBy = rootGetters['projects/selectedSortBy']
    const sort = rootGetters['projects/selectedSort']
    const perPage = rootGetters['projects/selectedPerPage']

    const filterPayload = {
      productTypes: selectedProductTypeIds,
      status: selectedStatusIds,
      users: selectedUserIds,
      from: rootGetters['projects/selectedFromDate'],
      to: rootGetters['projects/selectedToDate'],
      is_my_projects: showMyProjects,
    }

    payload.search = search
    payload.sortBy = sortBy
    payload.sort = sort
    payload.perPage = perPage

    if (payload.filter) {
      if (payload.filter.productTypes) {
        filterPayload['productTypes'] = payload.filter.productTypes
      }

      if (payload.filter.users) {
        filterPayload['users'] = payload.filter.users
      }

      if (payload.filter.status) {
        filterPayload['status'] = payload.filter.status
      }

      if (payload.filter.from) {
        filterPayload['from'] = payload.filter.from
      }

      if (payload.filter.to) {
        filterPayload['to'] = payload.filter.to
      }

      if (payload.filter.is_my_projects) {
        filterPayload['is_my_projects'] = payload.filter.is_my_projects
      }
    }

    payload.filter = filterPayload

    ProjectServices.projects
      .list(payload)
      .then((response) => {
        commit(mutations.PROJECTS_LOADING_PROJECTS_LIST, false)
        commit(mutations.PROJECTS_LIST, response.data)
      })
      .catch((error) => {
        commit(mutations.PROJECTS_LOADING_PROJECTS_LIST, false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while getting all projects.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: payload,
          },
          { root: true }
        )
      })
  },

  /**
   * Set the selected project ID for updating
   *
   * @param context
   * @param {number} projectId - the selected project ID
   */
  setSelectedProjectId({ commit }, projectId) {
    commit(mutations.PROJECTS_SELECTED_ID, projectId)
  },

  /**
   * Set the selected project
   *
   * @param commit
   * @param {number} project - the selected project
   */
  setSelectedProject({ commit }, project) {
    commit(mutations.PROJECTS_UPDATE_SELECTED_PROJECT, project)
  },

  /**
   * Update the selected fields for inline field update
   *
   * @param context
   * @param {object} selectedFieldForUpdating - the payload for updating the array of selected fields for updating
   * @param {number} selectedFieldForUpdating.project_id - the selected project ID for the selected field
   * @param {string} selectedFieldForUpdating.field - the selected field for updating
   */
  setSelectedFieldsForUpdating({ commit }, selectedFieldForUpdating) {
    commit(mutations.PROJECTS_UPDATE_SELECTED_FIELDS, selectedFieldForUpdating)
  },

  /**
   * Remove selected field for inline field update that finished saving
   *
   * @param context
   * @param {object} selectedFieldForUpdating - the payload for updating the array of selected fields for updating
   * @param {number} selectedFieldForUpdating.project_id - the selected project ID for the selected field
   * @param {string} selectedFieldForUpdating.field - the selected field to remove
   */
  removeSelectedFieldsForUpdating({ commit }, selectedFieldForUpdating) {
    commit(mutations.PROJECTS_REMOVE_SELECTED_FIELDS, selectedFieldForUpdating)
  },

  /**
   * Function to update a single field for the selected project
   *
   * @param context
   * @param {object} payload - the payload used for updating a single field
   * @param {number} payload.projectId - the selected project ID to update
   * @param {array} payload.includes - array of include fields
   * @param {object} payload.payload - the payload used for processing the single field update
   */
  inLineUpdateProjectDetails({ dispatch }, payload) {
    dispatch('setSelectedProjectId', payload.projectId)

    dispatch('project/setEditProjectLoading', true, { root: true })

    dispatch('setSelectedFieldsForUpdating', payload.selectedField)

    ProjectServices.projects
      .inlineUpdate(payload.projectId, payload.includes, payload.payload)
      .then((response) => {
        dispatch('project/setEditProjectLoading', false, { root: true })
        dispatch('removeSelectedFieldsForUpdating', payload.selectedField)
        dispatch('setSelectedProject', response.data.data)
        dispatch(
          'common/displayToastAlert',
          {
            title: `${response.data.data.name} project was updated successfully!`,
          },
          { root: true }
        )

        dispatch('setSelectedProjectId', null)
      })
      .catch((error) => {
        dispatch('setSelectedProjectId', null)
        dispatch('project/setEditProjectLoading', false, { root: true })
        dispatch('removeSelectedFieldsForUpdating', payload.selectedField)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error while updating the project details inline.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: payload.projectId,
              include: payload.includes,
              payload: payload.payload,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Function to update a project health field for the selected project
   * @param context
   * @param dispatch
   * @param {number} projectId - the selected project ID to update
   */
  getProjectHealth({ commit, dispatch }, projectId) {
    dispatch('setProjectHealthLoading', true)

    ProjectServices.projects.health
      .list(projectId, [])
      .then((response) => {
        commit(mutations.PROJECTS_UPDATE_PROJECT_HEALTH, {
          id: projectId,
          health: response.data.data,
        })

        dispatch('setProjectHealthLoading', false)
      })
      .catch((error) => {
        dispatch('setProjectHealthLoading', false)
        dispatch('common/displayErrorMessage', error.response?.data, {
          root: true,
        })
        dispatch(
          'common/bugsnagNotify',
          {
            title: 'Error retrieving project health.',
            severity: 'error',
            error: error.response ? error.response : error.message,
            payload: {
              project_id: projectId,
            },
          },
          { root: true }
        )
      })
  },

  /**
   * Action to set the project health loading state
   * @param commit
   * @param {Boolean} loading - the loading state
   */
  setProjectHealthLoading({ commit }, loading) {
    commit(mutations.PROJECTS_LOADING_PROJECT_HEALTH, loading)
  },

  /**
   * Update the project status filter
   *
   * @param context
   * @param {array} statuses - the selected status array
   */
  updateProjectStatusFilter({ commit }, statuses) {
    commit(mutations.PROJECTS_LIST_FILTER_STATUSES_UPDATE, statuses)
  },

  /**
   * Update the product type filter
   *
   * @param context
   * @param {array} productTypes - the selected product type array
   */
  updateProjectProductTypeFilter({ commit }, productTypes) {
    commit(mutations.PROJECTS_LIST_FILTER_PRODUCT_TYPE_UPDATE, productTypes)
  },

  /**
   * Update the project user filter
   *
   * @param context
   * @param {array} users - the selected users array
   */
  updateProjectUserFilter({ commit }, users) {
    commit(mutations.PROJECTS_LIST_FILTER_USER_UPDATE, users)
  },

  /**
   * Update the project type filter
   *
   * @param context
   * @param {string} projectType - the selected project type. Options are all_projects and my_projects
   */
  updateProjectTypeFilter({ commit }, projectType) {
    commit(mutations.PROJECTS_LIST_FILTER_MY_PROJECTS, projectType)
  },

  /**
   * Update the project search filter
   *
   * @param context
   * @param {string} searchKeyword - the selected search keyword
   */
  updateProjectSearchKeyword({ commit }, searchKeyword) {
    commit(mutations.PROJECTS_LIST_FILTER_SEARCH, searchKeyword)
  },

  /**
   * Update the project sort filter
   *
   * @param context
   * @param {string} sort - the selected sort. Options are asc or desc
   */
  updateProjectSort({ commit }, sort) {
    commit(mutations.PROJECTS_LIST_FILTER_SORT, sort)
  },

  /**
   * Update the project sort by filter
   *
   * @param context
   * @param {string} sortBy - the selected sortBy field
   */
  updateProjectSortBy({ commit }, sortBy) {
    commit(mutations.PROJECTS_LIST_FILTER_SORT_BY, sortBy)
  },

  /**
   * Update the project from date filter
   *
   * @param context
   * @param {string} from - the selected from field
   */
  updateProjectFromDate({ commit }, from) {
    commit(mutations.PROJECTS_LIST_FILTER_FROM_DATE, from)
  },

  /**
   * Update the project to date filter
   *
   * @param context
   * @param {string} to - the selected to field
   */
  updateProjectToDate({ commit }, to) {
    commit(mutations.PROJECTS_LIST_FILTER_TO_DATE, to)
  },

  /**
   * Update the project per page
   *
   * @param context
   * @param {number} perPage - the selected per page
   */
  updateProjectPerPage({ commit }, perPage) {
    commit(mutations.PROJECTS_LIST_FILTER_PER_PAGE, perPage)
  },

  /**
   * Update the project current page
   * @param context
   * @param {number} currentPage - the selected page
   */
  updateCurrentPage({ commit }, currentPage) {
    commit(mutations.PROJECTS_LIST_CURRENT_PAGE, currentPage)
  },

  /**
   * Set Project Dashboard filters in cookies
   *
   * @param context
   * @param {object} payload - the payload used for setting the type and value of the project dashboard filter cookies
   * @param {string} payload.type - the selected filter type name
   * @param {mixed} payload.value - the selected filter value. Types can be a string, array, object, int
   */
  setProjectsFilterCookies({ dispatch, rootGetters }, payload) {
    const filters = {
      projectType: rootGetters['projects/selectedProjectType'],
      createdDate: rootGetters['projects/selectedCreatedDate'],
      users: rootGetters['projects/selectedUserFilterId'],
      statuses: rootGetters['projects/selectedStatusesFilterId'],
      productTypes: rootGetters['projects/selectedProductTypeFilterId'],
      perPage: rootGetters['projects/selectedPerPage'],
      sort: rootGetters['projects/selectedSortFilter'],
    }

    filters[payload.type] = payload.value

    // reset the current page to 1 when the filter is changed
    dispatch('updateCurrentPage', 1)

    // save filters to cookies
    VueCookies.set('vmsx-dashboard-filters', filters, '7d')
  },

  /**
   * Set the Archived Projects
   *
   * @param context
   * @param {object} data - the archived projects data
   */
  setArchivedProjects({ commit }, data) {
    commit(mutations.PROJECTS_ARCHIVED_PROJECTS, data)
  },
}
