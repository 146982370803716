import Axios from '@plugins/axios'

export default () => {
  let url = `/api/v2/internal/product-types`

  let config = {
    headers: {},
    params: {},
  }

  return Axios.get(url, config)
}
