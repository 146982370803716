/**
 * Application specific configuration and constants.
 * @property {String} vmsApiUrl
 */
export default Object.freeze({
  vmsApiUrl: process.env.VUE_APP_VMS_URL,
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  bugsnagToken: process.env.VUE_APP_BUGSNAG_TOKEN,
  defaultTimezone: 'Australia/Melbourne',
})
