import actions from './role-actions'
import getters from './role-getters'
import mutations from './role-mutations'
import * as types from '@store/mutation-types'
import { merge } from 'lodash'

/**
 * @module store/role
 */

/**
 * Get default state for role module.
 * Keep this structure up to date with any changes to state.
 * @function initialState
 */
const initialState = () => {
  /**
   * @property {object}   loading - States for loaders within the role module.
   * @property {boolean}  loading.externalRoles - State for the external roles list loader
   * @property {array}    externalRoles - Array of objects of available external roles
   */
  return {
    loading: {
      externalRoles: false,
    },
    externalRoles: [],
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations: merge(mutations, {
    /**
     * Reset current state to initial state.
     * @param {object} state - State for current module.
     */
    [types.ROLE_RESET_STATE](state) {
      Object.assign(state, initialState())
    },
  }),
  state: initialState(),
}
