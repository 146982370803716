import * as types from '@store/mutation-types'

/**
 * @module store/media-manager/media-manager-mutations
 */
export default {
  /**
   * Update loader for the project list
   *
   * @param {object} state - state of the current module
   * @param {boolean} loading - the loader state
   */
  [types.MEDIA_MANAGER_ARCHIVED_PROJECTS_LOADING](state, loading) {
    state.loading.archivedProjects = loading
  },

  /**
   * Update selected project
   * @param {Object} state - state of the current module
   * @param {Object} project - the selected project
   */
  [types.MEDIA_MANAGER_SELECTED_ARCHIVED_PROJECT](state, project) {
    state.selectedArchivedProject = project
  },

  /**
   * Update the show state of the action archived project modal
   * @param {Object} state - state of the current module
   */
  [types.MEDIA_MANAGER_MODAL_ARCHIVE_PROJECT_FOOTAGE_SHOW](state) {
    state.modal.archiveProjectFootage.show = !state.modal.archiveProjectFootage
      .show
  },

  /**
   * Update the loading state of the action archived project
   * @param {Object} state - state of the current module
   * @param {Boolean} loading - the loading state of the action archived project
   */
  [types.MEDIA_MANAGER_LOADING_ARCHIVE_PROJECT_FOOTAGE](state, loading) {
    state.loading.archiveProjectFootage = loading
  },
}
